import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import {
  checkIntegrationLightSpeed,
  createRemouteRulsLightSpeedLocations, setCustomFieldsLightSpeedLocations,
  setDiscountProductLightSpeed, setLoyaltyProductLightSpeedLocations, setWebhookLightSpeedLocations
} from "../../api/lightSpeed";

// @ts-ignore
const ConfirmLightSpeed = () => {
  const [message, setMessage] = useState('')
  useEffect(() => {
    checkIntegrationLightSpeed(window.location.search).then(res => {
      setMessage(res.data.message)
      setDiscountProductLightSpeed()
      createRemouteRulsLightSpeedLocations()
      setWebhookLightSpeedLocations()
      setCustomFieldsLightSpeedLocations()
      setLoyaltyProductLightSpeedLocations()
    }).catch(err => {
      console.log(err);
      setMessage(err.data.message)
    })
  }, [])


  return (
    <Wrapper>
      <div className="response">
        {message}
      </div>

    </Wrapper>
  );
};

export default ConfirmLightSpeed;
