import React from "react";

import checkEmailImg from "src/assets/img/check-your-email.svg";

import { Wrapper } from "./styled";

const RegistrationAccountCheckEmail: React.FC = () => {
  return (
    <Wrapper>
      <div className="check-email-content">
        <img src={checkEmailImg} alt="" />
        <p className="title">Please check your emails for account approval</p>
      </div>
    </Wrapper>
  );
};

export default RegistrationAccountCheckEmail;
