import { SelectorListObj } from "../../../../components/Select/types";

const workHours: Array<SelectorListObj> = [];

for (let hour = 0; hour < 24; hour += 1) {
  for (let min = 0; min < 60; min += 30) {
    workHours.push({
      label: `${String(hour).padStart(2, "0")}:${String(min).padStart(2, "0")}`,
      value: `${String(hour).padStart(2, "0")}:${String(min).padStart(2, "0")}`,
    });
  }
}

export default workHours;
