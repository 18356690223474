import React, { FC} from "react";
import DataTable from "react-data-table-component";

import { Wrapper } from "./styled";

const OrderItem: FC = () => {
  const [customerListLoading, setCustomerListLoading] =
    React.useState<boolean>(false);

  const testOrderData = [
    {
    id: 1,
    order_name: '#0001',
    date: '19 Nov 2022',
    customer: 'Sebastian Stan',
    location: 'Oranmore'
  },{
    id: 2,
    order_name: '#0002',
    date: '19 Nov 2022',
    customer: 'Sebastian Stan',
    location: 'Oranmore'
  },{
    id: 3,
    order_name: '#0003',
    date: '19 Nov 2022',
    customer: 'Sebastian Stan',
    location: 'Oranmore'
  },{
    id: 4,
    order_name: '#0004',
    date: '19 Nov 2022',
    customer: 'Sebastian Stan',
    location: 'Oranmore'
  },
  ]

  const OrderListColumns = [
    {
      name: "Order",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.order_name}
          </div>
        );
      },
    },
    {
      name: "Date",
      minWidth: "225px",
      cell: (row: any): JSX.Element => {
        return <div>{row.date}</div>;
      },
    },
    {
      name: "Customer",
      minWidth: "175px",
      cell: (row: any): JSX.Element => {
        return <div>{row.customer}</div>;
      },
    },
    {
      name: "Location",
      minWidth: "120px",
      cell: (row: any): JSX.Element => {
        // let neeFormat = row.last_visit.replace(' ', 'T')
        return (
          <div>
            {/*{row.last_visit*/}
            {/*  ? format(new Date(neeFormat), "dd/MM/yyyy, HH:mm:ss")*/}
            {/*  : "-"}*/}
            {row.location}
          </div>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <div className="page-title">Orders</div>
      <div
        className={`table-wrap ${customerListLoading ? "loading-blue" : ""}`}
      >
        <div className="loading-innerwrap">
          {testOrderData.length > 0 ? (
            <>
              <div className="table">
                <DataTable
                  noHeader
                  columns={OrderListColumns}
                  data={testOrderData}
                  // expandableRows
                  // expandableRowsComponent={
                  //   <ExpandableTableList data={customerList} getCustomerList={getCustomerList}/>
                  // }
                />
              </div>
            </>
          ) : (
            <div className="nothing-found">Sorry, nothing found</div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default OrderItem;
