import axios from "./api";

export const getPromotionsCategoriesAxios = (): Promise<any> =>
  axios
    .get("/api/get-promotions")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
