import React from "react";

import { useTypedSelector } from "src/hooks/useTypedSelector";

import Sidebar from "src/components/Sidebar";
import Header from "src/components/Header";

import * as Types from "./types";
import { Wrapper } from "./styled";

const AdminWrapper: React.FC<Types.Props> = ({ children }) => {
  const { loading } = useTypedSelector((state) => state.auth);

  return (
    <Wrapper
      className={`${loading ? "loading-blue main-wrapper" : "main-wrapper"}`}
    >
      <Sidebar />
      <div className="main-panel">
        <Header />
        <div className="page-content">{children}</div>
      </div>
    </Wrapper>
  );
};

export default AdminWrapper;
