import React, { FC, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ReactComponent as DeleteIcon } from "../../../assets/img/delete.svg";
import { WrapperTableList } from "./styled";
import { getSataffFamily, removeSataffFamily } from "src/api/customers";

interface IProps {
  data: any;
  getCustomerList: any;
}

const StaffFamilyTable: FC<IProps> = ({ data, getCustomerList }) => {
  const [userInfo, setUserInfo] = useState([]);
  const getUserPointInfo = (id: string) => {
    getSataffFamily({ id }).then((response) => {
      setUserInfo(response.data.worker_relations);
    });
  };

  useEffect(() => {
    getUserPointInfo(data.id);
  }, [data.id]);

  function deleteLocationStaffAsk(id: any) {
    removeSataffFamily({ id })
      .then((res) => {
        getUserPointInfo(data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const staffMembersTableColumns = [
    {
      name: "First Name",
      minWidth: "150px",
      cell: (row: any) => {
        return <div>{row.first_name}</div>;
      },
    },
    {
      name: "Last Name",
      minWidth: "100px",
      maxWidth: "150px",
      cell: (row: any) => {
        return <div>{row.last_name}</div>;
      },
    },
    {
      name: "GC Number",
      minWidth: "50px",
      cell: (row: any) => {
        return <div>{row.gc_number}</div>;
      },
    },
    {
      name: "Email Address",
      minWidth: "275px",
      cell: (row: any) => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Store",
      minWidth: "100px",
      cell: (row: any) => {
        return <div>{row.store}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "100px",
      cell: (row: any) => {
        return <div>{row.fake_data ? "" : row.phone}</div>;
      },
    },
    {
      name: "",
      minWidth: "50px",
      cell: (row: any) => {
        return (
          <div className="action">
            {
              <button
                type="button"
                className="delete"
                onClick={() => deleteLocationStaffAsk(row.id)}
              >
                <DeleteIcon />
              </button>
            }
          </div>
        );
      },
    },
  ];

  return (
    <WrapperTableList>
      <DataTable data={userInfo} columns={staffMembersTableColumns} noHeader />
    </WrapperTableList>
  );
};

export default StaffFamilyTable;
