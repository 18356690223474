import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import InputGoogleAutocomplete from "src/components/InputGoogleAutocomplete";
import Input from "src/components/Input";

import { Wrapper } from "./styled";
import { getPlaceName, createFirstLocation } from "./api";

const RegistrationLocations: React.FC<{
  accountInformationToken: string | null;
  goToStep: (step: number) => void;
}> = ({ accountInformationToken, goToStep }) => {
  const [formErrorMessage, setFormErrorMessage] = useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [locations, setLocations] = useState<
    {
      id: number;
      address: string;
      latitude: number;
      longitude: number;
      location_name: string;
      phone: string;
    }[]
  >([
    {
      id: 1,
      address: "",
      location_name: "",
      latitude: NaN,
      longitude: NaN,
      phone: "",
    },
  ]);
  const setValue = (type: string, value: any, id: number) => {
    setFormErrorMessage({});
    setLocations((prevLocations) =>
      prevLocations.map((location) =>
        location.id === id
          ? {
              ...location,
              [type]: value,
            }
          : location
      )
    );
  };

  const [placeName, setPlaceName] = useState<string>();

  useEffect(() => {
    if (accountInformationToken && !placeName) {
      getPlaceName(accountInformationToken).then((response) => {
        setPlaceName(response.data.name);
      });
    }
  });
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (accountInformationToken) {
      setIsLoading(true);
      createFirstLocation(locations[0], accountInformationToken)
        .then(() => {
          setIsLoading(false);
          goToStep(9);
        })
        .catch((error) => {
          setIsLoading(false);
          setFormErrorMessage(error.data.errors);
        });
    }
  };

  return (
    <Wrapper>
      <div className="registration_locations">
        <h1 className="location_title">Your Business Locations</h1>
        <p className="location_text">
          Please Enter Your First Business Location to start using Lynked.
        </p>
        <form
          onSubmit={handleSubmit}
          className={`location_form lynked-form ${
            isLoading ? "loading-blue" : ""
          }`}
        >
          {locations.map((location) => (
            <div key={location.id} className="form_group two_items">
              <div className="autocomplete_wrapper">
                <InputGoogleAutocomplete
                  label="Add your first location"
                  placeholder="Please enter location"
                  infoTooltip="Enter the address of your business premises, this will allow Lynked app users find you via the in-app map, and help grow your customer base"
                  required
                  getValue={(value) => setValue("address", value, location.id)}
                  getLat={(value) => setValue("latitude", value, location.id)}
                  getLng={(value) => setValue("longitude", value, location.id)}
                />
                {formErrorMessage?.address ? (
                  <div className="form-error-message">
                    {formErrorMessage.address}
                  </div>
                ) : null}
                {formErrorMessage?.latitude ? (
                  <div className="form-error-message">
                    {formErrorMessage.latitude}
                  </div>
                ) : null}
                {formErrorMessage?.longitude ? (
                  <div className="form-error-message">
                    {formErrorMessage.longitude}
                  </div>
                ) : null}
              </div>
              <div className="input_wrapper">
                <Input
                  label="Location Name"
                  infoTooltip="Please enter the name of your location at this address. i.e. “Lynked Grafton Street“"
                  defaultValue={location.location_name}
                  placeholder={placeName}
                  getValue={(value) =>
                    setValue("location_name", value, location.id)
                  }
                  error={formErrorMessage.location_name}
                />
              </div>
              <div className="phone-wrap">
                <p className="phone_input_label">Phone number</p>
                <PhoneInput
                  containerClass="input-phone"
                  country="us"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    minLength: 6,
                  }}
                  onChange={(value) => {
                    setValue("phone", value, location.id);
                  }}
                />
              </div>
            </div>
          ))}
          {formErrorMessage?.token ? (
            <div className="form-error-message">{formErrorMessage.token}</div>
          ) : null}
          <button type="submit" className="btn-blue">
            Continue
          </button>
        </form>
      </div>
    </Wrapper>
  );
};

export default RegistrationLocations;
