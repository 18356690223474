import React, { useEffect } from "react";
import { getBusinessPlansAxios } from "src/api/businessPlan";
import { BusinessPlanObj } from "src/types/subscription";
import { ReactComponent as LitePlanIcon } from "src/assets/img/lite-plan-icon.svg";
import { ReactComponent as PlusPlanIcon } from "src/assets/img/plus-plan-icon.svg";
import { ReactComponent as PrimiumPlanIcon } from "src/assets/img/premium-plan-icon.svg";
import { ReactComponent as CheckCircle } from "src/assets/img/checkCircle.svg";

import { Wrapper } from "./styled";
import * as Const from "./const";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

const RegistrationChoosePlan: React.FC<{
  goToStep: (step: number) => void;
  setBusinessPlanPersonalInfo: (businessPlanPersonalInfo: string) => void;
}> = ({ goToStep, setBusinessPlanPersonalInfo }) => {
  const [businessPlansList, setBusinessPlansList] = React.useState<
    Array<BusinessPlanObj>
  >([]);
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const [periodType, setPeriodType] = React.useState<"monthly" | "yearly">(
    Const.PeriodTypeMonthly
  );

  const [isBusinessPlanChanged, setIsBusinessPlanChanged] =
    React.useState<boolean>(Const.businessPlanChanged);

  const [businessPlan, setBusinessPlan] = React.useState<string>();

  useEffect(() => {
    getBusinessPlansAxios().then(({ data }) => {
      setBusinessPlansList(data.subscriptions.reverse());
      if (data.subscriptions) {
        const lastPlan = data.subscriptions[data.subscriptions.length - 1];
        setBusinessPlan(lastPlan.price_id);
      }
    });
  }, []);

  return (
    <Wrapper>
      <div className="inner-wrap">
        {/* <div className="header">
          <div className="logo">
            <LogoFull />
          </div>
          <div className="period-type">
            <label htmlFor="periodTypeMonthly" className="periodTypeMonthly">
              <input
                type="radio"
                id="periodTypeMonthly"
                name="periodType"
                value="monthly"
                checked={periodType === "monthly"}
                onChange={() => setPeriodType("monthly")}
              />
              <span>Monthly</span>
            </label>
            <label htmlFor="periodTypeYearly" className="periodTypeYearly">
              <input
                type="radio"
                id="periodTypeYearly"
                name="periodType"
                value="yearly"
                disabled
                checked={periodType === "yearly"}
                onChange={() => setPeriodType("yearly")}
              />
              <span>Yearly</span>
            </label>
          </div>
        </div> */}
        <div className="title-block">
          <div className="title">Choose a Plan For Your Business</div>
          <div className="sub-title">
            Starting at {loggedUser.user.currency_symbol || '€'}3.75 a week, Lynked is the worlds most accessible
            loyalty option for business owners!
          </div>
        </div>
        {/* <div className="discount_wrapper">
          <div className="discount_input">
            <Input
              label="Have a discount code?"
              infoTooltip="Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo"
            />
          </div>
          <div className="switcher_wrapper">
            <Switcher
              isChecked={!(periodType === "monthly")}
              labelL="Monthly"
              labelR="Yearly"
              onChange={(value) => {
                if (value) {
                  setPeriodType("yearly");
                } else {
                  setPeriodType("monthly");
                }
              }}
            />
          </div>
        </div> */}
        <div className="test">
          <div
            className={`plants_wrapper ${
              !businessPlansList.length ? "loading-blue" : ""
            }`}
          >
            <div className="row_label_column">
              <div className="pricing_label_wrapper">
                <p className="pricing_label">Pricing</p>
                <span className="pricing_label_text">
                  Ut enim ad minim veniam, quis nostrud exercitation ulla
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </span>
              </div>
              {Const.PLANT_LABELS.map(({ label }) => (
                <p key={label} className="label_name">
                  {label}
                </p>
              ))}
              <div className="empty_label" />
            </div>
            {businessPlansList &&
              businessPlansList.map((plan) => (
                <div
                  key={plan.id}
                  className={
                    businessPlan === plan.price_id
                      ? "row_plan_item recommended_item"
                      : "row_plan_item"
                  }
                >
                  <div className="plan_header">
                    <div className="plan_header_top">
                      <div className="plan_icon_wrapper">
                        {plan.name.toLowerCase() === "lite" ? (
                          <LitePlanIcon />
                        ) : plan.name.toLowerCase() === "plus" ? (
                          <PlusPlanIcon />
                        ) : plan.name.toLowerCase() === "premium" ? (
                          <PrimiumPlanIcon />
                        ) : null}
                      </div>
                      {plan.name}
                    </div>
                    <p className="plan_desc">{plan.description}</p>
                    <p className="plan_price_wrapper">
                      <span className="plan_price">{loggedUser.user.currency_symbol || '€'}{plan.price}</span>
                      <span className="plan_period">
                        {periodType === "monthly" ? "/mo" : "/ye"}
                      </span>
                    </p>
                    <button
                      type="button"
                      className={`${
                        businessPlan === plan.price_id
                          ? "btn-blue"
                          : "btn-blue-outline"
                      }`}
                      onClick={() => {
                        setIsBusinessPlanChanged(true);
                        setBusinessPlan(plan.price_id);
                        setBusinessPlanPersonalInfo(plan.price_id);
                      }}
                    >
                      Choose Plan
                    </button>
                  </div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">{plan.count_filters}</div>
                  <div className="plan_access">{plan.count_locations}</div>
                  <div className="plan_access">{plan.count_staff}</div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">
                    <CheckCircle />
                  </div>
                  <div className="plan_access">{plan.count_campaigns}</div>
                  <div className="plan_access">
                    {plan.count_push_notification}
                  </div>
                  <div className="plan_access">{plan.count_text_campaigns}</div>
                  <div className="plan_access">Coming Soon</div>
                  <div className="btn_wrapper">
                    <button
                      type="button"
                      className={`${
                        businessPlan === plan.price_id
                          ? "btn-blue"
                          : "btn-blue-outline"
                      }`}
                      onClick={() => {
                        setIsBusinessPlanChanged(true);
                        setBusinessPlan(plan.price_id);
                        setBusinessPlanPersonalInfo(plan.price_id);
                      }}
                    >
                      Choose Plan
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* <div
          className={`plans-row ${
            businessPlansList.length < 1 ? "loading-white" : ""
          }`}
        >
          {businessPlansList &&
            businessPlansList.map((item) => (
              <label
                className={`plan-item ${item.name.toLowerCase()} ${
                  businessPlan === item.price_id ? "checked" : ""
                }`}
                htmlFor={`plan${item.name}`}
                key={item.price_id}
              >
                <input
                  type="radio"
                  id={`plan${item.name}`}
                  name="planItem"
                  value={item.price_id}
                  checked={businessPlan === item.price_id}
                  onChange={() => {
                    setBusinessPlan(item.price_id);
                    setBusinessPlanPersonalInfo(item.price_id);
                  }}
                />
                <div className={`plan-item-inner ${item.name.toLowerCase()}`}>
                  <div className="plan-item-content-wrap">
                    <div className="icon-name">
                      <div className="icon">
                        {item.name.toLowerCase() === "lite" ? (
                          <LitePlanIcon />
                        ) : item.name.toLowerCase() === "plus" ? (
                          <PlusPlanIcon />
                        ) : item.name.toLowerCase() === "premium" ? (
                          <PrimiumPlanIcon />
                        ) : null}
                      </div>
                      <div className="name">{item.name}</div>
                    </div>
                    <div className="price">
                      <span className="amoutn">€{item.price}</span>
                      <span className="period">/ per month</span>
                    </div>
                    <ul className="description">
                      <li>Points based loyalty</li>
                      <li>Access to dedicated portal</li>
                      <li>Access to dedicated portal</li>
                      {item.count_push_notification > 0 && (
                        <li>
                          {item.count_push_notification} in-app push
                          notifications
                        </li>
                      )}
                      {item.count_campaigns > 0 && (
                        <li>{item.count_campaigns} email campaigns</li>
                      )}
                      {item.count_text_campaigns > 0 && (
                        <li>{item.count_text_campaigns} text campaigns</li>
                      )}
                      {item.count_locations > 0 ? (
                        <li>{item.count_locations} locations</li>
                      ) : item.count_locations < 0 ? (
                        <li>All locations</li>
                      ) : null}
                      {item.count_filters > 0 ? (
                        <li>{item.count_filters} filters in customer groups</li>
                      ) : item.count_filters < 0 ? (
                        <li>All filters in customer groups</li>
                      ) : null}
                    </ul>
                  </div>
                  <button
                    className="choose-plan"
                    type="button"
                    onClick={() => {
                      setBusinessPlan(item.price_id);
                      setBusinessPlanPersonalInfo(item.price_id);
                    }}
                  >
                    Choose Plan
                  </button>
                </div>
              </label>
            ))}
        </div> */}
        <div className="btn-wrap-create-account">
          <button
            type="submit"
            className="btn-blue"
            onClick={() => {
              setBusinessPlanPersonalInfo(businessPlan!);
              goToStep(3);
            }}
          >
            Create Account
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default RegistrationChoosePlan;
