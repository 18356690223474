export const filterPerDate = [
  {
    value: "7",
    label: "Last 7 days",
  },
  {
    value: "1",
    label: "Today",
  },
  {
    value: "30",
    label: "Last 30 days",
  },
  {
    value: "365",
    label: "Last 365 days",
  },
];
