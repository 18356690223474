import React from "react";

import { Wrapper } from "./style";

interface ICheckbox {
  id?: string;
  label?: string | React.ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: (isChecked: boolean) => void;
  theme?: "darkBlue";
}

const Checkbox: React.FC<ICheckbox> = ({
  id,
  label = "",
  isChecked,
  isDisabled = false,
  theme = "darkBlue",
  onChange,
}) => {
  const themeCN = {
    darkBlue: "check_box_darkBlue",
  }[theme];

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <Wrapper>
      <input
        id={id}
        className="check_input"
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        onChange={handleCheck}
      />
      <span className={`check_box ${themeCN}`} />
      <p className="label_checkbox">{label}</p>
    </Wrapper>
  );
};

export default Checkbox;
