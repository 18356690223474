import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  
  .category-list{
    
    .table {
      width: 100%;
      
      &>div{
        overflow-y: initial;
        padding-bottom: 30px;
        &::-webkit-scrollbar {
          height: 12px;
          cursor: pointer;
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          background: ${Color.blueBackground};
          border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: ${Color.blue};
          border-radius: 3px;
        }
      }
      .rdt_Table{
        background-color: transparent;
        width: 100%;
      }
      
      .rdt_TableRow{
        padding: 10px 0;
        align-items: center;
        position: relative;
        
        .sc-idiyUo {
          position: absolute;
          z-index: 9999;
          left: -10px;
        }
        
        .rdt_TableCell{
          padding-left: 0;
          margin-right: 6px;
          
          .lock-icon-wrap{
            bottom: 10px;
            right: 0;
          }
          
          .isSelect{
            margin-bottom: 0;
            
            input{
              width: 75%;
              border-radius: 4px;
              margin: 0 2px 2px;
              
              &:disabled{
                width: 88.5%;
              }
            }
          }

          &>div{
            width: 100%;
            padding-bottom: 0;
            input{
              width: 75%;
            }
          }

          
          @media (max-width: 1440px) {
            .isSelect{

              input{
                width: 65%;

                &:disabled{
                  width: 84.5%;
                }
              }
            }

            &>div{
              input{
                width: 65%;
              }
            }
          }

          @media (max-width: 1200px) {
            .isSelect{

              input{
                width: 45%;

                &:disabled{
                  width: 75.5%;
                }
              }
            }

            &>div{
              input{
                width: 45%;
              }
            }
          }
          &:nth-last-child(1){
            align-items: start;
            .buttons{
              display: flex;
              justify-content: space-around;
              align-items: start;
              button {
                padding: 5px 12px;
                height: 46px;
                display: flex;
                justify-content: center;
                align-items: center;
                .icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }

            }
          }
          
        }
      }
      .rdt_ExpanderRow{
          .sc-ikZpkk {
            overflow: visible;
          }
      }
        
      
    }
    .table-edit{
      &>div{
        padding-bottom: 82px;
      }

    }
    
    .modal-content{
      text-align: center;
      padding: 60px 50px;
      @media (max-width: 600px) {
        padding: 30px 15px;
      }
      .title {
        color: ${Color.blueDarkText};
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        @media (max-width: 600px) {
          font-size: 23px;
          line-height: 28px;
        }
      }
      .sub-title{
        color: ${Color.blueDarkText};
        font-size: 25px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        @media (max-width: 600px) {
          font-size: 23px;
          line-height: 28px;
        }
      }
    }
    
    
  }
  
`;

export { Wrapper };
