import { AccountInformationInterface } from "../../../../types/registration";
import axios from "../../../../api/api";
import { RequestFormData } from "../../../../utils/requestFormData";

export const registerYourCompany = (
  data: AccountInformationInterface,
  accountInformationToken: string,
  acceptedFiles: File[]
): Promise<any> => {
  const formData = new RequestFormData();

  formData.append("details", data.rewardInformation);
  formData.append("name", data.companyName);
  formData.append("first_checkIn", data.first_checkIn.toString() ?? '0');
  formData.append("number_promotions", data.maxNumberPromotion.toString());
  formData.append("business_type", data.businessType.toString());
  formData.append("promotion_type", data.promotionType.toString());
  formData.append("stamp_icon", data.stampIcon.toString());
  formData.append("promotion_label", data.promotionLabel);
  formData.append("image", acceptedFiles[0]);
  formData.append("token", accountInformationToken);

  formData.appendArray("points", data.rewards);

  return axios
    .post("/public/register-company", formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getBusinessTypesAxios = (): Promise<any> =>
  axios
    .get("/public/business-type")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getStampIconsAxios = (): Promise<any> =>
  axios
    .get("/public/stamp-icon")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getActivateDaysAxios = (): Promise<any> =>
  axios
    .get("/public/activate-days")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
