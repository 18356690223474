import { Color } from "src/types/color";
import styled from "styled-components";

export const Wrapper = styled.div`
  .top-bar div.search-wrap {
    margin-left: 0;
  }
  .form-file{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px
  }
  .staff-top-bar{
    justify-content: start;
  }
  .staff-add-btn{
    margin-left: 25px;
  }
  .hide{
    display: none;
  }
  .table {
    & > div {
      // overflow: unset;
    }

    

    div.rdt_TableRow {

      & input {
        width:75%
      }
    }
    div:first-child {
      &::-webkit-scrollbar {
        height: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${Color.white};
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: ${Color.blueLight};
        }

        &::-webkit-scrollbar-thumb:hover {
          background: ${Color.blueText};
        }
      }
    }
    
  }
`;