import styled, {keyframes} from 'styled-components';
import { Color } from "src/types/color";

const uploadKeyFrames = keyframes`
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
`;
const Wrapper = styled.div`
    .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        margin-bottom: 20px;

        .column__title {
            width: 15%;
            font-size: 20px;
            color: ${Color.blueDarkText};
            font-weight: 500;
        }
    }

    .main__item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        

        .item__wrapper {
            width: 100%;
            gap: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .buttons{
                width: 10%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button{
                    width: 45%;
                    padding: 7.5px 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .save-btn{
                    width: 100%;
                    padding: 9.5px 10px;
                }
            }
            .column{
                width: 15%;
                .groups-wrap{
                    margin-bottom: 10px;
                    .calendar-wrapper{
                        input {
                            width: 77%;
                        }
                    }
                    .react-datepicker-popper{
                        z-index: 10;
                    }
                }
                
            }
            .big_box{
                width: 40%;
            }
            .selectImg {
                height: 150px;
                border-radius: 8px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                
                .dropZone {
                    display: flex;
                    flex-direction: column;
                    height: 100%;

                    &.with-photo {
                        & > div {
                            .dropZone-content-text {
                                opacity: 0;
                                transition: 0.5s all ease;
                            }
                        }

                        &:hover {
                            & > div {
                                .dropZone-content-text {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    & > div {
                        position: relative;
                        height: 100%;
                        outline: none;
                        cursor: pointer;

                        &.active_true {
                            border-color: ${Color.blueDark};
                            box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 35%);
                        }

                        &.active_false {
                            min-height: 150px;
                        }

                        input {
                            max-width: 100%;
                            display: block !important;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            margin: auto;
                            opacity: 0;
                            z-index: -1;
                        }

                        .img {
                            line-height: 0;
                        }

                        .dropZone-content-text {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            background-color: rgba(255, 255, 255, 0.85);

                            border: 1.5px dashed ${Color.blueDark};
                            border-radius: 4px;
                            outline: none;
                            padding: 30px;
                            cursor: pointer;
                            transition: all 0.225s cubic-bezier(0.77, 0.2, 0.05, 1);

                            & > svg {
                                margin-bottom: 5px;
                                animation: ${uploadKeyFrames} 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

                                path[fill-rule="evenodd"] {
                                    fill: ${Color.blueDark};
                                }
                            }

                            .text {
                                color: ${Color.blueDark};
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;

                                span {
                                    margin-bottom: 5px;
                                }

                                .title {
                                    font-size: 18px;
                                    font-weight: 300;
                                    line-height: 23px;
                                }

                                .description {
                                    opacity: 0.6;
                                    font-size: 12px;
                                    font-weight: 300;
                                    line-height: 15px;
                                }

                                .file-name {
                                    font-size: 21px;
                                    font-weight: 300;
                                    line-height: 23px;
                                }
                            }
                        }
                    }
                }

                .img {
                    height: 100%;
                    img {
                        max-width: 100%;
                        width: 100%;
                        max-height: 100%;
                        height: 100%;
                    }
                }
            }
        }
        
        .nothing_found{
            width: 100%;
            height: 20vh;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 700;
        }
        

    }

    .add-partner {
        margin-top: 30px;
        display: flex;
        align-items: center;

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
        
    }
    .move-column{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 15px;
        .move-btn{
            width: 65%;
            min-width: 155px;
            display: flex;
            justify-content: flex-start;
            .icon-revert {
                svg{
                    transform: rotate(180deg);
                }

            }
        }
    }
    
    
`;

export { Wrapper }
