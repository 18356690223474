import React from 'react';
import {customerPerPage} from 'src/types/customer';
import {ReactComponent as ArrowBackIcon} from "../../assets/img/arrowBack.svg";
import {ReactComponent as ArrowForwardIcon} from "../../assets/img/arrowForward.svg";
import Select from "../Select";

interface IProps {
    page: number;
    total_pages_count: number;
    limit: number;
    count_users: number;
    total_users_count: number;
    requestLimit: string;
    setRequestParamtrs: (arg: any) => void;
}

const Pagination = ({setRequestParamtrs, page, total_pages_count, limit, count_users, total_users_count, requestLimit}: IProps) => {

    const handleChangeRequestParamtrs = (
        name: string,
        value: string | number
    ) => {
        setRequestParamtrs((prevState: any) => {
            return {...prevState, [name]: value};
        });
    };

    return (
        <div className="paginate-prepage">
            <div className="paginate">
                <button
                    className="prev-page"
                    type="button"
                    disabled={page <= 1}
                    onClick={() => handleChangeRequestParamtrs("page", page - 1)}
                >
                    <ArrowBackIcon/>
                </button>
                <button
                    className="next-page"
                    type="button"
                    disabled={total_pages_count <= page}
                    onClick={() => handleChangeRequestParamtrs("page", page + 1)}
                >
                    <ArrowForwardIcon/>
                </button>
                <div className="text">{(page * limit - limit) + 1} - {page * limit}{" "}
                    from {total_users_count}
                </div>
            </div>
            <div className="perpage">
                <div className="text">Show on page:</div>
                <Select
                    selectorList={customerPerPage}
                    defaultValue={{
                        value: requestLimit,
                        label: requestLimit,
                    }}
                    positionForShow="top"
                    required
                    getValue={(value) => {
                        handleChangeRequestParamtrs("limit", value);
                        handleChangeRequestParamtrs("page", "1");
                    }}
                    hideSelectorFilter
                />
            </div>
        </div>
    );
};

export default Pagination;
