import React, { FC, useState, useRef } from "react";
import { Doughnut, Line } from "react-chartjs-2";

import { Color } from "src/types/color";
import { AGE_AXIS_COLORS } from "./const";
import { Wrapper } from "./styled";
import { Props, IActiveChart } from "./types";

const DashboardChart: FC<Props> = ({
  promotionType,
  customers,
  salesStatistic,
  dateFilter,
  endDate,
  statisticByLocation,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [activeChart, setActiveChart] = useState<IActiveChart>("Customers");

  const handleChangeActiveChart = (chartName: IActiveChart) => {
    setActiveChart(chartName);
    // todo
    setTimeout(() => {
      chartRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 200);
  };

  const AGE_LABEL = Object.keys(statisticByLocation[0]?.age_groups[0] ?? {});

  return (
    <Wrapper>
      <div className="nav-subpages">
        <div
          className={`nav-subpage ${
            activeChart === "Customers" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Customers")}
          >
            Customers
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Sales" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Sales")}
          >
            {promotionType === "Stamp" ? "Stamps" : "Points"}
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Gender" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Gender")}
          >
            Gender
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Age" ? "active-page" : ""
          }`}
        >
          <button type="button" onClick={() => handleChangeActiveChart("Age")}>
            Age
          </button>
        </div>
      </div>
      <div ref={chartRef} className="chart-wrapper">
        <div className="chart">
          {activeChart === "Gender" ? (
            <>
              <div className="doughnut_legend">
                <span className="legend_item">Males</span>
                <span className="legend_item">Females</span>
              </div>
              <div className="doughnut_items_wrapper">
                {statisticByLocation.length ? (
                  statisticByLocation.map(
                    ({ location_id, location_name, gender_count }) => (
                      <div key={location_id} className="doughnut_item_wrapper">
                        <Doughnut
                          // type="doughnut"
                          data={{
                            labels: ["Female", "Male"],
                            datasets: [
                              {
                                data: gender_count,
                                backgroundColor: [
                                  Color.blueLightMedium,
                                  Color.blueDark,
                                ],
                                borderColor: ["white", "white"],
                                borderWidth: 2,
                              },
                            ],
                          }}
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                        />
                        <span className="doughnut_location">
                          {location_name}
                        </span>
                        <div className="doughnut_legend_bottom">
                          <div className="legend_item_color">
                            <span className="legend_color" />
                            {`Female ${gender_count[0] ?? "0"}`}
                          </div>
                          <div className="legend_item_color">
                            <span className="legend_color" />
                            {`Male ${gender_count[1] ?? "0"}`}
                          </div>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="not_found">
                    <p>Sorry, nothing found</p>
                  </div>
                )}
              </div>
            </>
          ) : activeChart === "Age" ? (
            <>
              <div className="doughnut_legend">
                {AGE_LABEL.map((label, i) => ({
                  label,
                  color: AGE_AXIS_COLORS[i],
                })).map(({ label, color }) => (
                  <div key={label} className="legend_wrapper">
                    <span
                      className="legend_color"
                      style={{ backgroundColor: color }}
                    />
                    {label}
                  </div>
                ))}
              </div>
              <div className="doughnut_items_wrapper">
                {statisticByLocation.length ? (
                  statisticByLocation.map(
                    ({ location_id, location_name, age_groups }) => (
                      <div key={location_id} className="doughnut_item_wrapper">
                        <Doughnut
                          // type="doughnut"
                          data={{
                            labels: AGE_LABEL,
                            datasets: [
                              {
                                data: age_groups
                                  .map((location) => Object.values(location))
                                  .flat(),
                                backgroundColor: AGE_AXIS_COLORS,
                                borderColor: ["white"],
                                borderWidth: 2,
                              },
                            ],
                          }}
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                        />
                        <span className="doughnut_location">
                          {location_name}
                        </span>
                        <div className="center_wrapper">
                          <span className="center_wrapper_title">
                            Total
                            <br /> Customers:
                          </span>
                          <span className="center_count">
                            {age_groups
                              .map((location) => Object.values(location))
                              .flat()
                              .reduce(
                                (acc, count_of_cust) => acc + +count_of_cust,
                                0
                              )}
                          </span>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <div className="not_found">
                    <p>Sorry, nothing found</p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <Line
              // type="line"
              height={80}
              data={{
                labels: customers
                  .map((date, i) => {
                    const day = new Date(endDate);
                    if (dateFilter === 365) {
                      const newDate = new Date(
                        day.getFullYear(),
                        day.getMonth() - i,
                        1
                      );
                      return `${newDate.toLocaleString("en", {
                        month: "short",
                      })}`;
                    }
                    day.setDate(day.getDate() - i);
                    return `${day.getDate()} ${day.toLocaleString("en", {
                      month: "short",
                    })}`;
                  })
                  .reverse(),
                datasets: [
                  {
                    data:
                      activeChart === "Customers" ? customers : salesStatistic,
                    borderColor:
                      activeChart === "Customers" ? "#00A8FF" : "#2ECC71",
                    pointRadius: 6,
                    borderWidth: 2,
                    pointBorderWidth: 2,
                    pointBorderColor: "#fff",
                    // hoverRadius: 8,
                    pointBackgroundColor:
                      activeChart === "Customers" ? "#00A8FF" : "#2ECC71",
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                    intersect: false,
                    callbacks: {
                      title(tooltipItem: any[]) {
                        const date = new Date();

                        if (dateFilter === 365) {
                          const newDate = new Date(
                            date.getFullYear(),
                            date.getMonth() + tooltipItem[0].dataIndex + 1,
                            1
                          );
                          return `${newDate.toLocaleString("en", {
                            month: "short",
                          })} ${newDate.getFullYear() - 1}`;
                        }
                        date.setDate(
                          date.getDate() -
                            (tooltipItem[0].dataset.data.length -
                              tooltipItem[0].dataIndex -
                              1)
                        );

                        return `${tooltipItem[0].label}, ${date.getFullYear()}`;
                      },
                      label(tooltipItem: any) {
                        return `${
                          activeChart === "Customers"
                            ? "Customers"
                            : promotionType === "Stamp"
                            ? "Stamps"
                            : "Points"
                        }: ${tooltipItem.formattedValue}`;
                      },
                    },
                  },
                },
                animation: {
                  duration: 0,
                },
                scales: {
                  x: {
                    beginAtZero: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default DashboardChart;
