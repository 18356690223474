import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import InputGoogleAutocomplete from "src/components/InputGoogleAutocomplete";
import InfoTooltip from "src/components/infoTooltip";
import CheckBox from "src/components/CheckBox/CheckBox";
import ShowHideStaffMembers from "./ShowHideStaffMembers";
import Modal from "src/components/Modal";
import WorkTime from "src/components/WorkTime/WorkTime";
import Input from "src/components/Input";
import {
  // BusinessInfoInterface,
  LocationInfoInterface,
  NewLocationInterface,
  EditLocationInterface,
  LocationUserInfoInterface,
  EditLocationStaffInterface
} from "src/types/place";
import {
  getPlaceInfoAxios,
  // updateBusinessInfoAxios,
  addLocationAxios,
  removeLocationAxios,
  updateLocationStaffAxios,
  removeLocationStaffAxios,
  updateLocationAxios
} from "src/api/place";

import locationDefaultLogo from "src/assets/img/default-logo.png";
import { ReactComponent as CloseIcon } from "src/assets/img/clear.svg";
import { ReactComponent as UploadIcon } from "src/assets/img/upload.svg";
import { ReactComponent as DeleteIcon } from "src/assets/img/delete.svg";

import { Wrapper } from "./styled";
import Select from "../../../components/Select";
import { getLightSpeedLocations } from "../../../api/lightSpeed";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import Checkbox from "src/components/CheckBox/CheckBox";

const PromotionManage: React.FC = () => {
  const [addLocation, setAddLocation] = React.useState<boolean>(false);
  const [locationMaxCount, setLocationMaxCount] = React.useState<number>(0);
  const [locations, setLocations] = React.useState<Array<LocationInfoInterface>>([]);
  const { loggedUser } = useTypedSelector((state) => state.auth);

  const [lightSpeedSelect, setLightSpeedSelect] = useState([
    {
      value: "",
      label: "All Customer"
    }
  ]);

  useEffect(() => {
    if (loggedUser.user.allow_lightspeed) {
      getLightSpeedLocations().then((res) => {
        setLightSpeedSelect(res.data.outlets);
      });
    }
  }, []);

  const [formAddLocationLoading, setFormAddLocationLoading] =
    React.useState<boolean>(false);

  const [newLocation, setNewLocation] =
    React.useState<NewLocationInterface | null>({
      latitude: "",
      longitude: "",
      address: "",
      location_name: "",
      phone_number: "",
      work_time: {
        monday: {
          label: "Monday",
          allDay: true,
          isOpen: true,
          from: "",
          to: ""
        },
        tuesday: {
          label: "Tuesday",
          allDay: true,
          isOpen: true,
          from: "",
          to: ""
        },
        wednesday: {
          label: "Wednesday",
          allDay: true,
          isOpen: true,
          from: "",
          to: ""
        },
        thursday: {
          label: "Thursday",
          allDay: true,
          isOpen: true,
          from: "",
          to: ""
        },
        friday: {
          label: "Friday",
          allDay: true,
          isOpen: true,
          from: "",
          to: ""
        },
        saturday: {
          label: "Saturday",
          allDay: true,
          isOpen: true,
          from: "",
          to: ""
        },
        sunday: {
          label: "Sunday",
          allDay: true,
          isOpen: true,
          from: "",
          to: ""
        }
      }
    });

  const [newLocationPromotionName, setNewLocationPromotionName] =
    React.useState<string>("");
  const [newLocationPromotionDetails, setNewLocationPromotionDetails] =
    React.useState<string>("");
  const [newLocationImage, setNewLocationImage] = React.useState<string>("");
  const [newLocationPromotionDealId, setNewLocationPromotionDealId] =
    React.useState<string | number>("");

  const [formAddLocationErrorMessage, setFormAddLocationErrorMessage] =
    React.useState<string>("");

  const [locationSidebarData, setLocationSidebarData] =
    React.useState<EditLocationInterface | null>(null);

  const [deleteLocationObj, setDeleteLocationObj] =
    React.useState<LocationInfoInterface | null>(null);

  let [locationStaffSidebarData, setLocationStaffSidebarData] =
    React.useState<EditLocationStaffInterface | null>({first_name: '', seller_id: 0, password: '', only_offline_customers: false});

  const [formEditLocationStaffLoading, setFormEditLocationStaffLoading] =
    React.useState<boolean>(false);

  const [showLocationSidebar, setShowLocationSidebar] =
    React.useState<boolean>(false);

  const [isWelcomeGift, setIsWelcomeGift] = React.useState<boolean>(true);
  const [showOnMap, setShowOnMap] = React.useState<boolean>(true);

  const [showLocationStaffSidebar, setShowLocationStaffSidebar] =
    React.useState<boolean>(false);

  const [
    formEditLocationStaffErrorMessage,
    setFormEditLocationStaffErrorMessage
  ] = React.useState<string>("");

  const [deleteLocationLoading, setDeleteLocationLoading] =
    React.useState<boolean>(false);

  const [deleteStaffLoading, setDeleteStaffLoading] =
    React.useState<boolean>(false);

  const setValueNewLocation = (name: string, value: string) => {
    if (formAddLocationErrorMessage) {
      setFormAddLocationErrorMessage("");
    }
    setNewLocation((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const [deleteStaffObj, setDeleteStaffObj] =
    React.useState<LocationUserInfoInterface | null>(null);

  const [formEditLocationLoading, setFormEditLocationLoading] =
    React.useState<boolean>(false);

  const getPlaceInfo = () => {
    setFormEditLocationStaffLoading(true);
    getPlaceInfoAxios()
      .then(({ data }) => {
        setLocations(data.locations);
        setLocationMaxCount(data.location_max_count);
      })
      .finally(() => setFormEditLocationStaffLoading(false));
  };
  useEffect(() => {
    console.log(locationStaffSidebarData)
  }, [locationStaffSidebarData]);

  const handleUpdateLocationStaff = (event: React.FormEvent) => {
    event.preventDefault();
    if (locationStaffSidebarData) {
      setFormEditLocationStaffLoading(true);
      if (locationStaffSidebarData?.is_lightspeed_pos && !locationStaffSidebarData?.lightSpeedName) {
        // @ts-ignore
        locationStaffSidebarData = {...locationStaffSidebarData, lightSpeedName: `${lightSpeedSelect.find(item => item.value === `${locationStaffSidebarData.posId}`).label}`}
      }
      updateLocationStaffAxios(locationStaffSidebarData)
        .then((response) => {
          setLocations(response.data.locations);
          setFormEditLocationStaffLoading(false);
          setShowLocationStaffSidebar(false);
          setLocationStaffSidebarData(null);
        })
        .catch((error) => {
          setFormEditLocationStaffErrorMessage(
            error?.data?.message
              ? error?.data?.message
              : error?.data?.detail
                ? error?.data?.detail
                : "Internal Server Error"
          );
          setFormEditLocationStaffLoading(false);
        });
    }
  };

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles[0]) {
      setNewLocationImage(acceptedFiles[0]);
    }
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: ["image/jpeg", "image/jpg", "image/png"],
    multiple: false,
    onDrop
  });

  const handleAddLocation = (event: React.FormEvent) => {
    event.preventDefault();
    if (
      `${newLocation?.phone_number ? newLocation?.phone_number : ""}`.length < 5
    ) {
      setFormAddLocationErrorMessage(
        "Phone number must be longer than 4 characters"
      );
      return;
    }
    if (!newLocation?.latitude && !newLocation?.longitude) {
      setFormAddLocationErrorMessage("Please, choose a valid location.");
      return;
    }
    if (newLocation) {
      const requestFormData: any = new FormData();
      requestFormData.append("latitude", newLocation?.latitude);
      requestFormData.append("longitude", newLocation?.longitude);
      requestFormData.append("address", newLocation?.address);
      requestFormData.append("location_name", newLocation?.location_name);
      // delete this fields
      requestFormData.append("promotionName", newLocationPromotionName);
      // requestFormData.append("promotionDetailsId", newLocationPromotionDealId);
      requestFormData.append(
        "promotionDescription",
        newLocationPromotionDetails
      );
      // delete this fields
      requestFormData.append("phone_number", newLocation.phone_number);
      requestFormData.append("file", newLocationImage);

      Object.keys(newLocation.work_time).forEach((weekDay) => {
        requestFormData.append(
          `work_time[${weekDay}][allDay]`,
          newLocation.work_time[weekDay].allDay
        );
        requestFormData.append(
          `work_time[${weekDay}][from]`,
          newLocation.work_time[weekDay].from
        );
        requestFormData.append(
          `work_time[${weekDay}][to]`,
          newLocation.work_time[weekDay].to
        );
        requestFormData.append(
          `work_time[${weekDay}][label]`,
          newLocation.work_time[weekDay].label
        );
        requestFormData.append(
          `work_time[${weekDay}][isOpen]`,
          newLocation.work_time[weekDay].isOpen
        );
      });

      setFormAddLocationLoading(true);
      addLocationAxios(requestFormData)
        .then(() => {
          getPlaceInfo();
          setNewLocation({
            latitude: "",
            longitude: "",
            address: "",
            location_name: "",
            phone_number: "",
            work_time: {
              monday: {
                label: "Monday",
                allDay: true,
                isOpen: true,
                from: "",
                to: ""
              },
              tuesday: {
                label: "Tuesday",
                allDay: true,
                isOpen: true,
                from: "",
                to: ""
              },
              wednesday: {
                label: "Wednesday",
                allDay: true,
                isOpen: true,
                from: "",
                to: ""
              },
              thursday: {
                label: "Thursday",
                allDay: true,
                isOpen: true,
                from: "",
                to: ""
              },
              friday: {
                label: "Friday",
                allDay: true,
                isOpen: true,
                from: "",
                to: ""
              },
              saturday: {
                label: "Saturday",
                allDay: true,
                isOpen: true,
                from: "",
                to: ""
              },
              sunday: {
                label: "Sunday",
                allDay: true,
                isOpen: true,
                from: "",
                to: ""
              }
            }
          });
          setNewLocationPromotionName("");
          setNewLocationPromotionDealId("");
          setNewLocationPromotionDetails("");
          setNewLocationImage("");
          setFormAddLocationLoading(false);
          setAddLocation(false);
        })
        .catch((error) => {
          setFormAddLocationErrorMessage(
            error?.data?.message
              ? error?.data?.message
              : error?.data?.detail
                ? error?.data?.detail
                : "Internal Server Error"
          );
          setFormAddLocationLoading(false);
        });
    }
  };

  const hendelDeleteLocationAsk = (location: LocationInfoInterface) => {
    setDeleteLocationObj(location);
  };

  const closeEditLocationSidebar = () => {
    setShowLocationSidebar(false);
    setLocationSidebarData(null);
  };

  const editLocationOpenSidebar = (location: LocationInfoInterface) => {
    setLocationSidebarData({
      location_id: location.id,
      latitude: location.latitude,
      longitude: location.longitude,
      address: location.address,
      promotionDeal: {
        value: location.promotion_details?.id,
        label: location.promotion_details?.name
      },
      promotionName: location.promotion_name,
      promotionDetails: location.promotion_description,
      logo: location.image,
      locationName: location.location_name,
      workTime: location.work_time,
      phone_number: location.phone_number,
      hideOnMap: location.hideOnMap,
      ecommerce: location.ecommerce,
    });
    setShowOnMap(location.hideOnMap === 0 ? true : false)
    setShowLocationSidebar(true);
  };

  const setEditLocationStaff = (name: string, value: string | boolean) => {
    if (formEditLocationStaffErrorMessage) {
      setFormEditLocationStaffErrorMessage("");
    }
    setLocationStaffSidebarData((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const setEditLocation = (name: string, value: any) => {
    setLocationSidebarData((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const closeEditLocationStaffSidebar = () => {
    setShowLocationStaffSidebar(false);
    setLocationStaffSidebarData(null);
  };

  const hendelDeleteLocationConfirm = () => {
    if (deleteLocationObj) {
      setDeleteLocationLoading(true);
      removeLocationAxios(deleteLocationObj.id)
        .then(() => {
          getPlaceInfo();
          setDeleteLocationLoading(false);
          setDeleteLocationObj(null);
        })
        .catch(() => {
        });
    }
  };

  const hendelDeleteLocationStaffConfirm = () => {
    if (deleteStaffObj) {
      setDeleteStaffLoading(true);
      removeLocationStaffAxios(deleteStaffObj.id)
        .then(() => {
          getPlaceInfo();
          setDeleteStaffLoading(false);
          setDeleteStaffObj(null);
        })
        .catch(() => {
        });
    }
  };

  const handleUpdateLocation = (event: React.FormEvent) => {
    event.preventDefault();

    if (locationSidebarData) {
      const requestFormData: any = new FormData();
      requestFormData.append("location_id", locationSidebarData.location_id);
      requestFormData.append("latitude", locationSidebarData.latitude);
      requestFormData.append("longitude", locationSidebarData.longitude);
      requestFormData.append("address", locationSidebarData.address);
      requestFormData.append("location_name", locationSidebarData.locationName);
      requestFormData.append("phone_number", locationSidebarData.phone_number);
      requestFormData.append("hideOnMap", showOnMap ? 0 : 1);

      Object.keys(locationSidebarData.workTime).forEach((weekDay) => {
        requestFormData.append(
          `work_time[${weekDay}][allDay]`,
          locationSidebarData.workTime[weekDay].allDay
        );
        requestFormData.append(
          `work_time[${weekDay}][from]`,
          locationSidebarData.workTime[weekDay].from
        );
        requestFormData.append(
          `work_time[${weekDay}][to]`,
          locationSidebarData.workTime[weekDay].to
        );
        requestFormData.append(
          `work_time[${weekDay}][label]`,
          locationSidebarData.workTime[weekDay].label
        );
        requestFormData.append(
          `work_time[${weekDay}][isOpen]`,
          locationSidebarData.workTime[weekDay].isOpen
        );
      });

      requestFormData.append(
        "promotionName",
        isWelcomeGift ? locationSidebarData.promotionName : ""
      );
      // requestFormData.append(
      //   "promotionDetailsId",
      //   locationSidebarData.promotionDeal.value
      //     ? locationSidebarData.promotionDeal.value
      //     : locationSidebarData.promotionDeal
      // );
      requestFormData.append(
        "promotionDescription",
        isWelcomeGift ? locationSidebarData.promotionDetails : ""
      );
      if (newLocationImage) {
        requestFormData.append("file", newLocationImage);
      }

      setFormEditLocationLoading(true);
      updateLocationAxios(requestFormData)
        .then((response) => {
          setLocations(response.data.locations);
          setFormEditLocationLoading(false);
          closeEditLocationSidebar();
          setNewLocationImage("");
        })
        .catch(() => {
          // setFormEditLocationErrorMessage(
          //   error?.data?.message
          //     ? error?.data?.message
          //     : error?.data?.detail
          //     ? error?.data?.detail
          //     : "Internal Server Error"
          // );
          setFormEditLocationLoading(false);
        });
    }
  };

  useEffect(() => {
    getPlaceInfo();
  }, []);

  // @ts-ignore
  return (
    <Wrapper>
      <div className="page-title">Manage Promotion</div>
      <div className="page-subtitle">
        Your digital loyalty card is displayed below, this is what your
        customers will see on the Lynked App.
      </div>
      <div
        className={`locations ${
          formEditLocationStaffLoading ? "loading-blue" : ""
        }`}
      >
        <div className="title">
          Manage Locations
          <InfoTooltip
            infoText="Your business locations are listed below. Add users at each location to give them access to use the Lynked app and start scanning QR codes. Upgrade your plan if you need more locations or users" />{" "}
        </div>
        <div className="create-filter">
          <div className="btn-wrap">
            <button
              type="button"
              className="btn-white"
              disabled={locationMaxCount <= locations.length}
              onClick={() => setAddLocation(true)}
            >
              Add New Location ({locations.length}/{locationMaxCount})
            </button>
          </div>
        </div>
        {addLocation ? (
          <div className="create-location">
            <form
              onSubmit={handleAddLocation}
              className={`${formAddLocationLoading ? "loading-blue" : ""}`}
            >
              <div className="loading-innerwrap">
                <div className="title-close">
                  <div className="title">Create new location</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      setAddLocation(false);
                      setNewLocation(null);
                      setNewLocationPromotionName("");
                      setNewLocationPromotionDealId("");
                      setNewLocationPromotionDetails("");
                      setNewLocationImage("");
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="add-location-wrapper">
                  <div className="left-block">
                    <div className="input-wrap">
                      <Input
                        required
                        placeholder="Please enter location name"
                        maxLength={30}
                        getValue={(value) =>
                          setValueNewLocation("location_name", value)
                        }
                      />
                    </div>
                    <div className="input-wrap">
                      <div className="location-group">
                        <InputGoogleAutocomplete
                          placeholder="Please enter your location"
                          required
                          getValue={(value) =>
                            setValueNewLocation("address", value)
                          }
                          getLat={(value) =>
                            setValueNewLocation("latitude", value)
                          }
                          getLng={(value) =>
                            setValueNewLocation("longitude", value)
                          }
                        />
                      </div>
                    </div>
                    <div className="input-wrap phone-wrap">
                      <p className="phone_input_label">Phone number</p>
                      <PhoneInput
                        containerClass="input-phone"
                        country="us"
                        inputProps={{
                          name: "phone_number",
                          required: true,
                          minLength: 6
                        }}
                        onChange={(value) => {
                          setValueNewLocation("phone_number", value);
                        }}
                      />
                    </div>
                    {/* <div className="form-group select-deal-container">
                                <Select
                                  placeholder="Select Promotion Deal"
                                  selectorList={promotionDeal}
                                  positionForShow="bottom"
                                  required
                                  getValue={(value) =>
                                    setNewLocationPromotionDealId(value)
                                  }
                                  hideSelectorFilter
                                />
                              </div> */}
                    {/* <div className="input-wrap">
                              <Input
                                required
                                placeholder="Please enter promotion details"
                                maxLength={50}
                                getValue={(value) =>
                                  setNewLocationPromotionDetails(value)
                                }
                              />
                            </div> */}
                  </div>
                  <div className="right-block">
                    <div
                      className={`dropZone ${
                        newLocationImage ? "with-photo" : ""
                      }`}
                    >
                      <div>
                        <div
                          {...getRootProps()}
                          className={`active_${isDragActive} upload-image`}
                        >
                          {" "}
                          {/* eslint-disable-line */}
                          <input {...getInputProps()} required />{" "}
                          {/* eslint-disable-line */}
                          {newLocationImage ? (
                            <div className="img">
                              <img
                                src={URL.createObjectURL(
                                  newLocationImage as unknown as Blob
                                )}
                                alt="NewLocationImage"
                              />
                            </div>
                          ) : null}
                          <div className="dropZone-content-text">
                            <UploadIcon />
                            <span className="text">
                              <span className="title">
                                {newLocationImage
                                  ? "Update location image"
                                  : "Please click here and add location image"}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <WorkTime
                      workingTimeProp={newLocation?.work_time}
                      formErrorMessage={{}}
                      setWorkingTimeProp={(workTime: any) => {
                        const newData = {
                          ...newLocation
                        } as NewLocationInterface;
                        newData.work_time = workTime;
                        setNewLocation(newData);
                      }}
                    />
                  </div>
                  {formAddLocationErrorMessage && (
                    <div className="form-error-message">
                      {formAddLocationErrorMessage}
                    </div>
                  )}
                  <button type="submit" className="btn-blue">
                    Add Location
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : null}
        <div className="location-list">
          {locations.map((location) => (
            <div
              key={location.id}
              className={`location-item`}
            >
              <div className="main">
                <div className="logo-nameAddress">
                  <div className="logo">
                    {location.image ? (
                      <img src={location.image} alt="" />
                    ) : (
                      <img src={locationDefaultLogo} alt="" />
                    )}
                  </div>
                  <div className="name-address">
                    <div className="name">
                      {location.location_name} ({location.shortName})
                    </div>
                    <div className="address">{location.address}</div>
                  </div>
                  <div className="edit-remove">
                    {/* <button
                      type="button"
                      className="edit"
                      onClick={() => editLocationOpenSidebar(location)}
                    >
                      <EditIcon />
                    </button> */}
                    <button
                      type="button"
                      className="remove"
                      onClick={() => hendelDeleteLocationAsk(location)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              </div>
              <ShowHideStaffMembers setLocationStaffSidebarData={setLocationStaffSidebarData}
                                    setShowLocationStaffSidebar={setShowLocationStaffSidebar}
                                    setDeleteStaffObj={setDeleteStaffObj}
                                    getPlaceInfo={getPlaceInfo}
                                    locations={locations} isTwoBtn
                                    isShowLocationDetails={!(locationSidebarData?.location_id === location.id)}
                                    editLocationOpenSidebar={editLocationOpenSidebar} location={location} />

              {locationSidebarData?.location_id === location.id ? (
                <>
                  <div className="edit_location">
                    <div className="close-wrap">
                      <button
                        type="button"
                        className="close"
                        onClick={closeEditLocationSidebar}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                    <form
                      className={`edit_location_form ${
                        formEditLocationLoading ? "loading-blue" : ""
                      }`}
                      onSubmit={handleUpdateLocation}
                    >
                      <Input
                        placeholder="Location name"
                        label="Location Name"
                        maxLength={255}
                        defaultValue={
                          locationSidebarData && locationSidebarData.locationName
                            ? locationSidebarData.locationName
                            : ""
                        }
                        required
                        getValue={(value) =>
                          setEditLocation("locationName", value)
                        }
                      />
                      <div className="phone-wrap">
                        <p className="phone_label">Phone number</p>
                        <PhoneInput
                          containerClass="input-phone"
                          country="us"
                          value={`${locationSidebarData?.phone_number}`}
                          inputProps={{
                            name: "phoneNumber",
                            required: true,
                            minLength: 6
                          }}
                          onChange={(value) => {
                            setEditLocation("phone_number", value);
                          }}
                        />
                      </div>
                      <div className="location-group location-group-google-autocomplete">
                        <InputGoogleAutocomplete
                          label="Location"
                          placeholder="Location Address"
                          required
                          defaultValue={locationSidebarData?.address}
                          getValue={(value) => setEditLocation("address", value)}
                          getLat={(value) => setEditLocation("latitude", value)}
                          getLng={(value) => setEditLocation("longitude", value)}
                        />
                      </div>
                      <div className="location-group location-group-logo">
                        <div
                          className={`dropZone ${
                            locationSidebarData?.logo || newLocationImage
                              ? "with-photo"
                              : ""
                          }`}
                        >
                          <div
                            {...getRootProps()}
                            className={`active_${isDragActive} upload-image`}
                          >
                            {" "}
                            {/* eslint-disable-line */}
                            <input
                              {...getInputProps()}
                              required={!locationSidebarData?.logo}
                            />{" "}
                            {/* eslint-disable-line */}
                            {locationSidebarData?.logo || newLocationImage ? (
                              <div className="img">
                                {newLocationImage ? (
                                  <img
                                    src={URL.createObjectURL(
                                      newLocationImage as unknown as Blob
                                    )}
                                    alt="NewLocationImage"
                                  />
                                ) : (
                                  <img
                                    src={locationSidebarData?.logo || ""}
                                    alt="NewLocationImage"
                                  />
                                )}
                              </div>
                            ) : null}
                            <div className="dropZone-content-text">
                              <UploadIcon />
                              <span className="text">
                              <span className="title">
                                {locationSidebarData?.logo
                                  ? "Update location image"
                                  : "Please click here and add location image"}
                              </span>
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="welcome_gift_wrapper">
                        <h4 className="title_block">Welcome Gift</h4>
                        <div className="welcome_gift_form">
                          <CheckBox
                            label="Create a Welcome Gift to attract new customers?"
                            id="create_welcome_gift"
                            isChecked={isWelcomeGift}
                            onChange={(isChecked) => setIsWelcomeGift(isChecked)}
                          />
                          {isWelcomeGift ? (
                            <>
                              {/* <Input
                              label="Welcome Gift Name"
                              infoTooltip="Add the title of your welcome gift here, e.g “Free Coffee”, “Free Pastry”, “10% Discount”."
                              placeholder="Welcome Gift Name"
                              maxLength={30}
                              defaultValue={locationSidebarData?.promotionName}
                              required
                              getValue={(value) =>
                                setEditLocation("promotionName", value)
                              }
                            /> */}
                              <div className="location-group location-group-promotionDetails">
                                <Input
                                  label="Welcome Gift Details"
                                  infoTooltip="Add the information for your welcome gift here, e.g. “Free Coffee when you collec your 1st Lynked stamp”"
                                  placeholder="Welcome Gift Details"
                                  maxLength={50}
                                  defaultValue={
                                    locationSidebarData?.promotionDetails
                                  }
                                  required
                                  getValue={(value) =>
                                    setEditLocation("promotionDetails", value)
                                  }
                                />
                              </div>
                            </>
                          ) : null}
                          {/* <div className="location-group location-group-promotionName"> */}
                        </div>
                      </div>
                      {locationSidebarData.ecommerce === 0 ? <div className="welcome_gift_wrapper">
                        <h4 className="title_block">Show location in Map</h4>
                        <div className="welcome_gift_form">
                          <CheckBox
                            label="Show location in Map"
                            id="create_welcome_gift"
                            isChecked={showOnMap}
                            onChange={(isChecked) => setShowOnMap(isChecked)}
                          />
                        </div>
                      </div> : null}
                      
                      <WorkTime
                        workingTimeProp={locationSidebarData?.workTime}
                        formErrorMessage={{}}
                        setWorkingTimeProp={(workTime: any) => {
                          const sidebarData = {
                            ...locationSidebarData
                          } as EditLocationInterface;
                          sidebarData.workTime = workTime;
                          setLocationSidebarData(sidebarData);
                        }}
                      />
                      <div className="btn_wrapper">
                        <button className="btn-blue" type="submit">
                          Update Location
                        </button>
                      </div>
                    </form>
                  </div>
                  <ShowHideStaffMembers setLocationStaffSidebarData={setLocationStaffSidebarData}
                                        setShowLocationStaffSidebar={setShowLocationStaffSidebar}
                                        setDeleteStaffObj={setDeleteStaffObj}
                                        getPlaceInfo={getPlaceInfo}
                                        locations={locations}
                                        editLocationOpenSidebar={editLocationOpenSidebar} location={location} />
                </>
              ) : null}
            </div>
          ))}
        </div>

        <div className="sidebar-overlay-wrap sidebar-edit-staff">
          <div className={`sidebar ${showLocationStaffSidebar ? "show" : ""}`}>
            <div className="top">
              <div className="title-wrap">
                <div className="title">Update Location User</div>
              </div>
              <div className="close-wrap">
                <button
                  type="button"
                  className="close"
                  onClick={() => closeEditLocationStaffSidebar()}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div className="main-content">
              <form
                onSubmit={handleUpdateLocationStaff}
                className={`${
                  formEditLocationStaffLoading ? "loading-blue" : ""
                }`}
              >
                <div className="loading-innerwrap">
                  <div className="staff-group">
                    <Input
                      label="First name"
                      placeholder="First name"
                      defaultValue={locationStaffSidebarData?.first_name}
                      required
                      getValue={(value) =>
                        setEditLocationStaff("first_name", value)
                      }
                    />
                  </div>
                  <div className="staff-group">
                    {locationStaffSidebarData?.is_pos ? <Input
                      label="Pos Id"
                      placeholder="Pos Id"
                      defaultValue={locationStaffSidebarData?.posId}
                      required
                      getValue={(value) =>
                        setEditLocationStaff("posId", value)
                      }
                    /> : locationStaffSidebarData?.is_lightspeed_pos ?
                      <Select selectorList={lightSpeedSelect}
                              positionForShow="bottom"
                              defaultValue={lightSpeedSelect.find(item => item.value === `${locationStaffSidebarData?.posId}`)}
                              getValue={(value, label) => {
                                setEditLocationStaff("posId", `${value}`);
                                setEditLocationStaff("lightSpeedName", `${label}`);
                              }}
                              required
                              hideSelectorFilter/> : <Input
                        label="Last name"
                        placeholder="Last name"
                        defaultValue={locationStaffSidebarData?.last_name}
                        required
                        getValue={(value) =>
                          setEditLocationStaff("last_name", value)
                        }
                      />}
                  
                  </div>
                  <div className="staff-group">
                    <Input
                      label="Password"
                      placeholder="Password"
                      type="password"
                      defaultValue={locationStaffSidebarData?.password}
                      getValue={(value) =>
                        setEditLocationStaff("password", value)
                      }
                    />
                  </div>
                  {loggedUser.user.allow_offline_customers ? <div className="checkbox-group">
                    <Checkbox label={'Only for Offline Customers'}
                              isChecked={locationStaffSidebarData?.only_offline_customers} onChange={checked => {
                      setEditLocationStaff("only_offline_customers", checked)
                    }}/>
                  </div> : null}
                  
                  {formEditLocationStaffErrorMessage && (
                    <div className="form-error-message">
                      {formEditLocationStaffErrorMessage}
                    </div>
                  )}
                  {locationStaffSidebarData?.is_pos ? <button type="submit" className="btn-blue">
                    Update Location Pos
                  </button> : locationStaffSidebarData?.is_lightspeed_pos ? <button type="submit" className="btn-blue">
                    Update Location Ligthspeed
                  </button> : <button type="submit" className="btn-blue">
                    Update Location User
                  </button>}
                </div>
              </form>
            </div>
          </div>
          <button
            type="button"
            className={`sidebar-overlay ${
              showLocationStaffSidebar ? "show" : ""
            }`}
            onClick={() => closeEditLocationStaffSidebar()}
          >
            button
          </button>
        </div>

        {/* <SidebarEditLocation
                    showLocationSidebar={showLocationSidebar}
                    closeEditLocationSidebar={() => closeEditLocationSidebar()}
                    locationSidebarDataProps={locationSidebarData}
                    promotionDeal={promotionDeal}
                    setLocations={(value) => setLocations(value)}
                  /> */}
      </div>
      {deleteLocationObj && (
        <Modal
          openModal={!!deleteLocationObj}
          setOpenModal={() => setDeleteLocationObj(null)}
          closeOutsideClick
        >
          <div
            className={`remove-modal ${
              deleteLocationLoading ? "loading-blue" : ""
            }`}
          >
            <div className="loading-innerwrap">
              <div className="title">
                Please confirm delete location &quot;
                {deleteLocationObj.shortName}
                &quot; with adress: {deleteLocationObj.address}
              </div>
              <button
                type="button"
                className="btn-blue"
                onClick={() => {
                  hendelDeleteLocationConfirm();
                }}
              >
                Confirm delete
              </button>
            </div>
          </div>
        </Modal>
      )}

      {deleteStaffObj && (
        <Modal
          openModal={!!deleteStaffObj}
          setOpenModal={() => setDeleteStaffObj(null)}
          closeOutsideClick
        >
          <div
            className={`remove-modal ${
              deleteStaffLoading ? "loading-blue" : ""
            }`}
          >
            <div className="loading-innerwrap">
              <div className="title">
                Please confirm delete staff members &quot;
                {deleteStaffObj.first_name} {deleteStaffObj.last_name}
                &quot; with username: {deleteStaffObj.username}
              </div>
              <button
                type="button"
                className="btn-blue"
                onClick={() => {
                  hendelDeleteLocationStaffConfirm();
                }}
              >
                Confirm delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default PromotionManage;
