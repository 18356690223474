import React, { FC, useState, useEffect } from "react";

import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
import Select from "src/components/Select";
import { ReactComponent as CalendarIcon } from "src/assets/img/calendar.svg";

import {AdminDashboardInfoInterface} from "src/types/dashboard";
import {getAdminDashboardInfoAxios} from "src/api/dashboard";

import { Wrapper } from "./styled";
import { filterPerDate } from "./consts";
import DashboardChart from "./DashboardChart";
import moment from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {SelectorListObj} from "../../../components/Select/types";
import {getAdminBusinessesNamesAxios} from "../../../api/adminPanel";

const AdminDashboard: FC = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [locationIdFilter, setLocationIdFilter] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<{
    value: string;
    label: string;
  }>({
    value: filterPerDate[0].value,
    label: filterPerDate[0].label
  });
  const [dashboardInfo, setDashboardInfo] = useState<AdminDashboardInfoInterface>({
    businessCount: 0,
    customersCount: 0,
    newCustomerCount: 0,
    activatedCards: 0,
    totalPoints: 0,
    redeemedCampaigns: 0,
    countOfActivatedVouchers: 0,
    newCustomersForPeriod: [0],
    locations: [],
    salesStatistic: [0],
    saleStatisticPoints: [0],
    saleStatisticStamp: [0],
    genders: [0],
    ages: [0]
  });

  const { locations } = useTypedSelector((state) => state.groupsLocations);
  const { getGroupsLocations } = useActions();
  const [dates, setDatesState] = useState({
    startDate:moment(new Date()).subtract(7, "days").format("MMM D, YYYY"),
    endDate: moment(new Date()).format("MMM D, YYYY")
  });
  
  const setDates = (e: any, { startDate, endDate }: any) => {
    endDate >= moment() && startDate >= moment() ? setDatesState({
      startDate: moment().format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : endDate >= moment() &&  startDate <= moment() ? setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY")
    });
  };

  useEffect(() => {
    if (locations.length === 0) getGroupsLocations();
  }, []); //eslint-disable-line

  useEffect(() => {
    setPageLoading(true);
    
    getAdminDashboardInfoAxios({
      startDate: moment(dates.startDate).add(1, 'hours'),
      endDate: moment(dates.endDate).endOf('day'),
      businessId: locationIdFilter,
    }).then((res) => {
      setDashboardInfo(res.data);
      setPageLoading(false);
    });
  }, [dateFilter.value, locationIdFilter, dates.startDate, dates.endDate]);
  
  
  
  const ranges = {
    "Today": [moment(), moment()],
    "Last 7 Days": [moment().subtract(1, "weeks"), moment()],
    "Last 30 Days": [moment().subtract(1, "months"), moment()],
    "Last Year": [moment().subtract(1, "years"), moment()],
  };
  const [businesses, setBusinesses] = useState<SelectorListObj[]>([{value: '', label: ''}])
  useEffect(() => {
    getAdminBusinessesNamesAxios().then(({data}) => {
      let selectBusiness: any[] = []
      data.business.forEach((item: any) => {
        selectBusiness.push({value: item.id, label: item.business_name})
      })
      setBusinesses(selectBusiness)
    });
  }, []);

  return (
    <Wrapper className={`${pageLoading ? "loading-blue" : ""}`}>
      <div className="heading">
        <div className="page-title">Admin Dashboard</div>
        <div className="filters">
          <Select
            placeholder="All Businesses"
            selectorList={businesses}
            disabled={!(businesses.length > 0)}
            positionForShow="bottom"
            required
            isClearable
            getValue={(value) => setLocationIdFilter(+value)}
            hideSelectorFilter
          />
          <DateRangePicker
            onApply={setDates}
            initialSettings={{ startDate: moment(new Date()).subtract(7, "days"), endDate: new Date(), ranges: ranges }}
          
          >
            <div className={'isSelect select'}>
              <CalendarIcon/>
              <input
                type="text"
                value={dates.startDate + "-" + dates.endDate}
                onChange={(e) => {
                  // @ts-ignore
                  setDates(e, dates)
                }}
                className="form-control"
              />
            </div>
          
          </DateRangePicker>
        </div>
      </div>
      <div className="main-blocks-row">
        <div className="item all-customers">
          <div className="title">All Businesses</div>
          <div className="count">{dashboardInfo.businessCount}</div>
          <div className="description">overall</div>
        </div>
        <div className="item all-customers">
          <div className="title">All Customers</div>
          <div className="count">{dashboardInfo.customersCount}</div>
          <div className="description">overall</div>
        </div>
        <div className="item all-customers">
          <div className="title">New Customers</div>
          <div className="count">{dashboardInfo.newCustomerCount}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div className="title">
            Count of activated cards</div>
          <div className="count">
            {dashboardInfo.activatedCards}
          </div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        
      </div>
      <div className="main-blocks-row" style={{marginTop: '30px'}}>
        <div className="item all-customers">
          <div className="title">
            Total points
          </div>
          <div className="count">
            {dashboardInfo.totalPoints ? dashboardInfo.totalPoints : 0}
          </div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div
            className="title">Redeemed Campaigns
          </div>
          <div className="title"></div>
          <div
             className="count">{dashboardInfo.redeemedCampaigns}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div
            className="title">Count of activated vouchers
          </div>
          <div className="title"></div>
          <div
             className="count">{dashboardInfo.countOfActivatedVouchers}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
      </div>
      <DashboardChart
        promotionType={'admin'}
        customers={dashboardInfo.newCustomersForPeriod || []}
        salesStatistic={dashboardInfo.salesStatistic || []}
        salesStatisticPoint={dashboardInfo.saleStatisticPoints || []}
        salesStatisticStamp={dashboardInfo.saleStatisticStamp || []}
        dateFilter={+dateFilter.value}
        gender={dashboardInfo.genders}
        age={dashboardInfo.ages}
        statisticByLocation={dashboardInfo.locations || {}}
      />
    </Wrapper>
  );
};

export default AdminDashboard;
