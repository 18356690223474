import React, {useEffect, useState} from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Input from "src/components/Input";
import Select from "src/components/Select";
// import Modal from "src/components/Modal";
import { PersonalInformationInterface } from "src/types/registration";
import { registerPersonalInfo } from "./api";

// import { REACT_APP_STRIPE_PKEY } from "src/types/env";
// import { ReactComponent as LogoFull } from "src/assets/img/new-logo-full.svg";
// import slideImage1 from "src/assets/img/personal-information-slide-1.png";
// import checkEmailImg from "src/assets/img/check-your-email.svg";

import { Wrapper } from "./styled";
import * as Const from "./consts";
import {SelectorListObj} from "../../../../components/Select/types";
import {getCurrencyAxios} from "../../../../api/auth";

const RegistrationPersonalInformation: React.FC<{
  goToStep: (step: number) => void;
  businessPlan?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  passwordVerificationToken?: string | null;
}> = ({ businessPlan, goToStep }) => {
  const [personalInformation, setPersonalInformation] =
    React.useState<PersonalInformationInterface>({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      businessName: "",
      businessSize: "",
      businessPlan,
      vatNumber: "",
      currency: 2,
    });

  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [currencyList, setCurrencyList] = useState<SelectorListObj[]>([])
  
  const [formErrorMessage, setFormErrorMessage] = React.useState<any>([]);
  // const [openModalCheckEmail, setOpenModalCheckEmail] = React.useState<boolean>(
  //   false
  // );
  // const [passwordFormLoading, setPasswordFormLoading] = React.useState<boolean>(
  //   false
  // );
  // const [
  //   passwordFormErrorMessage,
  //   setPasswordFormErrorMessage,
  // ] = React.useState<string>("");
  // const [password, setPassword] = React.useState<string>("");
  // const [
  //   passwordConfirmation,
  //   setPasswordConfirmation,
  // ] = React.useState<string>("");
  
  useEffect(() => {
    getCurrencyAxios().then(res=>{
      res.data.currencies.length > 0 ? setCurrencyList(res.data.currencies) :
        setCurrencyList([{value: 2, label: '€'}])
    })
  }, []);

  const getValue = (type: string, value: number | string) => {
    if (formErrorMessage) {
      setFormErrorMessage([]);
    }
    setPersonalInformation({ ...personalInformation, [type]: value });
  };

  // const redirectToCheckoutStripe = (sessionId: string) => {
  //   loadStripe(REACT_APP_STRIPE_PKEY).then((stripe) => {
  //     if (stripe) {
  //       stripe
  //         .redirectToCheckout({
  //           sessionId,
  //         })
  //         .then(() => {})
  //         .catch((error) => {
  //           setPasswordFormErrorMessage(
  //             error?.data?.message
  //               ? error?.data?.message
  //               : "Internal Server Error"
  //           );
  //         });
  //     }
  //   });
  // };

  const handleCreateAccount = (event: React.FormEvent) => {
    event.preventDefault();
    setFormLoading(true);
    registerPersonalInfo({
      email: personalInformation.email,
      first_name: personalInformation.firstName,
      last_name: personalInformation.lastName,
      phone: personalInformation.phoneNumber,
      business_name: personalInformation.businessName,
      business_size: personalInformation.businessSize,
      subscription: personalInformation.businessPlan,
      vat_number: personalInformation.vatNumber,
      currency: `${personalInformation.currency}`,
    })
      .then(() => {
        goToStep(4);
        // setOpenModalCheckEmail(true);
        setFormLoading(false);
      })
      .catch((error) => {
        setFormErrorMessage(error.data.errors);
        setFormLoading(false);
      });
  };

  // const handleCreatePassword = (event: React.FormEvent) => {
  //   event.preventDefault();
  //   if (password === passwordConfirmation) {
  //     setPasswordFormLoading(true);
  //     registerCreatePassword({
  //       password,
  //       confirm_password: passwordConfirmation,
  //       verification_token: passwordVerificationToken,
  //     })
  //       .then((response) => {
  //         setPasswordFormLoading(false);
  //         if (response.data.status === true && response.data.sessionId) {
  //           redirectToCheckoutStripe(response.data.sessionId);
  //         } else {
  //           setPasswordFormErrorMessage(
  //             response.data.message
  //               ? response.data.message
  //               : "Internal Server Error"
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         setPasswordFormErrorMessage(
  //           error?.data?.message
  //             ? error?.data?.message
  //             : error?.data?.detail
  //             ? error?.data?.detail
  //             : "Internal Server Error"
  //         );
  //         setPasswordFormLoading(false);
  //       });
  //   } else {
  //     setPasswordFormErrorMessage("The password confirmation does not match");
  //   }
  // };

  return (
    <Wrapper>
      {/* <div
        className={`main ${
          openModalCheckEmail || passwordVerificationToken ? "blur" : ""
        }`}
      > */}
      <div className="main">
        {/* <div className="left-block"> */}
        {/* <div className="header">
            <div className="logo">
              <LogoFull />
            </div>
          </div> */}
        <div className="form-wrap">
          <div className="title">Your Account</div>
          <div className="subtitle">
            Fill in the below details to build your custom portal.
          </div>
          <form
            onSubmit={handleCreateAccount}
            className={`${formLoading ? "loading-blue" : ""} lynked-form`}
          >
            <div className="form-innerwrap">
              <div className="form_two_items">
                <div className="form-group first-name">
                  <Input
                    label="First Name"
                    required
                    getValue={(value) => getValue("firstName", value)}
                    error={formErrorMessage.first_name}
                  />
                </div>
                <div className="form-group last-name">
                  <Input
                    label="Last Name"
                    required
                    getValue={(value) => getValue("lastName", value)}
                    error={formErrorMessage.last_name}
                  />
                </div>
              </div>
              <div className="form_two_items">
                <div className="form-group">
                  <Input
                    label="Email"
                    type="email"
                    required
                    getValue={(value) => getValue("email", value)}
                    error={formErrorMessage.email}
                  />
                </div>
                <div className="form-group phone-wrap">
                  <span className="label">Phone number</span>
                  <PhoneInput
                    containerClass="input-phone"
                    country="us"
                    inputProps={{
                      name: "phoneNumber",
                      // required: true,
                      minLength: 6,
                    }}
                    onChange={(value) => {
                    //   if (event.target.classList.contains("form-control")) {
                    //     if (event.target.value.length > 6) {
                    //       event.target.setCustomValidity("");
                    //     } else {
                    //       event.target.setCustomValidity(
                    //         "Min length is 6 characters"
                    //       );
                    //     }
                    //   }
                      getValue("phoneNumber", value);
                    }}
                  />
                  {/* {formErrorMessage.phone ? ( */}
                  {/*   <div className="form-error-message"> */}
                  {/*     {formErrorMessage.phone} */}
                  {/*   </div> */}
                  {/* ) : null} */}
                </div>
              </div>
              <div className="form_two_items">
                <div className="form-group">
                  <Input
                    label="VAT Number"
                    required
                    getValue={(value) => getValue("vatNumber", value)}
                    error={formErrorMessage.vat_number}
                  />
                </div>
                <div className="form-group">
                  <Input
                    label="Business Name"
                    required
                    getValue={(value) => getValue("businessName", value)}
                    error={formErrorMessage.business_name}
                  />
                </div>
              </div>
              <div className="form_two_items">
                <div className="form-group">
                  <Select
                    label="Currency"
                    selectorList={currencyList}
                    positionForShow="bottom"
                    required
                    getValue={(value) => getValue("currency", value)}
                    defaultValue={currencyList.find(currency => currency.value === personalInformation.currency)}
                    hideSelectorFilter
                  />
                </div>
                <div className="form-group">
                  <Select
                    label="Business Size"
                    selectorList={Const.businessSizeArray}
                    positionForShow="top"
                    required
                    getValue={(value) => getValue("businessSize", value)}
                    hideSelectorFilter
                  />
                  {formErrorMessage.business_size ? (
                    <div className="form-error-message">
                      {formErrorMessage.business_size}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div className="form-group">
                <label htmlFor="terms" className="checkbox blue-checkbox">
                  <span className="checkbox-input-wrap">
                    <input type="checkbox" id="terms" required />
                    <span className="checkbox-view" />
                  </span>
                  <div className="text">
                    By completing, I agree to
                    <a href="/" rel="noreferrer" target="_blank">
                      Privacy Policy.
                    </a>
                  </div>
                </label>
              </div> */}
              <div className="btn_submit_wrapper">
                <button type="submit" className="btn-blue">
                  Create Account
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* </div> */}
        {/* <div className="right-block">
          <Slider className="slider" dots infinite arrows={false}>
            <div className="slide">
              <div className="slide-img-wrap">
                <img src={slideImage1} alt="" />
              </div>
              <div className="slide-content">
                <div className="info">Lynked Platform</div>
                <div className="title">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui.
                </div>
                <div className="description">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slide-img-wrap">
                <img src={slideImage1} alt="" />
              </div>
              <div className="slide-content">
                <div className="info">Lynked Platform</div>
                <div className="title">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui.
                </div>
                <div className="description">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slide-img-wrap">
                <img src={slideImage1} alt="" />
              </div>
              <div className="slide-content">
                <div className="info">Lynked Platform</div>
                <div className="title">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui.
                </div>
                <div className="description">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slide-img-wrap">
                <img src={slideImage1} alt="" />
              </div>
              <div className="slide-content">
                <div className="info">Lynked Platform</div>
                <div className="title">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui.
                </div>
                <div className="description">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="slide-img-wrap">
                <img src={slideImage1} alt="" />
              </div>
              <div className="slide-content">
                <div className="info">Lynked Platform</div>
                <div className="title">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui.
                </div>
                <div className="description">
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia deserunt mollit anim id est laborum.
                </div>
              </div>
            </div>
          </Slider>
        </div> */}
      </div>
      {/* {openModalCheckEmail && (
        <Modal
          openModal={openModalCheckEmail}
          setOpenModal={(value) => setOpenModalCheckEmail(value)}
          closeOutsideClick={false}
        >
          <div className="modal-check-email-content">
            <img src={checkEmailImg} alt="" />
            <div className="title">
              Please check your emails for account approval
            </div>
          </div>
        </Modal>
      )} */}
      {/* {passwordVerificationToken && (
        <Modal
          openModal={openModalCheckEmail}
          setOpenModal={(value) => setOpenModalCheckEmail(value)}
          closeOutsideClick={false}
        >
          <div className="modal-create-password-content">
            <form
              onSubmit={handleCreatePassword}
              className={`${passwordFormLoading ? "loading-blue" : ""}`}
            >
              <div className="form-innerwrap">
                <div className="title">Create Password</div>
                <div className="description">
                  This is your password for accessing your new Lynked portal for
                  viewing all your customer activity.
                </div>
                <div className="form-group">
                  <Input
                    type="password"
                    placeholder="Password"
                    required
                    getValue={(value) => {
                      if (passwordFormErrorMessage)
                        setPasswordFormErrorMessage("");
                      setPassword(value);
                    }}
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="password"
                    placeholder="Repeat Password"
                    required
                    getValue={(value) => {
                      if (passwordFormErrorMessage)
                        setPasswordFormErrorMessage("");
                      setPasswordConfirmation(value);
                    }}
                  />
                </div>
                {passwordFormErrorMessage && (
                  <div className="form-error-message">
                    {passwordFormErrorMessage}
                  </div>
                )}
                <button type="submit" className="btn-blue">
                  Create Password
                </button>
              </div>
            </form>
          </div>
        </Modal>
      )} */}
    </Wrapper>
  );
};

export default RegistrationPersonalInformation;
