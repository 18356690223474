import {
  CustomerRequestInterface,
  CustomerGroupsRequestInterface,
  CustomerGroupCreateRequestInterface, PointCountsInterface
} from "src/types/customer";
import axios from "./api";

export const getCustomerListAxios = (
  requestParamtrs: CustomerRequestInterface
): Promise<any> =>
  axios
    .post("/api/group/getAllUsersForPlace", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getOfflineCustomerListAxios = (
  requestParamtrs: { search: string }
): Promise<any> =>
  axios
    .post("/api/offlineUser/list", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getCustomerListWithFilterGroupsAxios = (
  requestParamtrs: CustomerRequestInterface
): Promise<any> =>
  axios
    .post("/api/group/getAllUsersForPlaceWithFilters", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getCustomerGroupsAxios = (
  requestParamtrs: CustomerGroupsRequestInterface
): Promise<any> =>
  axios
    .post("/api/group/getAllGroups", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getCustomerGroupUsersAxios = (
  requestParamtrs: {id: number}
): Promise<any> =>
  axios
    .post("", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getFiltersInfoAxios = (): Promise<any> =>
  axios
    .get("/api/group/getFilters")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const createCustomerGroupAxios = (
  requestParamtrs: CustomerGroupCreateRequestInterface
): Promise<any> =>
  axios
    .post("/api/group/create", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateCustomerGroupAxios = (
  requestParamtrs: CustomerGroupCreateRequestInterface
): Promise<any> =>
  axios
    .post("/api/group/update", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateCustomerGroupForProductDiscountAxios = (
  requestParamtrs: CustomerGroupCreateRequestInterface
): Promise<any> =>
  axios
    .post("/api/productGroup/update", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const createCustomerGroupForProductDiscountAxios = (
  requestParamtrs: CustomerGroupCreateRequestInterface
): Promise<any> =>
  axios
    .post("/api/productGroup/create", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const removeCustomerGroupForProductDiscountAxios = (
  requestParamtrs: any
): Promise<any> =>
  axios
    .post("/api/productGroup/remove", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const deleteCustomerGroupAxios = (group_id: string): Promise<any> =>
  axios
    .post("/api/group/delete", { id: group_id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getCustomerGroupInfoAxios = (group_id: string): Promise<any> =>
  axios
    .post("/api/getGroup", { id: group_id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getCustomerGroupInfoForProductDiscountAxios = (group_id: string): Promise<any> =>
  axios
    .post("/api/productGroup/get", { id: group_id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getAllCustomersAndProductsForProductDiscountAxios = (id: number): Promise<any> =>
  axios
    .post("/api/productGroup/info", {id})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getAllCustomerGroupForProductDiscountAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/productGroup/list", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getCustomerInfoAxios = (user_id: string, search: string, searchType: string, location: string, startDate: any, endDate: any): Promise<any> =>
  axios
    .post("/api/group/getUserForPlaceActivities", { user: user_id, search: search, searchType: searchType, location: location, startDate: startDate, endDate: endDate })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updatePointsCountAxios = (requestParams: PointCountsInterface): Promise<any> =>
  axios
    .post("/api/user/setUserActivityPoint", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const importWorkers = (requestParams: any): Promise<any> =>
  axios
    .post("/api/worker/import", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

    export const removeBulkStaff = (requestParams: any): Promise<any> =>
  axios
    .post("/api/worker/dashboard/bulk-remove", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const importStaffDiscountWorkersAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/import", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

    export const removeBulkStaffDiscountAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/bulk-remove", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });


export const getAllStaffMembers = (requestParams: any): Promise<any> =>
  axios
    .post("/api/worker/all", requestParams)
    .then((response) => {
      
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const addStaffMembersAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/worker/add", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const editStaffMembersAxios = (requestParams: any): Promise<any> =>
  axios
    .post("api/worker/update", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const removeStaffMembersAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/worker/delete", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getSataffFamily = (requestParams: any): Promise<any> =>
  axios
    .post("/api/worker/dashboard/get-relation", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeSataffFamily = (requestParams: any): Promise<any> =>
  axios
    .post("/api/worker/dashboard/remove-relation", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });


export const getAllStaffDiscountMembers = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/all", requestParams)
    .then((response) => {
      
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const addStaffDiscountMembersAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/create", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const editStaffDiscountMembersAxios = (requestParams: any): Promise<any> =>
  axios
    .post("api/staff/update", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const removeStaffDiscountMemberAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/delete", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getStaffDiscountLoyaltyCardAxios = (): Promise<any> =>
  axios
    .get("/api/staff/card/get")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const saveStaffDiscountLoyaltyCardAxios = (requestData: any): Promise<any> =>
  axios
    .post("/api/staff/card/add", requestData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getStaffDiscountActivity = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/transaction", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const setDiscountPercentForAllMembersAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/all-set-discount", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const setMaximumSpendDiscountAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/maximumSpend/set", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getMaximumSpendDiscountAxios = (): Promise<any> =>
  axios
    .get("/api/staff/maximumSpend/get")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const setNewDiscountRuleAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/restriction-category/add", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeDiscountRuleAxios = (requestParams: any): Promise<any> =>
  axios
    .post("/api/staff/restriction-category/remove", requestParams)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAllDiscountRulesAxios = (requestData: {startDate: any, endDate: any}): Promise<any> =>
  axios
    .post("/api/staff/restriction-category/get", requestData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
