interface GroupsTypes {
  value: number | string;
  label: string;
}
interface LocationsCategories {
  value: number | string;
  label: string;
}

export interface GroupsLocationsState {
  groups: GroupsTypes[];
  locations: LocationsCategories[];
}

export enum GroupsLocationsActionTypes {
  GET_GROUPS_LOCATIONS = "GET_GROUPS_LOCATIONS",
}

interface GetGroupsLocationsAction {
  type: GroupsLocationsActionTypes.GET_GROUPS_LOCATIONS;
  payload: {
    groups: GroupsTypes[];
    locations: LocationsCategories[];
  };
}

export type GroupsLocationsAction = GetGroupsLocationsAction;
