import styled from "styled-components";
import { Color } from "src/types/color";
import arrowDropDownImgBlack from "src/assets/img/arrowDropDownBlack.svg";

const Wrapper = styled.div<{
  required?: boolean;
  isSelect?: boolean;
  isLabel?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 24px;

  & > .label:not(:empty) {
    color: ${Color.blueDarkText};
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
    /* &:after {
      content: ${(props) => (props.required ? `" *"` : "none")};
      color: #eb4d4b;
    } */
  }

  & > svg {
    position: absolute;
    left: 8px;
    top: 8px;
  }

  & > input {
    border-radius: 2px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    padding: 13px 16px;
    font-size: 16px;
    line-height: 20px;
    cursor: ${(props) => (props.isSelect ? "pointer" : "initial")};
    background: ${(props) =>
      props.isSelect
        ? `url(${arrowDropDownImgBlack}) #ffffff no-repeat calc(100% - 12px) center`
        : "#ffffff"};

    &::placeholder {
      color: #95989d;
    }

    &:disabled:not(".lock") {
      background: rgba(0, 0, 0, 0.07);
      box-shadow: none;
    }
  }
  &.lock {
    input {
      background: none;
      cursor: initial;
    }
  }

  & > ul {
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 5;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 7px 0px;
    border-radius: 2px;
    padding: 5px 0;
    max-height: 230px;
    overflow: auto;
    &.top {
      bottom: calc(100% + 7px);
    }
    &.bottom {
      top: calc(100% + 7px);
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 144, 202, 0.4);
    }

    & > input {
      width: 90%;
      margin: 10px auto;
      display: block;
      padding: 7px 10px;
      background: unset;
      height: auto;
      border: 2px solid #ebebeb;
      border-radius: 6px;
      box-sizing: border-box;

      &::placeholder {
        color: #ced2d4;
      }
    }

    & > button {
      font-size: 14px;
      padding: 10px 16px;
      cursor: pointer;
      background: #fff;
      transition: background-color 0.225s;
      display: flex;
      width: 100%;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    & > li {
      padding: 10px 16px;
    }
  }

  .info-tooltip {
    position: absolute;
    right: 40px;
    bottom: 11px;
    width: 24px;
    height: 24px;
    margin: auto;
    cursor: pointer;
    transition: 0.3s all ease;
    .tooltip {
      transition: 0.3s all ease;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      bottom: 40px;
      right: -10px;
      width: 200px;
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      color: ${Color.white};
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 15px;
      padding: 10px;
      border-radius: 6px;
      z-index: 5;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        right: 17px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${Color.blue} transparent transparent transparent;
      }
    }
    svg path.circle {
      fill: #000d46;
      opacity: 0.6;
    }
    &:hover {
      svg path.circle {
        fill: ${Color.blue};
        opacity: 1;
      }
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .clearable-btn {
    position: absolute;
    background: none;
    right: 26px;
    bottom: 4px;
    opacity: 0;
    svg {
      fill: ${Color.blueDarkText};
    }
  }
  &:hover {
    .clearable-btn {
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
  }

  .lock-icon-wrap {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
`;

export { Wrapper };
