import React from "react";
import { useLocation } from "react-router-dom";
import { REACT_APP_STRIPE_PRODUCT_ID } from "src/types/env";
import { Wrapper } from "./styled";
// import {
//   AccountInformationInterface,
//   AdditionalInformationInterface,
// } from "../../types/registration";
import * as Const from "./consts";
import NavProgress from "./NavProgress";
import RegistrationWelcome from "./Step/RegistrationWelcome";
import RegistrationChoosePlan from "./Step/RegistrationChoosePlan";
import RegistrationPersonalInformation from "./Step/RegistrationPersonalInformation";
// import RegistrationAccountInformation from "./Step/RegistrationAccountInformation";
// import RegistrationAdditionalInformation from "./Step/RegistrationAdditionalInformation";
// import RegistrationSetupPromotion from "./Step/RegistrationSetupPromotion";
import RegistrationAccountCheckEmail from "./Step/RegistrationAccountCheckEmail";
import RegistrationActivateAccount from "./Step/RegistrationActivateAccount";
import RegistrationBuildYourCard from "./Step/RegistrationBuildYourCard";
import RegistrationLocations from "./Step/RegistrationLocations";
import RegistrationWelcomePromotion from "./Step/RegistrationWelcomePromotion";

const Registration: React.FC = () => {
  const location: any = useLocation();
  const verificationToken = new URLSearchParams(location.search).get(
    Const.verificationToken
  );
  const accountInformationToken = new URLSearchParams(location.search).get(
    Const.accountInformationToken
  );

  const [stepRegistration, setStepRegistration] = React.useState<number>(
    verificationToken ? 5 : accountInformationToken !== null ? 7 : 1
    // 9
  );

  const [businessPlanPersonalInfo, setBusinessPlanPersonalInfo] =
    React.useState<string>(REACT_APP_STRIPE_PRODUCT_ID);

  // const [
  //   accountInformation,
  //   setAccountInformation,
  // ] = React.useState<AccountInformationInterface>({
  //   token: accountInformationToken,
  //   companyName: "",
  //   businessType: "",
  //   promotionType: "",
  //   rewardInformation: "",
  //   rewardCode: "",
  //   maxNumberPromotion: 0,
  // });

  // const [
  //   additionalInformation,
  //   setAdditionalInformation,
  // ] = React.useState<AdditionalInformationInterface>({
  //   logo: "",
  //   address: "",
  //   latitude: NaN,
  //   longitude: NaN,
  // });

  return (
    <Wrapper>
      <NavProgress step={stepRegistration}>
        {stepRegistration === 1 ? (
          <RegistrationWelcome goToStep={() => setStepRegistration(3)} />
        ) : stepRegistration === 2 ? (
          <RegistrationChoosePlan
            goToStep={(value) => setStepRegistration(value)}
            setBusinessPlanPersonalInfo={(value) =>
              setBusinessPlanPersonalInfo(value)
            }
          />
        ) : stepRegistration === 3 ? (
          <RegistrationPersonalInformation
            goToStep={(value) => setStepRegistration(value)}
            businessPlan={businessPlanPersonalInfo}
          />
        ) : stepRegistration === 4 ? (
          <RegistrationAccountCheckEmail />
        ) : stepRegistration === 5 ? (
          <RegistrationActivateAccount
            passwordVerificationToken={verificationToken}
          />
        ) : stepRegistration === 7 ? (
          <RegistrationBuildYourCard
            accountInformationToken={accountInformationToken}
            goToStep={(value) => setStepRegistration(value)}
          />
        ) : stepRegistration === 8 ? (
          <RegistrationLocations
            accountInformationToken={accountInformationToken}
            goToStep={(value) => setStepRegistration(value)}
          />
        ) : stepRegistration === 9 ? (
          <RegistrationWelcomePromotion
            accountInformationToken={accountInformationToken}
          />
        ) : null}
      </NavProgress>
      {/* {stepRegistration === 7 ? (
        <RegistrationAccountInformation
          setAccountInformationRegistration={(value) => {
            setAccountInformation((prevInfo) => {
              return {
                ...prevInfo,
                companyName: value.companyName,
                businessType: value.businessType,
                promotionType: value.promotionType,
                rewardInformation: value.rewardInformation,
                maxNumberPromotion: value.maxNumberPromotion,
              };
            });
            setStepRegistration(8);
          }}
        />
      ) : stepRegistration === 8 ? (
        <RegistrationAdditionalInformation
          setAdditionalInformationRegistration={(value) => {
            setAdditionalInformation(value);
            setStepRegistration(9);
          }}
        />
      ) : stepRegistration === 9 ? (
        <RegistrationSetupPromotion
          additionalInformation={additionalInformation}
          accountInformation={accountInformation}
        />
      ) : null} */}
    </Wrapper>
  );
};

export default Registration;
