import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  .main-blocks-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 4px;
    .item {
      flex: 1;
      border-radius: 4px;
      background-color: ${Color.white};
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      padding: 15px 20px;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }

      .title {
        opacity: 0.6;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 15px;
      }
      .count {
        color: ${Color.blueDarkText};
        font-size: 44px;
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 10px;
      }
      .description {
        opacity: 0.2;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
  .add-category{
    margin: 5px 2px;
    width: 20%;
    justify-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 23px;
        height: 23px;
      }
    }
  }
    .add-stampCategory{
    margin: 5px 2px;
    width: 40%;
    justify-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 23px;
        height: 23px;
      }
    }
  }

  .header {
    display: flex;
    align-items: stretch;
    //justify-content: space-between;
    margin-bottom: 15px;
    gap: 15px;
    .column {
      width: 11.4%;

      //.column-group{
      //  display: flex;
      //  //justify-content: space-between;
      //  flex-wrap: nowrap;
      //  gap: 15px;
      //  .column {
      //    width: 50%;
      //  }
      //}
      .page-title {
        font-size: 16px;
        margin: 0 0 10px 0;
        line-height: normal;
      }
      .description{
        font-size: 12px;
        line-height: normal;
      }
      //&:nth-last-child(2){
      //  width: 29%;
      //}
      
    }
    .column_buttons {
      width: 10%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    padding-top: 20px;
    width: 100%;
    .item{
      display: flex;
      justify-content: space-between;
      &>div{
        width: 15%;
        input{
          width: 70%;
          padding-right: 40px;
        }
        &:nth-last-child(1){
          width: 8.5%;
          margin: 0;
        }
      }

    }
  }
  
  .stamp-based-wrapper {
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    position: relative;
    .header {
      flex-direction: column;
      width: 70%;
      .header-tab {
        height: auto;
        display: flex;
        justify-content: space-between;
        gap: 2%;

        .column {
          width: 15%;
        }
      }
      .stamp-based-form {
        display: flex;
        justify-content: space-between;
        .table {
          width: 100%;
            &>div{
                overflow-y: visible;
            }
          .rdt_TableRow{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0 0;
            
            .sc-idiyUo{
              position: absolute;
              z-index: 9999;
              left: -10px;
              margin-bottom: 12px;
            }
            .rdt_TableCell{
              width: 20%;
              padding-left: 0;
              &>div{
                width: 100%;
                input{
                  width: 70%;
                  padding-right: 40px;
                }
                
              }
              .select{
                margin-bottom: 12px;
              }
              &:nth-last-child(1){
                width: 8.5%;
                margin-bottom: 12px;
                .buttons{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  button{
                    width: 45%;
                    padding: 7.5px 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .save-btn{
                    width: 100%;
                    padding: 9.5px 10px;
                  }
                }
                
              }
            }
          }
          
          
          
        }
        .table-edit{
          &>div{
            padding-bottom: 140px;
            .select{
              .bottom{
                //max-height: 130px;
                width: 120%;
              }
            }
          }
        }
        .modal-content{
          text-align: center;
          padding: 60px 50px;
          @media (max-width: 600px) {
            padding: 30px 15px;
          }
          .title {
            color: ${Color.blueDarkText};
            font-size: 30px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
            @media (max-width: 600px) {
              font-size: 23px;
              line-height: 28px;
            }
          }
          .sub-title{
            color: ${Color.blueDarkText};
            font-size: 25px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
            @media (max-width: 600px) {
              font-size: 23px;
              line-height: 28px;
            }
          }
        }
      }
    }
    
  }
  
  @media (max-width: 1300px) {
    .main-blocks-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
      margin-right: -30px;
      margin-bottom: -30px;

      .item.all-customers {
        margin: 0 30px 30px 0;
      }
    }
  }
  @media (max-width: 399px) {
    .main-blocks-row {
      margin-right: 0px;
      .item.all-customers {
        margin-right: 0;
      }
    }
  }
`;

export { Wrapper };
