import styled, { keyframes } from "styled-components";
import { Color } from "src/types/color";

const uploadKeyFrames = keyframes`
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
`;

const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  display: flex;
  padding: 50px 40px 100px 100px;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);

  @media (max-width: 1200px) {
    padding: 40px 30px 40px 50px;
  }
  @media (max-width: 600px) {
    padding: 40px 10px;
  }

  .registration_promotion {
    margin: auto 0;
    width: 100%;
    .btn-blue {
      max-width: 300px;
      font-weight: 300;
    }

    .dropZone {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-bottom: 40px;

      & > div {
        position: relative;
        height: 100%;
        border: 1.5px dashed ${Color.blueDarkText};
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.09);
        outline: none;
        padding: 30px;
        cursor: pointer;
        transition: all 0.225s cubic-bezier(0.77, 0.2, 0.05, 1);

        &.active_true {
          border-color: ${Color.blueDarkText};
          box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 35%);
        }

        input {
          max-width: 100%;
          display: block !important;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          opacity: 0;
          z-index: -1;
        }

        & > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 100%;

          & > svg {
            margin-bottom: 5px;
            animation: ${uploadKeyFrames} 2s cubic-bezier(0.5, 0, 0.5, 1)
              infinite;
            path:last-child {
              fill: ${Color.blueDarkText};
            }
          }

          .text {
            color: ${Color.blueDarkText};
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
              margin-bottom: 5px;
            }
            .title {
              font-size: 18px;
              font-weight: 300;
              line-height: 23px;
            }
            .description {
              opacity: 0.6;
              font-size: 12px;
              font-weight: 300;
              line-height: 15px;
            }
            .file-name {
              font-size: 21px;
              font-weight: 300;
              line-height: 23px;
            }
          }
        }
      }
    }

    .control_switch {
      position: relative;
      display: flex;
      align-items: center;
      max-width: 480px;
      padding: 2px;
      margin-bottom: 30px;
      border: 1px solid #e4e4e4;
      border-radius: 4px;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
      background-color: white;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: 48.5%;
        height: 90%;
        background: linear-gradient(
          0deg,
          ${Color.gradientFreeCardFrom} -16.46%,
          ${Color.gradientFreeCardTo} 98.11%
        );
        border-radius: 4px;
        transition: left 0.3s ease;
      }
      &_right {
        &::before {
          left: 51%;
          transition: left 0.3s ease;
        }
      }
    }
    .btn_switch {
      width: 50%;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 20px;
      color: ${Color.greyLight};
      padding: 9px 1px;
      z-index: 2;
      transition: color 0.3s ease;
      background-color: transparent;
      &_active {
        color: ${Color.white};
      }
      @media (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
  .promotion_title {
    font-size: 48px;
    font-weight: 500;
    line-height: 61px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .promotion_text {
    font-size: 16px;
    font-weight: 300;
    opacity: 0.6;
    line-height: 24px;
    max-width: 600px;
    margin-bottom: 30px;
  }
  /* .promotion_form {
    .form_group {
      margin-bottom: 30px;
    }
    .btn-blue {
      max-width: 200px;
    }
  } */
  .setup_text {
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 20px;
  }
  .toggle_wrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease;
    &_open {
      overflow: visible;
      max-height: 100px;
      transition: max-height 0.4s ease-in;
    }
  }
  .promotion_wrapper {
    position: relative;
    display: flex;
    gap: 40px;
    margin-top: 40px;
    padding: 8px;

    .promotion_left {
      flex-basis: 60%;
      border-right: 1px solid #d8d8d8;
      padding-right: 40px;

      .promotion_form {
        .form-group {
          margin-bottom: 30px;
          & > div:first-child {
            .react-datepicker-wrapper {
              display: block;
              input {
                width: 100%;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }
    .promotion_right {
      flex-basis: 45%;
      .sticky_wrapper {
        position: sticky;
        top: -30px;
      }
      .look_text {
        font-size: 15px;
        font-weight: 500;
        line-height: 19px;
        margin-bottom: 13px;
      }
      .promotion_card_look {
        max-width: 300px;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
        .img_wrapper {
          position: relative;
          max-width: 320px;
          height: 150px;
          .img_look {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .promotion_deal_text {
            position: absolute;
            top: 12px;
            left: 15px;
            font-size: 12px;
            line-height: 12px;
            color: ${Color.white};
            background-color: ${Color.blue};
            padding: 4px 10px;
            border-radius: 2px;
          }
        }
        .card_bottom {
          position: relative;
          padding: 35px 20px 20px;
          .company_logo_wrapper {
            position: absolute;
            top: -34px;
            left: 15px;
            width: 50px;
            height: 50px;
            border: 8px solid white;
            border-radius: 50%;
            overflow: hidden;
            & > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .company_info_wrap {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            .info_bold {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              margin-bottom: 4px;
            }
            .company_info_left {
              .company_location {
                font-size: 11px;
                font-weight: 500;
                line-height: 14px;
                color: ${Color.greyMedium};
              }
            }
            .company_info_right {
              text-align: right;
              .company_open {
                font-size: 11px;
                font-weight: 500;
                line-height: 14px;
                color: ${Color.green};
              }
            }
          }
          .card_bottom_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .icon_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            * {
              margin-right: 8px;
              width: 22px;
              height: 22px;
            }
          }
          .small_button {
            width: 100px;
            font-size: 14px;
            padding: 4px 6px;
          }
          .gift_info_wrap {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 3px;
          }
          .offer_title {
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            color: ${Color.greyMedium};
            margin-right: 6px;
          }
          .offer_term_text {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            color: "000000";
          }
        }
      }
    }
    @media (max-width: 1100px) {
      flex-wrap: wrap;
      margin-top: 20px;
      .promotion_left {
        flex-basis: 100%;
        border-right: none;
        padding: 0px;
        order: 2;
      }
      .promotion_right {
        flex-basis: 100%;
      }
      .promotion_card_look {
        margin: 0 auto;
      }
    }
  }
  .lynked-form {
    .form-error-message {
      margin-top: 5px;
    }
  }
`;

export { Wrapper };
