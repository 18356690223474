import React, {FC, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";

import Modal from "src/components/Modal";
import Input from "src/components/Input";
import Select from "src/components/Select";
import { ReactComponent as SearchIcon } from "src/assets/img/search.svg";
import { ReactComponent as EditIcon } from "src/assets/img/edit.svg";
import { ReactComponent as DeleteIcon } from "src/assets/img/delete.svg";
import { ReactComponent as ArrowBackIcon } from "src/assets/img/arrowBack.svg";
import { ReactComponent as ArrowForwardIcon } from "src/assets/img/arrowForward.svg";

import {
  getCustomerGroupsAxios,
  deleteCustomerGroupAxios, getCustomerListWithFilterGroupsAxios, getCustomerGroupInfoAxios,
} from "src/api/customers";
import {
  CustomerGroupsRequestInterface,
  CustomerGroupsInterface,
  CustomerGroupsInfoInterface,
  customerPerPage, CustomerInterface,
} from "src/types/customer";

import { Wrapper } from "./styled";
import userDefaultImg from "../../../assets/img/default-user.jpg";

const CustomerGroups: FC = () => {
  const [requestParamtrs, setRequestParamtrs] =
    React.useState<CustomerGroupsRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
    });
  const [customerGroupsLoading, setCustomerGroupsLoading] =
    React.useState<boolean>(true);
  const [selectedGroup, setSelectedGroup] = useState<any>({})
  const [selectedGroupUsers, setSelectedGroupUsers] = useState<any[]>([])
  const [customerGroups, setCustomerGroups] =
    React.useState<Array<CustomerGroupsInterface> | null>(null);
  const [customerGroupsInfo, setCustomerGroupsInfo] =
    React.useState<CustomerGroupsInfoInterface>({
      count_groups: 0,
      limit: 0,
      page: 0,
      total_pages_count: 0,
      total_groups_count: 0,
    });

  /*   deleteGroupAsk   */
  const [deleteCustomerGroupLoading, setDeleteCustomerGroupLoading] =
    React.useState<boolean>(false);
  const [deleteCustomerGroup, setDeleteCustomerGroup] =
    React.useState<CustomerGroupsInterface | null>(null);
  const deleteGroupAsk = (obj: CustomerGroupsInterface) => {
    setDeleteCustomerGroup(obj);
  };
  const hendeldeleteGroupConfirm = () => {
    if (deleteCustomerGroup) {
      setDeleteCustomerGroupLoading(true);
      deleteCustomerGroupAxios(deleteCustomerGroup.id.toString())
        .then(() => {
          setCustomerGroupsLoading(true);
          getCustomerGroupsAxios({ limit: "10", page: "1", search: null }).then(
            (response) => {
              setCustomerGroups(response.data.groups);
              setCustomerGroupsInfo({
                count_groups: Number(response.data.count_groups),
                limit: Number(response.data.limit),
                page: Number(response.data.page),
                total_pages_count: Number(response.data.total_pages_count),
                total_groups_count: Number(response.data.total_groups_count),
              });
              setCustomerGroupsLoading(false);
            }
          );
          setDeleteCustomerGroupLoading(false);
          setDeleteCustomerGroup(null);
        })
        .catch(() => {});
    }
  };
  
  const getCustomerGroupUsers = (id: number) =>{
    getCustomerGroupInfoAxios(`${id}`).then(res=>{
      if(res.data.filters){
        const requestParams = {limit: "0",
          page: "1",
          search: null, filters: res.data.filters}
        getCustomerListWithFilterGroupsAxios(requestParams).then(res=>{setSelectedGroupUsers(res.data.users ?? [])})
        
      } else {setSelectedGroupUsers(res.data.users ?? [])}
    })
    
  }
  
  const manualChoosedCustomerTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      },
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.phone}</div>;
      },
    },
    {
      name: "Points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.end_value ?? "-"}</div>;
      },
    },
    {
      name: "Lifetime points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.total_spend ?? "-"}</div>;
      },
    },
    {
      name: "Check in",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.check_in_count ?? "-"}</div>;
      },
    },
    {
      name: "Last visit",
      minWidth: "120px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{new Date(row.last_visit).toLocaleString("en-GB")}</div>;
      },
    },
  ];

  const customerGroupsTableColumns = [
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerGroupsInterface): JSX.Element => {
        return <div>{row.name}</div>;
      },
    },
    {
      name: "Description",
      minWidth: "350px",
      cell: (row: CustomerGroupsInterface): JSX.Element => {
        return <div>{row.description}</div>;
      },
    },
    {
      name: "Type",
      minWidth: "200px",
      cell: (row: CustomerGroupsInterface): JSX.Element => {
        return <div>{row.group_type_title}</div>;
      },
    },
    {
      name: "",
      minWidth: "200px",
      cell: (row: CustomerGroupsInterface): JSX.Element => {
        return (
          <div className="action">
            <Link
              to={`/customers/edit-customer-group/${row.id}`}
              className="edit"
            >
              <EditIcon />
            </Link>
            <button
              type="button"
              className="delete"
              onClick={() => deleteGroupAsk(row)}
            >
              <DeleteIcon />
            </button>
          </div>
        );
      },
    },
  ];

  const handleChangeRequestParamtrs = (
    name: string,
    value: string | number
  ) => {
    setRequestParamtrs((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const getCustomerGroups = () => {
    setCustomerGroupsLoading(true);
    getCustomerGroupsAxios(requestParamtrs).then((response) => {
      setCustomerGroups(response.data.groups);
      setCustomerGroupsInfo({
        count_groups: Number(response.data.count_groups),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_groups_count: Number(response.data.total_groups_count),
      });
      setCustomerGroupsLoading(false);
    });
  };

  useEffect(() => {
    getCustomerGroups();
  }, [requestParamtrs.page]); // eslint-disable-line

  useEffect(() => { // eslint-disable-line
    if (requestParamtrs.search !== null) {
      const timeout = setTimeout(() => {
        getCustomerGroups();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [requestParamtrs.search]); // eslint-disable-line

  useEffect(() => {
    if (Number(requestParamtrs.page) === 1 && customerGroupsInfo.limit !== 0) {
      getCustomerGroups();
    }
  }, [requestParamtrs.limit]); // eslint-disable-line

  return (
    <Wrapper>
      <div className="page-title">Customer Groups</div>
      <div className="top-bar">
        <Link to="/customers/create-customer-group" className="white-btn-link">
          Create new customer groups
        </Link>
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon />}
            getValue={(value) => handleChangeRequestParamtrs("search", value)}
          />
        </div>
      </div>

      <div
        className={`table-wrap ${customerGroupsLoading ? "loading-blue" : ""}`}
      >
        <div className="loading-innerwrap">
          {customerGroups !== null ? (
            customerGroups.length > 0 ? (
              <>
                <div className="table">
                  <DataTable
                    noHeader
                    columns={customerGroupsTableColumns}
                    data={customerGroups}
                    onRowClicked={(row: any) => {
                      if(row.id !== selectedGroup.id){
                        setSelectedGroup(row)
                        getCustomerGroupUsers(+row.id)
                      } else {
                        setSelectedGroup(row)
                      }
                      
                    }}
                    expandableRows
                    expandableRowsHideExpander={true}
                    expandableRowExpanded={(row) => (row === selectedGroup)}
                    expandOnRowClicked={true}
                    expandableRowsComponent={
                      <>
                      <DataTable noHeader columns={manualChoosedCustomerTableColumns} data={selectedGroupUsers}/>
                      </>
                    }
                  />
                </div>
                <div className="paginate-prepage">
                  <div className="paginate">
                    <button
                      className="prev-page"
                      type="button"
                      disabled={customerGroupsInfo.page <= 1}
                      onClick={() =>
                        handleChangeRequestParamtrs(
                          "page",
                          customerGroupsInfo.page - 1
                        )
                      }
                    >
                      <ArrowBackIcon />
                    </button>
                    <button
                      className="next-page"
                      type="button"
                      disabled={
                        customerGroupsInfo.total_pages_count <=
                        customerGroupsInfo.page
                      }
                      onClick={() =>
                        handleChangeRequestParamtrs(
                          "page",
                          customerGroupsInfo.page + 1
                        )
                      }
                    >
                      <ArrowForwardIcon />
                    </button>
                    <div className="text">
                      {customerGroupsInfo.page * customerGroupsInfo.limit -
                        customerGroupsInfo.limit}
                      -
                      {customerGroupsInfo.page * customerGroupsInfo.limit -
                        customerGroupsInfo.limit +
                        customerGroupsInfo.count_groups}{" "}
                      from {customerGroupsInfo.total_groups_count}
                    </div>
                  </div>
                  <div className="perpage">
                    <div className="text">Show on page:</div>
                    <Select
                      selectorList={customerPerPage}
                      defaultValue={{
                        value: requestParamtrs.limit,
                        label: requestParamtrs.limit,
                      }}
                      positionForShow="top"
                      required
                      getValue={(value) => {
                        handleChangeRequestParamtrs("limit", value);
                        handleChangeRequestParamtrs("page", "1");
                      }}
                      hideSelectorFilter
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="nothing-found">Sorry, nothing found</div>
            )
          ) : null}
        </div>
      </div>

      {deleteCustomerGroup && (
        <Modal
          openModal={!!deleteCustomerGroup}
          setOpenModal={() => setDeleteCustomerGroup(null)}
          closeOutsideClick
        >
          <div
            className={`delete-modal ${
              deleteCustomerGroupLoading ? "loading-blue" : ""
            }`}
          >
            <div className="loading-innerwrap">
              <div className="title">
                Please confirm delete customer group &quot;
                {deleteCustomerGroup.name}&quot;
              </div>
              <button
                type="button"
                className="btn-blue"
                onClick={() => {
                  hendeldeleteGroupConfirm();
                }}
              >
                Confirm delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default CustomerGroups;
