import styled from "styled-components";
import { Color } from "src/types/color";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;

  .range_wrapper {
    position: relative;
    width: 100%;
  }
  .input_range {
    appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-out;
    &::-webkit-slider-thumb {
      appearance: none;
      height: 20px;
      width: 20px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      transition: box-shadow 0.3s ease-out;
    }
    &::-moz-range-thumb {
      appearance: none;
      height: 30px;
      width: 30px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      transition: box-shadow 0.3s ease-out;
    }
  }
  .current_value_range {
    position: absolute;
    top: -20px;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
  }
  .diapason_range {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
  }
  .blue_range {
    &::-webkit-slider-thumb {
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
    }
    &::-moz-range-thumb {
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
    }
    &:hover,
    &:focus-visible {
      box-shadow: 0px 0px 8px 2px rgba(81, 92, 221, 0.4);
      &::-webkit-slider-thumb {
        box-shadow: 0px 0px 8px 2px rgba(81, 92, 221, 0.4);
      }
      &::-moz-range-thumb {
        box-shadow: 0px 0px 8px 2px rgba(81, 92, 221, 0.4);
      }
    }
  }
`;
