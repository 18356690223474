import { Color } from "src/types/color";
import styled from "styled-components";

const Wrapper = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  min-height: 18px;
  text-align: left;
  font-size: 16px;
  color: ${Color.blueDarkText};
  cursor: pointer;
  padding-left: 20px;
  margin: 5px 0px;
  user-select: none;

  .check_input {
    appearance: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
    opacity: 0;
  }
  .check_box {
    position: absolute;
    width: 16px;
    height: 16px;
    /* top: 4px; */
    border-radius: 4px;
    margin-left: -20px;
    background-position: center;
    background-size: 120%;
    background-repeat: no-repeat;
    transition: background-color 0.2s ease-out;
  }
  .label_checkbox {
    margin-left: 8px;
  }
  .check_box_darkBlue {
    border: 2px solid ${Color.blueDarkText};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    .check_box {
      top: 1px;
    }
  }
`;

export { Wrapper };
