import React, { FC, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { updatePlacePhotoAxios } from "src/api/place";
import { ReactComponent as UploadIcon } from "src/assets/img/upload.svg";
import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";

import * as Types from "./types";

const BusinessLogo: FC<Types.Props> = ({
  businessLogoProps,
  setBusinessLogoProps,
}) => {
  const [loadingBusinessLogo, setLoadingBusinessLogo] =
    React.useState<boolean>(false);
  const [businessLogo, setBusinessLogo] =
    React.useState<string>(businessLogoProps);

  useEffect(() => {
    setBusinessLogo(businessLogoProps);
  }, [businessLogoProps]);

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles[0]) {
      setLoadingBusinessLogo(true);
      const requestFormData: any = new FormData();
      requestFormData.append("file", acceptedFiles[0]);
      updatePlacePhotoAxios(requestFormData)
        .then((response) => {
          setBusinessLogo(response.data.place.image);
          setBusinessLogoProps(response.data.place.image);
          setLoadingBusinessLogo(false);
        })
        .catch(() => {
          setLoadingBusinessLogo(false);
        });
    }
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    multiple: false,
    onDrop,
  });

  return (
    <div className={`logo-wrap ${loadingBusinessLogo ? "loading-blue" : ""}`}>
      <div className={`${loadingBusinessLogo ? "loading-innerwrap" : ""}`}>
        <div className={`dropZone ${businessLogo ? "with-photo" : ""}`}>
          <div {...getRootProps()} className={`active_${isDragActive}`}>
            {" "}
            {/*eslint-disable-line*/}
            <input {...getInputProps()} required /> {/*eslint-disable-line*/}
            {businessLogo ? (
              <div className="img">
                <img src={businessLogo} alt="" />
              </div>
            ) : null}
            <div className="dropZone-content-text">
              <UploadIcon />
              <span className="text">
                <span className="title">
                  {businessLogo
                    ? "Update your logo"
                    : "Please click here and add your logo"}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="info-sixe">
          <InfoIcon />
          <span className="text">Perfect size - 1080x1080px</span>
        </div>
      </div>
    </div>
  );
};

export default BusinessLogo;
