import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  overflow: hidden;
  max-width: 335px;
  border-radius: 15px;
  background-color: ${Color.white};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  padding: 25px;
  padding-bottom: 0;
  box-sizing: border-box;
  .top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    .logo {
      line-height: 0;
      margin-right: 15px;
      img {
        object-fit: cover;
        height: 60px;
        width: 60px;
        border-radius: 100%;
      }
    }
    .right {
      width: calc(100% - 75px);
    }
    .name-notificationCount {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 4px;
      .name {
        font-family: "Averta Std ExtraBold";
        font-size: 16px;
        line-height: 19px;
        color: #2f3640;
      }
      .notification-count {
        display: flex;
        align-items: center;
        .reward_level {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid ${Color.blue};
          border-radius: 50%;
          width: 18px;
          height: 18px;
          background-color: ${Color.white};
          padding-top: 1px;
          box-sizing: border-box;
          color: ${Color.blue};
          font-size: 12px;
          font-weight: 300;
        }
        @media (max-width: 400px) {
          flex-wrap: wrap;
          .notification {
            flex-basis: 100%;
          }
          .count {
            flex-basis: 100%;
          }
        }
      }
    }
    .info {
      font-family: "Averta Std Semibold";
      .title {
        color: #bcbcbc;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-right: 3px;
      }
      .content {
        color: ${Color.grey};
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
      }
    }
  }
  .promotion-count {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    .promotion-item {
      width: 49px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
      .promotion-item-icon {
        margin: auto;
        width: max-content;
      }
      .promotion-item-icon-number {
        font-family: "Averta Std ExtraBold";
        color: #ffffff;
        position: absolute;
        top: -1px;
        left: -3px;
        font-size: 9px;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        text-align: center;
        line-height: 49px;
      }
    }
    .promotion_done {
      width: 49px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .points-progress-line {
      width: 100%;
      display: flex;
      .progress-line {
        width: calc(100% - 85px);
        margin-right: 10px;
        border-radius: 4px;
        color: ${Color.white};
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        padding: 8px 15px;
        /* &.full { */
        background: #5771e1;
        /* } */
        /* &.empty {
                background-color: #dcdef7;
                color: ${Color.blueDarkText};
              } */
      }
      .progress-done {
        position: relative;
        line-height: 0;
        width: 75px;
        .text {
          position: absolute;
          top: 7px;
          left: 5px;
          color: ${Color.white};
          font-size: 10px;
          font-weight: 300;
          line-height: 11px;
          width: 45px;
          word-wrap: break-word;
        }
        .dark-text {
          color: ${Color.blueDarkText};
        }
      }
    }
  }
  &.full {
    .promotion-count {
      .promotion-item {
        svg {
          g {
            opacity: 1;
          }
          path {
            fill: ${Color.blue};
          }
        }
      }
    }
  }
  .progress_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 8px;
  }
  .progress_line_wrapper {
    flex-grow: 1;
  }
  .progress_reward {
    display: flex;
    width: 70px;
    height: 32px;
    margin-left: 20px;
    text-transform: uppercase;
    color: ${Color.white};
    border-radius: 4px;
    overflow: hidden;
    .progress_reward_off {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background-color: ${Color.orange};
      width: 16px;
      font-size: 6px;
      writing-mode: vertical-rl;
    }
    .progress_reward_one {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      font-size: 9px;
      font-weight: 500;
      padding: 4px;
    }
  }
  .progress_count {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    margin-bottom: 10px;
    color: ${Color.greyMedium};
  }
  .progress_indicator {
    width: 100%;
    appearance: none;
    border-radius: 4px;
    &::-webkit-progress-value {
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      border-radius: 4px;
      height: 12px;
    }
    &::-webkit-progress-bar {
      background-color: rgba(81, 92, 221, 0.2);
      border-radius: 4px;
      height: 12px;
    }
  }
  //
  .card-bottom-control-button {
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
            0deg,
              ${Color.gradientFreeCardFrom} -16.46%,
            ${Color.gradientFreeCardTo} 98.11%
    );
    border: 3px solid white;
    top: 0px;
    left: calc(50% - 13px);
    padding: 0;
  }
  .bottom-top {
    position: absolute;
    top: 0;
    width: 100%;
    height: 15px;
    border-radius: 0px 0px 15px 15px;
    background-color: white;
  }
  .card-closed {
    margin-bottom: 0;
    max-height: 1px;
    overflow: hidden;
    transition: max-height 0.5s ease-in, margin-bottom 0.3s ease-out;
  }
  .card-opened {
    margin-bottom: 24px;
    max-height: 150px;
    transition: max-height 0.5s ease-in, margin-bottom 0.2s ease-out;
  }
  .card-bottom-control-button:hover {
    transition: transform 0.8s ease-in-out;
    background: #ec9345;
  }
  .card-button-closed {
    .card-bottom-control-button {
      transition: transform 0.8s ease-in-out;
      transform: rotate(180deg);
    }
  }
  .card-button-opened {
    .card-bottom-control-button {
      transform: rotate(360deg);
    }
  }
  //
  .card-bottom {
    height: 45px;
    background: linear-gradient(
      0deg,
      ${Color.gradientFreeCardFrom} -16.46%,
      ${Color.gradientFreeCardTo} 98.11%
    );
    margin-left: -25px;
    margin-right: -25px;
    position: relative;
    .card-bottom-control-button {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      border: 3px solid white;
      top: 0px;
      left: calc(50% - 13px);
      padding: 0;
    }
    .bottom-top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 15px;
      border-radius: 0px 0px 15px 15px;
      background-color: white;
    }
    .card-closed {
      margin-bottom: 0;
      max-height: 1px;
      overflow: hidden;
      transition: max-height 0.5s ease-in, margin-bottom 0.3s ease-out;
    }
    .card-opened {
      margin-bottom: 24px;
      max-height: 150px;
      transition: max-height 0.5s ease-in, margin-bottom 0.2s ease-out;
    }
    .card-bottom-control-button:hover {
      transition: transform 0.8s ease-in-out;
      background: #ec9345;
    }
    .card-button-closed {
      .card-bottom-control-button {
        transition: transform 0.8s ease-in-out;
        transform: rotate(180deg);
      }
    }
    .card-button-opened {
      .card-bottom-control-button {
        transform: rotate(360deg);
      }
    }
    // &::after {
    //   position: absolute;
    //   display: block;
    //   content: "";
    //   border-radius: 50%;
    //   width: 35px;
    //   height: 35px;
    //   top: -35px;
    //   left: 0;
    //   box-shadow: -15px 15px 0 blue;
    // }
    // &::before {
    //   position: absolute;
    //   display: block;
    //   content: "";
    //   border-radius: 50%;
    //   width: 35px;
    //   height: 35px;
    //   top: -35px;
    //   right: 0;
    //   box-shadow: 15px 15px 0 blue;
    // }
  }
`;

export { Wrapper };
