import axios from "./api";

export const getEcommerceRuleListAxios = (): Promise<any> =>
  axios
    .get("/api/ecommerce/getCategoryRestrictions")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const createEcommerceRuleAxios = (data:any): Promise<any> =>
  axios
    .post("/api/ecommerce/addCategoryRestrictions", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeEcommerceRuleAxios = (id: number): Promise<any> =>
  axios
    .post("/api/ecommerce/deleteRule", {id})
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
