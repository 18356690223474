import React, { FC, useEffect, useState } from "react";
import { format } from "date-fns";
import DataTable from "react-data-table-component";

import userDefaultImg from "src/assets/img/default-user.jpg";
import { CustomerUserInterface } from "src/types/customer";
import EditablePoint from "./EditablePoinr";
import { ReactComponent as VisibilityIcon } from "src/assets/img/visibility.svg";

import { WrapperTableList } from "./styled";
import TransactionInformation from "../../../Customers/CustomerList/TransactionInformation/TransactionInformation";
import {getCustomerInfoAdminAxios} from "../../../../api/adminPanel";

interface IProps {
  data: any;
  getCustomerList: any;
  requestParamtrs: any;
  setcustomerListLoading: any;
}

function vouchersName(vouchers: any[]) {
  return vouchers.reduce((acc: any, voucher: any) => {
    const { reward_name } = voucher;
    acc.push(reward_name);
    return acc;
  }, []).join(", ") || "-";
}

function getRewards(info: CustomerUserInterface, key: string) {
  const { vouchers, stamp_activity, used_vouchers } = info;

  if (key === "earned") {
    // @ts-ignore
    return vouchers.length ? vouchersName(vouchers) : stamp_activity ? stamp_activity[key] : "-";
  } else {
    // @ts-ignore
    return used_vouchers.length ? vouchersName(used_vouchers) : stamp_activity ? stamp_activity[key] : "-";
  }

}

const ExpandableTableList: FC<IProps> = ({ data, getCustomerList, requestParamtrs, setcustomerListLoading }) => {
  const [userInfoPoints, setUserInfoPoints] = useState<Array<CustomerUserInterface>>([]);
  const [userInfoStamps, setUserInfoStamps] = useState<Array<CustomerUserInterface>>([]);
  const { photo, first_name, last_name } = data;
  const [showTransactionDetail, setShowTransactionDetail] = useState(false);
  const [customerInformation, setCustomerInformation] = useState<any>({});
  const [customer, setCustomer] = useState<any>({});
  const [activeType, setActiveType] = useState<string>('Points')
  
  
  const handleChangeActiveChart = (chartName: any) => {
    setActiveType(chartName);
    // todo
    // setTimeout(() => {
    //   chartRef.current?.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "nearest",
    //   });
    // }, 200);
  };

  const getUserPointInfo = (id: string) => {
    setcustomerListLoading(true)
    getCustomerInfoAdminAxios(`${id}`, requestParamtrs.search, requestParamtrs.searchType, requestParamtrs.location, format(requestParamtrs.startDate, "dd-MM-yyyy"), format(requestParamtrs.endDate, "dd-MM-yyyy")).then((response) => {
      setUserInfoPoints(response.data.activities.points || []);
      setUserInfoStamps(response.data.activities.stamps || []);
      
    }).finally(()=>{
      setcustomerListLoading(false)
    });
  };

  useEffect(() => {
    getUserPointInfo(data.id);
  }, [data.id, requestParamtrs]);

  const customerTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (): JSX.Element => {
        return (
          <div className="photo">
            {data.photo ? (
              <img src={photo} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "",
      minWidth: "150px",
      cell: (): JSX.Element => {
        return (
          <div>
            {first_name} {last_name}
          </div>
        );
      }
    },
    {
      name: 'Points',
      center: true,
      cell: ({ min_points, can_edit, points, activity_id, stamp_activity }: CustomerUserInterface): JSX.Element => {
        return <EditablePoint minValue={min_points} canEdit={can_edit} points={points}
                              pointId={activity_id} getCustomerList={getCustomerList} stamp_activity={stamp_activity}
                              getUserPointInfo={getUserPointInfo} userId={data.id} />;
      }
    },
    {
      name: `Reward Earned`,
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{getRewards(row, "earned")}</div>;
      }
    },
    {
      name: `Reward Used`,
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{getRewards(row, "used")}</div>;
      }
    },
    {
      name: "Store",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.store || "-"}</div>;
      }
    },
    {
      name: "POS.I.D",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.pos_id || "-"}</div>;
      }
    },
    {
      name: "POS User .I.D",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.pos_user_id || "-"}</div>;
      }
    },
    {
      name: "Receipt No.",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.receipt || "-"}</div>;
      }
    },
    {
      name: "Time",
      minWidth: "120px",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return (
          <div>
            {row.date
              ? format(new Date(row.date), "dd/MM/yyyy, HH:mm:ss")
              : "-"}
          </div>
        );
      }
    },
    {
      name: "Basket",
      maxWidth: "75px",
      right: true,
      cell: (row: any, i: number): JSX.Element => {
        return (
          <>
            {row.basket ? (<div className={"show-order"} data-tag="allowRowEvents">
              <VisibilityIcon data-tag="allowRowEvents" className={"visibility-icon"} />
            </div>) : null}

          </>
        );
      }
    }
  ];
  const customerTableColumnsStamp = [
    {
      name: "",
      width: "50px",
      cell: (): JSX.Element => {
        return (
          <div className="photo">
            {data.photo ? (
              <img src={photo} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "",
      minWidth: "150px",
      cell: (): JSX.Element => {
        return (
          <div>
            {first_name} {last_name}
          </div>
        );
      }
    },
    {
      name: 'Stamps',
      center: true,
      cell: ({ min_points, can_edit, points, activity_id, stamp_activity }: CustomerUserInterface): JSX.Element => {
        return <EditablePoint minValue={min_points} canEdit={can_edit} points={points}
                              pointId={activity_id} getCustomerList={getCustomerList} stamp_activity={stamp_activity}
                              getUserPointInfo={getUserPointInfo} userId={data.id} />;
      }
    },
    {
      name: `Stamps Earned`,
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{getRewards(row, "earned")}</div>;
      }
    },
    {
      name: `Stamps Used`,
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{getRewards(row, "used")}</div>;
      }
    },
    {
      name: "Staff i.d.",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return <div>{row.seller || "-"}</div>;
      }
    },
    {
      name: "Time",
      minWidth: "120px",
      cell: (row: CustomerUserInterface): JSX.Element => {
        return (
          <div>
            {row.date
              ? format(new Date(row.date), "dd/MM/yyyy, HH:mm:ss")
              : "-"}
          </div>
        );
      }
    }

  ];

  return (
    <WrapperTableList>
      {(!!userInfoPoints || !!userInfoStamps ) &&
          <>
              <div className="nav-subpages">
                  <div
                      className={`nav-subpage ${
                        activeType === "Points" ? "active-page" : ""
                      }`}
                  >
                      <button
                          type="button"
                          onClick={() => handleChangeActiveChart("Points")}
                      >
                          Points
                      </button>
                  </div>
                  <div
                      className={`nav-subpage ${
                        activeType === "Stamps" ? "active-page" : ""
                      }`}
                  >
                      <button
                          type="button"
                          onClick={() => handleChangeActiveChart("Stamps")}
                      >
                          Stamps
                      </button>
                  </div>
              </div>
              <div className="chart-wrapper">
                  <div className="chart">
                    {activeType === 'Points' ? <DataTable
                      data={userInfoPoints}
                      columns={customerTableColumns}
                      noHeader
                      onRowClicked={(row: any) => {
                        setShowTransactionDetail(true);
                        row.basket && setCustomerInformation(row.basket);
                        setCustomer(row);
                      }}
                      expandableRows
                      expandableRowsHideExpander={true}
                      expandOnRowClicked={true}
                      expandableRowsComponent={
                        <TransactionInformation show={showTransactionDetail} customerInformation={customerInformation}
                                                productList={customerInformation.productsList} customer={customer}
                                                setShow={setShowTransactionDetail}/>
                      }
                    /> : <DataTable
                      data={userInfoStamps}
                      columns={customerTableColumnsStamp}
                      noHeader
                      onRowClicked={(row: any) => {
                        setShowTransactionDetail(true);
                        row.basket && setCustomerInformation(row.basket);
                        setCustomer(row);
                      }}
                      expandableRows
                      expandableRowsHideExpander={true}
                      expandOnRowClicked={true}
                      expandableRowsComponent={
                        <TransactionInformation show={showTransactionDetail} customerInformation={customerInformation}
                                                productList={customerInformation.productsList} customer={customer}
                                                setShow={setShowTransactionDetail}/>
                      }
                    />}
                  </div>
              </div>
            
          </>
      }
    </WrapperTableList>
  
  );
};

export default ExpandableTableList;
