import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  .top-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    .select {
      margin-bottom: 15px;
    }
    input {
      padding: 10px 32px 10px 14px;
    }
  }
  .search-wrap {
    width: 320px;
    max-width: 100%;
    margin-left: 20px;
  }
  .groups-wrap {
    width: 200px;
    max-width: 100%;
    margin-left: 20px;
    ul {
      button {
        text-align: left;
      }
    }
  }
  .locations-wrap {
    width: 200px;
    max-width: 100%;
    margin-left: 20px;
  }

  .table-wrap {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    &.loading-blue {
      min-height: 300px;
    }
  }
  .table {
    button[data-testid] {
      &:hover:not(:disabled),
      &:focus {
        background: none;
      }
    }
    .rdt_Table {
    }
    .rdt_TableHead {
      border: none;
      .rdt_TableCol {
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }
    .rdt_TableRow {
      border: none;
      padding: 15px 0;
      &:nth-child(2n + 1) {
        background-color: ${Color.blueLightBackground};
      }
      .photo {
        line-height: 0;
        img {
          height: 34px;
          width: 34px;
          border-radius: 2px;
          object-fit: cover;
        }
      }
      .rdt_TableCell {
        &:nth-child(n + 2) {
          opacity: 0.6;
          color: ${Color.blueDarkText};
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
        }
      }
    }
    div:first-child {
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${Color.white};
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: ${Color.blueLight};
        }
        &::-webkit-scrollbar-thumb:hover {
          background: ${Color.blueText};
        }
      }
    }
  }

  .paginate-prepage {
    background-color: ${Color.white};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 25px 30px;
  }
  .paginate {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .prev-page,
    .next-page {
      height: 32px;
      width: 32px;
      border: 1px solid rgba(47, 54, 64, 0.2);
      border-radius: 3px;
      background-color: ${Color.white};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      &:hover:not([disabled]) {
        box-shadow: rgba(47, 54, 64, 0.25) 0px 0px 10px 0px;
        border: 1px solid ${Color.grey};
      }
      &:disabled {
        opacity: 0.5;
        background: rgba(0, 0, 0, 0.1);
        cursor: initial;
      }
    }
    .text {
      color: ${Color.blueDarkText};
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
    }
  }
  .perpage {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .text {
      margin-right: 12px;
      color: ${Color.blueDarkText};
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
    }
    .select {
      margin-bottom: 0;
      width: 90px;
      & > input,
      & > ul {
        color: ${Color.blueDarkText};
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
      }
      & > input {
        padding: 8px 30px 7px 10px;
      }
      & > ul {
        & > button {
          padding: 6px 10px;
        }
      }
    }
  }

  .nothing-found {
    padding: 50px 15px;
    text-align: center;
    color: ${Color.blueDarkText};
    font-size: 21px;
    font-weight: 300;
    line-height: 20px;
  }
  @media (max-width: 1140px) {
    .top-bar {
      flex-direction: column-reverse;
      align-items: start;
      margin-bottom: 10px;

      .locations-wrap,
      .groups-wrap {
        margin: 0 0 10px;
      }
      .search-wrap {
        margin-left: 0;
      }
    }
  }
  @media (max-width: 500px) {
    .table-wrap {
      .paginate-prepage {
        flex-direction: column;
        align-items: start;

        .paginate {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export { Wrapper };
