import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";

import DataTable from "react-data-table-component";

import Input from "src/components/Input";
import Select from "src/components/Select";
import { ReactComponent as SearchIcon } from "src/assets/img/search.svg";
import { ReactComponent as EditIcon } from "src/assets/img/edit.svg";
import { ReactComponent as DeleteIcon } from "src/assets/img/delete.svg";
import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import { ReactComponent as ArrowBackIcon } from "src/assets/img/arrowBack.svg";
import { ReactComponent as ArrowForwardIcon } from "src/assets/img/arrowForward.svg";

import { getCampaignsAxios, deleteCampaignsAxios } from "src/api/campaigns";
import {
  CampaignRequestInterface,
  CampaignInfoInterface,
  CampaignInterface,
  campaignsPerPage,
} from "src/types/campaign";

import { Wrapper } from "./styled";
import Modal from "src/components/Modal";

const ManageCampaigns: FC = () => {
  const [requestParamtrs, setRequestParamtrs] =
    React.useState<CampaignRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
    });
  const [ManageCampaignsLoading, setManageCampaignsLoading] =
    React.useState<boolean>(true);
  const [manageCampaigns, setManageCampaigns] =
    React.useState<Array<CampaignInterface> | null>(null);
  // const [
  //   customerGroupDelete,
  //   setCustomerGroupDelete,
  // ] = React.useState<DeleteCampaignInterface>({id: 0});
  const [manageCampaignsInfo, setManageCampaignsInfo] =
    React.useState<CampaignInfoInterface>({
      count_campaigns: 0,
      limit: 0,
      page: 0,
      total_pages_count: 0,
      total_campaigns_count: 0,
    });
  const [deleteCampaign, setDeleteCampaign] = React.useState<boolean>(false);
  const [deleteCampaignObj, setDeleteCampaignObj] = React.useState<any>(null);

  const deleteGroupAsk = (obj: CampaignInterface) => {
    if(obj.status !== 'Sent'){
      deleteCampaignsAxios({ id: obj.id });
    } 
    

    const timeout = setTimeout(() => {
      setDeleteCampaign(false);
      setDeleteCampaignObj(null);
      getManageCampaigns();
      
    }, 500);

    return () => clearTimeout(timeout);
  };

  const manageCampaignsTableColumns = [
    {
      name: "Campaign name",
      minWidth: "300px",
      cell: (row: CampaignInterface): JSX.Element => {
        return <div>{row.name}</div>;
      },
    },
    {
      name: "Applies to",
      minWidth: "150px",
      cell: (row: CampaignInterface): JSX.Element => {
        return <div>{row.group_name || "All customers"}</div>;
      },
    },
    {
      name: "Promotion",
      minWidth: "90px",
      cell: (row: CampaignInterface): JSX.Element => {
        return <div>{row.promotion < 1 ? "No" : "Yes"}</div>;
      },
    },
    {
      name: "Status",
      minWidth: "200px",
      cell: (row: CampaignInterface): JSX.Element => {
        return <div className={`status_${row.status}`}>{row.status}</div>;
      },
    },
    {
      name: "",
      minWidth: "200px",
      cell: (row: CampaignInterface): JSX.Element => {
        return (
          <div className="action">
            <div className="info">
              <InfoIcon />
              <span>{row.created}</span>
            </div>
            <Link to={`/campaigns/edit-campaign/${row.id}`} className="edit">
              <EditIcon />
            </Link>
            <button
              type="button"
              className="delete"
              onClick={() => {setDeleteCampaignObj(row); setDeleteCampaign(true)}}
            >
              <DeleteIcon />
            </button>
          </div>
        );
      },
    },
  ];

  const handleChangeRequestParamtrs = (
    name: string,
    value: string | number
  ) => {
    setRequestParamtrs((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  const getManageCampaigns = () => {
    setManageCampaignsLoading(true);
    getCampaignsAxios(requestParamtrs).then((response) => {
      setManageCampaigns(response.data.campaigns);
      setManageCampaignsInfo({
        count_campaigns: Number(response.data.count_campaigns),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_campaigns_count: Number(response.data.total_campaigns_count),
      });
      setManageCampaignsLoading(false);
    });
  };

  useEffect(() => {
    getManageCampaigns();
  }, [requestParamtrs.page]); // eslint-disable-line

  useEffect(() => {
    // eslint-disable-line
    if (requestParamtrs.search !== null) {
      const timeout = setTimeout(() => {
        getManageCampaigns();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [requestParamtrs.search]); // eslint-disable-line

  useEffect(() => {
    if (Number(requestParamtrs.page) === 1 && manageCampaignsInfo.limit !== 0) {
      getManageCampaigns();
    }
  }, [requestParamtrs.limit]); // eslint-disable-line

  return (
    <Wrapper>
      <div className="page-title">Campaigns</div>
      <div className="top-bar">
        <Link to="/campaigns/create-campaign" className="white-btn-link">
          Create new campaign
        </Link>
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon />}
            getValue={(value) => handleChangeRequestParamtrs("search", value)}
          />
        </div>
      </div>

      <div
        className={`table-wrap ${ManageCampaignsLoading ? "loading-blue" : ""}`}
      >
        <div className="loading-innerwrap">
          {manageCampaigns !== null ? (
            manageCampaigns.length > 0 ? (
              <>
                <div className="table">
                  <DataTable
                    noHeader
                    columns={manageCampaignsTableColumns}
                    data={manageCampaigns}
                  />
                </div>
                <div className="paginate-prepage">
                  <div className="paginate">
                    <button
                      className="prev-page"
                      type="button"
                      disabled={manageCampaignsInfo.page <= 1}
                      onClick={() =>
                        handleChangeRequestParamtrs(
                          "page",
                          manageCampaignsInfo.page - 1
                        )
                      }
                    >
                      <ArrowBackIcon />
                    </button>
                    <button
                      className="next-page"
                      type="button"
                      disabled={
                        manageCampaignsInfo.total_pages_count <=
                        manageCampaignsInfo.page
                      }
                      onClick={() =>
                        handleChangeRequestParamtrs(
                          "page",
                          manageCampaignsInfo.page + 1
                        )
                      }
                    >
                      <ArrowForwardIcon />
                    </button>
                    <div className="text">
                      {manageCampaignsInfo.page * manageCampaignsInfo.limit -
                        manageCampaignsInfo.limit}
                      -
                      {manageCampaignsInfo.page * manageCampaignsInfo.limit -
                        manageCampaignsInfo.limit +
                        manageCampaignsInfo.count_campaigns}{" "}
                      from {manageCampaignsInfo.total_campaigns_count}
                    </div>
                  </div>
                  <div className="perpage">
                    <div className="text">Show on page:</div>
                    <Select
                      selectorList={campaignsPerPage}
                      defaultValue={{
                        value: requestParamtrs.limit,
                        label: requestParamtrs.limit,
                      }}
                      positionForShow="top"
                      required
                      getValue={(value) => {
                        handleChangeRequestParamtrs("limit", value);
                        handleChangeRequestParamtrs("page", "1");
                      }}
                      hideSelectorFilter
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="nothing-found">Sorry, nothing found</div>
            )
          ) : null}
        </div>
      </div>
      {deleteCampaign && (
        <Modal
          openModal={deleteCampaign}
          setOpenModal={() => setDeleteCampaign(false)}
          closeOutsideClick
        >
          <div
            className={`delete-modal`}
          >
            <div className="loading-innerwrap">
              {/* <div className="title"> */}
                {deleteCampaignObj.status !== 'Sent' ? (<div className="title">Are you sure you want to delete the campaign &quot;
                {deleteCampaignObj.name}&quot;?</div>) : (<div className="title">You can not delete the campaing</div>
)}
              
              {/* </div> */}
              <button
                type="button"
                className="btn-blue"
                onClick={() => {
                  deleteGroupAsk(deleteCampaignObj);
                }}
              >
                {deleteCampaignObj.status !== 'Sent' ? 'Confirm delete' : 'Confirm'}
                
              </button>
            </div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default ManageCampaigns;
