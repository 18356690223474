import React from 'react';
import {Wrapper} from "./styled";
import Modal from "../Modal";

interface IRemoveModalProps {
  showModal: boolean;
  setShowModal: any;
  title: string;
  handleCancel: () => void;
  handleConfirm: () => void;
}

const RemoveModal = ({showModal, setShowModal, handleConfirm, handleCancel, title}: IRemoveModalProps) => {
  return (
    <Wrapper>
      {showModal && (<Modal
        openModal={showModal}
        setOpenModal={() => setShowModal(false)}
        closeOutsideClick
      >
        <div
          className={`delete-modal`}
        >
          <div className="loading-innerwrap">
            <div className="title">{title}</div>
            <div className="btn_wrap">
              <button
                type="button"
                className="btn-red"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-blue"
                onClick={handleConfirm}
              >
                Confirm delete
              </button>
            </div>
          </div>
        </div>
      </Modal>)}
      
    </Wrapper>
  );
};

export default RemoveModal;
