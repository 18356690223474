import axios from "./api";

export const getManageBillingUrlAxios = (): Promise<any> =>
  axios
    .get("/api/generateBillingUrl")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateBusinessPlanAxios = (price_id: string): Promise<any> =>
  axios
    .post("/api/user/updateSubscription", { price_id })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getSubscriptionData = (): Promise<any> =>
  axios
    .post("/api/user/getSubscriptionData")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
