import React, { useEffect } from "react";

import { ReactComponent as VisibilityIcon } from "src/assets/img/visibility.svg";
import { ReactComponent as InvisibleIcon } from "src/assets/img/invisible.svg";
import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import { ReactComponent as LockIcon } from "src/assets/img/lock.svg";

import { Wrapper } from "./styled";

interface IInputProps {
  type?: "number" | "email" | "password";
  label?: string;
  placeholder?: string;
  infoTooltip?: string;
  defaultValue?: string | number;
  icon?: React.SVGProps<SVGElement>;
  min?: number;
  max?: number;
  maxLength?: number;
  step?: number;
  required?: boolean;
  error?: string;
  disabled?: boolean;
  autoComplete?: "on" | "off" | any;
  getValue?: (value: any) => void;
  onFocus?: () => void;
}

const Input = ({
  label,
  type,
  placeholder,
  infoTooltip,
  defaultValue,
  icon,
  min,
  max,
  maxLength,
  step,
  required,
  error,
  disabled,
  autoComplete = "on",
  getValue,
  onFocus,
}: IInputProps) => {
  const [value, setValue] = React.useState<string | number>(defaultValue || "");

  useEffect(() => {
    if (defaultValue && defaultValue !== value) {
      setValue(defaultValue);
    }
    if (!defaultValue && type !== 'number') {
      setValue("");
    }
  }, [defaultValue]); //eslint-disable-line

  const [isPasswordVisible, setIsPasswordVisible] =
    React.useState<boolean>(false);

  const handleChange = (currentValue: string) => {
    setValue(currentValue);
    if (getValue) getValue(currentValue);
  };

  return (
    <Wrapper
      data-tag="allowRowEvents"
      required={required}
      isLabel={!!label}
      isPasswordVisible={isPasswordVisible}
    >
      <span data-tag="allowRowEvents" className="label">{label}</span>
      {icon}
      {infoTooltip && (
        <span data-tag="allowRowEvents" className="info-tooltip">
          <InfoIcon />
          <span data-tag="allowRowEvents" className="tooltip">{infoTooltip}</span>
        </span>
      )}
      <input data-tag="allowRowEvents"
        type={
          type === "password" && isPasswordVisible ? "text" : type || "text"
        }
        className={type === "password" ? " password " : ""}
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onChange={(event) => {
          return handleChange(event.target.value);
        }}
        onFocus={() => {
          if (onFocus) onFocus();
        }}
        autoComplete={autoComplete}
        maxLength={maxLength || undefined}
        minLength={type === "password" ? 8 : undefined}
        min={type === "number" && min ? min : undefined}
        max={type === "number" && max ? max : undefined}
        step={type === "number" && step ? step : undefined}
        onWheel={(e: any) => e.target.blur()}
      />
      {type === "password" && (
        <button data-tag="allowRowEvents"
          type="button"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          <VisibilityIcon />
          <InvisibleIcon />
        </button>
      )}
      {error ? <p data-tag="allowRowEvents" className="input_error">{error}</p> : null}
      {disabled && (
        <span data-tag="allowRowEvents" className="lock-icon-wrap">
          <LockIcon />
        </span>
      )}
    </Wrapper>
  );
};

export default Input;
