import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.span`
  width: 26px;
  position: relative;
  display: inline-block;
  height: 26px;
  margin-bottom: -10px;
  margin-left: 10px;

  .info-tooltip {
    position: absolute;
    top: -2px;
    width: 24px;
    height: 24px;
    margin: auto;
    cursor: pointer;
    transition: 0.3s all ease;
    .tooltip {
      transition: 0.3s all ease;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      bottom: 40px;
      left: -10px;
      width: 250px;
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      color: ${Color.white};
      -webkit-text-fill-color: ${Color.white};
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 15px;
      padding: 10px;
      border-radius: 6px;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 20px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${Color.blue} transparent transparent transparent;
      }
    }
    & svg path.circle {
      fill: #000d46;
      opacity: 0.6;
    }
    &:hover {
      svg path.circle {
        fill: ${Color.blue};
        opacity: 1;
      }
      .tooltip {
        visibility: visible;
        opacity: 1;
        z-index: 300;
      }
    }

    .tooltip {
      &.hiddeArrow {
        bottom: -25px;
        left: 40px;
        transition: none;

        &:after {
          display: none;
        }
      }
    }

    & + input {
      padding-right: 35px;
    }
  }
`;

export { Wrapper };
