import axios from "./api";

const getBusinessPlansAxios = (): Promise<any> =>
  axios
    .get("/public/getPlansInfo")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export { getBusinessPlansAxios };
