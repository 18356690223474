/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useState } from "react";

import Input from "src/components/Input";
import Card from "src/components/Card";
import Select from "src/components/Select";
import CheckBox from "src/components/CheckBox";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
import { BusinessInfoInterface } from "src/types/place";
import { getPlaceInfoAxios, updateBusinessInfoAxios } from "src/api/place";
import {
  getActivateDaysAxios,
  getBusinessTypesAxios,
  getStampIconsAxios
} from "src/views/Registration/Step/RegistrationBuildYourCard/api";
import { SelectorListObj } from "src/components/Select/types";
import { getPromotionsCategoriesAxios } from "src/api/promotion";
import { stampList } from "src/views/Registration/consts";
import { pointObjValuesToString } from "src/utils/helper";

import BusinessLogo from "src/views/Promotions/BusinessLogo";
import { stampIcon } from "src/types/stampIcon";
import { StampIconAdditionalData } from "./types";
import { DaysWrapperDiv, SpanInputText, Wrapper } from "./styled";
import {businessType} from "../../../types/businessType";
import PointsCollectionSettings from "../../../components/PointsCollectionSettings/PointsCollectionSettings";
import moment from "moment";

const Promotion: FC = () => {
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const [getInfoLoading, setGetInfoLoadingLoading] =
    React.useState<boolean>(true);

  const [cardClosed, setCardClosed] = useState<boolean>(true);
  // const [cardImage, setCardImage] = useState<File[]>();

  const [isOpenRewards] = useState(true);

  const [formBusinessInfoLoading, setFormBusinessInfoLoading] =
    React.useState<boolean>(false);

  const [formBusinessInfoErrorMessage, setFormBusinessInfoErrorMessage] =
    React.useState<string>("");
  
  const [ pointsCollectionSettingsObj, setPointsCollectionSettingsObj ] = useState({
    promotionPointsValue: 1,
    promotionPointsType: '1',
    promotionPointsStartDate: moment(new Date()).format('YYYY-MM-DD'),
    promotionPointsEndDate: moment(new Date()).add(7,'days').format('YYYY-MM-DD'),
    promotionPointsSelectedTypeValue: ''
  })

  const [businessInfo, setBusinessInfo] = React.useState<BusinessInfoInterface>(
    {
      name: "",
      category: "",
      promotionName: "",
      promotionTypeName: "",
      promotionVaucherInformation: "",
      promotionTypeID: 0,
      maxNumberPromotion: 0,
      verification_status: null,
      promotionLabel: null,
      businessType: {
        display_name: null,
        id: null,
        name: null,
      },
      stampIcon: {
        display_name: null,
        id: null,
        name: null,
        single_item: null,
      },
      points: [],
      website_url: "",
      defaultVoucherName: "",
      instagram_url: "",
      facebook_url: "",
      first_checkIn: '',
      bonusRewardTarget: 0,
      bonusRewardValue: 0,
      bonusRewardLabel: '',
      bonusRewardExpireDays: '30'
    }
  );

  const [openExpireDateReward, setOpenExpireDateReward] = useState<number[]>(
    []
  );

  const [businessLogo, setBusinessLogo] = React.useState<string>("");

  const [stampIconList, setStampIconList] = useState<SelectorListObj[]>([]);

  const [activateDaysList, setActivateDaysList] = useState<Array<SelectorListObj>>(
    []
  );

  // const [locations, setLocations] = React.useState<
  //   Array<LocationInfoInterface>
  // >([]);
  // const [locationMaxCount, setLocationMaxCount] = React.useState<number>(0);

  const { promotionTypes, promotionTypesEnable } = useTypedSelector(
    (state) => state.promotion
  );
  const { getPromotionsCategories } = useActions();
  // const [newLocationImage, setNewLocationImage] = React.useState<string>("");
  // const [
  //   newLocationPromotionDealId,
  //   setNewLocationPromotionDealId,
  // ] = React.useState<string | number>("");
  // const [
  //   newLocationPromotionName,
  //   setNewLocationPromotionName,
  // ] = React.useState<string>("");
  // const [
  //   newLocationPromotionDetails,
  //   setNewLocationPromotionDetails,
  // ] = React.useState<string>("");
  const [promotionDeal, setPromotionDeal] = React.useState<
    [
      {
        value: string | number;
        label: string;
      }
    ]
  >([
    {
      value: "",
      label: "",
    },
  ]);

  useEffect(() => {
    if (promotionTypes.length === 0) {
      getPromotionsCategories();
    }
  }, []); //eslint-disable-line

  const getPlaceInfo = () => {
    getPlaceInfoAxios().then(({ data }) => {
      setGetInfoLoadingLoading(false);
      setBusinessLogo(data.place.image);
      setPointsCollectionSettingsObj({
        ...pointsCollectionSettingsObj,
        promotionPointsSelectedTypeValue: data.placeOption.promotionPointsSelectedTypeValue ? data.placeOption.promotionPointsSelectedTypeValue : '0',
        promotionPointsEndDate: data.placeOption.promotionPointsEndDate ? data.placeOption.promotionPointsEndDate : moment(new Date()).add(7, 'days').format('YYYY-MM-DD'),
        promotionPointsStartDate: data.placeOption.promotionPointsStartDate ? data.placeOption.promotionPointsStartDate : new Date(),
        promotionPointsType: data.placeOption.promotionPointsType ? data.placeOption.promotionPointsType : 1,
        promotionPointsValue: data.placeOption.promotionPointsValue ? data.placeOption.promotionPointsValue : 1,
      })
      setBusinessInfo({
        ...businessInfo,
        name: data.place.name,
        category: data.place.category,
        promotionName: data.place.details,
        promotionVaucherInformation: data.place.description,
        promotionTypeName: data.place.promotion,
        promotionTypeID: data.place.promotion_id,
        maxNumberPromotion: data.place.maxStamp,
        verification_status: data.place.verification_status,
        promotionLabel: data.place.promotion_label,
        businessType: {
          display_name: data.place.business_type.display_name,
          id: data.place.business_type.id,
          name: data.place.business_type.name,
        },
        stampIcon: {
          display_name: data.place.stamp_icon?.display_name,
          id: data.place.stamp_icon?.id,
          name: data.place.stamp_icon?.name,
          single_item: data.place.stamp_icon?.single_item,
        },
        points: data.place.points.filter((item: any)=>item.min_spend === 0),
        website_url: data.place.website_url,
        defaultVoucherName: data.place.defaultVoucherName,
        instagram_url: data.place.instagram_url,
        facebook_url: data.place.facebook_url,
        bonusRewardTarget: data.placeOption.bonusRewardTarget,
        bonusRewardValue: data.placeOption.bonusRewardValue,
        bonusRewardLabel: data.placeOption.bonusRewardLabel,
        bonusRewardExpireDays: data.placeOption.bonusRewardExpireDays,
        first_checkIn: data.place.first_checkIn ?? 0,
      });
      // setLocations(data.locations);
      // setLocationMaxCount(data.location_max_count);
    });
  };
  const getPromotionDealList = () => {
    getPromotionsCategoriesAxios().then((response) => {
      const arr: any = [];
      response.data.promotion_details.map((item: any) =>
        arr.push({
          value: item.id,
          label: item.name,
        })
      );
      setPromotionDeal(arr);
    });
  };

  useEffect(() => {
    getActivateDaysAxios().then((response) => {
      setActivateDaysList(response.data.activateDays);
    });

  }, []);


  const handleGetStampIcons = () => {
    getStampIconsAxios().then((response) => {
      setStampIconList(
        response.data.stampIcons.map((_stampIcon: stampIcon) => {
          return {
            value: _stampIcon.id,
            label: _stampIcon.display_name,
            additional: {
              icon_name: _stampIcon.name,
              single_item: _stampIcon.single_item,
            },
          };
        })
      );
    });
  };

  useEffect(() => {
    getPlaceInfo();
    getPromotionDealList();
    handleGetStampIcons();
  }, []); //eslint-disable-line

  const setBusinessInfoValue = (type: string, value: number | string | {id: number}) => {
    if (formBusinessInfoErrorMessage) {
      setFormBusinessInfoErrorMessage("");
    }
    setBusinessInfo((prevState: any) => {
      return { ...prevState, [type]: value };
    });
  };

  const handleUpdateBusinessInfo = (event: React.FormEvent) => {
    event.preventDefault();
    setFormBusinessInfoLoading(true);
    const requestParamtrs= {
      business_name: businessInfo.name,
      business_category: businessInfo.category,
      promotion_name: businessInfo.promotionName,
      businessType: businessInfo?.businessType?.id,
      description: businessInfo.promotionVaucherInformation,
      promotion_type: businessInfo.promotionTypeID,
      max_count: businessInfo.maxNumberPromotion,
      promotion_label: businessInfo.promotionLabel,
      points: [
        ...businessInfo.points!.map((pointItem) => ({
          id: pointItem.id,
          rewardNumber: businessInfo!.points![0].rewardNumber,
          activateAfter: businessInfo!.points![0].activateAfter,
          pointsAmount:businessInfo!.points![0].pointsAmount,
          reward: businessInfo!.points![0].reward,
          rewardDetails: businessInfo!.points![0].rewardDetails,
          dateExpire: businessInfo!.points![0].dateExpire ?? 0,
        })),
      ],
      bonusRewardTarget: loggedUser.user.allow_bonus_reward ? businessInfo.bonusRewardTarget : null,
      bonusRewardValue: loggedUser.user.allow_bonus_reward ? businessInfo.bonusRewardValue : null,
      bonusRewardLabel: loggedUser.user.allow_bonus_reward ? businessInfo.bonusRewardLabel : null,
      bonusRewardExpireDays: loggedUser.user.allow_bonus_reward ? businessInfo.bonusRewardExpireDays : null,
      stamp_icon: businessInfo.stampIcon!,
      website_url: businessInfo.website_url,
      defaultVoucherName: businessInfo.defaultVoucherName,
      instagram_url: businessInfo.instagram_url,
      facebook_url: businessInfo.facebook_url,
      first_checkIn: businessInfo.first_checkIn ?? 0,
    };
    // todo
    // @ts-ignore
    updateBusinessInfoAxios(loggedUser.user.allow_promotion_points ? {...requestParamtrs, ...pointsCollectionSettingsObj} : requestParamtrs)
      .then(() => {
        setFormBusinessInfoLoading(false);
      })
      .catch((error: any) => {
        setFormBusinessInfoErrorMessage(
          error?.data?.message
            ? error?.data?.message
            : error?.data?.detail
            ? error?.data?.detail
            : "Please, enter valid data."
        );
        setFormBusinessInfoLoading(false);
      });
  };

  const setInputValue = (value: string, index: number) => {
    const newInfo = {
      ...businessInfo
    };

    if (newInfo.points) {
      newInfo.points[index].dateExpire = +value;
      setBusinessInfo(newInfo);
    }
  }
  
  const [businessTypeList, setBusinessTypeList] = useState<Array<SelectorListObj>>([]);
  
  useEffect(() => {
    getBusinessTypesAxios().then((response) => {
      setBusinessTypeList(
        response.data.businessTypes.map((_businessType: businessType) => {
          return {
            value: _businessType.id,
            label: _businessType.display_name,
          };
        })
      );
    });
  }, []);

  return (
    <Wrapper>
      <div className="page-title">Update Primary Promotion</div>
      <div className="page-subtitle">
        Your digital loyalty card is displayed below, this is what your
        customers will see on the Lynked App.
      </div>
      <div className={`main-content ${getInfoLoading ? "loading-blue" : ""}`}>
        {!getInfoLoading ? (
          <>
            <div className="business-info-row">
              <div className="business-info-left">
                <form
                  onSubmit={handleUpdateBusinessInfo}
                  className={`${
                    formBusinessInfoLoading ? "loading-blue" : ""
                  } form_wrapper`}
                >
                  <div className="form-innerwrap">
                    <div className="form-group">
                      <Input
                        placeholder="Company Name"
                        label="Company Name"
                        required
                        defaultValue={businessInfo.name}
                        getValue={(value) =>
                          setBusinessInfoValue("name", value)
                        }
                      />
                    </div>
                    <div className="category-promotionName">
                      <div className="form-group category-group">
                        <Select
                          label="Business Type"
                          placeholder="Select Business Type"
                          selectorList={businessTypeList}
                          positionForShow="bottom"
                          required
                          defaultValue={businessTypeList.find(item=>item.value === businessInfo?.businessType?.id)}
                          getValue={(value) => setBusinessInfoValue('businessType', {id: +value})}
                          hideSelectorFilter
                        />
                      </div>
                      <div className="form-group promotionName-group">
                        <Input
                          placeholder="Details"
                          label="Details"
                          required
                          maxLength={28}
                          defaultValue={businessInfo.promotionName}
                          getValue={(value) =>
                            setBusinessInfoValue("promotionName", value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      className={`promotionType-promotionCount ${businessInfo.promotionTypeName}`}
                    >
                      <div className="form-group promotionType-group">
                        <Select
                          label="Type of Promotion"
                          placeholder="Type of Promotion"
                          selectorList={promotionTypes}
                          disabled={!promotionTypesEnable}
                          lock
                          positionForShow="bottom"
                          defaultValue={{
                            value: businessInfo.promotionTypeID,
                            label: businessInfo.promotionTypeName,
                          }}
                          required
                          getValue={(value) =>
                            setBusinessInfoValue("promotionTypeID", value)
                          }
                          hideSelectorFilter
                        />
                      </div>
                      {businessInfo.promotionTypeID === 2 ?
                      <div className="form-group promotionName-group">
                        <Input
                          placeholder="First Check-In Present"
                          label="First Time Check-In Points"
                          required
                          maxLength={28}
                          type={'number'}
                          defaultValue={businessInfo.first_checkIn ? businessInfo.first_checkIn : '0' }
                          getValue={(value) =>
                            setBusinessInfoValue("first_checkIn", value)
                          }
                        />
                      </div> : null}
                      {businessInfo.promotionTypeID === 1 ? (
                          <>
                            <div className="form-group promotionName-group">
                              <Input
                                placeholder="Stamp Voucher Name"
                                label="Stamp Voucher Name"
                                required
                                maxLength={28}
                                defaultValue={businessInfo.defaultVoucherName}
                                getValue={(value) =>
                                  setBusinessInfoValue("defaultVoucherName", value)
                                }
                              />
                            </div>
                            <div className="form-group category-group">
                              <Select
                                label="Select Stamp Icon Most Suited to Your Business"
                                placeholder="Select Stamp Icon"
                                selectorList={stampIconList}
                                positionForShow="bottom"
                                required
                                defaultValue={{
                                  value: "",
                                  label:
                                    businessInfo.stampIcon?.display_name ?? "",
                                }}
                                getValue={(value, label, additional) => {
                                  const stampIconData: StampIconAdditionalData =
                                    additional as StampIconAdditionalData;
                                  const newBusinessInfo = {...businessInfo};
                                  newBusinessInfo.stampIcon = {
                                    display_name: label,
                                    id: +value,
                                    name: stampIconData.icon_name,
                                    single_item: stampIconData.single_item,
                                  };
                                  setBusinessInfo(newBusinessInfo);
                                }}
                                hideSelectorFilter
                              />
                            </div>
                            <div className="form-group promotionCount-group">
                              <Select
                                label="Select Loyalty Rewards Level"
                                placeholder="Select Loyalty Rewards Level"
                                selectorList={stampList}
                                positionForShow="bottom"
                                defaultValue={{
                                  value: businessInfo.maxNumberPromotion,
                                  label:
                                    businessInfo.maxNumberPromotion.toString(),
                                }}
                                required
                                getValue={(value) =>
                                  setBusinessInfoValue(
                                    "maxNumberPromotion",
                                    value
                                  )
                                }
                                hideSelectorFilter
                              />
                            </div>
                          </>
                        ) :
                        null}
                    </div>
                    <div className="form-group form-group_three_items">
                      <Input
                        placeholder="Website"
                        label="Website"
                        defaultValue={
                          businessInfo && businessInfo.website_url
                            ? businessInfo.website_url
                            : ""
                        }
                        getValue={(value) =>
                          setBusinessInfoValue("website_url", value)
                        }
                        maxLength={255}
                      />
                      <Input
                        placeholder="Instagram"
                        label="Instagram"
                        defaultValue={
                          businessInfo && businessInfo.instagram_url
                            ? businessInfo.instagram_url
                            : ""
                        }
                        getValue={(value) =>
                          setBusinessInfoValue("instagram_url", value)
                        }
                        maxLength={255}
                      />
                      <Input
                        placeholder="Facebook"
                        label="Facebook"
                        defaultValue={
                          businessInfo && businessInfo.facebook_url
                            ? businessInfo.facebook_url
                            : ""
                        }
                        getValue={(value) =>
                          setBusinessInfoValue("facebook_url", value)
                        }
                        maxLength={255}
                      />
                    </div>
                    {businessInfo.promotionTypeID === 2 ? (
                      <>
                        {!!loggedUser.user.allow_promotion_points ? <PointsCollectionSettings pointsCollectionSettingsObj={pointsCollectionSettingsObj} setPointsCollectionSettingsObj={setPointsCollectionSettingsObj} /> : null}
                        <div
                          className={`promotionReward_close ${
                            isOpenRewards ? "promotionReward_open" : ""
                          }`}
                        >
                          <p className="promotionReward_control">
                            Your Loyalty Program Details
                          </p>
                          <div className="line"/>
                          <div className="promotionReward">
                            {businessInfo.points?.map(
                              (
                                {
                                  pointsAmount,
                                  reward,
                                  rewardNumber,
                                  rewardDetails,
                                  dateExpire,
                                  min_spend,
                                },
                                i
                              ) => (
                                (min_spend === 0 && i < 1) &&
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={i} className="reward_block">
                                    <div className="reward_label_wrapper">
                                        <div className="reward_label">{`Reward`}</div>
                                      {i ? (
                                        <CheckBox
                                          label="Use previous reward criteria"
                                          isChecked={
                                            businessInfo.points
                                              ? pointObjValuesToString(
                                                businessInfo.points[i - 1]
                                              ) ===
                                              pointObjValuesToString(
                                                businessInfo.points[i]
                                              )
                                              : false
                                          }
                                          onChange={(isChecked) => {
                                            const newBusinessInfo = {
                                              ...businessInfo,
                                            };
                                            if (isChecked) {
                                              if (newBusinessInfo.points) {
                                                
                                                newBusinessInfo.points[i] = structuredClone(newBusinessInfo.points[i - 1]);
                                                
                                                setBusinessInfo(newBusinessInfo);
                                              }
                                            } else {
                                              if (newBusinessInfo.points) {
                                                newBusinessInfo.points[i] = {
                                                  ...newBusinessInfo.points[i],
                                                  pointsAmount: 0,
                                                  reward: "",
                                                  rewardNumber: 0,
                                                  rewardDetails: "",
                                                  dateExpire: 0,
                                                  activateAfter: 0
                                                };
                                              }
                                              setBusinessInfo(newBusinessInfo);
                                            }
                                          }}
                                        />
                                      ) : null}
                                    </div>
                                    <div className="reward_inputs">
                                        <Input
                                            required
                                            type="number"
                                            label="Points amount"
                                            infoTooltip="Enter the value"
                                            getValue={(value) => {
                                              const newBusinessInfo = {
                                                ...businessInfo,
                                              };
                                              if (newBusinessInfo.points) {
                                                newBusinessInfo.points[
                                                  i
                                                  ].pointsAmount = +value;
                                                setBusinessInfo(newBusinessInfo);
                                              }
                                            }}
                                            defaultValue={pointsAmount}
                                        />
                                        <Input
                                            required
                                            label="Reward Number"
                                            infoTooltip={`Enter a number value only if you are integrating your e-commerce store, e.g. “5“ this represents a “${loggedUser.user.currency_symbol || '€'}5 discount“.`}
                                            getValue={(value) => {
                                              const newBusinessInfo = {
                                                ...businessInfo,
                                              };
                                              if (newBusinessInfo.points) {
                                                newBusinessInfo.points[i].rewardNumber =
                                                  value;
                                                setBusinessInfo(newBusinessInfo);
                                              }
                                            }}
                                            defaultValue={`${rewardNumber}`}
                                            type={"number"}
                                        />
                                        <div style={{width: '100%'}}>
                                            <Input
                                                required
                                                label="Reward Label"
                                              // infoTooltip="Enter a number value only if you are integrating your e-commerce store, e.g. “5“ this represents a “€5 discount“."
                                                getValue={(value) => {
                                                  const newBusinessInfo = {
                                                    ...businessInfo,
                                                  };
                                                  if (newBusinessInfo.points) {
                                                    newBusinessInfo.points[i].reward =
                                                      value;
                                                    setBusinessInfo(newBusinessInfo);
                                                  }
                                                }}
                                                defaultValue={reward}
                                            />
                                        </div>
                                        <Input
                                            required
                                            label="Reward Details"
                                            infoTooltip={`Enter the details of your loyalty program here, i.e. “Collect 100 points to receive a ${loggedUser.user.currency_symbol || '€'}5 discount.`}
                                            getValue={(value) => {
                                              const newBusinessInfo = {
                                                ...businessInfo,
                                              };
                                              if (newBusinessInfo.points) {
                                                newBusinessInfo.points[
                                                  i
                                                  ].rewardDetails = value;
                                                setBusinessInfo(newBusinessInfo);
                                              }
                                            }}
                                            defaultValue={rewardDetails}
                                        />
                                        <Select
                                            selectorList={activateDaysList}
                                            positionForShow={businessInfo?.points && businessInfo.points.length - 1 === i ? "top" : "bottom"}
                                            label="Voucher Activation"
                                            defaultValue={activateDaysList[businessInfo?.points?.[i].activateAfter ? businessInfo.points[i].activateAfter - 1 : 0]}
                                            required
                                            hideSelectorFilter

                                            getValue={(value) => {
                                              const newInfo = {...businessInfo};
                                              
                                              if (newInfo.points) {
                                                newInfo.points[i].activateAfter = +value;
                                                setBusinessInfo(newInfo);
                                              }
                                            }}
                                        />
                                        <div className="reward_expired">
                                            <CheckBox
                                                label="Expires After"
                                                isChecked={
                                                  openExpireDateReward.includes(i) ||
                                                  !!dateExpire
                                                }
                                                onChange={(isChecked) => {
                                                  setOpenExpireDateReward(
                                                    (currValues) => {
                                                      if (isChecked) {
                                                        if (currValues.includes(i)) {
                                                          return currValues;
                                                        }
                                                        const newInfo = {
                                                          ...businessInfo,
                                                        };
                                                        if (newInfo.points) {
                                                          newInfo.points[
                                                            i
                                                            ].dateExpire = 30;
                                                          setBusinessInfo(newInfo);
                                                        }
                                                        return [...currValues, i];
                                                      }
                                                      const newInfo = {
                                                        ...businessInfo,
                                                      };
                                                      if (newInfo.points) {
                                                        newInfo.points[
                                                          i
                                                          ].dateExpire = 0;
                                                        setBusinessInfo(newInfo);
                                                      }
                                                      return currValues.filter(
                                                        (key) => key !== i
                                                      );
                                                    }
                                                  );
                                                }}
                                            />
                                          {openExpireDateReward.includes(i) ||
                                          !!dateExpire ? (
                                            // <Select
                                            //   selectorList={
                                            //     Const.EXPIRE_REWARD_LIST
                                            //   }
                                            //   placeholder="Select Expire Date"
                                            //   positionForShow="top"
                                            //   defaultValue={{
                                            //     value: businessInfo.points
                                            //       ? businessInfo.points[i]
                                            //           .dateExpire
                                            //       : "",
                                            //     label: businessInfo.points
                                            //       ? businessInfo?.points[
                                            //           i
                                            //         ].dateExpire.toString()
                                            //       : "",
                                            //   }}
                                            //   required
                                            //   getValue={(value) => {
                                            //     const newInfo = {
                                            //       ...businessInfo,
                                            //     };
                                            //     if (newInfo.points) {
                                            //       newInfo.points[
                                            //         i
                                            //       ].dateExpire = +value;
                                            //       setBusinessInfo(newInfo);
                                            //     }
                                            //   }}
                                            //   hideSelectorFilter
                                            // />
                                            <DaysWrapperDiv>
                                              <Input
                                                type="number"
                                                placeholder="Enter Expire Date"
                                                required
                                                defaultValue={dateExpire}
                                                getValue={(value) => setInputValue(value, i)}
                                              />
                                              {businessInfo?.points?.[i].dateExpire ?
                                                <SpanInputText>days</SpanInputText> : null}
                                            </DaysWrapperDiv>
                                          ) : null}
                                        </div>
                                    </div>
                                </div>
                              )
                            )}
                            {loggedUser.user.allow_bonus_reward ? (<div className="reward_block">
                              <div className="reward_label_wrapper">
                                <div className="reward_label">{`Bonus Reward`}</div>
                              </div>
                              <div className="bonus_reward_inputs">
                                <Input
                                  required
                                  type="number"
                                  label="Bonus Points Target"
                                  getValue={(value) => {
                                    setBusinessInfoValue('bonusRewardTarget', value)
                                  }}
                                  defaultValue={businessInfo && businessInfo.bonusRewardTarget ? businessInfo.bonusRewardTarget : '0'}
                                />
                                <Input
                                  required
                                  label="Bonus Reward Value"
                                  getValue={(value) => {
                                    setBusinessInfoValue('bonusRewardValue', value)
                                  }}
                                  defaultValue={`${businessInfo && businessInfo.bonusRewardValue ? businessInfo.bonusRewardValue : 0}`}
                                />
                                <Input
                                  required
                                  label="Bonus Reward Label"
                                  getValue={(value) => {
                                    setBusinessInfoValue('bonusRewardLabel', value)
                                  }}
                                  defaultValue={`${businessInfo && businessInfo.bonusRewardLabel ? businessInfo.bonusRewardLabel : ''}`}
                                />
                                <div className="reward_expired">
                                  <CheckBox
                                    label="Expires After"
                                    isChecked={!!businessInfo.bonusRewardExpireDays}
                                    onChange={(isChecked) => {
                                      if (!isChecked && !!businessInfo.bonusRewardExpireDays){
                                        setBusinessInfoValue('bonusRewardExpireDays', 0)
                                      } else {
                                        setBusinessInfoValue('bonusRewardExpireDays', 30)
                                      }
                                    }}
                                  />
                                  {!!businessInfo.bonusRewardExpireDays ? (
                                    <DaysWrapperDiv>
                                      <Input
                                        type="number"
                                        placeholder="Enter Expire Date"
                                        required
                                        defaultValue={businessInfo.bonusRewardExpireDays}
                                        getValue={(value) => setBusinessInfoValue('bonusRewardExpireDays', value)}
                                      />
                                      {businessInfo.bonusRewardExpireDays ?
                                        <SpanInputText>days</SpanInputText> : null}
                                    </DaysWrapperDiv>
                                  ) : null}
                                </div>
                              </div>
                            </div>) : null}
                          
                          </div>
                        </div>
                        {/* <button
                          className="promotionReward_control"
                          type="button"
                          onClick={() =>
                            setIsOpenRewards((currValue) => !currValue)
                          }
                        >
                          {`${isOpenRewards ? "Close" : "Open"} Rewards`}
                          <span
                            className={`${
                              isOpenRewards
                                ? "promotionReward_control_rotate"
                                : ""
                            }`}
                          >
                            <ArrowDownIcon />
                          </span>
                        </button> */}
                      </>
                    ) : null}
                    {formBusinessInfoErrorMessage && (
                      <div className="form-error-message">
                        {formBusinessInfoErrorMessage}
                      </div>
                    )}
                    <div className="button-row">
                      <div
                        className={`verification-status ${businessInfo.verification_status?.name}`}
                      >
                        {businessInfo.verification_status?.display_name}
                      </div>
                      <button type="submit" className="btn-blue">
                        Update Details
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="business-info-right_wrapper">
                <div className="card">
                  <div className="title">Your Loyalty Card</div>
                  <div className="description">
                    As visible to customers in the Lynked app
                  </div>
                  <Card
                    acceptedFiles={businessLogo}
                    accountInformation={{
                      companyName: businessInfo.name,
                      businessType: businessInfo.businessType?.id ?? "",
                      stampIcon: businessInfo.stampIcon?.id ?? "",
                      stampName: businessInfo.stampIcon?.name ?? "",
                      promotionType: businessInfo.promotionTypeID,
                      rewardInformation: businessInfo.promotionName,
                      rewardCode: "",
                      maxNumberPromotion: businessInfo.maxNumberPromotion,
                      promotionLabel: businessInfo.promotionLabel ?? "",
                      first_checkIn: +businessInfo.first_checkIn ?? '0'
                    }}
                    cardClosed={cardClosed}
                    setCardClosed={setCardClosed}
                    points_info={{
                      maxPoints: businessInfo.points
                        ? businessInfo.points[0]?.pointsAmount
                        : undefined,
                      points: businessInfo.points
                        ? businessInfo.points[0]?.pointsAmount / 2
                        : undefined,
                      reward: businessInfo.points
                        ? businessInfo.points[0]?.reward
                        : undefined,
                    }}
                  />
                </div>
                <div className="business-info-right">
                  <BusinessLogo
                    businessLogoProps={businessLogo}
                    setBusinessLogoProps={(businessLogoProps) =>
                      setBusinessLogo(businessLogoProps)
                    }
                  />
                </div>
              </div>
            </div>
            
            <div className="locations-card-row"/>
          </>
        ) : null}
      </div>
    </Wrapper>
  );
};

export default Promotion;
