import React, {useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import PosCategoryColumn from "../../../components/PosCategoryColumnWrapper/PosCategoryColumn";

import {ReactComponent as CirclePlus} from "../../../assets/img//circle-plus.svg";
import {ReactComponent as CheckCircle} from "../../../assets/img/checkCircle.svg";
import {ReactComponent as Edit} from "../../../assets/img/edit.svg";
import {ReactComponent as Delete} from "../../../assets/img/delete.svg";
import DataTable from "react-data-table-component";
import PosTabWrapper from "../../../components/PosTabWrapper";
import Select from "../../../components/Select";
import Input from "../../../components/Input";
import {getPosStampCategoryAxios} from "../../../api/posTerminals";
import {
  getAllSellersToLoyaltyRewards,
  getAllSiteLoyaltyRewards,
  removeSiteLoyaltyReward,
  setNewSiteLoyaltyReward, SitePosRulesEnum
} from "../../../api/sitePosRule";

const SitePosRules = () => {
  const [loyaltyCash, setLoyaltyCash] = useState<any>([])
  const [discountRules, setDiscountRules] = useState<any>([])
  const [posRulesSelectList, setPosRulesSelectList] = useState<any[]>([])
  const [sellersSelectList, setSellersSelectList] = useState<any[]>([])
  const loyaltyColumnList = [
    {
      name: "",
      maxWidth: "25%",
      cell: (item: any): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Select
              selectorList={sellersSelectList}
              positionForShow={"bottom"}
              defaultValue={item.site_id !== '' ? sellersSelectList.find(rule=>rule.value === item.site_id) : sellersSelectList[0]}
              required
              hideSelectorFilter
              disabled={!item.edit || (item.edit && item.id !== 'new')}
              getValue={(value) => {
                item.site_id = value
              }}
            />
          </div>
        
        )
          ;
      }
    },
    {
      name: "",
      maxWidth: "25%",
      cell: (item: any): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Input
              defaultValue={`${item.coupon}`}
              required
              getValue={(value) => item.coupon = value}
              disabled={!item.edit}
            />
          </div>

        );
      }
    },{
      name: "",
      maxWidth: "25%",
      cell: (item: any): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Input
              defaultValue={`${item.description}`}
              required
              getValue={(value) => item.description = value}
              disabled={!item.edit}
            />
          </div>

        );
      }
    },
    
    {
      name: "",
      maxWidth: "7.5%",
      cell: (item: any, index: number): JSX.Element => {

        return (
          <div className="buttons" data-tag="allowRowEvents" style={item.edit ? {paddingBottom: '200px'} : {}}>
            {item.edit && item.id !== 'new' ?
              <button className="btn-white edit-category save-btn" onClick={() => {
                saveEditItem(SitePosRulesEnum.LOYALTY, item.id)
              }}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category save-btn" onClick={() => {
                saveNewItem(SitePosRulesEnum.LOYALTY, index)
              }}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button> : <>
                <button disabled={false} className="btn-white edit-category" onClick={() => {
                  setEditItem(SitePosRulesEnum.LOYALTY, item.id)
                }}>
                      <span className="icon">
                        <Edit/>
                      </span>
                </button>
                <button disabled={false} className="btn-white remove-category" onClick={() => {
                  removeItem(item.id)
                }}>
                      <span className="icon">
                      <Delete/>
                      </span>
                </button>
              </>}

          </div>
        );
      }
    }
  ];
  const discountColumnList = [
    {
      name: "",
      maxWidth: "25%",
      cell: (item: any): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Select
              selectorList={sellersSelectList}
              positionForShow={"bottom"}
              defaultValue={item.site_id !== '' ? sellersSelectList.find(rule=>rule.value === item.site_id) : sellersSelectList[0]}
              required
              hideSelectorFilter
              disabled={!item.edit|| (item.edit && item.id !== 'new_discount')}
              getValue={(value) => {
                item.site_id = value
              }}
            />
          </div>
        
        )
          ;
      }
    },
    {
      name: "",
      maxWidth: "25%",
      cell: (item: any): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Input
              defaultValue={`${item.coupon}`}
              required
              getValue={(value) => item.coupon = value}
              disabled={!item.edit}
            />
          </div>

        );
      }
    },{
      name: "",
      maxWidth: "25%",
      cell: (item: any): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Input
              defaultValue={`${item.description}`}
              required
              getValue={(value) => item.description = value}
              disabled={!item.edit}
            />
          </div>

        );
      }
    },
    {
      name: "",
      maxWidth: "25%",
      cell: (item: any): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Select
              selectorList={posRulesSelectList}
              positionForShow={"bottom"}
              defaultValue={item.product_group === '' ? posRulesSelectList[0] : posRulesSelectList.find(rule=>rule.value === item.product_group)}
              required
              hideSelectorFilter
              disabled={!item.edit}
              getValue={(value) => {
                item.product_group = value
              }}
            />
          </div>
        
        )
          ;
      }
    },
    {
      name: "",
      maxWidth: "7.5%",
      cell: (item: any, index: number): JSX.Element => {

        return (
          <div className="buttons" data-tag="allowRowEvents" style={item.edit ? {paddingBottom: '200px'} : {}}>
            {item.edit && item.id !== 'new_discount' ?
              <button className="btn-white edit-category save-btn" onClick={() => {
                saveEditItem(SitePosRulesEnum.DISCOUNT, item.id)
              }}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category save-btn" onClick={() => {
                saveNewItem(SitePosRulesEnum.DISCOUNT, index)
              }}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button> : <>
                <button disabled={false} className="btn-white edit-category" onClick={() => {
                  setEditItem(SitePosRulesEnum.DISCOUNT,item.id)
                }}>
                      <span className="icon">
                        <Edit/>
                      </span>
                </button>
                <button disabled={false} className="btn-white remove-category" onClick={() => {
                  removeItem(item.id)
                }}>
                      <span className="icon">
                      <Delete/>
                      </span>
                </button>
              </>}

          </div>
        );
      }
    }
  ];
  
  useEffect(() => {
    getPosStampCategoryAxios().then((res) => {
      setPosRulesSelectList([...res.data.map((item: any) => item = {value: item.id, label: item.product_group})])
    })
    getAllSellersToLoyaltyRewards().then((res) => {
      setSellersSelectList([...res.sites.map((item: any) => item = {value: item.id, label: item.email})])
    })
    getAllRewardsHandler()
  }, [])
  
  const getAllRewardsHandler = () =>{
    getAllSiteLoyaltyRewards().then(res=>{
      setLoyaltyCash(res.loyalty_rules.map((item:any)=>item = {id: item.id, site_id: item.site.id, coupon: item.coupon, description: item.description, type: item.type}))
      setDiscountRules(res.discount_rules.map((item:any)=>item = {id: item.id, site_id: item.site.id, coupon: item.coupon, description: item.description, type: item.type, product_group: item.product_group.id}))
      
    })
  }
  
  const addNewItem = (type: number) =>{
    type === SitePosRulesEnum.LOYALTY ? (
      setLoyaltyCash([...loyaltyCash, {edit: true, id: 'new', site_id: sellersSelectList[0].value || '', coupon: '', description: ''}])
    ) : (
      setDiscountRules([...discountRules, {edit: true, id: 'new_discount', site_id: sellersSelectList[0].value || '', coupon: '', description: '', product_group: posRulesSelectList[0].value || ''}])
    )
  }
  
  const saveNewItem = (type: number, index: number) => {
    if(type === SitePosRulesEnum.LOYALTY){
      const requestData = {
        ...loyaltyCash[index],
        type: type,
        id: 'new'
      }
      setNewSiteLoyaltyReward(requestData).then(res => {
        getAllRewardsHandler()
      })
      // setLoyaltyCash([...loyaltyCash.map((item: any, i: number) => i === index ? {
      //   ...item,
      //   edit: false,
      //   id: Date.now()
      // } : item)])
    } else {
      const requestData = {
        ...discountRules[index],
        type: type,
        id: 'new'
      }
      setNewSiteLoyaltyReward(requestData).then(res => {
        getAllRewardsHandler()
      })
      // setLoyaltyCash([...discountRules.map((item: any, i: number) => i === index ? {
      //   ...item,
      //   edit: false,
      //   id: Date.now()
      // } : item)])
    }
    
    
  }
  
  const saveEditItem = (type: number, id: number) =>{
    if(type === SitePosRulesEnum.LOYALTY){
      const requestData = {
        ...loyaltyCash.find((item:any)=>item.id === id),
        type: type
      }
      setNewSiteLoyaltyReward(requestData).then(res => {
        getAllRewardsHandler()
      })
    } else {
      const requestData = {
        ...discountRules.find((item:any)=>item.id === id),
        type: type
      }
      setNewSiteLoyaltyReward(requestData).then(res => {
        getAllRewardsHandler()
      })
    }
    
  }
  
  const setEditItem = (type: number, id: number) =>{
    type === SitePosRulesEnum.LOYALTY ?(
      setLoyaltyCash([...loyaltyCash.map((item:any,i: number)=>item.id===id ? {...item, edit: true } : item)])
    ):(
      setDiscountRules([...discountRules.map((item:any,i: number)=>item.id===id ? {...item, edit: true} : item)])
    )
  }
  
  const removeItem = (id: number) =>{
      removeSiteLoyaltyReward({id}).then(res=>{
        getAllRewardsHandler()
      })
  }
  
  return (
    <Wrapper>
      <div className="heading">
        <div className="page-title">Pixel Point POS Rules</div>
      </div>
      <PosTabWrapper title={"Points Loyalty Cash Rewards:"}
                     description={""}
                     button={false}>
        <>
          <div className="content stamp-based-wrapper">
            <div className="header">
              <div className="header-tab">
                <PosCategoryColumn title={'Site I.D.'} description={''}/>
                <PosCategoryColumn title={'Coupon I.D.'}
                                   description={''}/>
                <PosCategoryColumn title={'Description'}
                                   description={''}/>
                <div className="column_buttons"></div>
              
              </div>
              
              
              <div className="stamp-based-form">
                
                {loyaltyCash !== null ? (
                  loyaltyCash.length > 0 ? (
                    <>
                        <div className="table">
                          <DataTable
                            noTableHead
                            noHeader
                            columns={loyaltyColumnList}
                            data={loyaltyCash}
                          />
                      </div>
                    </>
                  ) : (
                    <div className="nothing-found">Sorry, nothing found</div>
                  )
                ) : null}
              </div>
              <button disabled={false} className="btn-white add-stampCategory" onClick={() => addNewItem(SitePosRulesEnum.LOYALTY)}>
            <span className="icon">
              <CirclePlus/>
            </span>
                <span>Site</span>
              </button>
            </div>
          </div>
        </>
      
      </PosTabWrapper>
      <PosTabWrapper title={"Item Based Discount Rules:"}
                     description={""}
                     button={false}>
        <>
          <div className="content stamp-based-wrapper">
            <div className="header">
              <div className="header-tab">
                <PosCategoryColumn title={'Site I.D.'} description={''}/>
                <PosCategoryColumn title={'Coupon I.D.'}
                                   description={''}/>
                <PosCategoryColumn title={'Description'}
                                   description={''}/>
                <PosCategoryColumn title={'Product Group'}
                                   description={''}/>
                <div className="column_buttons"></div>
              
              </div>
              
              
              <div className="stamp-based-form">
                
                {discountRules !== null ? (
                  discountRules.length > 0 ? (
                    <>
                      <div className="table">
                        <DataTable
                          noTableHead
                          noHeader
                          columns={discountColumnList}
                          data={discountRules}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="nothing-found">Sorry, nothing found</div>
                  )
                ) : null}
              </div>
              <button disabled={false} className="btn-white add-stampCategory" onClick={() => addNewItem(SitePosRulesEnum.DISCOUNT)}>
            <span className="icon">
              <CirclePlus/>
            </span>
                <span>Site</span>
              </button>
            </div>
          </div>
        </>
      
      </PosTabWrapper>
    </Wrapper>
  );
};

export default SitePosRules;
