import styled from "styled-components";
import { Color } from "src/types/color";
import arrowDropDownImg from "src/assets/img/arrowDropDown.svg";

const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  display: flex;
  padding: 50px 40px 40px 100px;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);

  @media (max-width: 1200px) {
    padding: 40px 30px 40px 50px;
  }
  @media (max-width: 600px) {
    padding: 40px 15px;
  }

  .main {
    margin: auto 0;
    width: 100%;
    max-width: 950px;
    &.blur {
      filter: blur(3px);
    }
    /* .left-block,
    .right-block {
      width: 50%;
      box-sizing: border-box;
      padding: 60px 120px;
      background-color: ${Color.blueDarkBlour};
      @media (max-width: 1440px) {
        padding: 60px 60px;
      }
      @media (max-width: 1200px) {
        padding: 30px 30px;
      }
      @media (max-width: 991px) {
        width: 100%;
      }
      @media (max-width: 425px) {
        padding: 30px 15px;
      }
    } */
  }

  /* .header {
    margin-bottom: 25px;
    .logo {
      max-width: 250px;
      svg {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        font-family: "object-fit: cover";
      }
    }
  } */
  .form-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
    @media (max-width: 1200px) {
      height: auto;
    }
    .title {
      color: ${Color.blueDarkText};
      font-size: 48px;
      font-weight: 500;
      line-height: 61px;
      margin-bottom: 8px;
      @media (max-width: 550px) {
        font-size: 32px;
        line-height: 48px;
      }
    }
    .subtitle {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
      margin-bottom: 40px;
    }
  }

  form {
    padding-bottom: 30px;
    position: relative;
    .form_two_items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      //align-items: center;
      & > * {
        flex-basis: 48%;
      }

      @media (max-width: 768px) {
        & > * {
          flex-basis: 100%;
        }
      }
      .phone-wrap {
        display: flex;
        flex-direction: column;
        .label {
          color: ${Color.blueDarkText};
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 10px;
        }
      }
      /* .first-name {
        width: calc(50% - 15px);
        margin-right: 30px;
        @media (max-width: 550px) {
          width: 100%;
          margin-right: 0;
        }
      }
      .last-name {
        width: calc(50% - 15px);
        @media (max-width: 550px) {
          width: 100%;
        }
      } */
    }
    .btn_submit_wrapper {
      max-width: 350px;
    }
    .form-group {
      margin-bottom: 30px;
      /* input {
        background-color: rgba(255, 255, 255, 0.09) !important;
        color: ${Color.white};
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:active,
        &:-webkit-autofill:focus l {
          -webkit-box-shadow: 0 0 0 30px #4e5889 inset !important;
          -webkit-text-fill-color: ${Color.white} !important;
        }
      } */
      /* .blue-checkbox {
        .text {
          opacity: 0.8;
          color: ${Color.white};
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          a {
            margin-left: 5px;
            color: ${Color.white};
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      } */
      .select.isSelect {
        & > input {
          background: url(${arrowDropDownImg}) #ffffff no-repeat
            calc(100% - 12px) center;
        }
      }
    }
    .form-error-message {
      color: red;
      font-size: 14px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  /* .slider {
    position: relative;
    &:before {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 20px;
      background-color: rgb(26 37 89);
      background: linear-gradient(
        to right,
        rgba(26, 37, 89, 1),
        rgba(26, 37, 89, 0)
      );
    }
    &:after {
      content: "";
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 20px;
      background: linear-gradient(
        to left,
        rgba(26, 37, 89, 1),
        rgba(26, 37, 89, 0)
      );
    }
    .slick-slide {
      min-height: calc(100vh - 150px);
      display: flex;
      align-items: center;
    }
    .slide {
      box-sizing: border-box;
      padding: 25px;
    }
    .slide-img-wrap {
      margin-top: 30px;
      margin-bottom: 30px;
      img {
        margin: auto;
        max-width: 100%;
      }
    }
    .slide-content {
      text-align: center;
      color: ${Color.white};
      .info {
        color: ${Color.orange};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 15px;
      }
      .title {
        font-size: 24px;
        font-weight: 500;
        line-height: 34px;
        margin-bottom: 15px;
      }
      .description {
        opacity: 0.8;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
    .slick-dots {
      li {
        & > button:before {
          content: "";
          height: 8px;
          width: 8px;
          border-radius: 100%;
          opacity: 0.3;
          background-color: ${Color.white};
        }
        &.slick-active > button:before {
          opacity: 1;
          background-color: ${Color.orange};
        }
      }
    }
  } */

  /* .modal-check-email-content {
    text-align: center;
    padding: 60px 50px;
    @media (max-width: 600px) {
      padding: 30px 15px;
    }
    img {
      max-width: 100%;
      width: 185px;
      margin-bottom: 30px;
    }
    .title {
      color: ${Color.blueDarkText};
      font-size: 30px;
      font-weight: 500;
      line-height: 38px;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 23px;
        line-height: 28px;
      }
    }
  } */

  /* .modal-create-password-content {
    form {
      text-align: center;
      padding-bottom: 0;
      padding: 60px 50px;
      @media (max-width: 600px) {
        padding: 30px 15px;
      }
      .form-group {
        input {
          border: 1px solid rgba(0, 0, 0, 0.1);
          background-color: rgba(0, 13, 70, 0.03) !important;
          color: ${Color.grey};
          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:active,
          &:-webkit-autofill:focus l {
            box-shadow: 0 0 0 30px #e2e3ea inset !important;
            -webkit-text-fill-color: ${Color.grey} !important;
          }
        }
      }
    }
    .title {
      color: ${Color.blueDarkText};
      font-size: 40px;
      font-weight: 500;
      line-height: 51px;
      margin-bottom: 5px;
      @media (max-width: 600px) {
        font-size: 26px;
        line-height: 32px;
      }
    }
    .description {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      margin-bottom: 30px;
    }
  } */
  .lynked-form {
    .form-error-message {
      margin-top: 5px;
    }
  }
`;

export { Wrapper };
