const title = "Sign in your account";
const subTitle =
  "This is your password for accessing your new Lynked portal for viewing all your customer activity.";
const emailPlaceholder = "Your Email";
const passwordPlaceholder = "Your Password";
const signInBtn = "Sign In";
const forgotPasswordBtn = "Forgot Your Password?";
const signUpBtn = "Sign Up";

export {
  title,
  subTitle,
  emailPlaceholder,
  passwordPlaceholder,
  signInBtn,
  forgotPasswordBtn,
  signUpBtn,
};
