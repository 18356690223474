import React, {FC, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {useActions} from "src/hooks/useActions";
import Input from "src/components/Input";
import Select from "src/components/Select";
import {ReactComponent as SearchIcon} from "src/assets/img/search.svg";
import Pagination from "src/components/Pagination";
import userDefaultImg from "src/assets/img/default-user.jpg";
import ExpandableTableList from "./EditableTable/ExpandableTableList";

import {
  CustomerRequestInterface,
  CustomerInterface,
  CustomerInfoInterface
} from "src/types/customer";

import {Wrapper} from "./styled";
import {getAdminBusinessesAxios, getAdminBusinessesNamesAxios} from "../../../api/adminPanel";
import {SelectorListObj} from "../../../components/Select/types";

const arrangeCustomerSelect = [
  {value: "business_name:asc", label: "Sort A-Z"},
  {value: "business_name:desc", label: "Sort Z-A"},
  {value: "id:asc", label: "Sort Oldest → Newest"},
  {value: "id:desc", label: "Sort Newest → Oldest"},
  // {value: "last_visit:desc", label: "Check-ins Most Recent → Oldest"},
  // {value: "last_visit:asc", label: "Check-ins Oldest → Most Recent"},
  // {value: "check_in_count:desc", label: "Sort Check-ins High → Low"},
  // {value: "check_in_count:asc", label: "Sort Check-ins Low → High"},
  // {value: "total_spend:desc", label: "Sort Points High → Low"},
  // {value: "total_spend:asc", label: "Sort Points Low → High"}
];
// const searchType = [{ value: "0", label: "User" }, { value: "1", label: "Order" }];


const AdminBisinesses: FC = () => {
  const [requestParameters, setRequestParameters] =
    React.useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
      location: "",
      business: null,
      sort_type: "business_name:asc",
      startDate: new Date(2022, 0, 1),
      endDate: new Date()
    });
  const [customerListLoading, setCustomerListLoading] =
    React.useState<boolean>(true);
  const [customerList, setCustomerList] =
    React.useState<Array<CustomerInterface> | null>(null);
  
  const [customerListInfo, setCustomerListInfo] =
    useState<CustomerInfoInterface>({
      count_users: 0,
      limit: 0,
      page: 0,
      total_pages_count: 0,
      total_users_count: 0,
      startDate: new Date(2022, 0, 1),
      endDate: new Date(),
    });
  
  // const {locations} = useTypedSelector(
  //   (state) => state.groupsLocations
  // );
  const {getGroupsLocations} = useActions();
  useEffect(() => {
    getGroupsLocations();
  }, []); //eslint-disable-line
  
  const getCustomerList = () => {
    setCustomerListLoading(true);
    getAdminBusinessesAxios({
      ...requestParameters
    }).then((response) => {
      setCustomerList(response.data.business);
      setCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        startDate: new Date(response.data.startDate),
        endDate: new Date(response.data.endDate)
      });
      setCustomerListLoading(false);
    }).catch((err) => {
      console.log(err);
      
    });
  };
  
  const handleChangeRequestParamtrs = (
    name: string,
    value: string | number | Date
  ) => {
    setRequestParameters((prevState: any) => {
      return {...prevState, [name]: value};
    });
  };
  
  useEffect(() => {
    getCustomerList();
  }, [
    requestParameters.business,
    requestParameters.location,
    requestParameters.page,
    requestParameters.sort_type,
    requestParameters.searchType,
    requestParameters.startDate,
    requestParameters.endDate,
  ]);
  
  useEffect(() => {
    if (requestParameters.search !== null) {
      const timeout = setTimeout(() => {
        getCustomerList();
      }, 500);
      
      return () => clearTimeout(timeout);
    }
  }, [requestParameters.search]);
  
  useEffect(() => {
    if (Number(requestParameters.page) === 1 && customerListInfo.limit !== 0) {
      getCustomerList();
    }
  }, [requestParameters.limit]);
  
  const customerTableColumns = [
    
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.business_name}
          </div>
        );
      }
    },
    {
      name: "Icon",
      width: "100px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt=""/>
            ) : (
              <img src={userDefaultImg} alt=""/>
            )}
          </div>
        );
      }
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.phone}</div>;
      },
    },
    {
      name: "Loyalty Program",
      cell: (row: any): JSX.Element => {
        return <div>{row.place && row.place.promotion ? row.place.promotion :  "-"}</div>;
      }
    },
  ];
  const [listRow, setListRow] = useState<any>({});
  
  const [businesses, setBusinesses] = useState<SelectorListObj[]>([{value: '', label: ''}])
  useEffect(() => {
    getAdminBusinessesNamesAxios().then(({data}) => {
      let selectBusiness: any[] = []
      data.business.forEach((item: any) => {
        selectBusiness.push({value: item.id, label: item.business_name})
      })
      setBusinesses(selectBusiness)
    });
  }, []);
  
  
  return (
    <Wrapper>
      <div className="page-title">Admin Businesses</div>
      <div className="top-bar">
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon/>}
            getValue={(value) => setRequestParameters({...requestParameters, search: value})}
          />
        </div>
        {/*<div className="groups-wrap">*/}
        {/*  <Select*/}
        {/*    placeholder="What you search"*/}
        {/*    selectorList={searchType}*/}
        {/*    positionForShow="bottom"*/}
        {/*    defaultValue={searchType[0]}*/}
        {/*    required*/}
        {/*    getValue={(value) =>*/}
        {/*      handleChangeRequestParamtrs("searchType", value)*/}
        {/*    }*/}
        {/*    hideSelectorFilter*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="groups-wrap">
          <Select
            placeholder="All Businesses"
            selectorList={businesses}
            disabled={!(businesses.length > 0)}
            positionForShow="bottom"
            required
            isClearable
            getValue={(value) => handleChangeRequestParamtrs("business", value)}
            hideSelectorFilter
          />
        </div>
        <div className="groups-wrap">
          <Select
            placeholder="Arrange Customer List"
            selectorList={arrangeCustomerSelect}
            positionForShow="bottom"
            defaultValue={arrangeCustomerSelect[0]}
            required
            getValue={(value) =>
              handleChangeRequestParamtrs("sort_type", value)
            }
            hideSelectorFilter
          />
        </div>
        {/*<div className="locations-wrap">*/}
        {/*  <Select*/}
        {/*    placeholder="All Locations"*/}
        {/*    selectorList={locations}*/}
        {/*    disabled={!(locations.length > 0)}*/}
        {/*    positionForShow="bottom"*/}
        {/*    required*/}
        {/*    isClearable*/}
        {/*    getValue={(value) => handleChangeRequestParamtrs("location", value)}*/}
        {/*    hideSelectorFilter*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="groups-wrap">*/}
        {/*  <Calendar*/}
        {/*    selected={requestParameters.startDate}*/}
        {/*    onChange={(e) => e <= new Date() ? handleChangeRequestParamtrs("startDate", e) : handleChangeRequestParamtrs("startDate", new Date())}*/}
        {/*    dateFormat="MMMM d, yyyy"*/}
        {/*    timeSelect={false}*/}
        {/*    label={"Start Date"}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className="groups-wrap">*/}
        {/*  <Calendar*/}
        {/*    selected={requestParameters.endDate}*/}
        {/*    onChange={(e) => e < requestParameters.startDate ? handleChangeRequestParamtrs("endDate", requestParameters.startDate) : e >= new Date() ? handleChangeRequestParamtrs("endDate", new Date()) : handleChangeRequestParamtrs("endDate", e)}*/}
        {/*    dateFormat="MMMM d, yyyy"*/}
        {/*    timeSelect={false}*/}
        {/*    label={"End Date"}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      <div
        className={`table-wrap ${customerListLoading ? "loading-blue" : ""}`}
      >
        <div className="loading-innerwrap">
          {customerList !== null ? (
            customerList.length > 0 ? (
              <>
                <div className="table">
                  <DataTable
                    noHeader
                    columns={customerTableColumns}
                    data={customerList}
                    expandableRows
                    expandableRowExpanded={(row) => (row === listRow)}
                    onRowExpandToggled={(bool, row) => setListRow(row)}
                    expandableRowsComponent={
                      <ExpandableTableList data={customerList} getCustomerList={getCustomerList}
                                           requestParamtrs={requestParameters}/>
                    }
                  />
                </div>
                <Pagination {...customerListInfo} requestLimit={requestParameters.limit}
                            setRequestParamtrs={setRequestParameters}/>
              </>
            ) : (
              <div className="nothing-found">Sorry, nothing found</div>
            )
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminBisinesses;
