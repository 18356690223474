import { Color } from "src/types/color";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
  & > *:nth-child(2n + 3) {
    background-color: ${Color.white};
  }
  .line {
    height: 1px;
    background-color: rgba(0, 13, 70, 0.1);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .top_table_wrapper {
    display: flex;
    align-items: center;
    padding: 20px 0px;
    & > *:first-child {
      flex-basis: 0%;
    }
    & > *:nth-child(2) {
      flex-basis: 40%;
    }
    & > *:nth-child(n + 3) {
      flex-basis: 30%;
    }
    .table_title {
      font-size: 20px;
      line-height: 20px;
      text-align: left;
    }
  }
  .work-time-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 6px 0px;
    & > * {
      margin-bottom: 4px !important;
      margin-top: 4px !important;
    }
    .all-day-checkbox {
      cursor: pointer;
    }
    .small {
      width: 85px;
    }
    .large {
      width: 170px;
    }
    .form-group {
      justify-content: flex-start;
      flex-grow: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      * {
        margin-bottom: 0;
      }
      .select {
        width: 160px;
      }
    }
    /* @media (max-width: 1550px) {
      & > * {
        flex-basis: 50%;
        width: fit-content;
      }
    } */
    @media (max-width: 768px) {
      & > *:nth-child(n + 3) {
        flex-basis: 100%;
      }
      .large {
        & > div:first-child {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 768px) {
  .top_table_wrapper {
    display: none;
  }
`;

export { Wrapper };
