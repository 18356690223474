import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  .nav_progress {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 280px;
    max-height: inherit;
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: ${Color.white};
    padding: 40px 25px;
    overflow-y: auto;
    z-index: 2;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
    @media (max-width: 900px) {
      width: 40px;
      padding: 20px 10px;
      display: flex;
      align-items: center;
    }
  }
  .nav_logo_wrapper {
    width: 180px;
    margin: 0 auto 20px;
    svg {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
      font-family: "object-fit: cover";
    }

    @media (max-width: 900px) {
      display: none;
    }
  }
  .nav_text {
    opacity: 0.6;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
    margin-bottom: 50px;

    @media (max-width: 900px) {
      display: none;
    }
  }
  .nav_items {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .nav_item {
    position: relative;
    font-size: 18px;
    font-weight: 300;
    line-height: 23px;
    color: rgba(0, 13, 70, 0.3);
    font-weight: 500;
    padding-left: 30px;
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 6px;
      height: 6px;
      background-color: #cdd0db;
      border: 3px solid transparent;
      border-radius: 50%;
      top: 3px;
      left: 2px;
      z-index: 2;
    }
    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 2px;
      height: 45px;
      background-color: #cdd0db;
      top: 12px;
      left: 7px;
    }
    &_active {
      color: ${Color.blue};
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &::before {
        width: 10px;
        height: 10px;
        left: 0;
        background: linear-gradient(
          0deg,
          ${Color.gradientFreeCardFrom} -16.46%,
          ${Color.gradientFreeCardTo} 98.11%
        );
        border-color: ${Color.white};
        box-shadow: 0px 0px 0px 1px ${Color.blue};
      }
    }
    &_passed {
      color: ${Color.blueDarkText};
      &::before {
        background-color: ${Color.blueDarkText};
      }
      &::after {
        background-color: ${Color.blueDarkText};
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    @media (max-width: 900px) {
      font-size: 0;
    }
  }
`;

export { Wrapper };
