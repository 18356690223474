import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  height: 80px;
  background-color: ${Color.white};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;

  .account-under-review-wrap {
    border-radius: 4px;
    background-color: ${Color.blueLightBackground};
    padding: 7px 17px;
    svg {
      margin-bottom: -5px;
      path.circle {
        fill: ${Color.blue};
      }
    }
    .blue-text {
      color: ${Color.blue};
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      margin-left: 15px;
      margin-right: 15px;
      position: relative;
      top: -2px;
    }
    .main-text {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      position: relative;
      top: -2px;
    }
  }

  .nav-user {
    display: flex;
    align-items: center;
    padding: 0;
    background: none;
    cursor: pointer;
    position: relative;
    .img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 100%;
      }
    }
    .name-email {
      margin-right: 12px;
      text-align: left;
    }
    .name {
      color: ${Color.blueDarkText};
      font-size: 18px;
      font-weight: 300;
      line-height: 23px;
    }
    .email {
      opacity: 0.3;
      color: ${Color.blueDarkText};
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
    }
    .more {
      line-height: 0;
      cursor: pointer;
    }
    .dropdown-menu {
      transform: scaleY(0);
      position: absolute;
      top: calc(100% + 10px);
      right: -10px;
      background-color: ${Color.white};
      padding: 7px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      box-shadow: 0 5px 25px rgb(0 0 0 / 10%);
      max-width: 100%;
      min-width: 150px;
      text-align: left;
      z-index: 99;
      &:before {
        content: "";
        position: absolute;
        top: -1px;
        right: 10px;
        width: 0.75rem;
        height: 0.75rem;
        display: block;
        z-index: 10;
        box-sizing: border-box;
        background: ${Color.white};
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        transform: rotate(45deg) translate(-7px);
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }

      .logout {
        background: none;
        padding: 0;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 23px;
        text-align: center;
        display: block;
        width: 100%;
      }
    }

    &.open {
      .more {
        transform: rotateX(180deg);
      }
      .dropdown-menu {
        transform: scaleY(1);
      }
    }
  }
  @media (max-width: 991px) {
    height: 60px;
    /* z-index: 0; */
    padding: 10px;
    .nav-left {
      display: none;
    }
  }
`;

export { Wrapper };
