import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  a {
    display: flex;
    align-items: center;
    padding: 16px 18px;
    opacity: 0.6;
    color: ${Color.blueDarkText};
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 23px;
    .icon {
      margin-right: 20px;
      line-height: 0;
    }
  }
`;

export { Wrapper };
