import React, { FC } from "react";
import { Route, Switch, Link, useLocation } from "react-router-dom";

import { useDropzone } from "react-dropzone";

import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
// import { getManageBillingUrlAxios } from "src/api/userProfile";
import { ReactComponent as UploadIcon } from "src/assets/img/upload.svg";

import EditPersonalDetails from "./EditPersonalDetails";
// import UpdatePlan from "./UpdatePlan";

import { Wrapper } from "./styled";

const UserProfile: FC = () => {
  const location = useLocation();
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const { updateProfilePhoto } = useActions();

  const [loadingPhoto, setLoadingPhoto] = React.useState<boolean>(false);
  // const [loadingRedirectManageBilling, setLoadingRedirectManageBilling] =
  //   React.useState<boolean>(false);

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles[0]) {
      setLoadingPhoto(true);
      const requestFormData: any = new FormData();
      requestFormData.append("file", acceptedFiles[0]);
      updateProfilePhoto(requestFormData)
        .then(() => {
          setLoadingPhoto(false);
        })
        .catch(() => {
          setLoadingPhoto(false);
        });
    }
  };

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    multiple: false,
    onDrop,
  });

  // const redirectManageBilling = () => {
  //   setLoadingRedirectManageBilling(true);
  //   getManageBillingUrlAxios()
  //     .then((response) => {
  //       setLoadingRedirectManageBilling(false);
  //       window.open(response.data.url, "_blank");
  //     })
  //     .catch(() => {
  //       setLoadingRedirectManageBilling(false);
  //     });
  // };

  return (
    <Wrapper>
      <div className="page-title">User Profile</div>
      <div className="row">
        <div className="pages-wrap">
          <div className="nav-subpages">
            <div
              className={`nav-subpage ${
                location.pathname ===
                "/account-management/edit-personal-details"
                  ? "active-page"
                  : ""
              }`}
            >
              <Link to="/account-management/edit-personal-details">
                Edit Personal Details
              </Link>
            </div>
            {/*<div*/}
            {/*  className={`nav-subpage with-loading ${*/}
            {/*    loadingRedirectManageBilling ? "loading-blue" : ""*/}
            {/*  }`}*/}
            {/*>*/}
            {/*  <button type="button" onClick={() => redirectManageBilling()}>*/}
            {/*    Manage Billing*/}
            {/*  </button>*/}
            {/*</div>*/}
            {/* <div
              className={`nav-subpage ${
                location.pathname === "/account-management/update-plan"
                  ? "active-page"
                  : ""
              }`}
            >
              <Link to="/account-management/update-plan">Update Plan</Link>
            </div> */}
          </div>
          <div className="content-page">
            <Switch>
              <Route
                exact
                path="/account-management/edit-personal-details"
                component={EditPersonalDetails}
              />
              {/* <Route
                exact
                path="/account-management/update-plan"
                component={UpdatePlan}
              /> */}
            </Switch>
          </div>
        </div>
        <div className="short-user-info-wrap">
          <div className="short-user-info">
            <div className={`photo-wrap ${loadingPhoto ? "loading-blue" : ""}`}>
              <div
                className={`dropZone ${
                  loggedUser.user.photo ? "with-photo" : ""
                }`}
              >
                <div {...getRootProps()} className={`active_${isDragActive}`}>
                  {" "}
                  {/*eslint-disable-line*/}
                  <input {...getInputProps()} required />{" "}
                  {/*eslint-disable-line*/}
                  {loggedUser.user.photo ? (
                    <div className="img">
                      <img src={loggedUser.user.photo} alt="" />
                    </div>
                  ) : null}
                  <div className="dropZone-content-text">
                    <UploadIcon />
                    <span className="text">
                      <span className="title">
                        {loggedUser.user.photo
                          ? "Update your photo"
                          : "Please upload your photo"}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="name">{loggedUser.user.full_name}</div>
            <div className="joined">
              joined in{" "}
              {new Date(loggedUser.user.created).toLocaleString("en", {
                month: "long",
              })}{" "}
              {new Date(loggedUser.user.created).getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default UserProfile;
