import axios from "./api";

export const getKlaviyoIntegration = (): Promise<any> =>
  axios
    .post("/api/klaviyo/integration/get")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const createKlaviyoIntegration = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/klaviyo/integration", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const deleteKlaviyoIntegration = (): Promise<any> =>
  axios
    .post("/api/klaviyo/integration/delete")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
