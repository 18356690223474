import React, {FC, useEffect, useState} from "react";
import { format } from "date-fns";

import DataTable from "react-data-table-component";

import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
import Input from "src/components/Input";
import Select from "src/components/Select";
import { ReactComponent as SearchIcon } from "src/assets/img/search.svg";
import Pagination from "../../../components/Pagination";
import userDefaultImg from "src/assets/img/default-user.jpg";
import ExpandableTableList from "./EditableTable/ExpandableTableList";

import {getCustomerListAxios} from "src/api/customers";
import {
  CustomerRequestInterface,
  CustomerInterface,
  CustomerInfoInterface
} from "src/types/customer";

import { Wrapper } from "./styled";
import Calendar from "../../../components/Calendar";

const arrangeCustomerSelect = [
  { value: "last_name:asc", label: "Sort A-Z" },
  { value: "last_name:desc", label: "Sort Z-A" },
  { value: "id:asc", label: "Sort Oldest → Newest" },
  { value: "id:desc", label: "Sort Newest → Oldest" },
  { value: "last_visit:desc", label: "Check-ins Most Recent → Oldest" },
  { value: "last_visit:asc", label: "Check-ins Oldest → Most Recent" },
  { value: "check_in_count:desc", label: "Sort Check-ins High → Low" },
  { value: "check_in_count:asc", label: "Sort Check-ins Low → High" },
  { value: "total_spend:desc", label: "Sort Points High → Low" },
  { value: "total_spend:asc", label: "Sort Points Low → High" }
];
// const searchType = [{ value: "0", label: "User" }, { value: "1", label: "Order" }];

const UserProfile: FC = () => {
  const {loggedUser} = useTypedSelector((state) => state.auth);
  const [activeChart, setActiveChart] = useState<any>("Online");
  const [requestParameters, setRequestParameters] =
    React.useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
      location: "",
      // searchType: "0",
      group: "",
      sort_type: "last_name:asc",
      startDate: new Date(2022, 0, 1),
      endDate: new Date()
    });
  const [customerListLoading, setCustomerListLoading] =
    React.useState<boolean>(true);
  const [customerList, setCustomerList] =
    React.useState<Array<CustomerInterface> | null>(null);

  const [customerType, setCustomerType] = React.useState<string | number>("");

  const [customerListInfo, setCustomerListInfo] =
    React.useState<CustomerInfoInterface>({
      count_users: 0,
      limit: 0,
      page: 0,
      total_pages_count: 0,
      total_users_count: 0,
      // searchType: 0,
      startDate: new Date(2022, 0, 1),
      endDate: new Date()
    });

  const { groups, locations } = useTypedSelector(
    (state) => state.groupsLocations
  );

  const { getGroupsLocations } = useActions();
  
  useEffect(() => {
    getGroupsLocations();
  }, []);

  const getCustomerList = () => {
    setCustomerListLoading(true);
    getCustomerListAxios({
      ...requestParameters,
      startDate: format(requestParameters.startDate, "dd-MM-yyyy"),
      endDate: format(requestParameters.endDate, "dd-MM-yyyy"),
      offline: activeChart !== 'Online'
    }).then((response) => {
      setCustomerList(response.data.users);
      setCustomerType(
        response.data.users[0]?.promotion_type === "Stamp" ? 1 : 0
      );
      setCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
        startDate: new Date(response.data.startDate),
        endDate: new Date(response.data.endDate)
      });
      const reqDate = new Date(requestParameters.startDate)
      if(response.data.startDate !== format(reqDate, 'yyyy-MM-dd')){
        handleChangeRequestParamtrs('startDate', new Date(response.data.startDate));
      }

      setCustomerListLoading(false);
    }).catch((err) => {
      console.log(err);

    });
  };

  const handleChangeRequestParamtrs = (
    name: string,
    value: string | number | Date
  ) => {
    setRequestParameters((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  useEffect(() => {
      getCustomerList();
  }, [
    requestParameters.group,
    requestParameters.location,
    requestParameters.page,
    requestParameters.sort_type,
    requestParameters.searchType,
    requestParameters.startDate,
    requestParameters.endDate,
    activeChart
  ]);

  useEffect(() => {
    if (requestParameters.search !== null) {
      const timeout = setTimeout(() => {
        getCustomerList();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [requestParameters.search]);

  useEffect(() => {
    if (Number(requestParameters.page) === 1 && customerListInfo.limit !== 0) {
      getCustomerList();
    }
  }, [requestParameters.limit]);
  
  const handleChangeActiveChart = (chartName: any) => {
    setActiveChart(chartName);
  };

  const customerSpendTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className={'full-name-wrapp'} >
            <p>{row.first_name} {row.last_name}</p>
            <div className={'userDatails'}><p>Email: {row.email}</p><p>Number: {row.phone}</p></div>
          </div>
        );
      }
    },
    {
      name: customerType ? "Live Stamps" : "Points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{customerType ? row.stamp_end_value ?? "-"  : row.end_value ?? "-"}</div>;
      }
    },
    {
      name: "Spend Balance",
      omit: !loggedUser.user.allow_spend_card,
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.spend_balance || "0"}</div>;
      }
    },
    {
      name: "Used Spend Balance ",
      omit: !loggedUser.user.allow_spend_card,
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.used_spend_balance || "0"}</div>;
      }
    },
    {
      name: customerType ? "Lifetime stamps" : "Lifetime points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.total_spend ?? "-"}</div>;
      }
    },
    {
      name: "Check in",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.check_in_count ?? "-"}</div>;
      }
    },
    {
      name: "Last visit",
      minWidth: "120px",
      cell: (row: CustomerInterface): JSX.Element => {
        let neeFormat = row.last_visit.replace(" ", "T");
        return (
          <div>
            {row.last_visit
              ? format(new Date(neeFormat), "dd/MM/yyyy, HH:mm:ss")
              : "-"}
          </div>
        );
      }
    }
  ];
  
  return (
    <Wrapper>
      <div className="page-title">Customers</div>
      <div className="top-bar">
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon/>}
            getValue={(value) => setRequestParameters({...requestParameters, search: value})}
          />
        </div>
        {/*<div className="groups-wrap">*/}
        {/*  <Select*/}
        {/*    placeholder="What you search"*/}
        {/*    selectorList={searchType}*/}
        {/*    positionForShow="bottom"*/}
        {/*    defaultValue={searchType[0]}*/}
        {/*    required*/}
        {/*    getValue={(value) =>*/}
        {/*      handleChangeRequestParamtrs("searchType", value)*/}
        {/*    }*/}
        {/*    hideSelectorFilter*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="groups-wrap">
          <Select
            placeholder="All Groups"
            selectorList={groups}
            disabled={!(groups.length > 0)}
            positionForShow="bottom"
            required
            isClearable
            getValue={(value) => handleChangeRequestParamtrs("group", value)}
            hideSelectorFilter
          />
        </div>
        <div className="groups-wrap">
          <Select
            placeholder="Arrange Customer List"
            selectorList={arrangeCustomerSelect}
            positionForShow="bottom"
            defaultValue={arrangeCustomerSelect[0]}
            required
            getValue={(value) =>
              handleChangeRequestParamtrs("sort_type", value)
            }
            hideSelectorFilter
          />
        </div>
        <div className="locations-wrap">
          <Select
            placeholder="All Locations"
            selectorList={locations}
            disabled={!(locations.length > 0)}
            positionForShow="bottom"
            required
            isClearable
            getValue={(value) => handleChangeRequestParamtrs("location", value)}
            hideSelectorFilter
          />
        </div>
        <div className="groups-wrap">
          <Calendar
            selected={requestParameters.startDate}
            onChange={(e) => e <= new Date() ? handleChangeRequestParamtrs("startDate", e) : handleChangeRequestParamtrs("startDate", new Date())}
            dateFormat="MMMM d, yyyy"
            timeSelect={false}
            label={"Start Date"}
          />
        </div>
        <div className="groups-wrap">
          <Calendar
            selected={requestParameters.endDate}
            onChange={(e) => e < requestParameters.startDate ? handleChangeRequestParamtrs("endDate", requestParameters.startDate) : e >= new Date() ? handleChangeRequestParamtrs("endDate", new Date()) : handleChangeRequestParamtrs("endDate", e)}
            dateFormat="MMMM d, yyyy"
            timeSelect={false}
            label={"End Date"}
          />
        </div>
      </div>
      {loggedUser.user.allow_offline_customers ? <div className="nav-subpages">
        <div
          className={`nav-subpage ${
            activeChart === "Online" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Online")}
          >
            Online Customers
          </button>
        </div>
        <div
          className={`nav-subpage ${
            activeChart === "Offline" ? "active-page" : ""
          }`}
        >
          <button
            type="button"
            onClick={() => handleChangeActiveChart("Offline")}
          >
            Offline Customers
          </button>
        </div>
      </div>  : null}
      
      <div
        className={`table-wrap ${customerListLoading ? "loading-blue" : ""}`}
      >
        <div className="loading-innerwrap">
          {customerList !== null ? (
            customerList.length > 0 ? (
              <>
                <div className="table">
                  <DataTable
                    noHeader
                    columns={customerSpendTableColumns}
                    data={customerList}
                    expandableRows
                    expandableRowsComponent={
                      <ExpandableTableList data={customerList} getCustomerList={getCustomerList}
                                           requestParamtrs={requestParameters}/>
                    }
                  />
                </div>
                <Pagination {...customerListInfo} requestLimit={requestParameters.limit}
                            setRequestParamtrs={setRequestParameters}/>
              </>
            ) : (
              <div className="nothing-found">Sorry, nothing found</div>
            )
          ) : null}
        </div>
      </div>
    </Wrapper>
  );
};

export default UserProfile;
