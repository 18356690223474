import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  .create-campaign-wrap {
    background-color: ${Color.white};
    padding: 30px;

    .alert {
      border-radius: 4px;
      padding: 20px;
      color: rgba(0, 13, 70, 0.6);
      font-size: 14px;
      line-height: 20px;
      background-color: rgba(81, 92, 221, 0.2);

      & > p:not(:first-child) {
        margin-top: 20px;
      }
      .upgrade-btn {
        color: #000d46;
        font-weight: bold;
      }
    }

    .section-title {
      color: ${Color.blue};
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin: 30px 0 24px;
    }

    .section-sub-title {
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    .mb-12 {
      margin-bottom: 12px;
    }
    .mb-20 {
      margin-bottom: 20px;
    }
    .mb-24 {
      margin-bottom: 24px;
    }
    .input-block {
      max-width: 380px;
    }
    .separate-line {
      width: 100%;
      border-bottom: 1px solid rgba(0, 13, 70, 0.1);
      padding-top: 10px;
      margin-bottom: 29px;
    }
    .message-block {
      max-width: 560px;
      margin-bottom: 8px;

      Textarea {
        opacity: 0.6;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }
    .select-customers-block {
      max-width: 380px;
    }
    .hint-message {
      color: rgba(0, 13, 70, 0.6);
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      margin-bottom: 30px;
      .limit_error {
        color: ${Color.redMedium};
      }
    }
    .button-container {
      display: flex;
      justify-content: space-between;

      .button-white {
        background-color: ${Color.white};
        color: ${Color.blue};
        border: 2px solid ${Color.blue};
        border-radius: 4px;
        transition: 0.15s all ease;
        &:hover {
          box-shadow: ${Color.blue} 0px 0px 10px 0px;
        }
      }
      button {
        width: 120px;
      }
      .cancel-btn {
        height: 43px;
        margin-right: 20px;
      }
    }
    .choose-campaing-data__block {
      display: flex;
      align-items: flex-end;
      margin: 0 20px 30px 0;

      .label {
        opacity: 0.6;
        color: #000d46;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        margin-bottom: 8px;
      }
    }
    .checkbox-container {
      margin-bottom: 30px;

      .checkbox.white-checkbox {
        margin-bottom: 20px;
        margin-right: 25px;
        .checkbox-input-wrap {
          input {
            & + .checkbox-view {
              background-color: #000e46;
            }
          }
        }
      }
    }
    .image-container {
      display: flex;
      justify-content: center;
      padding-top: 30px;

      img {
        width: 300px;
      }
    }
    @media (max-width: 525px) {
      padding: 20px;
      .button-container {
        flex-direction: column;
        .button-white {
          height: 43px;
        }
        div {
          display: flex;
          flex-direction: column;
        }
        button {
          margin-bottom: 10px;
          width: 100%;
        }
      }
    }
  }
`;

export { Wrapper };
