import React, { ChangeEvent } from "react";
import { IRange } from "./types";
import { Wrapper } from "./styled";

const Range = ({
  minValue,
  maxValue,
  theme = "blue",
  colorProgressBefore = "#515CDD",
  colorProgressAfter = "#FFFFFF",
  labelMin,
  labelMax,
  step = 1,
  value,
  onChange,
}:IRange) => {
  const themeClassName = {
    blue: "blue_range",
  }[theme];

  const firstOperand = value - minValue;
  const secondOperand = maxValue - minValue;

  const minDiapason = labelMin ?? minValue;
  const maxDiapason = labelMax ?? maxValue;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(+e.target.value);
    }
  };
  return (
    <Wrapper>
      <span className="diapason_range">{minDiapason}</span>
      <div className="range_wrapper">
        <input
          type="range"
          min={minValue}
          max={maxValue}
          step={step}
          value={value}
          className={`input_range ${themeClassName}`}
          onChange={handleChange}
          style={{
            background: `linear-gradient(to right, ${colorProgressBefore} 0%, ${colorProgressBefore} ${
              (firstOperand / secondOperand) * 100
            }%,
                ${colorProgressAfter} ${
              (firstOperand / secondOperand) * maxValue
            }%, ${colorProgressAfter} 100%)`,
          }}
        />
        <span
          className="current_value_range"
          style={{
            left: `calc(${(firstOperand * 100) / secondOperand}% + ${
              6 - ((firstOperand * 100) / secondOperand) * 0.22
            }px)`,
          }}
        >
          {value}
        </span>
      </div>
      <span className="diapason_range">{maxDiapason}</span>
    </Wrapper>
  );
};

export default Range;
