import { RegisterPersonalInfoInterface } from "../../../../types/registration";
import axios from "../../../../api/api";

export const registerPersonalInfo = (
  data: RegisterPersonalInfoInterface
): Promise<any> => {
  const requestFormData = new FormData();

  requestFormData.append("email", data.email);
  requestFormData.append("first_name", data.first_name);
  requestFormData.append("last_name", data.last_name);
  requestFormData.append("phone", data.phone);
  requestFormData.append("business_name", data.business_name);
  requestFormData.append("business_size", data.business_size);
  requestFormData.append("subscription", data.subscription ?? "");
  requestFormData.append("vat_number", data.vat_number ?? "");
  requestFormData.append("currency", data.currency ?? '1');

  return axios
    .post("/public/register-personal-info", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
