import axios from "./api";

export const getAllMinimumSpendRulesAxios = (): Promise<any> =>
  axios
    .get("/api/voucher/min-spend/get")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const saveNewMinimumSpendRulesItemAxios = (requestData: any): Promise<any> =>
  axios
    .post("/api/voucher/min-spend/add", requestData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const removeMinimumSpendRulesItemAxios = (requestData: any): Promise<any> =>
  axios
    .post("/api/voucher/min-spend/remove", requestData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
