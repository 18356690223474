export const NAV_STEPS = [
  {
    nav_step: 1,
    label: "Welcome to Lynked",
  },
  // {
  //   nav_step: 2,
  //   label: "Select Your Plan",
  // },
  {
    nav_step: 3,
    label: "Your Account",
  },
  {
    nav_step: 4,
    label: "Create Account",
  },
  {
    nav_step: 5,
    label: "Activate Your Account",
  },
  {
    nav_step: 6,
    label: "Payment Details",
  },
  {
    nav_step: 7,
    label: "Lets Build Your Card",
  },
  {
    nav_step: 8,
    label: "Enter Your Location",
  },
  {
    nav_step: 9,
    label: "Welcome Gift",
  },
];
