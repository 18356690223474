import React, {useEffect, useState} from 'react';
import { Wrapper } from './styled'
import AdminPartnersItem from "../../../../components/AdminPartnersItem";
import {getAdvertOffersAxios} from "../../../../api/adminPanel";
import {AdvertsPartnerType} from "../../../../types/adminTypes";

const AdminAdvertsBrands = () => {
  const [partners, setPartners] = useState<AdvertsPartnerType[]>([])
  useEffect(() => {
    handlerGetAllBrands()
  }, []);
  const handlerGetAllBrands = () => {
    getAdvertOffersAxios({offer_type: 1}).then(({data}) => {
      setPartners(data.advertOffers)
    })
  }
  return (
    <Wrapper>
      <div className="header">
        <h2>In-App Advertisements</h2>
      </div>
      <div className="main_advert">
        <div className="subtitle">
          <h3>Offers (Brands)</h3>
        </div>
        <AdminPartnersItem setPartners={setPartners} type={'brands'} advertList={partners} offers={true} getAllPartners={handlerGetAllBrands
        }/>
      </div>
    </Wrapper>
  );
};

export default AdminAdvertsBrands;
