import {
  GroupsLocationsAction,
  GroupsLocationsActionTypes,
  GroupsLocationsState,
} from "../../types/groupsLocations";

const initialState: GroupsLocationsState = {
  groups: [],
  locations: [],
};

export const groupsLocationsReducer = (
  state = initialState,
  acion: GroupsLocationsAction
): GroupsLocationsState => {
  switch (acion.type) {
    case GroupsLocationsActionTypes.GET_GROUPS_LOCATIONS:
      return {
        ...state,
        groups: acion.payload.groups,
        locations: acion.payload.locations,
      };
    default:
      return state;
  }
};
