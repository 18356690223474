import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  margin-top: 30px;
  .chart {
      position: relative;
    background: ${Color.white};
    padding: 30px;
  }
    #arrow{
        background-color: rgb(51,51,51);
        position: absolute;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        top: -4px;
    }
    .orderLink{
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
        color: ${Color.white};
    }
    #custom-tooltip {
        position: absolute;
        width: 550px;
        display: none;
        background-color: rgb(51,51,51);
        color: ${Color.white};
        border-radius: 8px;
        padding: 10px;
        
        
        .tooltip-header{
            padding-bottom: 10px;
        }
        
        .details{
            .rdt_TableBody::-webkit-scrollbar {
                width: 5px;               /* ширина всієї смуги прокрутки */
            }

            .rdt_TableBody::-webkit-scrollbar-thumb {
                background-color: blue;    /* колір скролбару */
                border-radius: 20px;       /* заокруглення скролбару */
                border: 3px solid black;  /* додає паддінги навколу скролу */
            }
        }
    }
  .doughnut_items_wrapper {
    display: flex;
    align-items: center;
    overflow-x: auto;
    & > * {
      margin: 0px 15px;
    }
    &::-webkit-scrollbar {
      height: 12px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-track {
      background: ${Color.blueBackground};
      border-radius: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${Color.blue};
      border-radius: 3px;
    }
    .not_found {
      font-size: 20px;
      min-height: 200px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .doughnut_item_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    min-height: 200px;
    margin-bottom: 25px;
    .doughnut_location {
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      margin: 12px 0px;
    }
    .center_wrapper {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 100px;
      height: 100px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      text-align: center;
      .center_wrapper_title {
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        color: ${Color.grey};
      }
      .center_count {
        font-size: 28px;
        font-weight: 500;
        line-height: 38px;
      }
    }
  }
  .doughnut_legend {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    .legend_item {
      margin-right: 10px;
      &:last-child {
        margin-right: 0px;
      }
    }
    .legend_wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 25px;
      &:last-child {
        margin-right: 0px;
      }
      .legend_color {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        border-radius: 2px;
        background-color: ${Color.blueLightMedium};
      }
    }
  }
  .doughnut_legend_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    .legend_item_color {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      .legend_color {
        width: 15px;
        height: 12px;
        margin-right: 6px;
      }
      &:first-child {
        .legend_color {
          background-color: ${Color.blueLightMedium};
        }
      }
      &:last-child {
        .legend_color {
          background-color: ${Color.blueDark};
        }
      }
    }
  }
  @media (max-width: 1200px) {
    .chart {
      padding: 15px;
    }
  }
  @media (max-width: 800px) {
    .chart-wrapper {
      overflow-x: scroll;

      .chart {
        width: 700px;
      }
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${Color.white};
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: ${Color.blueLight};
        }
        &::-webkit-scrollbar-thumb:hover {
          background: ${Color.blueText};
        }
      }
    }
  }
`;

export { Wrapper };
