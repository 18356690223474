import styled from "styled-components";
import { Color } from "src/types/color";

export const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);
  .check-email-content {
    text-align: center;
    padding: 60px 50px;
    @media (max-width: 600px) {
      padding: 30px 15px;
    }
    img {
      max-width: 100%;
      width: 185px;
      margin-bottom: 30px;
    }
    .title {
      color: ${Color.blueDarkText};
      font-weight: 500;
      font-size: 48px;
      letter-spacing: 0;
      line-height: 61px;
      text-align: center;
      max-width: 600px;
      margin: 0 auto;
      @media (max-width: 600px) {
        font-size: 30px;
        line-height: 28px;
      }
    }
  }
`;
