import React, { FC } from "react";

import { ShowHideButton, ShowHideDiv, ShowHideSpan } from "../ShowHideStaffMembers/styles";
import { LocationInfoInterface } from "../../../../types/place";

interface IProps {
  isShowLocationDetails?: boolean;
  editLocationOpenSidebar: (location: LocationInfoInterface) => void;
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  location: LocationInfoInterface;
}

const ShowHideBtns: FC<IProps> = ({ isShowLocationDetails, editLocationOpenSidebar, setIsOpen, isOpen, location }) => {
  const toggleShowStaff = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="two_btn_wrapper">
      {isShowLocationDetails &&
        <button
          type="button"
          onClick={() => editLocationOpenSidebar(location)}
        >
          Show Location details
        </button>
      }
      <ShowHideDiv>
        <ShowHideButton
          type="button"
          onClick={toggleShowStaff}
        >
          <ShowHideSpan>
            {isOpen ? "Show" : "Hide"} all staff members ({location.users.length})
          </ShowHideSpan>
        </ShowHideButton>
      </ShowHideDiv>
    </div>
  );
};

export default ShowHideBtns;