/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "../../../../api/api";

export const welcomePromotionSubmit = (
  data: any,
  accountInformationToken: string,
  coverImage: File[],
  workTime: any
): Promise<any> => {
  const formData = new FormData();
  formData.append("details", data.promotionDetails);
  formData.append("name", data.promotionName);
  // formData.append("deal_id", data.promotionDealId);
  formData.append("open_time", data.openTime);
  formData.append("close_time", data.closeTime);
  formData.append("coverImage", coverImage[0]);
  formData.append("token", accountInformationToken);

  Object.keys(workTime).forEach((weekDay) => {
    formData.append(`work_time[${weekDay}][allDay]`, workTime[weekDay].allDay);
    formData.append(`work_time[${weekDay}][from]`, workTime[weekDay].from);
    formData.append(`work_time[${weekDay}][to]`, workTime[weekDay].to);
    formData.append(`work_time[${weekDay}][label]`, workTime[weekDay].label);
    formData.append(`work_time[${weekDay}][isOpen]`, workTime[weekDay].isOpen);
  });

  return axios
    .post("/public/register-welcome-promotion", formData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const welcomePromotionSubmitWithoutGift = (
  accountInformationToken: string,
  coverImage: File[],
  workTime: any
): Promise<any> => {
  const formData = new FormData();
  formData.append("token", accountInformationToken);
  formData.append("coverImage", coverImage[0]);

  Object.keys(workTime).forEach((weekDay) => {
    formData.append(`work_time[${weekDay}][allDay]`, workTime[weekDay].allDay);
    formData.append(`work_time[${weekDay}][from]`, workTime[weekDay].from);
    formData.append(`work_time[${weekDay}][to]`, workTime[weekDay].to);
    formData.append(`work_time[${weekDay}][label]`, workTime[weekDay].label);
    formData.append(`work_time[${weekDay}][isOpen]`, workTime[weekDay].isOpen);
  });

  return axios
    .post("public/complete-registration", formData)
    .then((response) => response)
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const getPlaceLogo = (accountInformationToken: string): Promise<any> =>
  axios
    .get("/public/get-place", {
      params: {
        token: accountInformationToken,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
