import styled, { keyframes } from "styled-components";
import { Color } from "src/types/color";

const uploadKeyFrames = keyframes`
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
`;

const Wrapper = styled.div`
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .pages-wrap {
    margin-right: 30px;
    width: calc(75% - 30px);
  }

  .short-user-info-wrap {
    width: 25%;
    margin-top: 60px;
  }
  .short-user-info {
    border-radius: 4px;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
    position: relative;
    .dropZone {
      display: flex;
      flex-direction: column;
      height: 100%;

      &.with-photo {
        & > div {
          .dropZone-content-text {
            opacity: 0;
            transition: 0.5s all ease;
          }
        }
        &:hover {
          & > div {
            .dropZone-content-text {
              opacity: 1;
            }
          }
        }
      }

      & > div {
        position: relative;
        height: 100%;
        outline: none;
        cursor: pointer;

        &.active_true {
          border-color: ${Color.blueDark};
          box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 35%);
        }

        &.active_false {
          min-height: 200px;
        }

        input {
          max-width: 100%;
          display: block !important;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          opacity: 0;
          z-index: -1;
        }

        .img {
          line-height: 0;
        }

        .dropZone-content-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.85);

          border: 1.5px dashed ${Color.blueDark};
          border-radius: 4px;
          outline: none;
          padding: 30px;
          cursor: pointer;
          transition: all 0.225s cubic-bezier(0.77, 0.2, 0.05, 1);

          & > svg {
            margin-bottom: 5px;
            animation: ${uploadKeyFrames} 2s cubic-bezier(0.5, 0, 0.5, 1)
              infinite;
            path[fill-rule="evenodd"] {
              fill: ${Color.blueDark};
            }
          }

          .text {
            color: ${Color.blueDark};
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
              margin-bottom: 5px;
            }
            .title {
              font-size: 18px;
              font-weight: 300;
              line-height: 23px;
            }
            .description {
              opacity: 0.6;
              font-size: 12px;
              font-weight: 300;
              line-height: 15px;
            }
            .file-name {
              font-size: 21px;
              font-weight: 300;
              line-height: 23px;
            }
          }
        }
      }
    }
    .img {
      img {
        max-width: 100%;
        width: 100%;
      }
    }
    .name {
      color: ${Color.blueDarkText};
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 3px;
    }
    .joined {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      text-align: center;
    }
  }
  @media (max-width: 1444px) {
    .row {
      flex-direction: column-reverse;
      .short-user-info-wrap {
        width: 325px;
        margin: 0 auto 40px;
        .short-user-info {
          margin-right: 30px;
        }
      }
      .pages-wrap {
        width: 100%;
      }
    }
  }
  @media (max-width: 640px) {
    .first-last-name {
      flex-direction: column;
      .form-group {
        width: 100%;
      }
      .last-name {
        margin-left: 0;
      }
    }
    .phone-wrap {
      width: 100%;
    }
    .pages-wrap {
      .nav-subpages {
        flex-direction: column;
        .nav-subpage {
          width: 100%;
          background: ${Color.white};
          display: flex;
          justify-content: center;
          border-bottom: 1px solid #f7f8fc;
        }
      }
    }
  }
`;

export { Wrapper };
