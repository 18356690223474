import axios from "./api";

export enum SitePosRulesEnum {
  LOYALTY = 0,
  DISCOUNT = 1,
  
}

export const getAllSiteLoyaltyRewards = (): Promise<any> =>
  axios
    .get("/api/site/pos/rules/get")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAllSellersToLoyaltyRewards = (): Promise<any> =>
  axios
    .get("/api/site/pos/rules/get-sites")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const setNewSiteLoyaltyReward = (
  requestParameters: any
): Promise<any> =>
  axios
    .post("/api/site/pos/rules/add", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeSiteLoyaltyReward = (
  requestParameters: any
): Promise<any> =>
  axios
    .post("/api/site/pos/rules/remove", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
