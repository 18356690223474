import React, {useCallback, useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import PosCategoryColumn from "../../../components/PosCategoryColumnWrapper/PosCategoryColumn";
import DataTable from "react-data-table-component";
import PosTabWrapper from "../../../components/PosTabWrapper";
import Input from "../../../components/Input";
import {ReactComponent as CheckCircle} from "../../../assets/img/checkCircle.svg";
import {ReactComponent as Edit} from "../../../assets/img/edit.svg";
import {ReactComponent as Delete} from "../../../assets/img/delete.svg";
import {ReactComponent as CirclePlus} from "../../../assets/img//circle-plus.svg";
import {createExtrasProductsAxios, getExtrasProductsAxios, removeExtrasProductsAxios} from "../../../api/posTerminals";

const ExtrasProducts = () => {
  const invalidInputTextValue = [
    '', 0, 0.0,'0'
  ]
  const [extrasProducts, setExtrasProducts] = useState<any[]>([]);
  
  const getExtrasProducts = useCallback(() => {
    getExtrasProductsAxios().then(res => {
      setExtrasProducts(res.data.productExtras);
    })
  }, [])
  
  useEffect(() => {
    getExtrasProducts()
  }, []);
  
  const saveEditStampCategory = (id: number) => {
    const newItems = extrasProducts.filter(item => item.id === id)[0];
    newItems.edit = true;
    if(invalidInputTextValue.includes(newItems.name) || invalidInputTextValue.includes(newItems.sku)) {
      return
    }
    
    const requestItem: any = {
      id: newItems.id,
      name: newItems.name,
      sku: newItems.sku,
    }
    createExtrasProductsAxios(requestItem).then(() => {
      getExtrasProducts();
    })
  }
  
  const addNewPosStampCategory = (index: number) => {
    const newItems = extrasProducts[index];
    newItems.edit = false;
    if(invalidInputTextValue.includes(newItems.name) || invalidInputTextValue.includes(newItems.sku)) {
      return
    }
    
    const requestItem: any = {
      name: newItems.name,
      sku: newItems.sku,
    }
    createExtrasProductsAxios(requestItem).then(() => {
      getExtrasProducts();
    }).catch(e => {
      console.log(e);
    });
  }
  
  const editPosStampCategoryItem = (id: number) => {
    const newItems = extrasProducts;
    newItems[id].edit = true;
    setExtrasProducts((stamp: Array<any>) => stamp = [...newItems]);
  };
  
  const removePosStampCategoryItem = (id: number | undefined) => {
    removeExtrasProductsAxios({id})
      .then((res) => {
        getExtrasProducts();
      }).catch(e => {
      console.log(e);
    });
    setExtrasProducts((items: Array<any>) => items.filter((category) => category.id !== id));
  };
  
  const addPosStampCategory = () => {
    const newStampCategoryItem: any = {
      name: '',
      sku: '',
      edit: true,
    }
    setExtrasProducts([...extrasProducts, newStampCategoryItem])
    // setEditStamp(true)
  }
  
  const extrasProductsList = [
    {
      name: "",
      maxWidth: "40%",
      cell: (item: any): JSX.Element => {
        return (
          <Input
            defaultValue={item.name}
            required
            getValue={(value) => item.name = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "40%",
      cell: (item: any): JSX.Element => {
        return (
          <Input
            defaultValue={item.sku}
            getValue={(value) => item.sku = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "10%",
      cell: (item: any, index: number): JSX.Element => {
        
        return (
          <div className="buttons" data-tag="allowRowEvents">
            {item.edit && item.id ?
              <button className="btn-white edit-category save-btn" onClick={() => saveEditStampCategory(item.id)}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button>
              : item.edit ?
                <button className="btn-white edit-category save-btn" onClick={() => addNewPosStampCategory(index)}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                  <span>Save</span>
                </button> : <>
                  <button className="btn-white edit-category" onClick={() => editPosStampCategoryItem(index)}>
                      <span className="icon">
                        <Edit/>
                      </span>
                  </button>
                  <button className="btn-white remove-category" onClick={() => removePosStampCategoryItem(item.id)}>
                      <span className="icon">
                      <Delete/>
                      </span>
                  </button>
                </>}
          
          </div>
        );
      }
    }
  ];
  
  return (
    <Wrapper>
      <PosTabWrapper title={"Extras to products"}
                     description={""}
                     button={false}>
        <>
          <div className="content extras-based-wrapper">
            <div className="header">
              <div className="header-tab">
                <PosCategoryColumn title={"Extras Name"} description={"Name your extras. e.g Fue"}/>
                <PosCategoryColumn title={"Extras SKU"}
                                   description={"Enter your Extras SKU processed through your POS"}/>
                <div className="column_buttons"></div>
              </div>
              <div className="extras-based-form">
                {extrasProducts !== null ? (
                  extrasProducts.length > 0 ? (
                    <div className="table">
                      <DataTable
                        noTableHead
                        noHeader
                        columns={extrasProductsList}
                        data={extrasProducts}
                      />
                    </div>
                  ) : (
                    <div className="nothing-found">Sorry, nothing found</div>
                  )
                ) : null}
              </div>
              <button className="btn-white add-stampCategory" onClick={() => addPosStampCategory()}>
            <span className="icon">
              <CirclePlus/>
            </span>
                <span>Add Extras</span>
              </button>
            </div>
          </div>
        
        </>
      
      </PosTabWrapper>
    </Wrapper>
  );
};

export default ExtrasProducts;
