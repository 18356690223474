import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
  & > div {
    position: absolute;
    left: calc(50% - 8px);
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    background-color: #fcfcfd;
    width: 90%;
    max-width: 500px;
    box-sizing: border-box;
    max-height: 90%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #737ce4;
    }
  }
`;

export { Wrapper };
