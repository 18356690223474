import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import Modal from "src/components/Modal";
import {
  getPlaceInfoByHashAxios,
  confirmPlaceInfoByHashAxios,
  declinePlaceInfoByHashAxios,
} from "src/api/place";
import { PlaceInfoInterface } from "src/types/place";
import { ReactComponent as LogoFull } from "src/assets/img/logo-full.svg";

import { Wrapper } from "./styled";
import { RouteParamsInterface } from "./types";

const PlaceApprove: FC = () => {
  const params = useParams<RouteParamsInterface>();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [placeInfo, setPlaceInfo] = React.useState<PlaceInfoInterface | null>(
    null
  );
  const [responseMessage, setResponseMessage] = React.useState<string>("");

  const getPlaceInfo = () => {
    getPlaceInfoByHashAxios(params.hash)
      .then((response) => {
        setPlaceInfo(response.data.place);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const declinePlace = () => {
    setLoading(true);
    declinePlaceInfoByHashAxios(params.hash).then((response) => {
      setResponseMessage(response.data.message);
      setLoading(false);
    });
  };

  const acceptPlace = () => {
    setLoading(true);
    confirmPlaceInfoByHashAxios(params.hash).then((response) => {
      setResponseMessage(response.data.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    getPlaceInfo();
  }, []); //eslint-disable-line

  return (
    <Wrapper>
      <div className="inner-wrap">
        <div className="logo">
          <LogoFull />
        </div>
        <div className={`main-block ${loading ? "loading-blue" : ""}`}>
          <div className="loading-innerwrap">
            {placeInfo ? (
              <>
                <div className="title">Place Information</div>
                <div className="place-information-content">
                  <div className="item">
                    <div className="name">Name of the company:</div>
                    <div className="description">{placeInfo?.name}</div>
                  </div>
                  <div className="item">
                    <div className="name">Card promotion name:</div>
                    <div className="description">{placeInfo?.details}</div>
                  </div>
                  {placeInfo.promotion === "Points" ? (
                    <div className="item">
                      <div className="name">Card promotion info:</div>
                      <div className="description">
                        {placeInfo?.description}
                      </div>
                    </div>
                  ) : null}
                  <div className="item">
                    <div className="name">Category:</div>
                    <div className="description">{placeInfo?.category}</div>
                  </div>
                  <div className="item">
                    <div className="name">Type of promotion:</div>
                    <div className="description">{placeInfo?.promotion}</div>
                  </div>
                </div>
                <div className="title">Locations</div>
                <div className="locations-information-content">
                  {placeInfo?.locations.map((item) => (
                    <div className="item" key={item.id}>
                      {item.address}
                    </div>
                  ))}
                </div>
                <div className="btns-decline-accept">
                  <button
                    type="button"
                    className="btn-red btn-decline"
                    onClick={() => declinePlace()}
                  >
                    Decline
                  </button>
                  <button
                    type="button"
                    className="btn-green btn-accept"
                    onClick={() => acceptPlace()}
                  >
                    Accept
                  </button>
                </div>
              </>
            ) : (
              <div className="warning-text">
                The place was updated with newly added information. This link is
                not valid anymore.
              </div>
            )}
          </div>
        </div>
      </div>
      {responseMessage && (
        <Modal
          openModal={!!responseMessage}
          setOpenModal={() => setResponseMessage("")}
          closeOutsideClick={false}
        >
          <div className="modal-content">
            <div className="title">{responseMessage}</div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default PlaceApprove;
