import axios from "./api";

export const getDashboardInfoAxios = (data: {
  date: any;
  startDate?: any;
  endDate?: any;
  locationId: number;
}): Promise<any> =>
  axios
    .post("/api/dashboard", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getStaffDashboardInfoAxios = (data: {
  date: any;
  startDate?: any;
  endDate?: any;
  locationId: number;
}): Promise<any> =>
  axios
    .post("/api/dashboard/staff", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getStaffDiscountSalesChannelsInfoAxios = (data: {
  sortType: any;
  startDate?: any;
  endDate?: any;
}): Promise<any> =>
  axios
    .post("/api/dashboard/staff/sales/channels", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getAdminDashboardInfoAxios = (data: {
  startDate?: any;
  endDate?: any;
  businessId: number;
}): Promise<any> =>
  axios
    .post("/api/admin/dashboard", data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
