import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {checkLoginUser} from "src/utils/checkLoginUser";
import {store} from "./store";
import "./index.scss";
import App from "./App";

import {AuthUserActionTypes} from "./types/auth";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

Bugsnag.start({
  apiKey: 'cd9dbe780524910850213887f982383c',
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: 'cd9dbe780524910850213887f982383c' })

store.dispatch({
  type: AuthUserActionTypes.LOGIN_CHECK,
});
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

checkLoginUser();
ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
    <Provider store={store}>
        <App/>
    </Provider>
      </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
