import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  padding: 50px 40px 40px 150px;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);

  @media (max-width: 1200px) {
    padding: 40px 30px 40px 50px;
  }
  @media (max-width: 600px) {
    padding: 40px 15px;
  }

  .inner-wrap {
    width: 100%;
    max-width: 950px;
  }

  /* .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 65px;
    }
    .logo {
      max-width: 250px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
      svg {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        font-family: "object-fit: cover";
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .period-type {
      input {
        & + span {
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.1);
          color: ${Color.white};
          width: 150px;
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
          text-align: center;
          box-sizing: border-box;
          padding: 15px;
          cursor: pointer;
          @media (max-width: 768px) {
            width: 85px;
            font-size: 16px;
            font-weight: 300;
            line-height: 21px;
            padding: 5px;
          }
        }
        &:disabled + span {
          cursor: not-allowed;
        }
        &:checked {
          & + span {
            background-color: ${Color.blue};
          }
        }
      }
      .periodTypeYearly {
        display: none;
      }
    }
  } */

  .title-block {
    margin-bottom: 30px;
    .title {
      color: ${Color.blueDarkText};
      font-size: 48px;
      font-weight: 500;
      line-height: 61px;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    .sub-title {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
      max-width: 600px;
    }
  }
  .discount_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
    .discount_input {
      flex-basis: 45%;
    }
  }
  .switcher_wrapper {
    margin-bottom: 15px;
  }
  .test {
    padding: 30px 10px 10px 10px;
    overflow-x: auto;
    width: 100%;
  }
  .plants_wrapper {
    min-width: max-content;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
  }
  .row_label_column {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: ${Color.white};
    display: flex;
    flex-direction: column;
    width: 250px;
    color: ${Color.grey};
    flex-shrink: 0;
    .label_name {
      flex-shrink: 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 50px;
      border: 1px solid ${Color.greyLight};
      border-left: unset;
      border-right: unset;
      border-top: unset;
      padding: 10px 25px;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 15px;
    }
    .empty_label {
      border-bottom-left-radius: 4px;
      box-sizing: border-box;
      height: 70px;
    }
    .pricing_label_wrapper {
      border-top-left-radius: 4px;
      box-sizing: border-box;
      height: 160px;
      padding: 35px 30px;
      border: 1px solid ${Color.greyLight};
      border-left: unset;
      border-top: unset;
      border-right: unset;
      font-size: 10px;
      font-weight: 300;
      line-height: 13px;
      .pricing_label {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 6px;
      }
      .pricing_label_text {
        opacity: 0.7;
      }
    }
  }
  .row_plan_item {
    background: ${Color.white};
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    color: ${Color.grey};
    flex-shrink: 0;
    .plan_header {
      box-sizing: border-box;
      padding: 20px;
      border: 1px solid ${Color.greyLight};
      border-top: unset;
      height: 160px;
      flex-shrink: 0;
      .plan_header_top {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 5px;
      }
      .plan_icon_wrapper {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${Color.greyLight};
        svg {
          -o-object-fit: cover;
          object-fit: cover;
          width: 90%;
          height: 90%;
          font-family: "object-fit: cover";
        }
      }
      .plan_desc {
        font-size: 10px;
        font-weight: 300;
        line-height: 13px;
        opacity: 0.6;
        margin-bottom: 8px;
      }
      .plan_price_wrapper {
        display: flex;
        align-items: flex-end;
        gap: 2px;
        margin-bottom: 10px;
        .plan_price {
          font-size: 20px;
          font-weight: 600;
          line-height: 25px;
        }
        .plan_period {
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          opacity: 0.6;
        }
      }
      & > button {
        padding: 7px 12px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
    .plan_header {
      border-right: unset;
    }
    .plan_access {
      flex-shrink: 0;
      box-sizing: border-box;
      height: 50px;
      padding: 4px;
      border: 1px solid ${Color.greyLight};
      border-right: unset;
      border-top: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    .btn_wrapper:last-child {
      border-bottom-right-radius: 4px;
    }
    .btn_wrapper {
      box-sizing: border-box;
      padding: 20px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${Color.greyLight};
      border-top: unset;
      border-right: unset;
      border-bottom: unset;
      & > button {
        padding: 7px 12px;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }
  .row_plan_item:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    .plan_header,
    .plan_access,
    .btn_wrapper {
      border-top-right-radius: 4px;
      border-right: unset;
    }
  }
  /* .plans-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    min-height: 620px;
    position: relative;
    @media (max-width: 1200px) {
      justify-content: space-around;
    }
  }
  .plan-item {
    flex: 1;
    margin-bottom: 30px;
    margin-right: 60px;
    box-sizing: border-box;
    &.premium {
      margin-right: 0;
    }
    background-color: ${Color.blueDarkBlour};
    border: 4px solid ${Color.blueDarkBlour};
    border-radius: 10px;
    padding: 50px 40px;
    color: ${Color.white};
    @media (max-width: 1024px) {
      margin-right: 0;
      width: calc((100% / 2) - 30px);
      flex: none;
    }
    @media (max-width: 768px) {
      margin-right: 0;
      width: 100%;
      max-width: 500px;
    }
    &.checked {
      border-radius: 10px;
      &.lite {
        border: 4px solid #50c2c5;
        button.choose-plan {
          background-color: #50c2c5;
          border: 2px solid #50c2c5;
        }
      }
      &.plus {
        border: 4px solid #ec9345;
        button.choose-plan {
          background-color: #ec9345;
          border: 2px solid #ec9345;
        }
      }
      &.premium {
        border: 4px solid #d34ee8;
        button.choose-plan {
          background-color: #d34ee8;
          border: 2px solid #d34ee8;
        }
      }
    }
    &.lite {
      order: 1;
      .description {
        li:before {
          background-color: #50c2c5;
        }
      }
      button.choose-plan:hover {
        box-shadow: #50c2c5 0px 0px 10px 0px;
        border: 2px solid #50c2c5;
      }
    }
    &.plus {
      order: 2;
      .description {
        li:before {
          background-color: #ec9345;
        }
      }
      button.choose-plan:hover {
        box-shadow: #ec9345 0px 0px 10px 0px;
        border: 2px solid #ec9345;
      }
    }
    &.premium {
      order: 3;
      .description {
        li:before {
          background-color: #d34ee8;
        }
      }
      button.choose-plan:hover {
        box-shadow: #d34ee8 0px 0px 10px 0px;
        border: 2px solid #d34ee8;
      }
    }
    &-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .icon-name {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .icon {
        width: 50px;
        height: 50px;
        line-height: 0;
        margin-right: 20px;
      }
      .name {
        font-size: 28px;
        font-weight: 700;
        line-height: 30px;
      }
    }
    .price {
      margin-bottom: 30px;
      .amoutn {
        font-size: 54px;
        font-weight: 500;
        line-height: 68px;
        margin-right: 5px;
      }
      .period {
        opacity: 0.5;
        font-size: 18px;
        font-weight: 300;
      }
    }
    .description {
      list-style: none;
      li {
        font-size: 20px;
        font-weight: 300;
        line-height: 25px;
        margin-bottom: 15px;
        position: relative;
        padding-left: 15px;
        &:before {
          content: "";
          display: inline-block;
          width: 7px;
          height: 7px;
          border-radius: 100%;
          top: 8px;
          left: 0;
          position: absolute;
        }
      }
    }
    button.choose-plan {
      box-sizing: border-box;
      width: 100%;
      padding: 10px;
      border: 2px solid ${Color.white};
      border-radius: 4px;
      background: none;
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
    }
  } */

  .btn-wrap-create-account {
    max-width: 362px;
    margin: 50px auto 50px;
  }

  .recommended_item .recommended_item_label {
    border: 1px solid ${Color.blue};
    position: absolute;
    top: -22px;
    left: -1px;
    height: 20px;
    width: 100%;
    border-radius: 3px 3px 0 0;
    background-color: ${Color.blue};
    color: ${Color.white};
    font-family: "Circular Std";
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  .recommended_item {
    border: 1px solid ${Color.blue};
    border-radius: 4px;
    .plan_header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .recommended_item .btn_wrapper {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
`;

export { Wrapper };
