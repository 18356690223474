import React from "react";
import {
  // DropEvent,
  // DropzoneOptions,
  // FileRejection,
  useDropzone,
} from "react-dropzone";

import { ReactComponent as UploadIcon } from "../../../../assets/img/upload.svg";

const DropZone: React.FC<{
  onDrop: (event: any) => void;
}> = ({ onDrop }) => {
  const { acceptedFiles, isDragActive, getRootProps, getInputProps } =
    useDropzone({
      accept: ['image/jpeg', 'image/jpg', 'image/png'],
      multiple: false,
      onDrop,
    });

  return (
  <div {...getRootProps()} className={`active_${isDragActive}`}> {/*eslint-disable-line*/}
    <input {...getInputProps()} required/> {/*eslint-disable-line*/}
      <div>
        <UploadIcon />
        {acceptedFiles.length ? (
          <span className="text">
            <span className="file-name">{acceptedFiles[0].name}</span>
          </span>
        ) : (
          <span className="text">
            <span className="title">Please upload your Cover Image</span>
            <span className="description">
              (reccomended size is 1920 x 1080 px)
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default DropZone;
