import React, { FC } from "react";
import { Link } from "react-router-dom";

import Modal from "src/components/Modal";
import Input from "src/components/Input";
import { forgotPassword } from "src/api/auth";

import { ReactComponent as LogoFull } from "src/assets/img/logo-full.svg";
import checkEmailImg from "src/assets/img/check-your-email.svg";

import { Wrapper } from "./styled";

const ForgotPassword: FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [successResponse, setSuccessResponse] = React.useState<string>("");
  const [errorResponse, setErrorResponse] = React.useState<string>("");

  const handleForgotPassword = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    forgotPassword(email)
      .then((response) => {
        setSuccessResponse(
          response?.data?.message ??
            "Please check your emails for reset password"
        );
        setLoading(false);
      })
      .catch((error) => {
        setErrorResponse(error?.data?.message ?? "Server error");
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <div className="inner-wrap">
        <div className="logo">
          <LogoFull />
        </div>
        <form
          onSubmit={handleForgotPassword}
          className={`${loading ? "loading-blue" : ""}`}
        >
          <div className="form-innerwrap">
            <div className="title">Forgot Password</div>
            <div className="form-group">
              <Input
                type="email"
                placeholder="Email"
                required
                getValue={(value) => {
                  setEmail(value);
                  setErrorResponse("");
                }}
              />
            </div>
            {errorResponse && (
              <div className="form-error-message">{errorResponse}</div>
            )}
            <button type="submit" className="btn-blue">
              Reset
            </button>
            <div className="forgot-signup">
              <Link to="/login" className="blue-link">
                Back to Login
              </Link>
            </div>
          </div>
        </form>
      </div>
      {successResponse && (
        <Modal
          openModal={!!successResponse}
          setOpenModal={() => setSuccessResponse("")}
          closeOutsideClick={false}
        >
          <div className="modal-check-email-content">
            <img src={checkEmailImg} alt="" />
            <div className="title">{successResponse}</div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default ForgotPassword;
