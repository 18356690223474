import React from "react";
import styled from "styled-components";
import Routers from "./Routers";

const GlobalWrapper = styled.div``;

const App: React.FC = () => {
  return (
    <GlobalWrapper>
      <Routers />
    </GlobalWrapper>
  );
};

export default App;
