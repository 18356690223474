import React, { FC, useEffect, useState } from "react";
import { WrapperTableList } from "src/views/StaffMembers/InnerTable/styled";
import DataTable from "react-data-table-component";
import Input from "../../Input";
import { ReactComponent as CheckCircle } from "../../../assets/img/checkCircle.svg";
import { ReactComponent as Edit } from "../../../assets/img/edit.svg";
import { ReactComponent as Delete } from "../../../assets/img/delete.svg";
import { ReactComponent as CirclePlus } from "../../../assets/img/circle-plus.svg";
import Select from "../../Select";
import {
   editStampCategoryAxios,
  removeStampCategoryAxios,
  setPosStampCategoryAxios
} from "../../../api/posTerminals";
import {
  posStampCategoryRequest
} from "../../../types/posRyles";
import Modal from "../../Modal";

const InnerPosStampCategoryGroup: FC<any> = ({ data, setItems, edit, setEdit, selectStampCategory, getAllCategories, scaleParemeterSelectList }) => {
  const [innerGroup, setInnerGroup] = useState<posStampCategoryRequest[]>([])
  const [allItems, setAllItems] = useState<any>(data)
  const [successResponse, setSuccessResponse] = useState<string>('')

  const categoryList = [
    {
      name: "",
      width: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.name}
            getValue={(value) => item.name = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_group}
            getValue={(value) => item.product_group = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_sku}
            getValue={(value) => item.product_sku = value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (<Select
            placeholder="Select stamp Icon"
            selectorList={scaleParemeterSelectList}
            positionForShow="bottom"
            required
            defaultValue={scaleParemeterSelectList.filter((stamp: any) => stamp.value === item.icon)[0] || scaleParemeterSelectList[0]}
            getValue={(value) => item.icon = value}
            hideSelectorFilter
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: posStampCategoryRequest): JSX.Element => {
        return (
          <Input
            defaultValue={item.target}
            type="number"
            getValue={(value: string) => item.target = +value}
            // disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "7.5%",
      cell: (item: any, index: number): JSX.Element => {

        return (
          <div className="buttons">
            {item.edit && item.id ?
              <button className="btn-white edit-category save-btn" onClick={() => saveEditItem(item.id)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category save-btn" onClick={() => saveItem(index)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button> :  <>
                <button disabled={edit} className="btn-white edit-category" onClick={() => editItem(index)}>
                      <span className="icon">
                        <Edit />
                      </span>
                </button>
                <button disabled={edit} className="btn-white remove-category" onClick={() => removeItem(item.id)}>
                      <span className="icon">
                      <Delete />
                      </span>
                </button>
              </>}

          </div>
        );
      }
    }
  ];

  useEffect(() => {
    setInnerGroup(allItems.children);
    setEdit(false)
  }, []);

  const saveItem = (id: number) => {
    const newItems = innerGroup;
    newItems[id].edit = false;

    const requestItem:posStampCategoryRequest  = {
      name: newItems[id].name,
      product_group: newItems[id].product_group,
      product_sku: newItems[id].product_sku,
      icon: `${newItems[id].icon}`,
      target: newItems[id].target,
      parent: `${allItems.id}`
    }
    setPosStampCategoryAxios(requestItem).then((res) => {
      getAllCategories();
      newItems[id] = { ...res.data, children: [] }
      setSuccessResponse('Group was created')
      setEdit(false);
    }).catch(e => {
      console.log(e);
    });

  };
  const editItem = (id: number) => {
    const newItems = innerGroup;
    newItems[id].edit = true;
    setEdit(true);
  };
  const saveEditItem = (id: number | undefined) => {
    const newItems = innerGroup.filter(item => item.id === id)[0];
    newItems.edit = false;

    const requestItem:posStampCategoryRequest  = {
      id: newItems.id,
      name: newItems.name,
      product_group: newItems.product_group,
      product_sku: newItems.product_sku,
      icon: `${newItems.icon}`,
      target: newItems.target,
      parent: `${allItems.id}`
    }
    editStampCategoryAxios(requestItem).then(()=>{
      getAllCategories();
      setEdit(false);
    })
  };
  const removeItem = (id: number | undefined) => {
    removeStampCategoryAxios(`${id}`)
      .then((res) => {
        getAllCategories();
        setInnerGroup((items: Array<any>) => items.filter((category, index) => category.id !== id));
        setSuccessResponse('Group was removed')
        setEdit(false);
      }).catch(e => {
      console.log(e);
    });

  };

  const addNewCategory = () => {
    const newItem:posStampCategoryRequest = {
      name: '',
      product_group: '',
      product_sku: '',
      icon: 'coffee_cup',
      target: 0,
      edit: true,
    }
    setEdit(true);

    setInnerGroup((group:posStampCategoryRequest[]) => group = [...group, newItem])
  }


  return (
    <WrapperTableList>
      {innerGroup.length > 0 ?
        <>
          {edit ? <div className="table table-edit"><DataTable
            data={innerGroup}
            columns={categoryList}
            noHeader
            noTableHead
            onRowClicked={(row) => selectStampCategory(row)}
            expandableRows
            expandableRowsComponent={
              <InnerPosStampCategoryGroup data={innerGroup} edit={edit} setEdit={setEdit} getAllCategories={getAllCategories} setItems={setInnerGroup} scaleParemeterSelectList={scaleParemeterSelectList}/>
            }
          /></div> : <div className="table"><DataTable
            data={innerGroup}
            columns={categoryList}
            noHeader
            noTableHead
            onRowClicked={(row) => selectStampCategory(row)}
            expandableRows
            expandableRowsComponent={
              <InnerPosStampCategoryGroup data={innerGroup} edit={edit} setEdit={setEdit} getAllCategories={getAllCategories} setItems={setInnerGroup} scaleParemeterSelectList={scaleParemeterSelectList}/>
            }
          /></div>}
        </> : (
          <div className="nothing-found">Sorry, nothing found</div>
        )
      }
      <button disabled={edit} className="btn-white add-category" onClick={() => addNewCategory()}>
            <span className="icon">
              <CirclePlus/>
            </span>
        <span>Add Group</span>
      </button>

      {successResponse && (
        <Modal
          openModal={!!successResponse}
          setOpenModal={() => setSuccessResponse("")}
          closeOutsideClick={true}
        >
          <div className="modal-content">
            <div className="title">Success</div>
            <div className="sub-title">{successResponse}</div>
          </div>
        </Modal>
      )}
    </WrapperTableList>

  );
};

export default InnerPosStampCategoryGroup;
