import styled from "styled-components";
// import { Color } from "src/types/color";

const Wrapper = styled.div`
  margin-bottom: 20px;
  form {
    position: relative;
    padding: 30px;

    @media (max-width: 500px) {
      padding: 30px 20px 40px;
    }
  }
  .form-group {
    margin-bottom: 30px;
    .label_block {
      display: inline-flex;
      color: #000d46;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
      text-align: left;
    }
  }
  .first-last-name,
  .form-row {
    display: flex;
    .first-name {
      flex: 1;
      margin-right: 15px;
    }
    .last-name {
      flex: 1;
    }
    .form-input {
      flex: 1;
      margin-right: 15px;
      margin-left: 15px;
      @media (max-width: 500px) {
        margin-left: 0px;
      }
    }
    .form-input:first-child {
      margin-left: unset;
    }
    .form-input:last-child {
      margin-right: unset;
    }
  }
  .phone-wrap {
    width: calc(50% - 15px);
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .сity-сountry-postal {
    display: flex;
    @media (max-width: 1444px) {
      flex-direction: column;
      .form-group {
        width: 100%;
        &.сountry,
        &.postal {
          margin-left: 0;
        }
      }
    }
    .сity {
      flex: 1;
      margin-right: 15px;
    }
    .сountry {
      flex: 1;
      margin-right: 15px;
      margin-left: 15px;
    }
    .postal {
      flex: 1;
      margin-left: 15px;
    }
  }

  .form-error-message {
    color: red;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
  }

  .button-wrap {
    text-align: right;
    button {
      width: auto;
      padding: 10px 30px;

      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
`;

export { Wrapper };
