export interface LoginUser {
  email: string;
  password: string;
}

export interface AuthUserState {
  loggedUser: any;
  loading: boolean;
  error: null | string;
}

export enum AuthUserActionTypes {
  LOGIN = "LOGIN",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_ERROR = "LOGIN_ERROR",
  LOGIN_CHECK = "LOGIN_CHECK",
  LOGIN_CHECK_FETCH = "LOGIN_CHECK_FETCH",

  LOGOUT = "LOGOUT",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_ERROR = "LOGOUT_ERROR",

  UPDATE_PHOTO_SUCCESS = "UPDATE_PHOTO_SUCCESS",
  UPDATE_PERSONAL_DETAILS_SUCCESS = "UPDATE_PERSONAL_DETAILS_SUCCESS",
  UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS",
}

interface LoginAction {
  type: AuthUserActionTypes.LOGIN;
}
interface LoginSuccessAction {
  type: AuthUserActionTypes.LOGIN_SUCCESS;
  payload: any;
}
interface LoginErrorAction {
  type: AuthUserActionTypes.LOGIN_ERROR;
  payload: string;
}
interface LoginCheck {
  type: AuthUserActionTypes.LOGIN_CHECK;
}
interface LoginCheckFetch {
  type: AuthUserActionTypes.LOGIN_CHECK_FETCH;
  payload: any;
}

interface LogoutAction {
  type: AuthUserActionTypes.LOGOUT;
}
interface LogoutSuccessAction {
  type: AuthUserActionTypes.LOGOUT_SUCCESS;
}
interface LogoutErrorAction {
  type: AuthUserActionTypes.LOGOUT_ERROR;
  payload: string;
}

interface UpdatePhotoSuccessAction {
  type: AuthUserActionTypes.UPDATE_PHOTO_SUCCESS;
  payload: string;
}
interface UpdatePersonalDetailsSuccessAction {
  type: AuthUserActionTypes.UPDATE_PERSONAL_DETAILS_SUCCESS;
  payload: any;
}
interface UpdateSubscriptionSuccessAction {
  type: AuthUserActionTypes.UPDATE_SUBSCRIPTION_SUCCESS;
  payload: string;
}

export type AuthUserAction =
  | LoginAction
  | LoginSuccessAction
  | LoginErrorAction
  | LoginCheck
  | LoginCheckFetch
  | LogoutAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | UpdatePhotoSuccessAction
  | UpdatePersonalDetailsSuccessAction
  | UpdateSubscriptionSuccessAction;

export interface ResetPasswordRequest {
  password: string;
  password_confirm: string;
  token: string;
}

export interface PersonalDetailsInterface {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  address: string;
  city: string;
  country: string;
  code: string;
  about_me: string;
  vat_number: string;
  business_name: string;
  business_size: string;
  currency: number;
}
