import React, {useCallback, useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import Input from "../../components/Input";
import {ReactComponent as SearchIcon} from "../../assets/img/search.svg";
import { ReactComponent as CloseIcon } from "../../assets/img/clear.svg";
import {ReactComponent as DeleteIcon} from "../../assets/img/delete.svg";
import {CustomerInfoInterface, CustomerRequestInterface} from "../../types/customer";
import Select from "../../components/Select";
import {SelectorListObj} from "../../components/Select/types";
import DataTable from "react-data-table-component";
import Pagination from "../../components/Pagination";
import {ProductDiscountItem} from "../../types/productDiscountTypes";
import {
  addSpendCardsAxios,
  getSpendCardsAxios,
  getSpendUsersSelectListAxios,
  removeSpendCardsAxios
} from "../../api/spendCard";
import Modal from "../../components/Modal";

const SpendCard = () => {
  const [requestParamtrs, setRequestParamtrs] =
    useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
    });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [spendList, setSpendList] = useState<ProductDiscountItem[]>([]);
  const [spendListInfo, setSpendListInfo] = useState<CustomerInfoInterface>({
    count_users: 0,
    limit: 0,
    page: 0,
    total_pages_count: 0,
    total_users_count: 0,
  });
  const [usersList, setUsersList] = useState<SelectorListObj[]>([])
  const [newSpend, setNewSpend] = useState<{customer_id: number | string, init_value: number}>({customer_id: 0, init_value: 0})
  const [deleteStampCardModal, setDeleteStampCardModal] = useState<any>(null)
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      getAllSpendCards();
    }, 500);
    
    return () => clearTimeout(timeout);
  }, [requestParamtrs]);
  
  const getAllSpendCards = useCallback(() =>{
    setIsLoading(true)
    getSpendCardsAxios(requestParamtrs).then(res => {
      setSpendList(res.spendCards)
      setSpendListInfo({
        count_users: Number(res.count_spend_card),
        limit: Number(res.limit),
        page: Number(res.page),
        total_pages_count: Number(res.total_pages_count),
        total_users_count: Number(res.total_spend_cards_count),
      });
    }).finally(()=>{
      setIsLoading(false)
    })
    getSpendUsersSelectListAxios().then(res=>{
      setUsersList(res.customers)
      setNewSpend({...newSpend, customer_id: +res.customers[0].value})
    })
  },[newSpend, requestParamtrs])
  
  const handleAddNewSpend = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    addSpendCardsAxios(newSpend).then(res=>{
      getAllSpendCards()
      setShowAddForm(false)
    })
  }
  
  const removeProductDiscount = (id: number) =>{
    setDeleteStampCardModal(spendList.find(item=>item.id === id))
  }
  
  const hendelDeleteLocationStaffConfirm = () => {
    if (deleteStampCardModal) {
      setIsDeleteLoading(true);
      removeSpendCardsAxios({id: deleteStampCardModal.id}).then(res=>{
        getAllSpendCards()
        setIsDeleteLoading(false)
        setDeleteStampCardModal(null)
      })
    }
  };
  const saveEditSpendCard = (item: any) =>{
    setIsLoading(true)
    const requestData = {
      id: item.id,
      customer_id: item.customer.id,
      init_value: item.add_value,
      used_value: item.used_value
    }
    
    addSpendCardsAxios(requestData).then(res=>{
      getAllSpendCards()
    })
  }
  
  
  const productDiscountTableColumns = [
    {
      name: "Customer Full Name",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {row.customer.full_name}
          </div>
        );
      },
    },
    {
      name: "Customer Email",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {
              row.customer.email
            }
          </div>
        );
      },
    },
    {
      name: "Lifetime Spend Balance",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {
              row.init_value
            }
          </div>
        );
      },
    },
    {
      name: "Current Balance",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {
              parseFloat(String(row.init_value - row.used_value)).toFixed(2)
            }
          </div>
        );
      },
    },
    {
      name: "Used Spend",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {
              row.used_value
            }
          </div>
        );
      },
    },
    {
      name: "Add Spend",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div className={'addSpendWrap'}>
               <input
                 placeholder="Lifetime Spend Balance"
                 required
                 defaultValue={0}
                 autoComplete="off"
                 onInput={e=>row.add_value = e.currentTarget.value}
                 style={{
                   borderRadius: "4px",
                   padding: "13px 16px",
                   boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                 }}
               />
              <button
                className="btn-blue-outline"
                style={{padding: "10px 20px", backgroundColor: "transparent"}}
                onClick={() => saveEditSpendCard(row)}
              >
                Add
              </button>
          </div>
        );
      },
    },
    {
      name: "",
      maxWidth: "5%",
      minWidth: "5%",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            {/*{!row.edit ? (*/}
            {/*  <>*/}
                {/*<button*/}
                {/*  type="button"*/}
                {/*  className="edit"*/}
                {/*  onClick={() => setEditItem(row.id)}*/}
                {/*>*/}
                {/*  <EditIcon/>*/}
                {/*</button>*/}
                <button
                  type="button"
                  className="delete"
                  onClick={() => removeProductDiscount(row.id)}
                >
                  <DeleteIcon/>
                </button>
            {/*  </>*/}
            {/*) : (*/}
            {/*  <button*/}
            {/*    className="btn-blue-outline"*/}
            {/*    style={{padding: "10px 20px", backgroundColor: "transparent"}}*/}
            {/*    onClick={() => saveEditSpendCard(row)}*/}
            {/*  >*/}
            {/*    Save*/}
            {/*  </button>*/}
            {/*)}*/}
          </div>
        );
      },
    },
  ];
  
  return (
    <Wrapper>
      <div className="page-title">Spend Card</div>
      <div className="top-bar staff-top-bar">
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon/>}
            getValue={(value) =>
              setRequestParamtrs({...requestParamtrs, search: value})
            }
          />
        </div>
        <button
          type="button"
          className="btn-white staff-add-btn"
          style={{marginBottom: "12px", marginRight: "20px"}}
          onClick={() => setShowAddForm(true)}
        >
          Add New Spend
        </button>
      
      
      </div>
      {showAddForm ? <div className="create-new-user">
        <form
          onSubmit={(e)=>handleAddNewSpend(e)}
          className={`${
            isLoading ? "loading-blue" : ""
          }`}
          autoComplete="off"
        
        >
          <div className="loading-innerwrap">
            <div className="title-close">
              <div className="title">Create new spend</div>
              <button
                type="button"
                className="close"
                onClick={() => setShowAddForm(false)}
              >
                <CloseIcon/>
              </button>
            </div>
            <div className="input-wrap">
              <div className="first_name-group">
                <Select
                  placeholder="Select User"
                  selectorList={usersList}
                  positionForShow="bottom"
                  required
                  defaultValue={usersList[0]}
                  getValue={(value) => setNewSpend({...newSpend, customer_id: +value})}
                  hideSelectorFilter={false}
                />
              </div>
              <div className="last_name-group">
                <Input
                  placeholder="Spend Value"
                  required
                  autoComplete="off"
                  getValue={(value) =>
                    setNewSpend({...newSpend, init_value: +value})
                  }
                />
              </div>
            </div>
            <button type="submit" className="btn-blue">
              Add User
            </button>
          </div>
        </form>
      </div> : null}
      <div className={`table-wrap ${isLoading ? "loading-blue" : ""}`}>
        <div className="table">
          <DataTable
            noHeader
            columns={productDiscountTableColumns}
            data={spendList}
            // expandableRows
            // expandableRowsComponent={
            //   <StaffFamilyTable data={staffList} getCustomerList={getStaff}/>
            // }
          />
          <Pagination
            {...spendListInfo}
            requestLimit={requestParamtrs.limit}
            setRequestParamtrs={setRequestParamtrs}
          />
        </div>
      </div>
      
      {deleteStampCardModal && (
        <Modal
          openModal={!!deleteStampCardModal}
          setOpenModal={() => setDeleteStampCardModal(null)}
          closeOutsideClick
        >
          <div
            className={`remove-modal ${
              isDeleteLoading ? "loading-blue" : ""
            }`}
          >
            <div className="loading-innerwrap">
              <div className="title">
                Please confirm delete spend card &quot;
                {deleteStampCardModal.customer.full_name}&quot;
              </div>
              <button
                type="button"
                className="btn-blue"
                onClick={hendelDeleteLocationStaffConfirm}
              >
                Confirm delete
              </button>
            </div>
          </div>
        </Modal>
      )}
    
    </Wrapper>
  );
};

export default SpendCard;
