/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from "react";
// import { useDropzone } from "react-dropzone";
// import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { getPromotionsCategoriesAxios } from "src/api/promotion";
import Input from "src/components/Input";
// import Select from "src/components/Select";
// import Calendar from "src/components/Calendar";
// import { SetupPromotionInterface } from "src/types/registration";
import userDefaultImg from "src/assets/img/default-user.jpg";
import { ReactComponent as DirectionRightIcon } from "src/assets/img/direction_right.svg";
import { ReactComponent as InfoIcon } from "src/assets/img/info_grad.svg";
import { ReactComponent as ShareIcon } from "src/assets/img/ios_share_grad.svg";
// import { ReactComponent as UploadIcon } from "src/assets/img/upload.svg";

import { Wrapper } from "./styled";
import DropZone from "./DropZone";
import {
  welcomePromotionSubmit,
  getPlaceLogo,
  welcomePromotionSubmitWithoutGift,
} from "./api";
// import workHours from "./const";
import WorkTime from "../../../../components/WorkTime/WorkTime";

const RegistrationWelcomePromotion: React.FC<{
  accountInformationToken: string | null;
}> = ({ accountInformationToken }) => {
  const [formErrorMessage, setFormErrorMessage] = useState<any>({});
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [workingTime, setWorkingTime] = useState<any>({
    monday: {
      label: "Monday",
      allDay: true,
      isOpen: true,
      from: "",
      to: "",
    },
    tuesday: {
      label: "Tuesday",
      allDay: true,
      isOpen: true,
      from: "",
      to: "",
    },
    wednesday: {
      label: "Wednesday",
      allDay: true,
      isOpen: true,
      from: "",
      to: "",
    },
    thursday: {
      label: "Thursday",
      allDay: true,
      isOpen: true,
      from: "",
      to: "",
    },
    friday: {
      label: "Friday",
      allDay: true,
      isOpen: true,
      from: "",
      to: "",
    },
    saturday: {
      label: "Saturday",
      allDay: true,
      isOpen: true,
      from: "",
      to: "",
    },
    sunday: {
      label: "Sunday",
      allDay: true,
      isOpen: true,
      from: "",
      to: "",
    },
  });

  const [setupPromotion, setSetupPromotion] = useState<{
    // promotionDealId: number | null;
    promotionName: string;
    promotionDetails: string;
    setupWelcomePromotion: boolean;
    openTime: string | null;
    closeTime: string | null;
  }>({
    // promotionDealId: null,
    promotionName: "",
    promotionDetails: "",
    setupWelcomePromotion: true,
    openTime: null,
    closeTime: null,
  });

  const getValue = (type: string, value: any) => {
    setFormErrorMessage({});
    setSetupPromotion((prevInfo) => {
      return { ...prevInfo, [type]: value };
    });
  };

  const [promotionDeal, setPromotionDeal] = React.useState<
    [
      {
        value: string | number;
        label: string;
      }
    ]
  >([
    {
      value: "",
      label: "",
    },
  ]);

  const [coverFile, setCoverFile] = React.useState<File[]>();

  const [defaultFields, setDefaultFields] = useState<{
    welcomePromotionLogo: string;
    defaultAddress: string;
    details: string;
    locationName: string;
    type: string | number;
  }>({
    welcomePromotionLogo: "",
    defaultAddress: "",
    details: "",
    locationName: "",
    type: 0,
  });

  const getPlaceInfo = () => {
    getPlaceLogo(accountInformationToken!).then(({ data }) => {
      setDefaultFields({
        welcomePromotionLogo: data.logo,
        defaultAddress: data.default_address,
        details: data.details,
        locationName: data.locationName,
        type: data.type,
      });
    });
  };

  // const [currPromoDeal, setCurrPromoDeal] = useState<string>("");

  // const onDrop = (acceptedFiles: any) => {
  // const onDrop = () => {
  //   setAdditionalInformation({
  //     ...additionalInformation,
  //     logo: acceptedFiles[0],
  //   });
  // };

  // const {
  //   acceptedFiles,
  //   isDragActive,
  //   getRootProps,
  //   getInputProps,
  // } = useDropzone({
  //   accept: "image/*",
  //   multiple: false,
  //   onDrop,
  // });

  const handleAccountInformation = (event: React.FormEvent) => {
    event.preventDefault();
    if (accountInformationToken && coverFile) {
      setIsLoading(true);
      welcomePromotionSubmit(
        { ...setupPromotion },
        accountInformationToken,
        coverFile,
        workingTime
      )
        .then(() => {
          setIsLoading(false);
          window.location.href = "/login";
        })
        .catch((error) => {
          setIsLoading(false);
          setFormErrorMessage(error.data.errors);
        });
    }
    // setAdditionalInformationRegistration(additionalInformation);
  };

  const handleAccountInformationWOGift = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    welcomePromotionSubmitWithoutGift(
      accountInformationToken ?? "",
      coverFile!,
      workingTime
    )
      .then(() => {
        window.location.href = "/login";
      })
      .finally(() => setIsLoading(false));
  };

  const getPromotionDealList = () => {
    if (promotionDeal.length === 1) {
      getPromotionsCategoriesAxios().then((response) => {
        const arr: any = [];
        response.data.promotion_details.map((item: any) =>
          arr.push({
            value: item.id,
            label: item.name,
          })
        );
        setPromotionDeal(arr);
      });
    }
  };

  useEffect(() => {
    getPromotionDealList();
    getPlaceInfo();
  }, []);//eslint-disable-line

  return (
    <Wrapper>
      <form
        className="registration_promotion"
        onSubmit={
          setupPromotion.setupWelcomePromotion
            ? handleAccountInformation
            : handleAccountInformationWOGift
        }
      >
        <h1 className="promotion_title">Create a Welcome Gift</h1>
        <p className="promotion_text">
          Create a welcome gift to attract customers in your area. This is a
          good way to attract new customers into your business and upsell other
          products and services, once they visit they are now enrolled in your
          Loyalty Program which increases the potential of repeat spending and
          increases revenue.
        </p>
        <div className={`promotion_wrapper ${isLoading ? "loading-blue" : ""}`}>
          <div className="promotion_left">
            <div className="form-group">
              <div className="dropZone">
                <DropZone onDrop={setCoverFile} />
              </div>
              {formErrorMessage?.coverImage ? (
                <div className="form-error-message">
                  {formErrorMessage.coverImage}
                </div>
              ) : null}
            </div>
            <WorkTime
              workingTimeProp={workingTime}
              formErrorMessage={formErrorMessage}
              setWorkingTimeProp={setWorkingTime}
            />
            <p className="setup_text">
              Would you like to setup a welcome gift?
            </p>
            <div
              className={`control_switch ${
                !setupPromotion.setupWelcomePromotion
                  ? "control_switch_right"
                  : ""
              }`}
            >
              <button
                type="button"
                className={`btn_switch ${
                  setupPromotion.setupWelcomePromotion
                    ? "btn_switch_active"
                    : ""
                }`}
                onClick={() => getValue("setupWelcomePromotion", true)}
              >
                Yes (I want more customers)
              </button>
              <button
                type="button"
                className={`btn_switch ${
                  !setupPromotion.setupWelcomePromotion
                    ? "btn_switch_active"
                    : ""
                }`}
                onClick={() => getValue("setupWelcomePromotion", false)}
              >
                No (I can setup later)
              </button>
            </div>
            <div
              className={`toggle_wrapper ${
                setupPromotion.setupWelcomePromotion
                  ? "toggle_wrapper_open"
                  : ""
              }`}
            >
              <div className="promotion_form lynked-form">
                {/* <div className="form-group">
                  <div className="dropZone">
                    <DropZone onDrop={setCoverFile} />
                  </div>
                  {formErrorMessage?.coverImage ? (
                    <div className="form-error-message">
                      {formErrorMessage.coverImage}
                    </div>
                  ) : null}
                </div> */}
                {/* <div className="form-group">
                  <Input
                    label="Welcome Gift Name"
                    getValue={(value) => getValue("promotionName", value)}
                    // infoTooltip="A sample promotion if you are a coffee shop: Spend over €15 and receive a free donut. This can be any promotion that you can offer any customer that may visit you once, when passing through or visit you every day so keep it open!"
                    error={formErrorMessage.name}
                  />
                </div> */}
                <div className="form-group">
                  <Input
                    label="Welcome Gift"
                    infoTooltip="A welcome gift is a one-time offer that enables you to attract Lynked app users into your business for their first transaction. This is a useful means of attracting new customers who will then repeat shop with you. For example, “Free coffee with your first Lynked stamp”"
                    getValue={(value) => {
                      getValue("promotionDetails", value);
                    }}
                    defaultValue={setupPromotion.promotionDetails}
                    error={formErrorMessage.promotionDetails}
                  />
                </div>
                {/* <div className="form-group">
                  <Select
                    label="Type of Deal"
                    selectorList={promotionDeal}
                    positionForShow="top"
                    required
                    getValue={(value) => {
                      getValue("promotionDealId", value);
                      // setCurrPromoDeal(label);
                    }}
                    hideSelectorFilter
                  />
                  {formErrorMessage?.deal_id ? (
                    <div className="form-error-message">
                      {formErrorMessage.deal_id}
                    </div>
                  ) : null}
                </div> */}
                {formErrorMessage?.token ? (
                  <div className="form-error-message">
                    {formErrorMessage.token}
                  </div>
                ) : null}
                <button type="submit" className="btn-blue">
                  Create Promotion and Log In
                </button>
              </div>
            </div>
          </div>
          <div className="promotion_right">
            <div className="sticky_wrapper">
              <p className="look_text">What your Welcome Gift looks like</p>
              <div className="promotion_card_look">
                <div className="img_wrapper">
                  <img
                    src={
                      coverFile && coverFile.length
                        ? URL.createObjectURL(coverFile[0])
                        : userDefaultImg
                    }
                    className="img_look"
                    alt="icon"
                  />
                  {/* {!currPromoDeal || currPromoDeal === "No Deal" ? null : (
                    <span className="promotion_deal_text">{currPromoDeal}</span>
                  )} */}
                </div>
                <div className="card_bottom">
                  <div className="company_logo_wrapper">
                    <img
                      src={defaultFields.welcomePromotionLogo}
                      alt="business"
                    />
                  </div>
                  <div className="company_info_wrap">
                    <div className="company_info_left">
                      <p className="info_bold">{defaultFields.locationName}</p>
                      {/* <p className="company_location">
                        {defaultFields.defaultAddress}
                      </p> */}
                      <div className="gift_info_wrap">
                        <p className="offer_title">Digital Loyalty Program:</p>
                        <p className="offer_term_text">
                          {!defaultFields.type ? "Stamps" : "Points"}
                        </p>
                      </div>
                    </div>
                    <div className="company_info_right">
                      <p className="info_bold">1.25km</p>
                      <p className="company_open">Open</p>
                    </div>
                  </div>
                  {defaultFields.details ? (
                    <div className="gift_info_wrap">
                      <p className="offer_title">Details:</p>
                      <p className="offer_term_text">{defaultFields.details}</p>
                    </div>
                  ) : null}
                  {setupPromotion.promotionDetails ? (
                    <div className="gift_info_wrap">
                      <p className="offer_title">Welcome Gift:</p>
                      <p className="offer_term_text">
                        {setupPromotion.promotionDetails}
                      </p>
                    </div>
                  ) : null}
                  <p className="line_hor" />
                  <div className="card_bottom_wrapper">
                    <div className="icon_wrapper">
                      <InfoIcon />
                      <DirectionRightIcon />
                      <ShareIcon />
                    </div>
                    <button type="button" className="btn-blue small_button">
                      Redeem
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!setupPromotion.setupWelcomePromotion ? (
          <button type="submit" className="btn-blue">
            Skip and Log In
          </button>
        ) : null}
      </form>
    </Wrapper>
  );
};

export default RegistrationWelcomePromotion;
