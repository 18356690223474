export class RequestFormData extends FormData {
  appendArray = (key: string, data: any) => {
    if (this.isIterable(data)) {
      Object.keys(data).forEach((index: string) => {
        this.appendArray(`${key}[${index}]`, data[index]);
      });
    } else {
      this.append(key, data);
    }
  };

  isIterable = (obj: any) => {
    if (obj == null) {
      return false;
    }
    return typeof obj === "object";
  };
}
