import styled from "styled-components";

export const ShowHideSpan = styled.span`
  background: none;
  color: #515CDD;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
`;

export const ShowHideButton = styled.button`
  background: none;
`;

export const ShowHideDiv = styled.div`
  margin-left: auto;
`;
