import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  .calendar-wrapper {
    position: relative;
    & > .label:not(:empty) {
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
      text-align: left;
    }
    .icon-calendar {
      position: absolute;
      left: 13px;
      bottom: 8px;
    }
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        z-index: 1;
        input {
          width: 326px;
          height: 40px;
          border: 1px solid #e4e4e4;
          border-radius: 4px;
          background-color: ${Color.white};
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          cursor: pointer;
          color: ${Color.blueDarkText};
          font-size: 16px;
          font-weight: 300;
          line-height: 20px;
          opacity: 0.6;
          padding-left: 50px;
          caret-color: transparent;
        }
      }
    }
    .react-datepicker__tab-loop {
      .react-datepicker {
        border-radius: 4px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #e4e4e4;
        font-family: "Circular Std";

        .react-datepicker__header {
          background-color: #f7f8fc;

          .react-datepicker__current-month,
          .react-datepicker-time__header {
            color: ${Color.blueDarkText};
          }
        }
      }
    }
  }
`;

export { Wrapper };
