import {
  PromotionAction,
  PromotionActionTypes,
  PromotionState,
} from "../../types/promotion";

const initialState: PromotionState = {
  promotionTypes: [],
  promotionCategories: [],
  promotionTypesEnable: false,
  promotionCategoriesEnable: false,
};

export const promotionReducer = (
  state = initialState,
  acion: PromotionAction
): PromotionState => {
  switch (acion.type) {
    case PromotionActionTypes.GET_PROMOTION:
      return {
        ...state,
        promotionTypes: acion.payload.promotionTypes,
        promotionCategories: acion.payload.promotionCategories,
        promotionTypesEnable: true,
        promotionCategoriesEnable: true,
      };
    default:
      return state;
  }
};
