import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  display: flex;
  padding: 40px 40px 40px 100px;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);

  @media (max-width: 1200px) {
    padding: 40px 30px 40px 50px;
  }
  @media (max-width: 600px) {
    padding: 40px 15px;
  }

  .registration_locations {
    margin: auto 0;
    width: 100%;
  }
  .location_title {
    font-size: 48px;
    font-weight: 500;
    line-height: 61px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .location_text {
    font-size: 16px;
    font-weight: 300;
    opacity: 0.6;
    line-height: 24px;
    max-width: 470px;
    margin-bottom: 25px;
  }
  .location_form {
    .form_group {
      margin-bottom: 30px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      .phone-wrap {
        width: 400px;
        @media (max-width: 700px) {
          width: 100%;
        }
      }
    }
    .two_items {
      display: flex;
      gap: 20px;
      //align-items: center;
      & > div {
        width: 400px;
      }

      @media (max-width: 700px) {
        flex-wrap: wrap;
        & > div {
          width: 100%;
        }
      }
    }
    .btn-blue {
      max-width: 200px;
    }
  }
  .lynked-form {
    position: relative;
    .form-error-message {
      margin-top: 5px;
    }
  }
`;

export { Wrapper };
