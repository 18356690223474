import { Color } from "src/types/color";
import styled, { keyframes } from "styled-components";

const uploadKeyFrames = keyframes`
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
`;

const Wrapper = styled.div`
  .dropZone {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 60px;

    &.with-photo {
      margin-bottom: 20px;
      & > div {
        .dropZone-content-text {
          opacity: 0;
          transition: 0.5s all ease;
        }
      }

      &:hover {
        & > div {
          .dropZone-content-text {
            opacity: 0.9;
          }
        }
      }
    }

    & > div {
      position: relative;
      height: 100%;
      outline: none;
      cursor: pointer;

      &.active_true {
        border-color: ${Color.blueDark};
        box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 35%);
      }

      &.active_false {
        min-height: 320px;
      }

      input {
        max-width: 100%;
        display: block !important;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        opacity: 0;
        z-index: -1;
      }

      .img {
        line-height: 0;
        height: 250px;
        max-width: 800px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .dropZone-content-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${Color.blueLightBackground};
        border: 1px dashed ${Color.blue};
        border-radius: 4px;
        outline: none;
        padding: 30px;
        cursor: pointer;
        transition: all 0.225s cubic-bezier(0.77, 0.2, 0.05, 1);

        & > svg {
          margin-bottom: 5px;
          animation: ${uploadKeyFrames} 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

          path[fill-rule="evenodd"] {
            fill: ${Color.blue};
          }
        }

        .text {
          color: ${Color.blue};
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          span {
            margin-bottom: 5px;
          }

          .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
          }

          .description {
            opacity: 0.6;
            font-size: 12px;
            font-weight: 300;
            line-height: 15px;
          }

          .file-name {
            font-size: 21px;
            font-weight: 300;
            line-height: 23px;
          }
        }
      }
    }
  }
  .locations {
    position: relative;
    /* margin-right: 30px; */
    /* width: calc(70% - 30px); */
    margin-top: 30px;
    border-radius: 4px;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 30px;
    box-sizing: border-box;

    .title {
      color: ${Color.blueDarkText};
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 25px;
    }

    .create-filter {
      margin-bottom: 15px;
    }

    .create-location {
      form {
        margin-bottom: 30px;
        border: 1px solid rgba(0, 13, 70, 0.1);
        border-radius: 5px;
        padding: 15px;
        position: relative;
      }

      .title-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 0px;
        }

        .close {
          background: none;
          opacity: 0.6;
          line-height: 0;

          &:hover {
            opacity: 1;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .select-deal-container {
        .select {
          margin-bottom: 15px;
        }
      }

      .input-wrap {
        margin-bottom: 15px;

        .location-group {
          svg path {
            fill: ${Color.blue};
          }
        }
      }

      .add-location-wrapper {
        display: flex;
        flex-direction: column;

        .left-block {
          width: 100%;
          margin-right: 15px;
        }

        .right-block {
          width: 100%;

          .upload-image {
            /* height: 150px; */
            margin-bottom: 15px;
          }
        }
      }
    }

    .create-new-user {
      form {
        margin-bottom: 30px;
        border: 1px solid rgba(0, 13, 70, 0.1);
        border-radius: 5px;
        padding: 15px;
        overflow: hidden;
      }

      .title-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 0px;
        }

        .close {
          background: none;
          opacity: 0.6;
          line-height: 0;

          &:hover {
            opacity: 1;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .input-wrap {
        display: flex;
        margin-bottom: 15px;

        & > div {
          flex: 1;
          width: calc(100% / 3);
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
          
          &.last_name-group{
            &>div{
              ul.bottom{
                max-height: 70px;
              }
            }
          }
        }
      }
    }

    .location-item {
      border-radius: 4px;
      background-color: ${Color.blueLightBackground};
      margin-bottom: 10px;
      padding: 20px;
      box-sizing: border-box;

      .main {
        border-bottom: 1px solid rgba(0, 13, 70, 0.1);
        margin-bottom: 15px;

        .logo-nameAddress {
          display: flex;
          align-items: flex-start;
        }

        .logo {
          line-height: 0;
          margin-right: 15px;

          img {
            height: 40px;
            width: 40px;
            border-radius: 100%;
            object-fit: cover;
          }
        }

        .name-address {
          width: calc(100% - 55px);
        }

        .name {
          color: ${Color.blueDarkText};
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
          margin-bottom: 5px;
        }

        .address {
          opacity: 0.6;
          color: ${Color.blueDarkText};
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          margin-bottom: 20px;
        }

        .edit-remove {
          display: flex;
          align-items: center;

          button {
            background: none;
            padding: 0;
            opacity: 0.6;

            &:hover {
              opacity: 1;
            }
          }

          .edit {
            margin-right: 10px;
          }
        }
      }

      .two_btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 600px) {
          * {
            flex-basis: 100%;
            margin-bottom: 10px;
          }
        }
        button {
          background: none;
          color: ${Color.blue};
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
        }
      }

      &.hide-staff {
        .show-hide-staff {
          button {
            .hide {
              display: none;
            }
          }
        }

        .staff-list-wrap {
          height: 0px;
          overflow: hidden;
        }
      }

      &.show-staff {
        .show-hide-staff {
          button {
            .show {
              display: none;
            }
          }
        }
      }

      .show-hide-staff {
        text-align: center;
      }

      .staff-list-wrap {
        transition: 0.5s all ease;
      }

      .create-filter {
        display: flex;

        .search-wrap {
          margin-left: 20px;
          flex: 1;
          width: 100%;
        }

        button {
          margin-bottom: 15px;
        }
      }

      .staff-list {
        border-bottom: 1px solid rgba(0, 13, 70, 0.1);
        padding-bottom: 20px;
        box-sizing: border-box;
        margin-bottom: 15px;

        &.table {
          & > div {
            overflow: unset;
          }
        }

        &-table-wrap {
          position: relative;
        }

        div.rdt_TableRow {
          padding: 13px 0;
        }

        div:first-child {
          &::-webkit-scrollbar {
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: ${Color.white};
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: ${Color.blueText};
          }

          &:hover {
            &::-webkit-scrollbar-thumb {
              background: ${Color.blueLight};
            }

            &::-webkit-scrollbar-thumb:hover {
              background: ${Color.blueText};
            }
          }
        }
      }
      @media (max-width: 500px) {
        padding: 15px;
      }
    }

    .edit_location {
      margin-bottom: 25px;
      .close-wrap {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0px 15px;
        .close {
          background: none;
        }
      }

      .edit_location_form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & > *:nth-child(1) {
          flex-basis: 45%;
        }
        & > *:nth-child(2) {
          flex-basis: 45%;
        }
        & > *:nth-child(3) {
          flex-basis: 100%;
        }
        .btn_wrapper {
          width: 100%;
        }
        @media (max-width: 600px) {
          & > *:nth-child(1) {
            flex-basis: 100%;
          }
          & > *:nth-child(2) {
            flex-basis: 100%;
            margin: 15px 0px;
          }
        }
      }

      .location-group-logo {
        flex-basis: 100%;
        margin: 15px 0px;
      }
      .welcome_gift_wrapper {
        background-color: ${Color.white};
        padding: 16px;
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      .welcome_gift_form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        & > * {
          flex-basis: 100%;
          margin: 10px 0px;
        }
      }
      .title_block {
        color: ${Color.blueDarkText};
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin: 0px 0px 16px;
      }
    }

    .sidebar-edit-location {
      .location-group {
        padding: 5px 3px 0px 3px;
        margin-bottom: 15px;
      }

      .location-group-google-autocomplete {
        .place-point-icon {
          svg path {
            fill: ${Color.blue};
          }
        }
      }

      .location-group-logo {
        .dropZone > div .img img {
          position: absolute;
        }
      }

      form {
        button[type="submit"] {
          margin-top: 30px;
        }
      }
    }

    .sidebar-edit-staff {
      .staff-group {
        padding: 5px 3px 0px 3px;
        margin-bottom: 15px;
      }

      form {
        button[type="submit"] {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 500px) {
      padding: 15px 10px;
    }
  }
  .remove-modal {
    text-align: center;
    padding: 30px 35px;

    .title {
      color: ${Color.blueDarkText};
      font-size: 21px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }

    button {
      margin-top: 20px;
      width: auto;
      padding: 10px 20px;
    }
  }
`;

export { Wrapper };
