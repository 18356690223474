import styled from "styled-components";

import { Color } from "src/types/color";

const Wrapper = styled.div`
  .content {
    position: relative;
    border-radius: 4px;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;

      .search-wrap {
          margin-left: 20px;
          input {
              padding: 10px 16px 10px 46px;
              margin: 0 2px -2px;
          }
          svg{
              top: 50%;
              transform: translate(0,-50%);
          }
      }
    form {
      max-width: 700px;
      .form-group {
        margin-bottom: 30px;
      }
      .buttons-wrap {
        border-top: 1px solid rgba(0, 13, 70, 0.1);
        padding-top: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        button {
          width: auto;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &.full-width-form {
        max-width: unset;
      }
    }

    .manually-choosing-wrap {
      .top {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .title {
          color: ${Color.blueDarkText};
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin-right: 30px;
        }
      }
    }
    .all-customers {
      position: relative;
    }

    .using-filters-wrap {
      .filter-limit {
        border-radius: 4px;
        background-color: rgba(81, 92, 221, 0.2);
        color: rgba(0, 13, 70, 0.6);
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        padding: 15px 20px;
        margin-bottom: 30px;
      }
      .curent-filters-wrap {
        .form-group-filter,
        .col-filter {
          width: calc(100% - 210px);
        }
        .form-group-days,
        .col-days {
          width: 110px;
          margin-right: 30px;
          margin-left: 30px;
        }
        .btn-delete,
        .col-delete {
          width: 40px;
          background: none;
          &:hover {
            svg {
              path[fill-rule="evenodd"] {
                fill: ${Color.blue};
              }
            }
          }
        }
      }
      .curent-filter-titles {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
      .curent-filter {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .form-group-filter {
          .select {
            margin-bottom: 0;
          }
        }
      }

      .butns-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-blue-dashed {
          margin-bottom: 30px;
        }
      }

      .users-by-filters {
        position: relative;
      }
    }
  }
  .table {
    div:first-child {
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${Color.white};
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: ${Color.blueLight};
        }
        &::-webkit-scrollbar-thumb:hover {
          background: ${Color.blueText};
        }
      }
    }
  }
  @media (max-width: 500px) {
    .content {
      padding: 20px;
      .form-innerwrap {
        .buttons-wrap {
          flex-direction: column;
          button:first-child {
            margin: 0 0 20px 0;
          }
        }
      }
    }
    .paginate-prepage {
      flex-direction: column;
      align-items: start;

      .paginate {
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 720px) {
    .using-filters-wrap {
      .curent-filters-wrap {
        .curent-filter-titles {
          justify-content: space-between;

          .col-filter,
          .col-days {
            width: fit-content;
            margin: 0;
          }
          .col-delete {
            display: none;
          }
        }
        .curent-filter {
          flex-direction: column;
          align-items: start;
          .form-group-days {
            margin: 10px 0;
          }
          .form-group-filter {
            width: 100%;
          }
          &:after {
            content: "";
            width: 100%;
            border: 1px solid #f7f8fc;
            margin-top: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    .content {
      .loading-innerwrap {
        .full-width-form {
          .form-innerwrap {
            .using-filters-wrap {
              .curent-filters-wrap {
                .curent-filter {
                  .form-group-filter {
                    input {
                      text-overflow: ellipsis;
                      padding-right: 40px;
                    }
                    .bottom {
                      button {
                        text-align: inherit;
                      }
                    }
                  }
                }
                .butns-wrap {
                  flex-direction: column;

                  button {
                    width: 100%;
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export { Wrapper };
