import styled from "styled-components";
import { Color } from "../../types/color";

const Wrapper = styled.div`
    .delete-modal {
        text-align: center;
        padding: 60px 50px;
        .title {
            color: ${Color.blueDarkText};
            font-size: 21px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
        }

        .btn_wrap{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            button {
                margin-top: 20px;
                width: auto;
                padding: 10px 20px;
            }
        }
    }
`;

export { Wrapper };
