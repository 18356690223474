import axios from "./api";

export const getSpendCardsAxios = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/customer/spend_card/get", requestParameters)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getSpendUsersSelectListAxios = (): Promise<any> =>
  axios
    .get("/api/spend_card/cusomers/get")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const addSpendCardsAxios = (requestParamtrs: any): Promise<any> =>
  axios
    .post("/api/customer/spend_card/add", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const removeSpendCardsAxios = (requestParamtrs: any): Promise<any> =>
  axios
    .post("/api/customer/spend_card/remove", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
