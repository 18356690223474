import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  box-sizing: border-box;
  min-height: 100vh;
  background-color: ${Color.blueDark};
  .inner-wrap {
    max-width: 100%;
  }
  .logo {
    text-align: center;
    margin: 0 auto 10vh;
    max-width: 250px;

    svg {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
      font-family: "object-fit: cover";
    }
  }
  form {
    border-radius: 8px;
    background-color: ${Color.white};
    padding: 60px 55px;
    width: 500px;
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    @media (max-width: 550px) {
      padding: 30px 15px;
    }
  }
  .title {
    color: ${Color.blueDarkText};
    font-size: 40px;
    font-weight: 500;
    line-height: 51px;
    margin-bottom: 5px;
  }
  .sub-title {
    opacity: 0.6;
    color: ${Color.blueDarkText};
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 30px;
  }
  .form-group {
    margin-bottom: 30px;
  }
  .form-error-message {
    color: red;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 15px;
  }
  button {
    margin-bottom: 20px;
  }
`;

export { Wrapper };
