export const pointObjValuesToString = (
  obj: { [key: string]: number | string } | null
): string | typeof NaN => {
  if (!obj) return NaN;
  const resultStr = Object.entries(obj).reduce((str, [key, value]) => {
    if (key !== "id") {
      return str + value;
    }
    return str;
  }, "");
  return resultStr !== "00" ? resultStr : NaN;
};
