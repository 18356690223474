import styled from "styled-components";

export const WrapperTableList = styled.div`
  width: calc(100% - 40px);
  padding: 0 20px 10px;

  button {
    background: none;
  }
  .visibility-icon{
    width: 20px;
    height: 20px;
  }
  .show-order{
    cursor: pointer;
    padding: 10px 0 10px 10px;
  }

    .allow_functions {
    margin-bottom: 15px;
    }
    .allow_title {
        font-weight: 300;
        font-size: 25px;
    }
    .functionality_list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
    }
    .allow_item {
    display: flex;
        align-items: center;
        padding: 10px;
        font-size: 20px;
        font-weight: 400;
        gap: 5px;
    }
  
`;

export const PointWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PointInput = styled.input`
  width: 50px;
  background: none;
  color: #000D46;
  text-align: end;

  &[readonly] {
    cursor: default;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
