import axios from "./api";

export const getLightSpeed = (): Promise<any> =>
  axios
    .post("/api/lightspeed/get")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const createLightSpeed = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/lightspeed/create", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const deleteLightSpeed = (requestParameters: any): Promise<any> =>
  axios
    .post("/api/lightspeed/delete", requestParameters)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const checkIntegrationLightSpeed = (requestParameters: any): Promise<any> =>
  axios
    .get(`/api/lightspeed/integration${requestParameters}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const setDiscountProductLightSpeed = (): Promise<any> =>
  axios
    .get(`/api/lightspeed/create-discount-product`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const createRemouteRulsLightSpeedLocations = (): Promise<any> =>
  axios
    .get(`/api/lightspeed/create-remote-rules`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const setWebhookLightSpeedLocations = (): Promise<any> =>
  axios
    .get(`/api/lightspeed/set-webhook`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const setLoyaltyProductLightSpeedLocations = (): Promise<any> =>
  axios
    .get(`/api/lightspeed/set-loyalty-product`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const setCustomFieldsLightSpeedLocations = (): Promise<any> =>
  axios
    .get(`/api/lightspeed/set-custom-fields`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getLightSpeedLocations = (): Promise<any> =>
  axios
    .get(`/api/lightspeed/outlets`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
