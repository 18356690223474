import React from "react";
import {Wrapper} from "./styled";
import {ReactComponent as OnlineShop} from '../../assets/img/menu-item/online-cart.svg'

interface IPosTabWrapper {
  title: string,
  description: string,
  button: boolean,
  buttonText?: string,
  children: React.AllHTMLAttributes<any>
}

const PosTabWrapper = ({title, description, button, buttonText, children}: IPosTabWrapper) => {

  return (
    <Wrapper >
      <div className="heading">
        <div className="title-wrap">
          <h2 className="page-title">{title}</h2>
          <p className="description">{description}</p>
        </div>
        {button ? <button className="uploadBtn btn-white">
          <span className="icon">
            <OnlineShop/>
          </span>
          <span>{buttonText}</span>
        </button> : null}
      </div>
      {children}
    </Wrapper>
  );
};

export default PosTabWrapper;
