import { Dispatch } from "redux";
import { PromotionAction, PromotionActionTypes } from "../../types/promotion";
import { getPromotionsCategoriesAxios } from "../../api/promotion";

export const getPromotionsCategories = (): any => {
  return async (dispatch: Dispatch<PromotionAction>) => {
    getPromotionsCategoriesAxios().then((response) => {
      const promotionTypes = response.data.promotions.map(
        (item: { id: number; name: string }) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );
      const promotionCategories = response.data.categories.map(
        (item: { id: number; name: string }) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );
      dispatch({
        type: PromotionActionTypes.GET_PROMOTION,
        payload: {
          promotionTypes,
          promotionCategories,
        },
      });
    });
  };
};
