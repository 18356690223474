export const DEFAULT_WORK_TIME_OBJ = {
  monday: {
    allDay: true,
    isOpen: true,
    from: "",
    to: "",
    label: "Monday",
  },
  tuesday: {
    allDay: true,
    isOpen: true,
    from: "",
    to: "",
    label: "Tuesday",
  },
  wednesday: {
    allDay: true,
    isOpen: true,
    from: "",
    to: "",
    label: "Wednesday",
  },
  thursday: {
    allDay: true,
    isOpen: true,
    from: "",
    to: "",
    label: "Thursday",
  },
  friday: {
    allDay: true,
    isOpen: true,
    from: "",
    to: "",
    label: "Friday",
  },
  saturday: {
    allDay: true,
    isOpen: true,
    from: "",
    to: "",
    label: "Saturday",
  },
  sunday: {
    allDay: true,
    isOpen: true,
    from: "",
    to: "",
    label: "Sunday",
  },
};
