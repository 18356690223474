import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
    .filters {
      display: flex;
      align-items: center;
      .select {
        margin-left: 20px;
        input {
          padding: 10px 16px 10px 40px;
        }
        svg path:not([fill="none"]) {
          fill: #666e90;
        }
      }
    }
    .title-wrap{
      width: 70%;
      .description {
        opacity: 0.5;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
      .page-title {
        margin: 0;
        margin-bottom: 10px;
      }
    }
    .uploadBtn{
      margin-right: 10px;
      display: flex;
      align-items: center;
      
      .icon {
        margin-right: 8px;
        svg path:not([fill="none"]) {
          fill: ${Color.blue};
        }
      }

    }
    
  }

  .main-blocks-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 4px;
    .item {
      flex: 1;
      border-radius: 4px;
      background-color: ${Color.white};
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      padding: 15px 20px;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }

      .title {
        opacity: 0.6;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 15px;
      }
      .count {
        color: ${Color.blueDarkText};
        font-size: 44px;
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 10px;
      }
      .description {
        opacity: 0.2;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
  @media (max-width: 1300px) {
    .main-blocks-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
      margin-right: -30px;
      margin-bottom: -30px;

      .item.all-customers {
        margin: 0 30px 30px 0;
      }
    }
  }
  @media (max-width: 399px) {
    .main-blocks-row {
      margin-right: 0px;
      .item.all-customers {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1040px) {
    .filters {
      flex-direction: column;

      div {
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 760px) {
    .filters {
      flex-direction: column;

      div {
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 480px) {
    .heading {
      flex-direction: column;

      .page-title {
        text-align: center;
      }
    }
  }
`;

export { Wrapper };
