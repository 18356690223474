import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  .delete-modal {
    text-align: center;
    padding: 60px 50px;
    .title {
      color: ${Color.blueDarkText};
      font-size: 21px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }
    button {
      margin-top: 20px;
      width: auto;
      padding: 10px 20px;
    }
  }
  .table {
    div:first-child {
      &::-webkit-scrollbar {
        height: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: ${Color.white};
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background: ${Color.blueLight};
        }
        &::-webkit-scrollbar-thumb:hover {
          background: ${Color.blueText};
        }
      }
    }
      .rdt_Table{
          .rdt_TableBody{
              .rdt_TableCell {
                  color: ${Color.blueDarkBlour};
                  font-size: 16px;
                  font-weight: 300;
                  line-height: 20px;
                  .status {
                      color: #ffc107;
                      &_Sent {
                          color: #2ecc71;
                      }
                  }
              }
          }
      }
      
  }
  
  @media (max-width: 600px) {
    .top-bar {
      flex-direction: column;
      align-items: start;

      .search-wrap {
        margin: 20px 0 0;
      }
    }
    .table-wrap {
      .paginate-prepage {
        flex-direction: column;
        align-items: start;

        .paginate {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

export { Wrapper };
