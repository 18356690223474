import styled from "styled-components";
import {Color} from "../../types/color";
import arrowDropDownImgBlack from "src/assets/img/arrowDropDownBlack.svg";

const Wrapper = styled.div`
    .title {
        display: flex;
        align-items: center;
        background-color: ${Color.white};
        font-size: 18px;
        margin-bottom: 6px;
        
    }
    .reward_block {
        margin: 30px 0 0;
    }

    .reward_label_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        @media (max-width: 450px) {
            flex-wrap: wrap;
        }
    }

    .reward_label {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        margin-right: 10px;
    }
    .filters {
        display: flex;
        align-items: flex-end;
        padding-right: 5px;
        gap: 20px;
        &>div{
            margin-bottom: 24px;
        }
        .select{
            display: flex;
            flex-direction: column;
            position: relative;
            margin-bottom: 34px;

            & > .label:not(:empty) {
                color: ${Color.blueDarkText};
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 10px;
            }

            & > svg {
                position: absolute;
                left: 8px;
                top: 8px;
            }
            svg path:not([fill="none"]) {
                fill: #666e90;
            }

            & > input {
                border-radius: 2px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
                padding: 13px 16px;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;
                background: url(${arrowDropDownImgBlack}) #ffffff no-repeat calc(100% - 12px) center;


                &::placeholder {
                    color: #95989d;
                }

                &:disabled:not(".lock") {
                    background: rgba(0, 0, 0, 0.07);
                    box-shadow: none;
                }
            }
            &.lock {
                input {
                    background: none;
                    cursor: initial;
                }
            }

            & > ul {
                position: absolute;
                width: 100%;
                background: #fff;
                z-index: 5;
                box-shadow: rgb(0 0 0 / 20%) 0px 2px 7px 0px;
                border-radius: 2px;
                padding: 5px 0;
                max-height: 230px;
                overflow: auto;
                &.top {
                    bottom: calc(100% + 7px);
                }
                &.bottom {
                    top: calc(100% + 7px);
                }

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 144, 202, 0.4);
                }

                & > input {
                    width: 90%;
                    margin: 10px auto;
                    display: block;
                    padding: 7px 10px;
                    background: unset;
                    height: auto;
                    border: 2px solid #ebebeb;
                    border-radius: 6px;
                    box-sizing: border-box;

                    &::placeholder {
                        color: #ced2d4;
                    }
                }

                & > button {
                    font-size: 14px;
                    padding: 10px 16px;
                    cursor: pointer;
                    background: #fff;
                    transition: background-color 0.225s;
                    display: flex;
                    width: 100%;

                    &:hover {
                        background-color: rgba(0, 0, 0, 0.04);
                    }
                }

                & > li {
                    padding: 10px 16px;
                }
            }
            &:hover {
                .clearable-btn {
                    opacity: 0.4;
                    &:hover {
                        opacity: 1;
                    }
                }
            }

            .lock-icon-wrap {
                position: absolute;
                bottom: 10px;
                right: 10px;
            }
        }
        .calendarSelect {
            &>input {
                padding: 10px 16px 10px 40px;
            }
        }
    }

    .reward_inputs, .bonus_reward_inputs {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .isSelect {
            //padding-bottom: 12px;

            input {
                margin: 0 2px 2px;
            }

            ul.bottom, ul.top {
                margin: 0 2px 2px;
                width: calc(100% - 4px);
            }
            ul.bottom {
                overflow-y: visible;
            }


            @media (max-width: 1300px) {
                ul.top {
                    flex-direction: column;
                }
            }
        }

        & > * {
            margin: 15px 0px;
            width: 47%;
        }
        & > *:last-child {
            flex-basis: 47%;
            max-width: 47%;
        }
        @media (max-width: 550px) {
            & > * {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
    }
    .reward_expired {
        padding: 0px 5px;
        label {
            margin-bottom: 15px;
        }
    }
    
}
`;

export { Wrapper };
