import React from "react";

import Input from "src/components/Input";
import { registerCreatePassword } from "./api";

import { Wrapper } from "./styled";
import * as Const from "../../consts";
import { useLocation } from "react-router-dom";

const RegistrationActivateAccount: React.FC<{
  passwordVerificationToken: string | null;
}> = ({ passwordVerificationToken }) => {
  const location: any = useLocation();
  const [passwordFormLoading, setPasswordFormLoading] =
    React.useState<boolean>(false);
  const [passwordFormErrorMessage, setPasswordFormErrorMessage] =
    React.useState<any>({});
  const [password, setPassword] = React.useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState<string>("");

  const handleCreatePassword = (event: React.FormEvent) => {
    event.preventDefault();
    if (password === passwordConfirmation) {
      setPasswordFormLoading(true);
      registerCreatePassword({
        password,
        confirm_password: passwordConfirmation,
        verification_token: passwordVerificationToken,
      })
        .then((response) => {
          setPasswordFormLoading(false);
          if (response.data.status === true && response.data.sessionId) {
            const serchParams = new URLSearchParams(location.search)
            const token = serchParams.get(
              Const.verificationToken
            );
            serchParams.delete('verification_token')
            serchParams.set('account_information_token', `${token}`)
            window.location.replace(`${location.pathname}?account_information_token=${serchParams.get(Const.accountInformationToken)}`)

          } else {
            setPasswordFormErrorMessage(response.data.errors);
          }
        })
        .catch((error) => {
          setPasswordFormErrorMessage(error.data.errors);
          setPasswordFormLoading(false);
        });
    } else {
      setPasswordFormErrorMessage({
        confirm_password: "The password confirmation does not match",
      });
    }
  };

  return (
    <Wrapper>
      <div className="create-password-content">
        <form
          onSubmit={handleCreatePassword}
          className={`${passwordFormLoading ? "loading-blue" : ""} lynked-form`}
        >
          <div className="form-innerwrap">
            <div className="title">Create Password</div>
            <div className="description">
              This will be your password to log in to the Lynked business
              portal. Here you can manage your loyalty program and monitor store
              performance.
            </div>
            <div className="form-group">
              <Input
                label="Password"
                type="password"
                required
                getValue={(value) => {
                  if (passwordFormErrorMessage) setPasswordFormErrorMessage("");
                  setPassword(value);
                }}
                error={passwordFormErrorMessage.password}
              />
            </div>
            <div className="form-group">
              <Input
                label="Repeat Password"
                type="password"
                required
                getValue={(value) => {
                  if (passwordFormErrorMessage) setPasswordFormErrorMessage("");
                  setPasswordConfirmation(value);
                }}
                error={
                  passwordFormErrorMessage?.confirm_password
                    ? passwordFormErrorMessage.confirm_password
                    : passwordFormErrorMessage?.verification_token
                    ? passwordFormErrorMessage.verification_token
                    : passwordFormErrorMessage?.stripe
                    ? passwordFormErrorMessage.stripe
                    : ""
                }
              />
            </div>
            <button type="submit" className="btn-blue">
              Create Password
            </button>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default RegistrationActivateAccount;
