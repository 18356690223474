import React from "react";

import { ReactComponent as LogoFullDark } from "src/assets/img/logo_full_dark.svg";
import { NAV_STEPS } from "./conts";

import { Wrapper } from "./styled";

const NavProgress: React.FC<{
  children: React.ReactNode;
  step: number;
}> = ({ children, step }) => {
  return (
    <Wrapper>
      <div className="nav_progress">
        <div className="nav_logo_wrapper">
          <LogoFullDark />
        </div>
        <p className="nav_text">
          Grow your customer base, increase your revenue & successfully engage
          your customers with Lynked.
        </p>
        <ul className="nav_items">
          {NAV_STEPS.map(({ nav_step, label }) => (
            <li
              key={nav_step}
              className={`nav_item ${
                nav_step === step ? "nav_item_active" : ""
              } 
              ${step > nav_step ? "nav_item_passed" : ""}
              `}
            >
              {label}
            </li>
          ))}
        </ul>
      </div>
      {children}
    </Wrapper>
  );
};

export default NavProgress;
