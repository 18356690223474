import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  display: flex;
  max-height: 100vh;
  overflow: hidden;
  .main-panel {
    width: calc(100% - 280px);
    background-color: ${Color.blueLightBackground};
  }

  .page-content {
    padding: 35px 30px;
    box-sizing: border-box;
    overflow: auto;
    height: calc(100vh - 80px);

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${Color.white};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${Color.blueDarkText};
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: ${Color.blue};
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueDarkText};
      }
    }
  }
  @media (max-width: 991px) {
    &.main-wrapper {
      flex-direction: column;

      .main-panel {
        width: 100%;
      }
    }
  }
  @media (max-width: 640px) {
    .page-content {
      padding: 20px;
    }
  }
`;

export { Wrapper };
