import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  width: 280px;
  z-index: 1;
  background-color: ${Color.white};
  .logo-wrap {
    background-color: ${Color.blueDarkText};
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding: 25px;
    box-sizing: border-box;
    svg {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
    .logo-container {
      position: relative;
      width: 100%;
      max-width: 195px;
      img {
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%;
        font-family: "object-fit: cover";
      }
    }
    #burger-icon {
      width: 26px;
      height: 23px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      background: transparent;

      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }
        &:nth-child(2) {
          top: 10px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }
        &:nth-child(3) {
          top: 20px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }
      }
      &.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 1px;
        left: 1px;
        width: 30px;
      }
      &.open span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 22px;
        left: 1px;
        width: 30px;
      }
    }
  }
  .content {
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 30px 10px 10px;
    overflow: auto;
    height: calc(100vh - 80px);

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${Color.white};
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${Color.blueText};
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: ${Color.blueLight};
      }
      &::-webkit-scrollbar-thumb:hover {
        background: ${Color.blueText};
      }
    }
  }
  .main-btn {
    a {
      width: auto;
      margin: 0 30px 30px 30px;
      border-radius: 4px;
      background-color: ${Color.blue};
      color: ${Color.white};
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      text-align: center;
      display: block;
      padding: 13px;
      &:hover {
        background-color: ${Color.blueHover};
      }
    }
  }

  .nav-items {
    &.nav-bottom-items {
      margin-top: 60px;
    }
  }

  @media (max-width: 991px) {
    width: unset;
    .logo-wrap {
      width: 100%;
      justify-content: space-between;
      padding: 17px;
      height: 57px;
      .logo-container {
        max-width: 160px;
      }
    }
    .content {
      transition: left 1s;
      width: 280px;
      position: absolute;
      left: -280px;
      background: ${Color.white};
      &.show-mobile-menu {
        transition: all 1s;
        left: 0;
        z-index: 5;
      }
    }
    .disable-blur-content {
      display: none;
    }
    .enable-blur-content {
      position: absolute;
      left: 0;
      top: 57px;
      bottom: 0;
      width: 100%;
      z-index: 1;
      background-color: transparent;
      backdrop-filter: blur(3px);
    }
  }
  @media (min-width: 992px) {
    #burger-icon {
      display: none;
    }
  }
`;

export { Wrapper };
