import styled from "styled-components";
import {Color} from "../../../../types/color";

export const WrapperTableList = styled.div`
  width: calc(100% - 40px);
  padding: 0 20px 10px;

  button {
    background: none;
  }
  .visibility-icon{
    width: 20px;
    height: 20px;
  }
  .show-order{
    cursor: pointer;
    padding: 10px 0 10px 10px;
  }
    .chart {
        background: ${Color.white};
        padding: 0 30px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0px 8px 8px 8px;

    }
  
`;

export const PointWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PointInput = styled.input`
  width: 50px;
  background: none;
  color: #000D46;
  text-align: end;

  &[readonly] {
    cursor: default;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
