import React, {useEffect, useState} from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { useActions } from "src/hooks/useActions";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import { PersonalDetailsInterface } from "src/types/auth";

import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import InfoTooltip from "src/components/infoTooltip";

// import { ReactComponent as LockIcon } from "src/assets/img/lock.svg";

import { Wrapper } from "./styled";
import Select from "../../../components/Select";
import * as Const from "../../Registration/Step/RegistrationPersonalInformation/consts";
import { SelectorListObj } from "../../../components/Select/types";
import {getCurrencyAxios} from "../../../api/auth";

const EditPersonalDetails: React.FC = () => {
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const { updateProfilePersonalDetails } = useActions();

  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = React.useState<string>("");
  const [currencyList, setCurrencyList] = useState<SelectorListObj[]>([])
  const [personalDetails, setPersonalDetails] =
    React.useState<PersonalDetailsInterface>({
      email: "",
      first_name: "",
      last_name: "",
      phone: "",
      address: "",
      city: "",
      country: "",
      code: "",
      about_me: "",
      vat_number: "",
      business_name: "",
      business_size: "",
      currency: 2,
    });

  const [selectedBusinessSize, setSelectedBusinessSize] =
    React.useState<SelectorListObj>();

  useEffect(() => {
    getCurrencyAxios().then(res=>{
      res.data.currencies.length > 0 ? setCurrencyList(res.data.currencies) :
      setCurrencyList([{value: 2, label: '€'}])
    })
    if (!personalDetails.email && loggedUser.user) {
      setPersonalDetails({
        email: loggedUser.user.email,
        first_name: loggedUser.user.first_name,
        last_name: loggedUser.user.last_name,
        phone: loggedUser.user.phone,
        address: loggedUser.user.user_profile?.address,
        city: loggedUser.user.user_profile?.city,
        country: loggedUser.user.user_profile?.country,
        code: loggedUser.user.user_profile?.postalCode,
        about_me: loggedUser.user.user_profile?.about,
        vat_number: loggedUser.user.vat_number,
        business_size: loggedUser.user.business_size,
        business_name: loggedUser.user.business_name,
        currency: loggedUser.user.currency,
      });

      setSelectedBusinessSize(() =>
        Const.businessSizeArray.find(
          (element) => loggedUser.user.business_size === element.value
        )
      );
    }
  }, []); //eslint-disable-line

  const getValue = (type: string, value: number | string) => {
    if (formErrorMessage) {
      setFormErrorMessage("");
    }
    setPersonalDetails({ ...personalDetails, [type]: value });
  };

  const handleUpdateProfile = (event: React.FormEvent) => {
    event.preventDefault();
    setFormLoading(true);

    updateProfilePersonalDetails(personalDetails)
      .then(() => {
        setFormLoading(false);
      })
      .catch((error: any) => {
        setFormErrorMessage(
          error?.data?.message
            ? error?.data?.message
            : error?.data?.detail
            ? error?.data?.detail
            : "Internal Server Error"
        );
        setFormLoading(false);
      });

    // registerPersonalInfo({
    //   email: personalInformation.email,
    //   first_name: personalInformation.firstName,
    //   last_name: personalInformation.lastName,
    //   phone: personalInformation.phoneNumber,
    //   business_name: personalInformation.businessName,
    //   business_size: personalInformation.businessSize,
    //   subscription: personalInformation.businessPlan,
    // })
    //   .then(() => {
    //     setOpenModalCheckEmail(true);
    //     setFormLoading(false);
    //   })
    //   .catch((error) => {
    //     setFormErrorMessage(
    //       error?.data?.message
    //         ? error?.data?.message
    //         : error?.data?.detail
    //         ? error?.data?.detail
    //         : "Internal Server Error"
    //     );
    //     setFormLoading(false);
    //   });
  };

  return (
    <Wrapper>
      <form
        onSubmit={handleUpdateProfile}
        className={`${formLoading ? "loading-blue" : ""}`}
      >
        <div className="form-innerwrap">
          <div className="form-group">
            <Input
              type="email"
              placeholder="Account Email"
              label="Account Email"
              required
              disabled
              defaultValue={personalDetails.email}
              getValue={(value) => getValue("email", value)}
            />
          </div>
          <div className="first-last-name">
            <div className="form-group first-name">
              <Input
                placeholder="First Name"
                label="First Name"
                required
                defaultValue={personalDetails.first_name}
                getValue={(value) => getValue("first_name", value)}
              />
            </div>
            <div className="form-group last-name">
              <Input
                placeholder="Last Name"
                label="Last Name"
                required
                defaultValue={personalDetails.last_name}
                getValue={(value) => getValue("last_name", value)}
              />
            </div>
          </div>
          <div style={{display: 'flex'}}>
            <div className="form-group phone-wrap phone-wrap-white" style={{flex: 1, marginRight: '15px'}}>
              <span className="label">Phone Number</span>
              <PhoneInput
                value={personalDetails.phone}
                containerClass="input-phone"
                country="us"
                inputProps={{
                  name: "phoneNumber",
                  // required: true,
                  minLength: 6,
                }}
                onChange={(value, data, event) => {
                  // if (event.target.classList.contains("form-control")) {
                  //     if (event.target.value.length > 6) {
                  //       event.target.setCustomValidity("");
                  //     } else {
                  //       event.target.setCustomValidity(
                  //         "Min length is 6 characters"
                  //       );
                  //     }
                  //   }
                  getValue("phone", value);
                }}
              />
              {/* <span className="lock-icon-wrap">
              <LockIcon />
            </span> */}
            </div>
            <div className="form-group" style={{flex: 1}}>
              <Select
                label="Currency"
                selectorList={currencyList}
                positionForShow="bottom"
                required
                getValue={(value) => getValue("currency", value)}
                defaultValue={currencyList.find(currency=> currency.value === personalDetails.currency)}
                hideSelectorFilter
              />
            </div>
          </div>
          <div className="form-group">
            <Input
              placeholder="Your Business Billing Address"
              label="Business Billing Address"
              required
              defaultValue={personalDetails.address}
              getValue={(value) => getValue("address", value)}
            />
          </div>
          <div className="сity-сountry-postal">
            <div className="form-group сity">
              <Input
                label="City"
                placeholder="Your city"
                required
                defaultValue={personalDetails.city}
                getValue={(value) => getValue("city", value)}
              />
            </div>
            <div className="form-group сountry">
              <Input
                label="Country"
                placeholder="Your country"
                required
                defaultValue={personalDetails.country}
                getValue={(value) => getValue("country", value)}
              />
            </div>
            <div className="form-group postal">
              <Input
                label="Postal Code"
                placeholder="Your postal code"
                required
                defaultValue={personalDetails.code}
                getValue={(value) => getValue("code", value)}
              />
            </div>
          </div>
          <div className="first-last-name">
            <div className="form-group form-input">
              <Input
                label="VAT Number"
                placeholder="VAT Number"
                required
                defaultValue={personalDetails.vat_number}
                getValue={(value) => getValue("vat_number", value)}
              />
            </div>
            <div className="form-group form-input">
              <Input
                label="Business Name"
                placeholder="Business Name"
                required
                defaultValue={personalDetails.business_name}
                getValue={(value) => getValue("business_name", value)}
              />
            </div>
          </div>
          <div className="form-group form-input">
            <Select
              label="Business Size"
              selectorList={Const.businessSizeArray}
              positionForShow="top"
              required
              getValue={(value) => getValue("business_size", value)}
              defaultValue={selectedBusinessSize}
              hideSelectorFilter
            />
          </div>
          <div className="form-group">
            <div className="label_block">
              About Your Company
              <InfoTooltip
                infoText="“In this section enter information about your business, it will be displayed in the mobile app to users who may want to know more about your business.e.g. “We are an artisan bakery that started in 2015. We use traditional methods to create delicious, handmade breads, pastries and cakes”"/>
            </div>
            <Textarea
              placeholder="Please include a short bio about your company here. Your customers may see this."
              required
              defaultValue={personalDetails.about_me}
              getValue={(value) => getValue("about_me", value)}
            />
          </div>
          {formErrorMessage && (
            <div className="form-error-message">{formErrorMessage}</div>
          )}
          <div className="button-wrap">
            <button type="submit" className="btn-blue">
              Update Profile
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default EditPersonalDetails;
