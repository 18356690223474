import React, {FC, useState, useEffect, useCallback} from "react";

import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
import Select from "src/components/Select";
import { ReactComponent as PlacePointIcon } from "src/assets/img/place-point.svg";
import { ReactComponent as CalendarIcon } from "src/assets/img/calendar.svg";
import {ReactComponent as ArrowDown} from "../../assets/img/arrowDown.svg";
import {ReactComponent as ArrowForward} from "../../assets/img/arrowForward.svg";

import {
  getStaffDashboardInfoAxios,
  getStaffDiscountSalesChannelsInfoAxios
} from "src/api/dashboard";

import { Wrapper } from "./styled";
import { filterPerDate } from "./consts";
import DashboardChart from "./DashboardChart";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const StaffDiscountDashboard: FC = () => {
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [locationIdFilter, setLocationIdFilter] = useState<number>(0);
  const [dateFilter, setDateFilter] = useState<{
    value: string;
    label: string;
  }>({
    value: filterPerDate[0].value,
    label: filterPerDate[0].label
  });
  const [selectedSortType, setSelectedSortType] = useState<string>('noScans:asc')
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const [openChannels, setOpenChannels] = useState<boolean>(false)
  const [sitesList, setSitesList] = useState<any[]>([])
  const [dashboardInfo, setDashboardInfo] = useState<any>({
    allStaffMembers: 0,
    countAllCheckIns: 0,
    countOfActivatedCards: 0,
    netSales: 0,
    countOfRedeemCampaign: 0,
    averagePriceSales: 0,
    avTimeBetweenRepeatVisits: 0,
    avTimeBetweenVoucherUsed: 0,
    vouchers_used_sum_reward: 0,
    vouchers_earned_sum_reward: 0,
    topProduct: [],
    loyalty_visits: [],
    total_loyalty_volume: [],
    av_loyalty_visits: [],
    av_voucher_used_value: [],
    grossSales: 0,
    customers: [0],
    discount: [0],
    locations: [],
    newStaffMembers: 0,
    promotionType: "Stamp",
    salesStatistic: [0]
  });
  
  const { locations } = useTypedSelector((state) => state.groupsLocations);
  const { getGroupsLocations } = useActions();
  const [dates, setDatesState] = useState({
    startDate:moment(new Date()).subtract(7, "days").format("MMM D, YYYY"),
    endDate: moment(new Date()).format("MMM D, YYYY")
  });
  
  useEffect(() => {
    if (locations.length === 0) getGroupsLocations();
  }, []); //eslint-disable-line
  
  const getStaffDashboardInform = useCallback(()=>{
    getStaffDashboardInfoAxios({
      date: +dateFilter.value,
      startDate: moment(dates.startDate).add(1, 'hours'),
      endDate: moment(dates.endDate).endOf('day'),
      locationId: locationIdFilter
    }).then((res) => {
      setDashboardInfo(res.data.data);
      setPageLoading(false);
      setDateFilter({value: res.data.daysDiff, label: res.data.daysDiff})
    });
  }, [dateFilter.value, dates.endDate, dates.startDate, locationIdFilter])
  
  useEffect(() => {
    setPageLoading(true);
    getStaffDashboardInform()
  }, [getStaffDashboardInform]);
  
  useEffect(()=>{
    const result: any = []
    getStaffDiscountSalesChannelsInfoAxios({
      sortType: selectedSortType,
      startDate: moment(dates.startDate).add(1, 'hours'),
      endDate: moment(dates.endDate).endOf('day')
    }).then(res=>{
      for (const dataKey in res.data.data) {
        result.push(res.data.data[dataKey][Object.keys(res.data.data[dataKey])[0]])
      }
      
      setSitesList([...result])
    })
  }, [selectedSortType, dates.startDate, dates.endDate])
  
  const selectSortMethod = (type:any) =>{
    if(selectedSortType.includes(type)) {
      if(selectedSortType.includes('asc')){
        setSelectedSortType(`${type}:desc`)
      } else {
        setSelectedSortType(`${type}:asc`)
      }
    } else {
      setSelectedSortType(`${type}:asc`)
    }
  }
  
  const setDates = (e: any, { startDate, endDate }: any) => {
    endDate >= moment() && startDate >= moment() ? setDatesState({
      startDate: moment().format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : endDate >= moment() &&  startDate <= moment() ? setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY")
    });
  };
  
  const ranges = {
    "Today": [moment(), moment()],
    "Last 7 Days": [moment().subtract(1, "weeks"), moment()],
    "Last 30 Days": [moment().subtract(1, "months"), moment()],
    "Last Year": [moment().subtract(1, "years"), moment()],
  };
  return (
    <Wrapper className={`${pageLoading ? "loading-blue" : ""}`}>
      <div className="heading">
        <div className="page-title">Staff Discount Dashboard</div>
        <div className="filters">
          <Select
            placeholder="All locations"
            selectorList={locations}
            disabled={!(locations.length > 0)}
            positionForShow="bottom"
            isClearable
            icon={<PlacePointIcon/>}
            required
            hideSelectorFilter
            getValue={(value) => {
              setLocationIdFilter(+value);
            }}
          />
          <DateRangePicker
            onApply={setDates}
            initialSettings={{ startDate: moment(new Date()).subtract(7, "days"), endDate: new Date(), ranges: ranges }}
          
          >
            <div className={'isSelect select'}>
              <CalendarIcon/>
              <input
                type="text"
                value={dates.startDate + "-" + dates.endDate}
                onChange={(e) => {
                  // @ts-ignore
                  setDates(e, dates)
                }}
                className="form-control"
              />
            </div>
          
          </DateRangePicker>
        </div>
      </div>
      <div className="main-blocks-row">
        <div className="item all-customers">
          <div className="title">All Members</div>
          <div className="count">{dashboardInfo.allStaffMembers ?? 0}</div>
          <div className="description">overall</div>
        </div>
        <div className="item all-customers">
          <div className="title">New Members</div>
          <div className="count">{dashboardInfo.newStaffMembers ?? 0}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div className="title">Member Scans</div>
          <div className="count">{dashboardInfo.countAllCheckIns ?? 0}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div className="title">Gross Sales</div>
          <div className="count">
            {loggedUser.user.currency_symbol || '€'}{dashboardInfo.grossSales ?? 0}
          </div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
        <div className="item all-customers">
          <div
            className="title">Total Discount Issued</div>
          <div className="title"></div>
          <div
            className="count">{loggedUser.user.currency_symbol || '€'}{(dashboardInfo.grossSales - dashboardInfo.netSales).toFixed(2) ?? 0}</div>
          <div className="description">{dates.startDate + "-" + dates.endDate}</div>
        </div>
      
      </div>
          <div className="main-blocks-row" style={{marginTop: '30px'}}>
            <div className="item all-customers">
              <div
                className="title">Net Sales After Discount
              </div>
              <div className="title"></div>
              <div
                className="count">{loggedUser.user.currency_symbol || '€'}{dashboardInfo.netSales ?? 0}</div>
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Average Order Value</div>
              <div className="count">{loggedUser.user.currency_symbol || '€'}{dashboardInfo.averagePriceSales}</div>
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
            <div className="item all-customers">
              <div className="title">Top Selling Products</div>
              {dashboardInfo.topProduct.map((product: any, index: number) => (
                index <= 2 && <div key={`${product.product_id}_${product.name}`} className={'sales_item products'}><p
                      className={'sale_name'}>{product.name}</p> <p>{product.quantity}</p></div>))}
              <div className="description">{dates.startDate + "-" + dates.endDate}</div>
            </div>
          </div>
          <button className="btn-white sales_channels" onClick={() => {
            setOpenChannels(!openChannels)
          }}>
            <span>Sales Channels: </span>
            <span className={openChannels ? "icon icon_rotate" : "icon"}>
              <ArrowDown/>
            </span>
          </button>
          {openChannels ? (<div className="sales-blocks-row" style={{marginTop: '30px'}}>
            <div className="item">
              <div className="sites-list">
                <div className="list-header" style={{borderBottom: '1px solid #000', paddingBottom: '10px'}}>
                  <div className="column">
                    <p className={'column-label'}>Location</p>
                  </div>
                  <div className="column">
                    <p className={'column-label'}>No. Scans</p>
                    <button className={'sort-btn'} onClick={()=>selectSortMethod('noScans')}><ArrowForward/><ArrowForward/></button>
                  </div><div className="column">
                    <p className={'column-label'} >Gross Sales</p>
                    <button className={'sort-btn'} onClick={()=>selectSortMethod('grossSales')}><ArrowForward/><ArrowForward/></button>
                  </div><div className="column">
                    <p className={'column-label'}>Total Discount Issued</p>
                    <button className={'sort-btn'} onClick={()=>selectSortMethod('totalDiscount')}><ArrowForward/><ArrowForward/></button>
                  </div><div className="column">
                    <p className={'column-label'}>Net Sales</p>
                    <button className={'sort-btn'} onClick={()=>selectSortMethod('netSales')}><ArrowForward/><ArrowForward/></button>
                  </div>
                </div>
                <div className="list-body">
                  {sitesList.length > 0 ? sitesList.map((site:any, index:number)=>(<div key={index} style={{padding: '10px 0'}} className={'row'}>
                    <div className="column">
                      <div className="column-label">{site.location_name}</div>
                    </div><div className="column">
                      <div className="column-label">{site.ScanCount}</div>
                    </div><div className="column">
                      <div className="column-label">{site.TotalDiscountDiscount}</div>
                    </div><div className="column">
                      <div className="column-label">{(site.Discount).toFixed(2)}</div>
                    </div><div className="column">
                      <div className="column-label">{(+site.TotalDiscount).toFixed(2)}</div>
                    </div>
                  </div>)) : <div className="nothing-found"><p>Sorry nothing found</p></div>}
                </div>
                
              </div>
            </div>
          </div>) : null}
      <DashboardChart
        promotionType={dashboardInfo.promotionType}
        customers={dashboardInfo.customers}
        discount={dashboardInfo.discount}
        salesStatistic={dashboardInfo.salesStatistic}
        dateFilter={+dateFilter.value}
        statisticByLocation={dashboardInfo.locations}
        endDate={dates.endDate}
      />
    </Wrapper>
  );
};

export default StaffDiscountDashboard;
