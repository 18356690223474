import axios from "axios";
import { REACT_APP_API_URL } from "src/types/env";
import Bugsnag from "@bugsnag/js";

const defaultOptions = {
  baseURL: REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
};

const axiosInstance = axios.create(defaultOptions);
axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  "bearer_token"
)}`;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      axios.defaults.headers.common.Authorization = `Bearer null`;
      window.location.replace("/login");
    }
    Bugsnag.notify(new Error(JSON.stringify(error)))
    return Promise.reject(error);
  }
);

export default axiosInstance;
