import React, { FC } from "react";
import { Link } from "react-router-dom";

import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";
import { LoginUser } from "src/types/auth";

import Input from "src/components/Input";

import { ReactComponent as LogoFull } from "src/assets/img/new-logo-full.svg";

import * as content from "./strings";
import { Wrapper } from "./styled";

const SignIn: FC = () => {
  const { loading, error } = useTypedSelector((store) => store.auth);
  const { loginUser } = useActions();

  const [user, setUser] = React.useState<LoginUser>({
    email: "",
    password: "",
  });

  const setValue = (type: string, value: string) => {
    setUser({ ...user, [type]: value });
  };

  const handleSignIn = (event: React.FormEvent) => {
    event.preventDefault();
    loginUser(user);
  };

  return (
    <Wrapper>
      <div className="inner-wrap">
        <div className="logo">
          <LogoFull />
        </div>
        <form
          onSubmit={handleSignIn}
          className={`${loading ? "loading-blue" : ""}`}
        >
          <div className="form-innerwrap">
            <div className="title">{content.title}</div>
            <div className="sub-title">{content.subTitle}</div>
            <div className="form-group">
              <Input
                type="email"
                placeholder={content.emailPlaceholder}
                required
                getValue={(value) => setValue("email", value)}
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                placeholder={content.passwordPlaceholder}
                required
                getValue={(value) => setValue("password", value)}
              />
            </div>
            {error && <div className="form-error-message">{error}</div>}
            <button type="submit" className="btn-blue">
              {content.signInBtn}
            </button>
            <div className="forgot-signup">
              <Link to="/forgot-password" className="blue-link">
                {content.forgotPasswordBtn}
              </Link>
              <span> or </span>
              <Link to="/registration" className="blue-link">
                {content.signUpBtn}
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Wrapper>
  );
};

export default SignIn;
