export const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL ?? "https://api.dev.lynked.ie/";
  // process.env.REACT_APP_API_URL ?? "https://api.tomandjerry.pp.ua/";
  // process.env.REACT_APP_API_URL ?? "https://api.lynked.dotcode.pp.ua/";
// process.env.REACT_APP_API_URL ?? "https://lynked-api-dev.herokuapp.com/";
// process.env.REACT_APP_API_URL ?? "https://lynked-api-qa.herokuapp.com/";
// process.env.REACT_APP_API_URL ?? "https://lynked-api.herokuapp.com/";
// process.env.REACT_APP_API_URL ?? "https://api.lynked.ie/";
// process.env.REACT_APP_API_URL ?? "https://15d0-92-253-212-17.ngrok-free.app/"; // api Max
// gimel27478@sfpixel.com
// zudxuf-nopvar-2muBwu

export const REACT_APP_STRIPE_PKEY =
  process.env.REACT_APP_STRIPE_PKEY ??
  // "pk_test_51K6XmmK0QBqmkHwLqE4EJfaxfB31Pu2VyXDChGzLAzlP1ZOm6OMj1vgfX1wNc7MmO15whN8r54K4EcGN9fpJREY400WkIOnyUA";
  "pk_test_51K6XZrFNYafCv0OY0g1vawJBAwaa49iToMnlKpCpscqmIWPQvFGpnnwJmPaU4t6BehALlN0xg1ppExiOGZAufbMd00zQBLurCg";

export const REACT_APP_STRIPE_PRODUCT_ID =
  process.env.REACT_APP_STRIPE_PRODUCT_ID ??
  // "pk_test_51K6XmmK0QBqmkHwLqE4EJfaxfB31Pu2VyXDChGzLAzlP1ZOm6OMj1vgfX1wNc7MmO15whN8r54K4EcGN9fpJREY400WkIOnyUA";
  "price_1KinvGFNYafCv0OYoHtiqLQE";

export const GOOGLE_API_KEY =
  process.env.GOOGLE_API_KEY ?? "AIzaSyDgkBU1tstZff-kq0AE_MVJPJ0aLiVYhrQ";

export const REACT_APP_EPOS_NOW_API =
  process.env.REACT_APP_EPOS_NOW_API ?? "https://api.lynked.ie";

export const REACT_APP_EPOS_NOW_DASHBOARD =
  process.env.REACT_APP_EPOS_NOW_DASHBOARD ?? "https://app.lynked.ie";

export const REACT_APP_EPOS_NOW_STG =
  process.env.REACT_APP_EPOS_NOW_STG ?? "https://api.eposnowhq.com";
