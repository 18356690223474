import React, { FC, useEffect, useState } from "react";
import { WrapperTableList } from "src/views/StaffMembers/InnerTable/styled";
import DataTable from "react-data-table-component";
import Input from "../../Input";
import { ReactComponent as CheckCircle } from "../../../assets/img/checkCircle.svg";
import { ReactComponent as Edit } from "../../../assets/img/edit.svg";
import { ReactComponent as Delete } from "../../../assets/img/delete.svg";
import { ReactComponent as CirclePlus } from "../../../assets/img/circle-plus.svg";
import Select from "../../Select";
import {
  editCategoryAxios,
  removeCategoryAxios,
  setCategoryAxios,
} from "../../../api/posTerminals";
import { IPosCategoryGroup, posCategoryItem, posCategoryItemRequest } from "../../../types/posRyles";
import Modal from "../../Modal";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

const InnerPosCategoryGroup: FC<IPosCategoryGroup> = ({ data, setItems, edit, setEdit, getAllCategories, scaleParemeterSelectList }) => {
  const [innerGroup, setInnerGroup] = useState<posCategoryItem[]>([])
  const [allItems, setAllItems] = useState<any>(data)
  const [successResponse, setSuccessResponse] = useState<string>('')
  const { loggedUser } = useTypedSelector((state) => state.auth);

  const customerTableColumnsStamp = [
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.name}
            getValue={(value) => item.name = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_group}
            getValue={(value) => item.product_group = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.product_sku}
            getValue={(value) => item.product_sku = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Select
            placeholder="Select Scale Parameter"
            selectorList={scaleParemeterSelectList}
            positionForShow="bottom"
            disabled={!item.edit}
            required
            defaultValue={item.scale_parameter  === 'price' ? { value: "price", label: "Price" } : {value: "qty", label: "Quantity"}}
            getValue={(value:string|number) =>
              item.scale_parameter = value
            }
            hideSelectorFilter
          />
        );
      },
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={item.scale_factor}
            type="number"
            getValue={(value: string) => item.scale_factor = value}
            disabled={!item.edit}
          />
        );
      },
    },
    {
      name: "",
      minWidth: '8%',
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            type={item.edit ? "number" : undefined}
            defaultValue={item.edit ? `${parseFloat(item.transaction_value as string)}` : `${loggedUser.user.currency_symbol || '€'}${parseFloat(item.transaction_value as string)}`}
            getValue={(value: string) => item.transaction_value = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "8%",
      cell: (item: posCategoryItem): JSX.Element => {
        return (
          <Input
            defaultValue={`${+item.scale_factor * +item.transaction_value}`}
            disabled={true}
          />
        );
      }
    },
    {
      name: "",
      minWidth: "3.5%",
      cell: (item: posCategoryItem, index:number): JSX.Element => {

        return (
          <div className="buttons">
            {item.edit && item.id ?
              <button className="btn-white edit-category" onClick={() => saveEditItem(item.id)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category" onClick={() => saveItem(index)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button> :  <>
                <button disabled={edit} className="btn-white edit-category" onClick={() => editItem(index)}>
                      <span className="icon">
                        <Edit />
                      </span>
                </button>
                <button disabled={edit} className="btn-white remove-category" onClick={() => removeItem(item.id)}>
                      <span className="icon">
                      <Delete />
                      </span>
                </button>
              </>}

          </div>
        );
      }
    }
  ];

  useEffect(() => {
    setInnerGroup(allItems.children);
    setEdit(false)
  }, []);

  const saveItem = (id: number) => {
    const newItems = innerGroup;
    newItems[id].edit = false;
    newItems[id].allocated = `${+newItems[id].scale_factor * +newItems[id].transaction_value}`;

    const requestItem:posCategoryItemRequest  = {
      name: newItems[id].name,
      product_group: newItems[id].product_group,
      product_sku: newItems[id].product_sku,
      scale_parameter: `${newItems[id].scale_parameter}`,
      scale_factor: newItems[id].scale_factor,
      transaction_value: `${newItems[id].transaction_value}`,
      parent: `${allItems.id}`
    }
    setCategoryAxios(requestItem).then((res) => {
      getAllCategories();
      newItems[id] = { ...res.data, children: [] }
      setSuccessResponse('Group was created')
      setEdit(false);
    }).catch(e => {
      console.log(e);
    });

  };
  const editItem = (id: number) => {
    const newItems = innerGroup;
    newItems[id].edit = true;
    setEdit(true);
  };
  const saveEditItem = (id: number | undefined) => {
    const newItems = innerGroup.filter(item => item.id === id)[0];
    newItems.edit = false;

    const requestItem:posCategoryItemRequest  = {
      id: newItems.id,
      name: newItems.name,
      product_group: newItems.product_group,
      product_sku: newItems.product_sku,
      scale_parameter: `${newItems.scale_parameter}`,
      scale_factor: newItems.scale_factor,
      transaction_value: `${newItems.transaction_value}`,
      parent: `${allItems.id}`
    }
    editCategoryAxios(requestItem).then(()=>{
      getAllCategories();
      setEdit(false);
    })
  };
  const removeItem = (id: number | undefined) => {
    removeCategoryAxios(`${id}`)
      .then((res) => {
        getAllCategories();
        setInnerGroup((items: Array<any>) => items.filter((category, index) => category.id !== id));
        setSuccessResponse('Group was removed')
        setEdit(false);
      }).catch(e => {
      console.log(e);
    });

  };

  const addNewCategory = () => {
    const newItem:posCategoryItem = {
      edit: true,
      name: '',
      product_group: '',
      product_sku: '',
      scale_parameter: 'price',
      scale_factor: '',
      transaction_value: 0,
      currency: loggedUser.user.currency_symbol || '€',
      allocated: '',
      children: []
    }
    setEdit(true);

    setInnerGroup((group:posCategoryItem[]) => group = [...group, newItem])
  }


  return (
    <WrapperTableList>
      {innerGroup.length > 0 ?
        <>
          {edit ? <div className="table table-edit"><DataTable
            data={innerGroup}
            columns={customerTableColumnsStamp}
            noHeader
            noTableHead
            expandableRows
            expandableRowsComponent={
              <InnerPosCategoryGroup data={innerGroup} edit={edit} setEdit={setEdit} getAllCategories={getAllCategories} setItems={setInnerGroup} scaleParemeterSelectList={scaleParemeterSelectList}/>
            }
          /></div> : <div className="table"><DataTable
            data={innerGroup}
            columns={customerTableColumnsStamp}
            noHeader
            noTableHead
            expandableRows
            expandableRowsComponent={
              <InnerPosCategoryGroup data={innerGroup} edit={edit} setEdit={setEdit} getAllCategories={getAllCategories} setItems={setInnerGroup} scaleParemeterSelectList={scaleParemeterSelectList}/>
            }
          /></div>}
        </> : (
          <div className="nothing-found">Sorry, nothing found</div>
        )
      }
      <button disabled={edit} className="btn-white add-category" onClick={() => addNewCategory()}>
            <span className="icon">
              <CirclePlus/>
            </span>
        <span>Add Group</span>
      </button>

      {successResponse && (
        <Modal
          openModal={!!successResponse}
          setOpenModal={() => setSuccessResponse("")}
          closeOutsideClick={true}
        >
          <div className="modal-content">
            <div className="title">Success</div>
            <div className="sub-title">{successResponse}</div>
          </div>
        </Modal>
      )}
    </WrapperTableList>

  );
};

export default InnerPosCategoryGroup;
