import {
  LoginUser,
  ResetPasswordRequest,
  PersonalDetailsInterface,
} from "src/types/auth";
// import {
//   RegisterPersonalInfoInterface,
//   RegisterCreatePasswordInfoInterface,
//   AccountInformationInterface,
// } from "src/types/registration";

import axios from "./api";

export const signIn = (user: LoginUser): Promise<any> =>
  axios
    .post("/public/sign-in", user)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const checkSignIn = (): Promise<any> =>
  axios
    .post("/api/check-sign-in")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const logOut = (): Promise<any> =>
  axios
    .post("/api/logout")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const forgotPassword = (email: string): Promise<any> =>
  axios
    .post("public/forgot_password", { email })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const declineAccount = (verification_token: string): Promise<any> =>
  axios
    .post("public/decline-email-verification", { verification_token })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const resetPassword = (
  resetPasswordRequest: ResetPasswordRequest
): Promise<any> =>
  axios
    .post("public/reset_password", resetPasswordRequest)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const registerAccountAdditionalInfo = (
  requestFormData: any //eslint-disable-line
): Promise<any> =>
  axios
    .post("/public/create-new-place", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const checkRewardCode = (
  requestFormData: any //eslint-disable-line
): Promise<any> =>
  axios
    .post("/public/check-reward-code", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateProfilePhotoAxios = (
      requestFormData: any //eslint-disable-line
): Promise<any> =>
  axios
    .post("/api/user/updatePhoto", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateProfilePersonalDetailsAxios = (
  personalDetails: PersonalDetailsInterface
): Promise<any> =>
  axios
    .post("/api/update-user-profile", personalDetails)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getCurrencyAxios = (): Promise<any> =>
  axios
    .get("/public/currencies")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const saveWelcomePromotion = (
  accountInformationToken: string
): Promise<any> =>
  axios
    .post("/api/save-first-welcome-promotion", {
      token: accountInformationToken,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
