import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div<{
  required?: boolean;
  isLabel?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  & > .label:not(:empty) {
    color: ${Color.blueDarkText};
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
    /* &:after {
      content: ${(props) => (props.required ? `" *"` : "none")};
      color: #eb4d4b;
    } */
  }

  & > textarea {
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    padding: 13px 16px;
    font-family: "Circular Std";
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    background: #ffffff;
    min-width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    min-height: 100px;
    height: 120px;
    max-height: 200px;

    &::placeholder {
      color: #95989d;
    }

    &:disabled {
      padding: 13px 40px 13px 16px;
      /* background: rgba(0, 0, 0, 0.07);
      box-shadow: none; */
    }
    &.password {
      padding-right: 45px;
    }
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export { Wrapper };
