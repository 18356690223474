import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
    position: relative;
    overflow: hidden;
    width: 50%;

    .add-stampCategory {
        margin: 5px 2px;
        width: 30%;
        justify-self: start;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 23px;
                height: 23px;
            }
        }
    }

    .extras-based-wrapper {
        width: 100%;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        position: relative;

        .header {
            flex-direction: column;
            width: 70%;

            .header-tab {
                height: auto;
                display: flex;
                justify-content: space-between;
                gap: 2%;

                .column {
                    width: 40%;
                }

                .column_buttons {
                    min-width: 100px;
                }


            }

            .extras-based-form {
                display: flex;
                justify-content: space-between;

                .table {
                    width: 100%;

                    & > div {
                        overflow-y: visible;

                        & > div {
                            display: block;
                        }

                    }

                    .rdt_TableRow {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 2%;
                        padding: 12px 0 0;

                        .rdt_TableCell {
                            width: 30%;
                            padding-left: 0;

                            & > div {
                                width: 100%;

                                input {
                                    padding-right: 40px;
                                }

                            }

                            .select {
                                margin-bottom: 12px;
                            }

                            &:nth-last-child(1) {
                                width: 10%;
                                margin-bottom: 12px;

                                .buttons {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    button {
                                        width: 45%;
                                        padding: 7.5px 15px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }

                                    .save-btn {
                                        width: 100%;
                                        padding: 9.5px 10px;

                                        .icon {
                                            margin-right: 5px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            
                                        }
                                    }
                                }

                            }
                        }
                    }


                }

                .table-edit {
                    & > div {
                        padding-bottom: 140px;

                        .select {
                            .bottom {
                                //max-height: 130px;
                                width: 120%;
                            }
                        }
                    }
                }

                .modal-content {
                    text-align: center;
                    padding: 60px 50px;
                    @media (max-width: 600px) {
                        padding: 30px 15px;
                    }

                    .title {
                        color: ${Color.blueDarkText};
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 38px;
                        text-align: center;
                        @media (max-width: 600px) {
                            font-size: 23px;
                            line-height: 28px;
                        }
                    }

                    .sub-title {
                        color: ${Color.blueDarkText};
                        font-size: 25px;
                        font-weight: 500;
                        line-height: 38px;
                        text-align: center;
                        @media (max-width: 600px) {
                            font-size: 23px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }

    }
`;

export { Wrapper };
