import styled from "styled-components";
import { Color } from "src/types/color";
// import "bootstrap/dist/css/bootstrap.css";
// you will also need the css that comes with bootstrap-daterangepicker
import "bootstrap-daterangepicker/daterangepicker.css";

const Wrapper = styled.div`
  position: relative;
    overflow: hidden;
    height: 100%;
    .header{
        h2{
            color: ${Color.blueDarkText};
            font-size: 30px;
            font-weight: 300;
        }
    }
    .groups-wrap{
        .label {
            font-size: 20px;
            font-weight: 300;
            padding-bottom: 10px;
        }
        width: 70%;
    }
    .settings_content{
        .settings_name{
            font-size: 25px;
            font-weight: 300;
        }
        .settings_fields{
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            width: 100%;
            margin-bottom: 30px;
            .field{
                width: 48%;
                text-transform: capitalize;
                .label{
                    font-size: 20px;
                    
                }
            }
            
        }
        .button-row {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            .verification-status {
                margin-right: 20px;
                padding: 10px 20px;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                color: rgb(0, 13, 70);
                border: 1.5px solid rgb(0, 13, 70);
                border-radius: 4px;
                background-color: ${Color.white};

                &.approved {
                    border: 1.5px solid ${Color.blue};
                    color: ${Color.blue};
                }
            }

            .btn-blue {
                width: 20%;
                padding: 10px 30px;
            }
        }
    }
`;

export { Wrapper };
