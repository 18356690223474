import React, {useState} from 'react';
import DataTable from "react-data-table-component";
// import TransactionInformation from "../TransactionInformation/TransactionInformation";
import {WrapperTableList} from "../../../Customers/CustomerList/EditableTable/styled";
import userDefaultImg from "../../../../assets/img/default-user.jpg";
import UserTransactionList from "./UserTransactionList";
import {getAllUserTransactionForBusinessPlaceAxios} from "../../../../api/adminPanel";

interface IPlaceUsersList {
  users: any[],
  locationId: number,
  promotion: any,
  store: string
}

const PlaceUsersList = ({users, locationId, promotion, store}: IPlaceUsersList) => {
  
  const customerTableColumns = [
    {
      name: "Icon",
      width: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="photo">
            {row?.user?.photo ? (
              <img src={row.user.image} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      }
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.user.full_name}
          </div>
        );
      }
    },
    // {
    //   name: type,
    //   center: true,
    //   cell: ({ min_points, can_edit, points, activity_id, stamp_activity }: CustomerUserInterface): JSX.Element => {
    //     return <EditablePoint minValue={min_points} canEdit={can_edit} points={points}
    //                           pointId={activity_id} getCustomerList={getCustomerList} stamp_activity={stamp_activity}
    //                           getUserPointInfo={getUserPointInfo} userId={data.id} />;
    //   }
    // },
    // {
    //   name: "loyalty Program",
    //   cell: (row: any): JSX.Element => {
    //     return <div>{data.place.promotion}</div>;
    //   }
    // },
    {
      name: "Email",
      cell: (row: any): JSX.Element => {
        return <div>{row.user.email}</div>;
      }
    },
    {
      name: "Phone",
      cell: (row: any): JSX.Element => {
        return <div>{row.user.phone}</div>;
      }
    },
    // {
    //   name: "Address",
    //   cell: (row: any): JSX.Element => {
    //     return <div>{row.address || "-"}</div>;
    //   }
    // },
    // {
    //   name: "POS.I.D",
    //   cell: (row: CustomerUserInterface): JSX.Element => {
    //     return <div>{row.pos_id || "-"}</div>;
    //   }
    // },
    // {
    //   name: "POS User .I.D",
    //   cell: (row: CustomerUserInterface): JSX.Element => {
    //     return <div>{row.pos_user_id || "-"}</div>;
    //   }
    // },
    // {
    //   name: "Receipt No.",
    //   cell: (row: CustomerUserInterface): JSX.Element => {
    //     return <div>{row.receipt || "-"}</div>;
    //   }
    // },
    // {
    //   name: "Time",
    //   minWidth: "120px",
    //   cell: (row: CustomerUserInterface): JSX.Element => {
    //     return (
    //       <div>
    //         {row.date
    //           ? format(new Date(row.date), "dd/MM/yyyy, HH:mm:ss")
    //           : "-"}
    //       </div>
    //     );
    //   }
    // },
    // {
    //   name: "Basket",
    //   maxWidth: "75px",
    //   right: true,
    //   cell: (row: any, i: number): JSX.Element => {
    //     return (
    //       <>
    //         {row.basket ? (<div className={"show-order"} data-tag="allowRowEvents">
    //           <VisibilityIcon data-tag="allowRowEvents" className={"visibility-icon"} />
    //         </div>) : null}
    //
    //       </>
    //     );
    //   }
    // }
  ];
  
  const [transactions, setTransactions] = useState([]);
  const [customer, setCustomer] = useState<any>({});
  
  const getUserTransactions = (userId: any) => {
    getAllUserTransactionForBusinessPlaceAxios({location_id: locationId, user_id: userId}).then(res => {
      setTransactions(res.data.transactions)
    })
  }
  
  return (
    <WrapperTableList>
      {users &&
          <>
              <DataTable
                  data={users}
                  columns={ customerTableColumns}
                  noHeader
                  onRowClicked={(row: any) => {
                    getUserTransactions(row.user.id)
                  }}
                  expandableRows
                  expandableRowsHideExpander={true}
                  expandableRowExpanded={(row) => (row === customer)}
                  onRowExpandToggled={(bool, row) => setCustomer(row)}
                  expandOnRowClicked={true}
                  expandableRowsComponent={
                    <UserTransactionList transactions={transactions} promotion={promotion} store={store}/>
                  }
              />
      
          </>
      }
    </WrapperTableList>
  );
};

export default PlaceUsersList;
