export const businessSizeArray = [
  {
    value: "2-10",
    label: "2-10 employees",
  },
  {
    value: "11-50",
    label: "11-50 employees",
  },
  {
    value: "51-200",
    label: "51-200 employees",
  },
  {
    value: "201-500",
    label: "201-500 employees",
  },
  {
    value: "501-1000",
    label: "501-1000 employees",
  },
  {
    value: "1001+",
    label: "1001+ employees",
  },
];
