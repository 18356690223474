import React, {useEffect, useRef, useState} from "react";

import { useTypedSelector } from "src/hooks/useTypedSelector";
import { useActions } from "src/hooks/useActions";

import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import userDefaultImg from "src/assets/img/default-user.jpg";
import { ReactComponent as ArrowDownIcon } from "src/assets/img/arrowDown.svg";

import { Wrapper } from "./styled";

const Header = () => {
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const { logoutUser } = useActions();

  const [isOpenDropdownMenu, setIsOpenDropdownMenu] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: any) => {
    if (!ref?.current?.contains(event.target) && isOpenDropdownMenu) {
      setIsOpenDropdownMenu(false);
    }
  };
  useEffect(() => {
    if (isOpenDropdownMenu) {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
    return undefined;
  });

  return (
    <Wrapper>
      <div className="nav-left">
        {!loggedUser?.place_confirmed ? (
          <div className="account-under-review-wrap">
            <InfoIcon />
            <span className="blue-text">Account under review</span>
            <span className="main-text">
              Please wait while a member of the Lynked team is reviewing your
              account.
            </span>
          </div>
        ) : null}
      </div>
      <div className="nav-notification" />
      <div className={`nav-user ${isOpenDropdownMenu ? "open" : ""}`} ref={ref} onClick={() => setIsOpenDropdownMenu(!isOpenDropdownMenu)} > {/*eslint-disable-line*/}
        <div className="img">
          {loggedUser?.user?.photo ? (
            <img src={loggedUser.user.photo} alt="" />
          ) : (
            <img src={userDefaultImg} alt="" />
          )}
        </div>
        <div className="name-email">
          <div className="name">{loggedUser?.user?.full_name}</div>
          <div className="email">{loggedUser?.user?.email}</div>
        </div>
        <div className="more">
          <ArrowDownIcon />
        </div>
        <div className="dropdown-menu">
          <button className="logout" type="button" onClick={logoutUser}>
            Log Out
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Header;
