const businessPlanChanged = false;
const PeriodTypeMonthly = "monthly";
const PeriodTypeYearly = "yearly";
const selectedBusinessPlan = "price_1K6bv2K0QBqmkHwLlRVXLank";

const PLANT_LABELS = [
  { label: "Access Dedicated Portal" },
  { label: "Location on the Lynked Map" },
  { label: "Points or Stamp Based Loyalty" },
  { label: "Custom Digital Loyalty Card" },
  { label: "Customer Grouping" },
  { label: "Amount of Filters" },
  { label: "Number of Locations" },
  { label: "Staff Accounts" },
  { label: "Loyalty Performance Analytics" },
  { label: "Multi Location Monitoring" },
  { label: "Welcome Promotion" },
  { label: "Promo Campaign to reach new customers" },
  { label: "In App Notifications" },
  { label: "Email Campaigns" },
  { label: "EPOS Integration" },
];

export {
  businessPlanChanged,
  PeriodTypeMonthly,
  PeriodTypeYearly,
  selectedBusinessPlan,
  PLANT_LABELS,
};
