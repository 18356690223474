import { Dispatch } from "redux";

import {
  LoginUser,
  AuthUserAction,
  AuthUserActionTypes,
  PersonalDetailsInterface,
} from "src/types/auth";
import {
  signIn,
  logOut,
  updateProfilePhotoAxios,
  updateProfilePersonalDetailsAxios,
} from "src/api/auth";
import { updateBusinessPlanAxios } from "src/api/userProfile";

export const loginUser = (loginUserData: LoginUser): any => {
  return async (dispatch: Dispatch<AuthUserAction>) => {
    dispatch({ type: AuthUserActionTypes.LOGIN });
    signIn(loginUserData)
      .then((response) => {
        if (response.data.token) {
          window.location.replace(
            `/registration?account_information_token=${
              response.data.token ?? response.data.decline_token
            }`
          );
        } else {
          dispatch({
            type: AuthUserActionTypes.LOGIN_SUCCESS,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: AuthUserActionTypes.LOGIN_ERROR,
          payload: error.data.message,
        });
      });
  };
};

export const logoutUser = (): any => {
  return async (dispatch: Dispatch<AuthUserAction>) => {
    dispatch({ type: AuthUserActionTypes.LOGOUT });
    logOut()
      .then((response) => {
        dispatch({
          type: AuthUserActionTypes.LOGOUT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: AuthUserActionTypes.LOGOUT_ERROR,
          payload: error.data.message,
        });
      });
  };
};

export const updateProfilePhoto = (
  requestFormData: any  //eslint-disable-line
): any => {
  return async (dispatch: Dispatch<AuthUserAction>) =>
    new Promise((resolve, reject) => {
      updateProfilePhotoAxios(requestFormData)
        .then((response) => {
          dispatch({
            type: AuthUserActionTypes.UPDATE_PHOTO_SUCCESS,
            payload: response.data.photo,
          });
          return resolve(response);
        })
        .catch((error) => {
          return reject(error);
        });
    });
};

export const updateProfilePersonalDetails = (
  personalDetails: PersonalDetailsInterface
): any => {
  return async (dispatch: Dispatch<AuthUserAction>) =>
    new Promise((resolve, reject) => {
      updateProfilePersonalDetailsAxios(personalDetails)
        .then((response) => {
          dispatch({
            type: AuthUserActionTypes.UPDATE_PERSONAL_DETAILS_SUCCESS,
            payload: response.data.user,
          });
          return resolve(response);
        })
        .catch((error) => {
          return reject(error);
        });
    });
};

export const updateBusinessPlan = (price_id: string): any => {
  return async (dispatch: Dispatch<AuthUserAction>) =>
    new Promise((resolve, reject) => {
      updateBusinessPlanAxios(price_id)
        .then((response) => {
          dispatch({
            type: AuthUserActionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
            payload: price_id,
          });
          return resolve(response);
        })
        .catch((error) => {
          return reject(error);
        });
    });
};
