import React from "react";

import { ReactComponent as PlayCircleIcon } from "src/assets/img/play_circle.svg";
import { ReactComponent as IncrementIcon } from "src/assets/img/increment.svg";
import { ReactComponent as CheckBusinessIcon } from "src/assets/img/check_business.svg";
import { ReactComponent as CustomerConnectIcon } from "src/assets/img/customer_connect.svg";
import { ReactComponent as DataAnalyzeIcon } from "src/assets/img/data_analyze.svg";
import { ReactComponent as LoyaltyIcon } from "src/assets/img/loyalty.svg";

import { Wrapper } from "./styled";

const RegistrationWelcome: React.FC<{
  goToStep: () => void;
}> = ({ goToStep }) => {
  return (
    <Wrapper>
      <div className="welcome_registration_wrapper">
        <h1 className="welcome_title">Welcome to Lynked</h1>
        <p className="welcome_text">
          Loyalty Matters - Your Lynked users spend on average 35% more than
          your regular customers.
        </p>
        <p className="welcome_benefits">
          <PlayCircleIcon />
          What are the benefits of Lynked?
        </p>
        <div className="welcome_benefits_wrapper">
          <div className="welcome_benefits_item">
            <div className="welcome_icon_wrapper">
              <LoyaltyIcon />
            </div>
            <p className="welcome_benefits_text">Take your loyalty digital</p>
          </div>
          <div className="welcome_benefits_item">
            <div className="welcome_icon_wrapper">
              <CustomerConnectIcon />
            </div>
            <p className="welcome_benefits_text">Connect with your customers</p>
          </div>
          <div className="welcome_benefits_item">
            <div className="welcome_icon_wrapper">
              <DataAnalyzeIcon />
            </div>
            <p className="welcome_benefits_text">Analyze your data</p>
          </div>
          <div className="welcome_benefits_item">
            <div className="welcome_icon_wrapper">
              <IncrementIcon />
            </div>
            <p className="welcome_benefits_text">Drive more sales</p>
          </div>
          <div className="welcome_benefits_item">
            <div className="welcome_icon_wrapper">
              <CheckBusinessIcon />
            </div>
            <p className="welcome_benefits_text">
              Make informed business decisions
            </p>
          </div>
        </div>
        <div className="welcome_btn_wrapper">
          <button className="btn-blue" type="button" onClick={goToStep}>
            Get Started
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default RegistrationWelcome;
