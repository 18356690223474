import React, {useCallback, useEffect, useRef, useState} from "react";
import DataTable from "react-data-table-component";

import Input from "../../components/Input";
import { ReactComponent as SearchIcon } from "../../assets/img/search.svg";
import Pagination from "../../components/Pagination";
import {
  CustomerInfoInterface,
  CustomerRequestInterface,
} from "../../types/customer";
import {
  getAllStaffMembers,
  addStaffMembersAxios,
  editStaffMembersAxios,
  removeStaffMembersAxios,
  importWorkers,
  removeBulkStaff
} from "../../api/customers";
import { Wrapper } from "./styled";

import { ReactComponent as EditIcon } from "../../assets/img/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/img/delete.svg";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import StaffFamilyTable from "./InnerTable/StaffFamilyTable";
import Modal from "src/components/Modal";
import {REACT_APP_API_URL} from "../../types/env";

const StaffMembers = () => {
  const [staffList, setStaffList] = useState<any>([]);
  const firstNameInp = useRef<HTMLInputElement | null>(null);
  const lastNameInp = useRef<HTMLInputElement | null>(null);
  const employeeNumberInp = useRef<HTMLInputElement | null>(null);
  const gcNumberInp = useRef<HTMLInputElement | null>(null);
  const { loggedUser } = useTypedSelector((state) => state.auth);

  const [requestParamtrs, setRequestParamtrs] =
    useState<CustomerRequestInterface>({
      limit: "10",
      page: "1",
      search: null,
      location: "",
      group: "",
      sort_type: "lastName:asc",
    });
  const [staffListInfo, setStaffListInfo] = useState<CustomerInfoInterface>({
    count_users: 0,
    limit: 0,
    page: 0,
    total_pages_count: 0,
    total_users_count: 0,
    // searchType: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openBulkRemoveForm, setOpenBulkRemoveForm] = useState(false);
  const [fileBase64, setFileBase64] = useState<any>("");
  // const [filePassword, setFilePassword] = useState<any>("");

  const [newUser, setNewUser] = useState<any | null>({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    gc_number: "",
    store_name: loggedUser.user.global_card_name,
    employee_number: "",
    code_name: "",
    new: true,
  });

  const [editUser, setEdinUser] = useState<any | null>({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    gc_number: "",
    store_name: loggedUser.user.global_card_name,
    employee_number: "",
    code_name: "",
  });
  const removeInp = useRef<HTMLInputElement>(null)
  const importInp = useRef<HTMLInputElement>(null)
  let newStaff = newUser;

  const staffMembersTableColumns = [
    {
      name: "First Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="First name"
                required
                autoComplete="off"
                onInput={(e) => {
                  changeNewStaffValueInput("first_name", e.currentTarget.value);
                }}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="First name"
                ref={firstNameInp}
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.first_name
            )}
          </div>
        );
      },
    },
    {
      name: "Last Name",
      minWidth: "100px",
      maxWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="Last name"
                required
                autoComplete="off"
                onInput={(e) =>
                  changeNewStaffValueInput("last_name", e.currentTarget.value)
                }
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="Last name"
                ref={lastNameInp}
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.last_name
            )}
          </div>
        );
      },
    },
    {
      name: "Employee Number",
      minWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="Employee Number"
                required
                autoComplete="off"
                onInput={(e) =>
                  changeNewStaffValueInput(
                    "employee_number",
                    e.currentTarget.value
                  )
                }
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="Employee Number"
                ref={employeeNumberInp}
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.employee_number
            )}
          </div>
        );
      },
    },
    {
      name: "GC Number",
      minWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="GC Number"
                required
                autoComplete="off"
                onInput={(e) =>
                  changeNewStaffValueInput("gc_number", e.currentTarget.value)
                }
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : row.edit ? (
              <input
                placeholder="GC Number"
                ref={gcNumberInp}
                required
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.gc_number
            )}
          </div>
        );
      },
    },
    {
      name: "Email Address",
      minWidth: "275px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new ? (
              <input
                placeholder="Email Address"
                required
                autoComplete="off"
                onInput={(e) =>
                  changeNewStaffValueInput("email", e.currentTarget.value.trim())
                }
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.email
            )}
          </div>
        );
      },
    },
    {
      name: "Store",
      minWidth: "100px",
      cell: (row: any): JSX.Element => {
        return <div>{loggedUser.user.global_card_name}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "100px",
      cell: (row: any): JSX.Element => {
        return <div>{row.fake_data ? "" : row.phone}</div>;
      },
    },
    {
      name: "",
      minWidth: "50px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            {!row.new && !row.edit ? (
              <>
                <button
                  type="button"
                  className="edit"
                  onClick={() => editLocationStaffOpenSidebar(row.id, row)}
                >
                  <EditIcon />
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => deleteLocationStaffAsk(row.id)}
                >
                  <DeleteIcon />
                </button>
              </>
            ) : !row.new && row.edit ? (
              <button
                className="btn-blue-outline"
                style={{ padding: "10px 20px", backgroundColor: "transparent" }}
                onClick={() => saveStaffMembers(row.id)}
              >
                Save
              </button>
            ) : (
              <button
                className="btn-blue-outline"
                style={{ padding: "10px 20px", backgroundColor: "transparent" }}
                onClick={() => addNewUserToLocation(row.id)}
              >
                Add
              </button>
            )}
          </div>
        );
      },
    },
  ];

  function saveStaffMembers(id: any) {
    setIsLoading(true);
    let user = {
      id: id,
      email: editUser.email,
      first_name: firstNameInp?.current?.value,
      last_name: lastNameInp?.current?.value,
      phone: editUser.phone,
      gc_number: gcNumberInp?.current?.value,
      store_name: loggedUser.user.global_card_name,
      employee_number: employeeNumberInp?.current?.value,
      code_name: "",
    };

    editStaffMembersAxios(user)
      .then((response) => {
        getStaff();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  function changeNewStaffValueInput(label: string, value: string) {
    newStaff = { ...newStaff, [label]: value };
  }

  useEffect(() => {
    setTimeout(() => {
      if (
        firstNameInp.current &&
        lastNameInp.current &&
        employeeNumberInp.current &&
        gcNumberInp.current
      ) {
        firstNameInp.current.value = editUser.first_name;
        lastNameInp.current.value = editUser.last_name;
        employeeNumberInp.current.value = editUser.employee_number;
        gcNumberInp.current.value = editUser.gc_number;
      }
    }, 500);
  }, [editUser]);

  function editLocationStaffOpenSidebar(id: any, row: any) {
    setEdinUser(row);
    setIsLoading(true);

    getAllStaffMembers({ ...requestParamtrs, edit_id: id })
      .then((response) => {
        setStaffList(response.data.workers);
        setStaffListInfo({
          count_users: Number(response.data.count_workers),
          limit: Number(response.data.limit),
          page: Number(response.data.page),
          total_pages_count: Number(response.data.total_pages_count),
          total_users_count: Number(response.data.total_workers_count),
          // searchType: Number(response.data.searchType),
        });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  function addStaffMembers() {
    setNewUser(newStaff);
    setStaffList((staffList: any) => (staffList = [newUser, ...staffList]));
  }
  function deleteLocationStaffAsk(row: any) {
    setIsLoading(true);
    removeStaffMembersAxios({ id: row })
      .then((response) => {
        getStaff();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  const getStaff = useCallback(() => {
    setIsLoading(true);

    getAllStaffMembers(requestParamtrs)
      .then((response) => {
        setStaffList(response.data.workers);

        setStaffListInfo({
          count_users: Number(response.data.count_workers),
          limit: Number(response.data.limit),
          page: Number(response.data.page),
          total_pages_count: Number(response.data.total_pages_count),
          total_users_count: Number(response.data.total_workers_count),
          // searchType: Number(response.data.searchType),
        });
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  },[requestParamtrs]);

  useEffect(() => {
    getStaff();
  }, [requestParamtrs.page]);

  useEffect(() => {
    if (requestParamtrs.search !== null) {
      const timeout = setTimeout(() => {
        getStaff();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [requestParamtrs.search]);

  useEffect(() => {
    if (Number(requestParamtrs.page) === 1 && staffListInfo.limit !== 0) {
      getStaff();
    }
  }, [requestParamtrs.limit]);

  const addNewUserToLocation = (id: any) => {
    setIsLoading(true);
    newStaff = { ...newStaff, new: false };
    addStaffMembersAxios(newStaff)
      .then((response) => {
        getStaff();
        setIsLoading(false);
        newStaff = newUser;
      })
      .catch(() => setIsLoading(false));
  };

  const getBase64 = (e: any) => {
    const file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileBase64(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleImportWorkers = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setOpenForm(false);
    
    importWorkers({
      file: fileBase64,
      // @ts-ignore
      name: importInp.current.files[0].name,
    })
      .finally(() => {
        setIsLoading(false);
        getStaff();
        setFileBase64(null)
      });
  };

  const handleBulkRemove = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    setOpenBulkRemoveForm(false);
    removeBulkStaff({
      file: fileBase64,
      // @ts-ignore
      name: removeInp.current.files[0].name,
    })
      .finally(() => {
        setIsLoading(false);
        getStaff();
        setFileBase64(null)
      });
  };

  return (
    <Wrapper>
      <div className="page-title">Staff members</div>
      <div className="top-bar staff-top-bar">
        <div className="search-wrap">
          <Input
            placeholder="Search..."
            required
            icon={<SearchIcon />}
            getValue={(value) =>
              setRequestParamtrs({ ...requestParamtrs, search: value })
            }
          />
        </div>
        <button
          type="button"
          className="btn-white staff-add-btn"
          style={{ marginBottom: "12px", marginRight: "20px" }}
          onClick={() => addStaffMembers()}
        >
          Add New Member
        </button>
        <button
          type="button"
          className="btn-white staff-add-btn"
          style={{ marginBottom: "12px", marginRight: "20px" }}
          onClick={() => setOpenForm(!openForm)}
        >
          Import from File
        </button>
        <button
          type="button"
          className="btn-white staff-add-btn"
          style={{ marginBottom: "12px", marginRight: "20px" }}
          onClick={() => setOpenBulkRemoveForm(!openBulkRemoveForm)}
        >
          Bulk Remove
        </button>
      </div>
      {openForm && (
        <Modal style={{maxWidth: '700px'}} openModal={openForm} closeOutsideClick={true} setOpenModal={() => setOpenForm(!openForm)}>
          <form
            encType="multipart/form-data"
            className={"form-file"}
            onSubmit={(e) => handleImportWorkers(e)}
          >
            <h3>Import your File</h3>
            <p style={{ marginBottom: "30px", textAlign: 'center' }}>
            Please choose a file from your drive to upload to the dashboard in xls format.
            </p>
            <p style={{color: 'red', marginBottom: '5px'}}>Example</p>
            <img style={{marginBottom: '20px',objectFit: 'contain', width: '100%'}} src={REACT_APP_API_URL+"/img/examples/worker_import_example.png"} alt="example"/>
            <input
              type="file"
              ref={importInp}
              name="file"
              style={{ marginBottom: "20px" }}
              id="file"
              accept=".xlsx"
              onChange={(e: any) => getBase64(e)}
            />
            <div style={{ marginTop: "20px" }}>
              <button
                type="button"
                className="btn-red staff-add-btn"
                style={{ marginBottom: "12px", marginRight: "20px" }}
                onClick={() => setOpenForm(!openForm)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-white staff-add-btn"
                disabled={!fileBase64}
                style={{ marginBottom: "12px", marginRight: "20px" }}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
      )}
      {openBulkRemoveForm && (
        <Modal style={{maxWidth: '700px'}} openModal={openBulkRemoveForm} setOpenModal={() => setOpenBulkRemoveForm(!openBulkRemoveForm)}>
          <form
            encType="multipart/form-data"
            className={"form-file"}
            onSubmit={(e) => handleBulkRemove(e)}
          >
            <h3>Import your File</h3>
            <p style={{marginBottom: "30px", textAlign: 'center'}}>
              Please select a file from your drive to remove staff members from dashboard in xls format.
            </p>
            <p style={{color: 'red', marginBottom: '5px'}}>Example</p>
            <img style={{marginBottom: '20px',objectFit: 'contain', width: '100%'}} src={REACT_APP_API_URL+"/img/examples/worker_import_example.png"}
                 alt="example"/>
            
            <input
              type="file"
              ref={removeInp}
              name="file"
              style={{marginBottom: "20px"}}
              id="file"
              accept=".xlsx"
              onChange={(e: any) => getBase64(e)}
            />
            <div style={{marginTop: "20px"}}>
              <button
                type="button"
                className="btn-red staff-add-btn"
                style={{marginBottom: "12px", marginRight: "20px"}}
                onClick={() => setOpenBulkRemoveForm(!openBulkRemoveForm)}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn-white staff-add-btn"
                disabled={!fileBase64}
                style={{marginBottom: "12px", marginRight: "20px"}}
              >
                Remove
              </button>
            </div>
          </form>
        </Modal>
      )}
      
      <div className={`table-wrap ${isLoading ? "loading-blue" : ""}`}>
        <div className="table">
          <DataTable
            noHeader
            columns={staffMembersTableColumns}
            data={staffList}
            expandableRows
            expandableRowsComponent={
              <StaffFamilyTable data={staffList} getCustomerList={getStaff} />
            }
          />
          <Pagination
            {...staffListInfo}
            requestLimit={requestParamtrs.limit}
            setRequestParamtrs={setRequestParamtrs}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default StaffMembers;
