import styled from "styled-components";
import { Color } from "src/types/color";
import arrowDropDownImgBlack from "src/assets/img/arrowDropDownBlack.svg";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  .heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    .filters {
      display: flex;
      align-items: center;
      .select {
        margin-left: 20px;
        input {
          padding: 10px 16px 10px 40px;
        }
        svg path:not([fill="none"]) {
          fill: #666e90;
        }
      }
    }
  }

  .main-blocks-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 4px;
    .item {
      flex: 1;
      border-radius: 4px;
      background-color: ${Color.white};
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      padding: 15px 20px;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }

      .title {
        opacity: 0.6;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 15px;
      }
      .count {
        color: ${Color.blueDarkText};
        font-size: 44px;
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 10px;
      }
      .description {
        opacity: 0.2;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
  @media (max-width: 1300px) {
    .main-blocks-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
      margin-right: -30px;
      margin-bottom: -30px;

      .item.all-customers {
        margin: 0 30px 30px 0;
      }
    }
  }
  @media (max-width: 399px) {
    .main-blocks-row {
      margin-right: 0px;
      .item.all-customers {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 992px) and (max-width: 1040px) {
    .filters {
      flex-direction: column;

      div {
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 760px) {
    .filters {
      flex-direction: column;

      div {
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 480px) {
    .heading {
      flex-direction: column;

      .page-title {
        text-align: center;
      }
    }
  }

    .select{
        display: flex;
        flex-direction: column;
        position: relative;
        margin-bottom: 24px;

        & > .label:not(:empty) {
            color: ${Color.blueDarkText};
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            margin-bottom: 10px;
        }

        & > svg {
            position: absolute;
            left: 8px;
            top: 8px;
        }

        & > input {
            border-radius: 2px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
            padding: 13px 16px;
            font-size: 16px;
            line-height: 20px;
            cursor: pointer;
            background: url(${arrowDropDownImgBlack}) #ffffff no-repeat calc(100% - 12px) center;


            &::placeholder {
                color: #95989d;
            }

            &:disabled:not(".lock") {
                background: rgba(0, 0, 0, 0.07);
                box-shadow: none;
            }
        }
        &.lock {
            input {
                background: none;
                cursor: initial;
            }
        }

        & > ul {
            position: absolute;
            width: 100%;
            background: #fff;
            z-index: 5;
            box-shadow: rgb(0 0 0 / 20%) 0px 2px 7px 0px;
            border-radius: 2px;
            padding: 5px 0;
            max-height: 230px;
            overflow: auto;
            &.top {
                bottom: calc(100% + 7px);
            }
            &.bottom {
                top: calc(100% + 7px);
            }

            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 144, 202, 0.4);
            }

            & > input {
                width: 90%;
                margin: 10px auto;
                display: block;
                padding: 7px 10px;
                background: unset;
                height: auto;
                border: 2px solid #ebebeb;
                border-radius: 6px;
                box-sizing: border-box;

                &::placeholder {
                    color: #ced2d4;
                }
            }

            & > button {
                font-size: 14px;
                padding: 10px 16px;
                cursor: pointer;
                background: #fff;
                transition: background-color 0.225s;
                display: flex;
                width: 100%;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }

            & > li {
                padding: 10px 16px;
            }
        }
        &:hover {
            .clearable-btn {
                opacity: 0.4;
                &:hover {
                    opacity: 1;
                }
            }
        }
`;

export { Wrapper };
