import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  display: flex;
  padding: 40px 40px 40px 150px;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);

  @media (max-width: 900px) {
    padding: 40px 30px 40px 50px;
  }
  @media (max-width: 600px) {
    padding: 40px 15px;
  }

  .welcome_registration_wrapper {
    margin: auto 0;
  }
  .welcome_title {
    font-size: 48px;
    font-weight: 500;
    line-height: 61px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 40px;
    }
  }
  .welcome_text {
    font-size: 16px;
    font-weight: 300;
    opacity: 0.6;
    line-height: 24px;
    max-width: 470px;
    margin-bottom: 24px;
  }
  .welcome_btn_wrapper {
    max-width: 240px;
    @media (max-width: 400px) {
      max-width: 100%;
    }
  }
  .welcome_benefits {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: ${Color.blue};
    background: linear-gradient(
      0deg,
      ${Color.gradientFreeCardFrom} -16.46%,
      ${Color.gradientFreeCardTo} 98.11%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .welcome_benefits_wrapper {
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 35px;

    @media (max-width: 500px) {
      & > * {
        flex-basis: 100%;
      }
    }
  }
  .welcome_benefits_item {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 120px;
  }
  .welcome_icon_wrapper {
    width: 24px;
    height: 24px;
    background-color: #edeefc;
    border-radius: 50%;
    padding: 15px;
    svg {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%;
      font-family: "object-fit: cover";
      /* path:last-child {
        fill: ${Color.blue};
      } */
    }
  }
  .welcome_benefits_text {
    text-align: center;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
`;

export { Wrapper };
