import React, {FC, useRef, useState} from "react";
import {WrapperTransaction} from "./styled";
import {Wrapper} from "../../../Dashboard/DashboardChart/styled";
import {ReactComponent as EditIcon} from "../../../../assets/img/edit.svg";
import {ReactComponent as DeleteIcon} from "../../../../assets/img/delete.svg";
import DataTable from "react-data-table-component";
import {ProductDiscountItem} from "../../../../types/productDiscountTypes";
import Calendar from "../../../../components/Calendar";
import moment from "moment/moment";
import {
  createProductDiscountForCustomerGroupAxios, removeCustomerForCustomerGroupAxios,
  removeProductDiscountForCustomerGroupAxios
} from "../../../../api/productDiscount";
import userDefaultImg from "../../../../assets/img/default-user.jpg";
import {CustomerInterface,} from "../../../../types/customer";
import RemoveModal from "../../../../components/RemoveModal/RemoveModal";
import Checkbox from "../../../../components/CheckBox";


interface IProps {
  show: boolean
  productList: any,
  setProductList: any,
  setIsLoading: any,
  getProductDiscounts: any,
  getAllCustomersGroupForProductDiscount: any,
  customerList: any;
  selectedGroupId: number | null;
}

const GroupDetail: FC<IProps> = ({
                                   show,
                                   productList,
                                   customerList,
                                   getAllCustomersGroupForProductDiscount,
                                   selectedGroupId,
                                   setProductList,
                                   getProductDiscounts,
                                   setIsLoading
                                 }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [activeChart, setActiveChart] = useState<any>("Customers");
  const [showDeleteProductDiscountModal, setShowDeleteProductDiscountModal] = useState<boolean>(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<CustomerInterface | null>(null);
  const [selectedProductDiscount, setSelectedProductDiscount] = useState<any>(null);
  const handleChangeActiveChart = (chartName: any) => {
    setActiveChart(chartName);
    
  };
  
  const changeProductParameters = (id: number, columnName: string, value: Date | string | null | boolean | number | {
    value: string,
    label: string
  }) => {
    productList[id] = {...productList[id], [columnName]: value}
    setProductList([...productList])
  }
  
  const createProductDiscount = (data: ProductDiscountItem) => {
    setIsLoading(true)
    const requestData = {
      ...data,
      group_id: selectedGroupId,
      start_date: moment(data.start_date).format('yyyy-MM-DD HH:mm:ss'),
      end_date: data.end_date ? moment(data.end_date).format('yyyy-MM-DD HH:mm:ss') : null
    }
    createProductDiscountForCustomerGroupAxios(requestData).then(res => {
      getProductDiscounts(selectedGroupId)
    }).finally(() => {
      setIsLoading(false)
    })
  }
  
  const setEditItem = (id: number) => {
    setProductList(productList.map((item: any) => item.id === id ? item = {...item, edit: true} : item))
  }
  
  const removeProductDiscountFromGroup = (id: number) => {
    setIsLoading(true)
    removeProductDiscountForCustomerGroupAxios({id}).then(res => {
      getProductDiscounts(selectedGroupId)
    }).finally(() => {
      setIsLoading(false)
      setShowDeleteProductDiscountModal(false);
    })
  }
  
  const removeCustomerFromGroup = (id: number) => {
    setIsLoading(true)
    removeCustomerForCustomerGroupAxios({id, group_id: selectedGroupId}).then(res => {
      getProductDiscounts(selectedGroupId)
      getAllCustomersGroupForProductDiscount()
    }).finally(() => {
      setIsLoading(false)
      setShowDeleteUserModal(false)
    })
  }
  
  const selectCustomerToRemove = (customer: CustomerInterface) => {
    setSelectedUser(customer);
    setShowDeleteUserModal(true);
  }
  const selectProductDiscountToRemove = (product: any) => {
    setSelectedProductDiscount(product);
    setShowDeleteProductDiscountModal(true);
  }
  
  const manualChoosedCustomerTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt=""/>
            ) : (
              <img src={userDefaultImg} alt=""/>
            )}
          </div>
        );
      },
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.phone}</div>;
      },
    },
    {
      name: "Points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.end_value ?? "-"}</div>;
      },
    },
    {
      name: "Lifetime points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.total_spend ?? "-"}</div>;
      },
    },
    {
      name: "Check in",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.check_in_count ?? "-"}</div>;
      },
    },
    {
      name: "Last visit",
      minWidth: "120px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{new Date(row.last_visit).toLocaleString("en-GB")}</div>;
      },
    }, {
      name: "",
      minWidth: "50px",
      maxWidth: "60px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div className="action">
          <button
            type="button"
            className="delete"
            onClick={() => selectCustomerToRemove(row)}
          >
            <DeleteIcon/>
          </button>
        </div>;
      },
    },
  ];
  
  const productDiscountTableColumns = [
    {
      name: "Product Name",
      minWidth: "15%",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="Product Name"
                required
                defaultValue={row.product_name}
                autoComplete="off"
                onInput={e => row.product_name = e.currentTarget.value}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.product_name
            )}
          </div>
        );
      },
    },
    {
      name: "Product Group",
      minWidth: "15%",
      // maxWidth: "150px",
      cell: (row: any, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="Product Group"
                required
                defaultValue={row.product_group}
                autoComplete="off"
                onInput={e => row.product_group = e.currentTarget.value}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.product_group
            )}
          </div>
        );
      },
    },
    {
      name: "SKU",
      minWidth: "15%",
      // maxWidth: "150px",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="SKU"
                required
                defaultValue={row.sku}
                autoComplete="off"
                onInput={e => row.sku = e.currentTarget.value}
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.sku
            )}
          </div>
        );
      },
    },
    {
      name: "Discount percent",
      minWidth: "15%",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <input
                placeholder="Discount percent"
                required
                type={"number"}
                min={0}
                max={100}
                onInput={e => +e.currentTarget.value <= 0 ? row.discount = 0 : +e.currentTarget.value >= 100 ? row.discount = 100 : row.discount = e.currentTarget.value}
                defaultValue={row.discount}
                autoComplete="off"
                style={{
                  borderRadius: "4px",
                  padding: "13px 16px",
                  boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                }}
              />
            ) : (
              row.discount
            )}
          </div>
        );
      },
    },
    {
      name: "Start Date",
      minWidth: "15%",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <Calendar
                selected={new Date(row.start_date)}
                onChange={(e) => e >= new Date() ? changeProductParameters(index, 'start_date', e.getTime()) : changeProductParameters(index, 'start_date', Date.now())}
                dateFormat="MMMM d, yyyy"
                timeSelect={false}
                label={""}
              />
            ) : (
              `${moment(row.start_date).format('MMM D, yyyy')}`
            )}
          </div>
        );
      },
    },
    {
      name: "End Date",
      minWidth: "15%",
      cell: (row: ProductDiscountItem, index: number): JSX.Element => {
        return (
          <div>
            {row.edit ? (
              <>
                <Checkbox label={'Use End Date'} isChecked={!!row.end_date} onChange={(checked) => !checked ? changeProductParameters(index, 'end_date', null) : changeProductParameters(index, 'end_date', Date.now())}/>
                {!!row.end_date && (<Calendar
                  selected={new Date(row.end_date)}
                  onChange={(e) => e >= new Date() ? changeProductParameters(index, 'end_date', e.getTime()) : changeProductParameters(index, 'end_date', Date.now())}
                  dateFormat="MMMM d, yyyy"
                  timeSelect={false}
                  label={""}
                />)}
                
              </>
              
            ) : (
              `${row.end_date ? moment(row.end_date).format('MMM D, yyyy') : 'Unlimited'}`
            )}
          </div>
        );
      },
    },
    {
      name: "",
      minWidth: "10%",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            {!row.edit ? (
              <>
                <button
                  type="button"
                  className="edit"
                  onClick={() => setEditItem(row.id)}
                >
                  <EditIcon/>
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => selectProductDiscountToRemove(row)}
                >
                  <DeleteIcon/>
                </button>
              </>
            ) : (
              <button
                className="btn-blue-outline"
                style={{padding: "10px 20px", backgroundColor: "transparent"}}
                onClick={() => createProductDiscount(row)}
              >
                Save
              </button>
            )}
          </div>
        );
      },
    },
  ];
  
  const addNewProduct = () => {
    const newProductItem: any = {
      edit: true,
      product_name: '',
      product_group: '',
      sku: '',
      discount: '',
      start_date: Date.now(),
      end_date: Date.now()
    }
    setProductList([...productList, newProductItem])
  }
  return (
    <>
      {show ? <WrapperTransaction>
        <div className="transactionWrapper">
          <div className="transaction-body">
            <Wrapper>
              <div className="nav-subpages">
                <div
                  className={`nav-subpage ${
                    activeChart === "Customers" ? "active-page" : ""
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleChangeActiveChart("Customers")}
                  >
                    Customers
                  </button>
                </div>
                <div
                  className={`nav-subpage ${
                    activeChart === "Products Discount" ? "active-page" : ""
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => handleChangeActiveChart("Products Discount")}
                  >
                    Products Discount
                  </button>
                </div>
              </div>
              <div ref={chartRef} className="chart-wrapper">
                <div className="chart">
                  {activeChart === "Customers" ? (
                    <>
                      <div className="chart-content">
                        <div className="customersTable">
                          <DataTable noHeader columns={manualChoosedCustomerTableColumns} data={customerList}/>
                        </div>
                      </div>
                    
                    </>
                  ) : (
                    <>
                      <div className="chart-content">
                        <div className="chart-content-header">
                          <button
                            type="button"
                            className={'btn-white'}
                            onClick={() => addNewProduct()}
                          >
                            Add new product discount
                          </button>
                        </div>
                        <div className="productsTable">
                          <DataTable noHeader columns={productDiscountTableColumns} data={productList}/>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Wrapper>
          </div>
        </div>
        <RemoveModal showModal={showDeleteUserModal} setShowModal={setShowDeleteUserModal} handleCancel={() => {
          setSelectedUser(null)
          setShowDeleteUserModal(false)
        }} handleConfirm={() => {
          removeCustomerFromGroup(selectedUser!.id)
        }} title={`Are you sure you want to delete the User "${selectedUser?.email}" from customers group?`}/>
        
        <RemoveModal showModal={showDeleteProductDiscountModal} setShowModal={setShowDeleteProductDiscountModal}
                     title={`Are you sure you want to delete the Product discount "${selectedProductDiscount?.product_name}" from customers group?`}
                     handleCancel={() => {
                       setSelectedProductDiscount(null)
                       setShowDeleteProductDiscountModal(false)
                     }} handleConfirm={() => removeProductDiscountFromGroup(selectedProductDiscount!.id)}/>
      
      </WrapperTransaction> : null}
    
    </>
  
  );
};

export default GroupDetail;
