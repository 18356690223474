import { RegisterCreatePasswordInfoInterface } from "../../../../types/registration";
import axios from "../../../../api/api";

export const registerCreatePassword = (
  data: RegisterCreatePasswordInfoInterface
): Promise<any> => {
  const requestFormData = new FormData();

  requestFormData.append("password", data.password);
  requestFormData.append("confirm_password", data.confirm_password);
  requestFormData.append("verification_token", data.verification_token ?? "");

  return axios
    .post("/public/create-password", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
