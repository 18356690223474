import React, {useEffect, useState} from 'react';
import { Wrapper } from './styled'
import AdminPartnersItem from "../../../../components/AdminPartnersItem";
import {getAdvertOffersAxios} from "../../../../api/adminPanel";
import {AdvertsPartnerType} from "../../../../types/adminTypes";

const AdminAdvertsOutlets = () => {
  const [partners, setPartners] = useState<AdvertsPartnerType[]>([])
  useEffect(() => {
    handlerGetAllOutlets()
  }, []);
  
  const handlerGetAllOutlets = () =>{
    getAdvertOffersAxios({offer_type: 0}).then(({data}) => {
      setPartners(data.advertOffers)
    })
  }
  
  return (
    <Wrapper>
      <div className="header">
        <h2>In-App Advertisements</h2>
      </div>
      <div className="main_advert">
        <div className="subtitle">
          <h3>Offers (Outlets)</h3>
        </div>
        <AdminPartnersItem setPartners={setPartners} type={'outlets'} advertList={partners} offers={true} getAllPartners={handlerGetAllOutlets}/>
      </div>
    </Wrapper>
  );
};

export default AdminAdvertsOutlets;
