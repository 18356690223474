import React, {useEffect, useRef} from "react";
import { Wrapper } from "./styled";
import {Props} from "./types";

const Modal = ({
  openModal,
  setOpenModal,
  closeOutsideClick = true,
  children,
  style={}
}: Props) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClickOutside = (event: any) => {
    if (!modalRef?.current?.contains(event.target) && openModal) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (closeOutsideClick) {
      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }

    return undefined;
  });

  return (
    <Wrapper>
      <div style={style} ref={modalRef}>{children}</div>
    </Wrapper>
  );
};

export default Modal;
