import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
    .top-bar div.search-wrap {
        margin-left: 0;
    }
    .staff-top-bar{
        justify-content: start;
        gap: 20px;
    }
    .addSpendWrap{
        display: flex;
        align-items: center;
        gap: 10px;
        input{
            width: 100%;
            min-width: 30px;
        }
        button {
            width: 40%;
        }
    }
    .remove-modal {
        text-align: center;
        padding: 30px 35px;

        .title {
            color: ${Color.blueDarkText};
            font-size: 21px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
        }

        button {
            margin-top: 20px;
            width: auto;
            padding: 10px 20px;
        }
    }
    .create-new-user {
        form {
            margin-bottom: 30px;
            border: 1px solid rgba(0, 13, 70, 0.1);
            border-radius: 5px;
            padding: 15px;
            //overflow: hidden;
        }

        .title-close {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .title {
                font-size: 18px;
                font-weight: 500;
                line-height: 21px;
                margin-bottom: 0px;
            }

            .close {
                background: none;
                opacity: 0.6;
                line-height: 0;

                &:hover {
                    opacity: 1;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .input-wrap {
            display: flex;
            margin-bottom: 15px;

            & > div {
                flex: 1;
                width: calc(100% / 3);
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }

                &.last_name-group{
                    &>div{
                        ul.bottom{
                            max-height: 70px;
                        }
                    }
                }
            }
        }
    }
`;

export { Wrapper };
