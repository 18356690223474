import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  
  .response {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    z-index: 53453453;
    h3 {
      color: ${Color.blueDarkText};
    }
  }
`;

export { Wrapper };
