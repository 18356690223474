import React, {useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import PosCategoryColumn from "../../components/PosCategoryColumnWrapper/PosCategoryColumn";
import DataTable from "react-data-table-component";
import Input from "../../components/Input";
import Select from "../../components/Select";
import {ReactComponent as CheckCircle} from "../../assets/img/checkCircle.svg";
import {ReactComponent as Edit} from "../../assets/img/edit.svg";
import {ReactComponent as Delete} from "../../assets/img/delete.svg";
import {ReactComponent as CirclePlus} from "../../assets/img//circle-plus.svg";
import {RuleType} from "../../types/ecommerceRuleTypes";
import {SelectorListObj} from "../../components/Select/types";
import {
  createEcommerceRuleAxios,
  getEcommerceRuleListAxios,
  removeEcommerceRuleAxios
} from "../../api/ecommerceRule";
import {IScaleParameter} from "../../types/posRyles";

const EcommerceRule = () => {
  const [ecommerceList, setEcommerceList] = useState<RuleType[]>([])
  const [selectRestrictionTypeList, setSelectRestrictionTypeList] = useState<SelectorListObj[]>([])
  const [selectEcommerceTypeList, setSelectEcommercwTypeList] = useState<SelectorListObj[]>([])
  const [editEcom, setEditEcom] = useState<boolean>(false)
  
  useEffect(() => {
    getEcommerceInfo()
  }, []);
  
  const scaleParemeterSelectList: IScaleParameter[] = [
    {value: "price", label: "Price"},
    {value: "qty", label: "Quantity"}
  ];
  
  const getEcommerceInfo = () => {
    getEcommerceRuleListAxios().then(({data}) => {
      setEcommerceList(data.category_restrictions)
      setSelectRestrictionTypeList(data.rule_types)
      setSelectEcommercwTypeList(data.ecommerce)
    })
  }
  
  const setEditRule = (id: number) => {
    setEditEcom(true)
    setEcommerceList(ecommerceList.map(rule => rule.id === id ? {...rule, edit: true} : rule))
    // setEcommerceList([ecommerceList.forEach(rule=> rule.id === id ? rule = {...rule, edit: true} : rule)])
  }
  
  
  const categoryList = [
    {
      name: "",
      maxWidth: "15%",
      cell: (item: any): JSX.Element => {
        return (
          <Input
            defaultValue={item.name}
            getValue={(value) => item.name = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: any): JSX.Element => {
        return (<Select
            placeholder="Select restriction type"
            selectorList={selectRestrictionTypeList}
            positionForShow="bottom"
            required
            defaultValue={selectRestrictionTypeList.filter(stamp => +stamp.value === +item.restriction_type)[0] || selectRestrictionTypeList[0]}
            getValue={(value) => item.restriction_type = +value}
            hideSelectorFilter
            disabled={!item.edit}
          />
        );
      }
    },
    // {
    //   name: "",
    //   maxWidth: "15%",
    //   cell: (item: any): JSX.Element => {
    //     return (<Select
    //         placeholder="Select stamp Icon"
    //         selectorList={selectEcommerceTypeList}
    //         positionForShow="bottom"
    //         required
    //         defaultValue={selectEcommerceTypeList.filter(stamp => stamp.value === item.ecommerce)[0] || selectEcommerceTypeList[0]}
    //         getValue={(value) => item.ecommerce = value}
    //         hideSelectorFilter
    //         disabled={!item.edit}
    //       />
    //     );
    //   }
    // },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: any): JSX.Element => {
        return (
          <Input
            defaultValue={item.restriction_value}
            getValue={(value: string) => item.restriction_value = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: any): JSX.Element => {
        return (
          <Select
            placeholder="Select Scale Parameter"
            selectorList={scaleParemeterSelectList}
            positionForShow="bottom"
            disabled={true}
            required
            defaultValue={scaleParemeterSelectList.filter(stamp => stamp.value === item.scale_parameter)[0] || scaleParemeterSelectList[0]}
            getValue={(value: string | number) =>
              item.scale_parameter = value
            }
            hideSelectorFilter
            
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: any): JSX.Element => {
        return (
          <Input
            defaultValue={`${item.scale_factor}`}
            type="number"
            getValue={(value: string) => item.scale_factor = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "7.5%",
      cell: (item: any, index: number): JSX.Element => {
        
        return (
          <div className="buttons" data-tag="allowRowEvents">
            {item.edit && item.id ?
              <button className="btn-white edit-category save-btn" onClick={() => saveEcomerceRule(item)}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category save-btn" onClick={() => saveEcomerceRule(item)}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button> : <>
                <button disabled={false} className="btn-white edit-category" onClick={() => setEditRule(item.id)}>
                      <span className="icon">
                        <Edit/>
                      </span>
                </button>
                <button disabled={false} className="btn-white remove-category"
                        onClick={() => removeEcommerceRule(item.id)}>
                      <span className="icon">
                      <Delete/>
                      </span>
                </button>
              </>}
          
          </div>
        );
      }
    }
  ];
  
  const saveEcomerceRule = (ruleObj: any) => {
    const data = ruleObj.id === 'new' ? {
      name: ruleObj.name,
      restriction_type: ruleObj.restriction_type,
      restriction_value: ruleObj.restriction_value,
      ecommerce: ruleObj.ecommerce,
      scale_parameter: ruleObj.scale_parameter ? ruleObj.scale_parameter : 'price',
      scale_factor: ruleObj.scale_factor ? ruleObj.scale_factor : 0,
    } : ruleObj
    createEcommerceRuleAxios(data).then(res => {
      getEcommerceInfo()
    })
    setEditEcom(false)
  }
  
  const removeEcommerceRule = (id: number) => {
    removeEcommerceRuleAxios(id).then(res => {
      getEcommerceInfo()
    })
  }
  
  const addNewRule = () => {
    setEcommerceList([...ecommerceList, {
      id: 'new',
      name: '',
      edit: true,
      restriction_type: 0,
      ecommerce: 0,
      restriction_value: '',
      scale_parameter: 'price',
      scale_factor: 0
    }])
    setEditEcom(true)
  }
  
  return (
    <Wrapper>
      <h1>E-Commerce Rule</h1>
      <>
        <div className="content stamp-based-wrapper">
          <div className="header">
            <div className="header-tab">
              <PosCategoryColumn title={'Rule Name'} description={''}/>
              <PosCategoryColumn title={'Rule Type'}
                                 description={''}/>
              <PosCategoryColumn title={'Rule Value'}
                                 description={''}/>
              <PosCategoryColumn title={'Scale Parameter'}
                                 description={''}/>
              <PosCategoryColumn title={'Scale Factor'}
                                 description={''}/>
              <div className="column_buttons"></div>
            
            </div>
            
            
            <div className="stamp-based-form">
              
              {ecommerceList !== null ? (
                ecommerceList.length > 0 ? (
                  <>
                    {editEcom ? <div className="table table-edit">
                      <DataTable
                        noTableHead
                        noHeader
                        columns={categoryList}
                        // onRowClicked={(row) => console.log('sd')}
                        data={ecommerceList}
                        // expandableRows
                        // expandableRowsComponent={
                        //   <InnerPosStampCategoryGroup selectStampCategory={selectStampCategory} data={items} edit={editStamp} setEdit={setEditStamp} getAllCategories={getPosStampCategories} setItems={setPosStamps} scaleParemeterSelectList={stampIconList}/>
                        // }
                      />
                    </div> : <div className="table">
                      <DataTable
                        noTableHead
                        noHeader
                        columns={categoryList}
                        data={ecommerceList}
                        // onRowClicked={(row) => selectStampCategory(row)}
                        // expandableRows
                        // expandableRowsComponent={
                        //   <InnerPosStampCategoryGroup selectStampCategory={selectStampCategory} data={items} edit={editStamp} setEdit={setEditStamp} getAllCategories={getPosStampCategories} setItems={setPosStamps} scaleParemeterSelectList={stampIconList}/>
                        // }
                      />
                    </div>}
                  
                  </>
                ) : (
                  <div className="nothing-found">Sorry, nothing found</div>
                )
              ) : null}
            </div>
            <button disabled={false} className="btn-white add-stampCategory" onClick={addNewRule}>
            <span className="icon">
              <CirclePlus/>
            </span>
              <span>Add Rule</span>
            </button>
          </div>
        
        </div>
      
      </>
    </Wrapper>
  );
};

export default EcommerceRule;
