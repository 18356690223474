import React, {useEffect, useRef, useState} from "react";

import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import { ReactComponent as ClearIcon } from "src/assets/img/clear.svg";
import { ReactComponent as LockIcon } from "src/assets/img/lock.svg";

import { Wrapper } from "./styled";
import {Props, SelectorListObj} from "./types";

const Select = ({
  label,
  placeholder,
  infoTooltip,
  defaultValue,
  required,
  isClearable,
  icon,
  selectorList,
  positionForShow,
  hideSelectorFilter,
  disabled,
  isHideWhenSelect = true,
  lock,
  getValue,
  onFocus,
}: Props) => {
  const [value, setValue] = useState<string>(defaultValue?.label || "");
  const [list, setList] = useState<SelectorListObj[]>(selectorList)
  
  useEffect(() => {
    if (defaultValue && defaultValue.label !== value) {
      setValue(defaultValue.label);
    }
    setList(selectorList)
  }, [defaultValue]); //eslint-disable-line

  const [isShowSelectList, setIsShowSelectList] =
    React.useState<boolean>(false);
  const [selectorFilter, setSelectorFilter] = useState<string>("");

  const ref = useRef<HTMLUListElement>(null);

  const handleClickOutside = (event: any) => {
    if (!ref?.current?.contains(event.target) && isShowSelectList) {
      setIsShowSelectList(false);
    }
  };

  const handleChange = (currentItem: SelectorListObj) => {
    if (isShowSelectList && isHideWhenSelect) setIsShowSelectList(false);

    setValue(currentItem.label);
    if (getValue) {
      getValue(currentItem.value, currentItem.label, currentItem.additional);
    }
  };

  useEffect(() => {
    if (isShowSelectList) {
      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }

    return undefined;
  });

  useEffect(() => {
    setList(selectorList.filter((option) =>
      option.label.toLowerCase().includes(selectorFilter.toLowerCase())
    ))
  }, [selectorFilter, selectorList]);
  
  return (
    <Wrapper data-tag="allowRowEvents"
      required={required}
      isSelect={!!selectorList}
      isLabel={!!label}
      className={`select ${selectorList ? "isSelect" : ""} ${
        lock ? "lock" : ""
      }`}
    >
      <span data-tag="allowRowEvents" className="label">{label}</span>
      {icon}
      <input data-tag="allowRowEvents"
        type="text"
        value={value}
        placeholder={placeholder}
        required={required}
        disabled={disabled || lock}
        autoComplete={selectorList ? "nope" : ""}
        onChange={() => {
          return null;
        }}
        onFocus={(event) => {
          if (selectorList) {
            event.target.blur();
          }

          if (onFocus) onFocus();
        }}
        onClick={() =>
          selectorList && !isShowSelectList && setIsShowSelectList(true)
        }
      />
      {selectorList?.length && isShowSelectList ? (
        <ul
          ref={ref}
          className={`${positionForShow === "top" ? "top" : "bottom"}`}
        >
          {!hideSelectorFilter && (
            <input
              type="text"
              placeholder="Search"
              value={selectorFilter}
              onChange={(event) => setSelectorFilter(event?.target.value)}
            />
          )}
          {list.map((item, index) => (
            <button
              type="button"
              key={item.value + `${index}`}
              onClick={() => handleChange(item)}
            >
              {item.label}
            </button>
          ))}
          {list.length === 0 && <li>Not found</li>}
        </ul>
      ) : null}
      {lock && (
        <span className="lock-icon-wrap">
          <LockIcon />
        </span>
      )}
      {infoTooltip ? (
        <span className="info-tooltip">
          <InfoIcon />
          <span className="tooltip">{infoTooltip}</span>
        </span>
      ) : null}
      {isClearable && value ? (
        <button
          className="clearable-btn"
          type="button"
          onClick={() => {
            setValue("");
            if (getValue) getValue("", "", {});
          }}
        >
          <ClearIcon />
        </button>
      ) : null}
    </Wrapper>
  );
};

export default Select;
