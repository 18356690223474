const verificationToken = "verification_token";
const accountInformationToken = "account_information_token";
const stampList = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
];

const EXPIRE_REWARD_LIST = [
  {
    value: 30,
    label: "30",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 100,
    label: "100",
  },
  {
    value: 365,
    label: "365",
  },
];

export {
  verificationToken,
  accountInformationToken,
  stampList,
  EXPIRE_REWARD_LIST,
};
