import React from "react";
import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import { Wrapper } from "./styled";

interface IProps {
  infoText: string;
}

const Info = ({ infoText }: IProps) => {
  return (
    <Wrapper>
      <span className="info-tooltip">
        <InfoIcon />
        <span className="tooltip">{infoText}</span>
      </span>
    </Wrapper>
  );
};

export default Info;
