import { Dispatch } from "redux";
import {
  GroupsLocationsAction,
  GroupsLocationsActionTypes,
} from "../../types/groupsLocations";
import { getGroupsLocationstAxios } from "../../api/groupsLocations";

export const getGroupsLocations = (): any => {
  return async (dispatch: Dispatch<GroupsLocationsAction>) => {
    getGroupsLocationstAxios().then((response) => {
      const groups = response.data.groups.map(
        (item: { id: number; name: string }) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );
      const locations = response.data.locations.map(
        (item: { id: number; location_name: string }) => {
          return {
            value: item.id,
            label: item.location_name,
          };
        }
      );
      dispatch({
        type: GroupsLocationsActionTypes.GET_GROUPS_LOCATIONS,
        payload: {
          groups,
          locations,
        },
      });
    });
  };
};
