import React, {useState} from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as ArrowDownIcon } from "src/assets/img/arrowDown.svg";
import MunuItem from "../MunuItem";
import { MenuList } from "../types";
import {Props} from "./types";
import { Wrapper } from "./styled";

const Menu = ({ menuList }:Props) => {
  const location = useLocation();
  const [openSubMenuList, setOpenSubMenuList] = useState<string[]>([]);

  const activeParentPage = (item: MenuList) => {
    if (item.children) {
      return item.children.some((e) => e.path === location.pathname);
    }
    return false;
  };

  const checkActiveSubPath = (activeSubPath?: string[]) => {
    if (activeSubPath) {
      return activeSubPath.some((e) => e === location.pathname);
    }
    return false;
  };

  const openChildrenNav = (itemPath: string) => {
    const openSubMenuListTemp = [...openSubMenuList];
    const index = openSubMenuListTemp.indexOf(itemPath);
    if (index > -1) {
      openSubMenuListTemp.splice(index, 1);
      setOpenSubMenuList(openSubMenuListTemp);
    } else {
      setOpenSubMenuList((oldOpenSubMenuList) => [
        ...oldOpenSubMenuList,
        itemPath,
      ]);
    }
  };

  const showSubPage = (itemPath: string) => {
    return openSubMenuList.some((e) => e === itemPath);
  };

  return (
    <Wrapper>
      {menuList.map((item) => (
        <div
          className={`nav-item ${item.type} ${
            item.path === location.pathname ||
            checkActiveSubPath(item.activeSubPath)
              ? "active-page"
              : ""
          } ${
            activeParentPage(item) || checkActiveSubPath(item.activeSubPath)
              ? "active-parent-page"
              : ""
          }  ${showSubPage(item.path) ? "show-sub-page" : ""} ${
            item.type === "item-parent" ? "nav-item_not_active" : ""
          }`}
          key={item.name}
        >
          {item.type === "item" || item.type === "external" ? (
            <MunuItem item={item} />
          ) : item.type === "item-parent" ? (
            <>
              <MunuItem item={item} />
              <button
                type="button"
                className="arrow-drop-down"
                onClick={() => openChildrenNav(item.path)}
              >
                <ArrowDownIcon />
              </button>
              <div className="nav-children-items">
                {item.children
                  ? item.children.map((itemChildren) => (
                      <div
                        className={`nav-children-item ${itemChildren.type} ${
                          itemChildren.path === location.pathname
                            ? "active-page"
                            : ""
                        }`}
                        key={itemChildren.name}
                      >
                        <MunuItem item={itemChildren} />
                      </div>
                    ))
                  : null}
              </div>
            </>
          ) : null}
        </div>
      ))}
    </Wrapper>
  );
};

export default Menu;
