import React from "react";

interface IFormErrorProps {
  formErrorMessages: any;
  field: string;
}

const FormError = ({ formErrorMessages, field }: IFormErrorProps) => {
  return (
    <>
      {formErrorMessages && formErrorMessages[field] ? (
        <div className="form-error-message">{formErrorMessages[field]}</div>
      ) : null}
    </>
  );
};

export default FormError;
