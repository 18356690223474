import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
    .content {
        width: 100%;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        position: relative;
        display: flex;
        padding-top: 20px;
        .item{
            display: flex;
            justify-content: space-between;
            &>div{
                width: 15%;
                input{
                    width: 70%;
                    padding-right: 40px;
                }
                &:nth-last-child(1){
                    width: 8.5%;
                    margin: 0;
                }
            }

        }
        .reward_expired {
            display: flex;
            flex-direction: column;
            label{
                width: 100%;
                justify-content: flex-start;
                
                input{
                    width: 20px!important;
                    padding-right: 0!important;
                }
                .label_checkbox{
                    width: 70%;
                }
            }
        }
        .header {
            flex-direction: column;
            width: 100%;
            .header-tab {
                height: auto;
                display: flex;
                justify-content: flex-start;
                gap: 2%;

                .column {
                    width: 17%;
                    
                    .page-title{
                        font-size: 18px;
                        margin-bottom: 5px;
                    }
                    .description {
                        font-size: 14px;
                    }
                }
            }
            .stamp-based-form {
                display: flex;
                justify-content: space-between;
                .table {
                    width: 100%;
                    .rdt_TableRow{
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        gap: 2%;
                        padding: 12px 0 0;

                        .sc-idiyUo{
                            position: absolute;
                            z-index: 9999;
                            left: -10px;
                            margin-bottom: 12px;
                        }
                        .rdt_TableCell{
                            width: 30%;
                            padding-left: 0;
                            &>div{
                                width: 100%;
                                input{
                                    width: 80%;
                                    padding-right: 40px;
                                }

                            }
                            .select{
                                margin-bottom: 12px;
                                height: 48px;
                            }
                            &:nth-last-child(1){
                                width: 8.5%;
                                padding-bottom: 12px;
                                .buttons{
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    button{
                                        width: 45%;
                                        padding: 9.5px 15px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                    .save-btn{
                                        width: 100%;
                                        padding: 9.5px 10px;
                                    }
                                }

                            }
                        }
                    }



                }
                .table-edit{
                    &>div{
                        padding-bottom: 90px;
                        .select{
                            .bottom{
                                max-height: 80px;
                                width: 120%;
                            }
                        }
                    }
                }
                .modal-content{
                    text-align: center;
                    padding: 60px 50px;
                    @media (max-width: 600px) {
                        padding: 30px 15px;
                    }
                    .title {
                        color: ${Color.blueDarkText};
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 38px;
                        text-align: center;
                        @media (max-width: 600px) {
                            font-size: 23px;
                            line-height: 28px;
                        }
                    }
                    .sub-title{
                        color: ${Color.blueDarkText};
                        font-size: 25px;
                        font-weight: 500;
                        line-height: 38px;
                        text-align: center;
                        @media (max-width: 600px) {
                            font-size: 23px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
    }
    .groups-wrap{
        .calendar-wrapper {
            .react-datepicker-wrapper {
                .react-datepicker__input-container {
                    input {
                        height: 45px;
                        width: 63% !important;
                    }
                }
            }
            svg{
                bottom: 12px;
            }
        }
    }
    .add-stampCategory{
        margin: 5px 2px;
        width: 30%;
        justify-self: start;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 23px;
                height: 23px;
            }
        }
    }
`;

export { Wrapper };
