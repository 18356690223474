import axios from "../../../../api/api";

export const getPlaceName = (accountInformationToken: string): Promise<any> =>
  axios
    .get("/public/place-name", {
      params: { token: accountInformationToken },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const createFirstLocation = (
  location: any,
  accountInformationToken: string
): Promise<any> => {
  const requestFormData = new FormData();
  requestFormData.append("address", location.address);
  requestFormData.append("latitude", location.latitude);
  requestFormData.append("longitude", location.longitude);
  requestFormData.append("location_name", location.location_name);
  requestFormData.append("token", accountInformationToken);
  requestFormData.append("phone_number", location.phone);

  return axios
    .post("/public/create-locations", requestFormData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};
