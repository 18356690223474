import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { promotionReducer } from "./promotionReducer";
import { groupsLocationsReducer } from "./groupsLocationsReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  promotion: promotionReducer,
  groupsLocations: groupsLocationsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
