import React, { FC, useRef, useState } from "react";

import { ReactComponent as EditIcon } from "../../../../assets/img/edit.svg";
import { PointInput, PointWrapper } from "./styled";
import { updatePointsCountAxios } from "../../../../api/customers";

interface IProp {
  points: string;
  pointId: string;
  userId: string;
  getCustomerList: any;
  getUserPointInfo: any;
  canEdit: boolean;
  minValue: number;
  stamp_activity?: any;
}

const EditablePoint: FC<IProp> = ({
                                    points,
                                    minValue,
                                    pointId,
                                    canEdit,
                                    userId,
                                    getCustomerList,
                                    getUserPointInfo,
                                    stamp_activity
                                  }) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [point, setPoint] = useState(points ?? "-");

  const editPoint = () => {
    if (ref.current) {
      ref.current.focus();
      ref.current.readOnly = false;
    }
  };

  const changePoints = (e: any) => {
    let value = e.target.value;

    if (minValue > e.target.value) {
      value = minValue;
    }

    if (+point !== +value) {
      setPoint(value);
    }
  };

  const handleBlur = () => {
    if (ref.current) {
      ref.current.readOnly = true;
      if (+points !== +point) {
        updatePointsCountAxios({ activity_id: pointId, points: `${+point}` }).then(r => {
          getCustomerList();
          getUserPointInfo(userId);
        });
      }
    }
  };

  return (
    <PointWrapper>
      <PointInput type="number" value={Object.keys(stamp_activity).length ? stamp_activity.stamps : point} readOnly ref={ref} onChange={changePoints} onBlur={handleBlur}
                  min={minValue} />
      {
        canEdit && <button type="button" onClick={editPoint}>
          <EditIcon />
        </button>
      }
    </PointWrapper>
  );
};

export default EditablePoint;
