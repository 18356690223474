import React, { FC, useState } from "react";

import StaffMembers from "../StaffMembers";
import ShowHideBtns from "../ShowHideBtns";

import { EditLocationStaffInterface, LocationInfoInterface, LocationUserInfoInterface } from "../../../../types/place";

interface IProps {
  location: LocationInfoInterface;
  editLocationOpenSidebar: (location: LocationInfoInterface) => void;
  isShowLocationDetails?: boolean;
  setLocationStaffSidebarData: (arg: EditLocationStaffInterface | null) => void;
  setShowLocationStaffSidebar: (arg: boolean) => void;
  setDeleteStaffObj: (arg: LocationUserInfoInterface | null) => void;
  getPlaceInfo: () => void;
  locations: Array<LocationInfoInterface>;
  isTwoBtn?: boolean;
}

const ShowHideStaffMembers: FC<IProps> = ({
                                            location, setLocationStaffSidebarData,
                                            editLocationOpenSidebar, isShowLocationDetails,
                                            setShowLocationStaffSidebar, setDeleteStaffObj,
                                            isTwoBtn, getPlaceInfo, locations
                                          }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      {!isOpen &&
        <StaffMembers location={location} locations={locations}
                      setLocationStaffSidebarData={setLocationStaffSidebarData}
                      setShowLocationStaffSidebar={setShowLocationStaffSidebar}
                      setDeleteStaffObj={setDeleteStaffObj}
                      getPlaceInfo={getPlaceInfo} />
      }

      {
        !(isTwoBtn && !isShowLocationDetails) &&
        <ShowHideBtns isShowLocationDetails={isShowLocationDetails} editLocationOpenSidebar={editLocationOpenSidebar}
                      setIsOpen={setIsOpen} isOpen={isOpen} location={location} />
      }
    </>
  );
};

export default ShowHideStaffMembers;