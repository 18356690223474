import styled from 'styled-components';
import { Color } from "src/types/color";

const Wrapper = styled.div`
    .header{
        h2{
            color: ${Color.blueDarkText};
            font-size: 30px;
        }
    }
    .main_advert{
        .subtitle{
            h3{
                font-size: 25px;
                color: ${Color.blueDarkText};
            }
        }
    }
`;

export { Wrapper }