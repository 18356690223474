import styled from 'styled-components';
import { Color } from "../../../../types/color";

export const WrapperTransaction = styled.div`
  
  .transactionWrapper{
    //position: absolute;
    z-index: 100;
    //top: 0;
    //left: 0;
    width: 100%;
    padding: 20px 0;
  }
  
  //.transaction-header{
  //  background-color: #F7F8FC;
  //  padding: 25px 30px;
  //}
  //
  //.topBar{
  //  width: 20%;
  //  display: flex;
  //  align-items: center;
  //  cursor: pointer;
  //
  //  & svg{
  //    width: 25px;
  //    height: 25px;
  //    margin-right: 15px;
  //  }
  //}
  .transaction-body{
    background-color: #fff;
    padding: 0 30px;
  }
  .transaction-title{
    font-size: 32px;
    color: #000d46;
    font-weight: 450;
    margin: 0 0 15px;
  }
  .transaction-description{
    font-size: 14px;
    font-weight: 450;
    color: #000d46;
    opacity: 0.6;
    margin-bottom: 30px;
  }

  .purchase-wrapper{
    padding: 20px 0 20px;
  }
  .main-info{
    display: flex;
    justify-content: space-around;
    align-items: inherit;
    margin-bottom: 30px;
  }
  .main-info-item{
    max-width: 300px;
  }
  .item-label{
    font-size: 14px;
    font-weight: 450;
    color: #000d46;
    margin-bottom: 15px;
  }
  .item-value{
    font-size: 16px;
    font-weight: 450;
    color: #000d46;
  }
    .total_wrap{
        display: flex;
        align-items: center;
    }
  .total-prise{
    margin-top: 20px;
    margin-right: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
    & p{
      font-size: 16px;
      font-weight: 500;
      margin-left: 40px;
    }
  }
  .chart {
    background: ${Color.white};
    padding: 0 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px 8px 8px 8px;

  }
  
`;
