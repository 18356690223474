import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  min-height: 100vh;
  background-color: ${Color.blueDark};
  .inner-wrap {
    max-width: 100%;
  }
  .logo {
    text-align: center;
    margin-bottom: 10vh;
  }
  .main-block {
    border-radius: 8px;
    background-color: ${Color.white};
    padding: 60px 55px;
    width: 500px;
    max-width: 100%;
    box-sizing: border-box;
    text-align: center;
    position: relative;
    @media (max-width: 550px) {
      padding: 30px 15px;
    }

    .warning-text {
      font-size: 24px;
      line-height: 35px;
      color: ${Color.blueDarkText};
      font-weight: 500;
    }
  }
  .title {
    color: ${Color.blueDarkText};
    font-size: 40px;
    font-weight: 500;
    line-height: 51px;
    margin-bottom: 15px;
  }

  .place-information-content {
    margin-bottom: 30px;
    .item {
      display: flex;
      text-align: left;
      margin-bottom: 5px;
      .name {
        width: 50%;
      }
      .description {
        width: 50%;
      }
    }
  }
  .locations-information-content {
    margin-bottom: 30px;
    .item {
      margin-bottom: 5px;
      &:before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #000;
        border-radius: 100%;
        position: relative;
        top: -2px;
        margin-right: 5px;
      }
    }
  }
  .btns-decline-accept {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .btn-decline {
    }
    .btn-accept {
    }
  }
  .modal-content {
    text-align: center;
    padding: 60px 50px;
    @media (max-width: 600px) {
      padding: 30px 15px;
    }
    .title {
      color: ${Color.blueDarkText};
      font-size: 30px;
      font-weight: 500;
      line-height: 38px;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 23px;
        line-height: 28px;
      }
    }
  }
`;

export { Wrapper };
