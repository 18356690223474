import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarIcon } from "src/assets/img/calendar.svg";
import { Wrapper } from "./styled";

interface Props {
  dateFormat?: string;
  selected?: Date;
  onChange?: (value: Date) => void;
  placeholder?: string;
  label?: string;
  timeSelect?: boolean;
}

const Calendar: React.FC<Props> = ({
  dateFormat,
  selected,
  placeholder,
  label,
  onChange,
  timeSelect = true,
}) => {
  return (
    <Wrapper>
      <div className="calendar-wrapper">
        {label ? <p className="label">{label}</p> : null}
        <DatePicker
          selected={selected}
          onChange={(date: Date) => {
            if (onChange) onChange(date);
          }}
          showTimeSelect={timeSelect}
          dateFormat={dateFormat}
          placeholderText={placeholder}
        />
        <CalendarIcon className="icon-calendar" />
      </div>
    </Wrapper>
  );
};

export default Calendar;
