interface PromotionTypes {
  value: number | string;
  label: string;
}
interface PromotionCategories {
  value: number | string;
  label: string;
}

export interface PromotionState {
  promotionTypes: PromotionTypes[];
  promotionCategories: PromotionCategories[];
  promotionTypesEnable: boolean;
  promotionCategoriesEnable: boolean;
}

export enum PromotionActionTypes {
  GET_PROMOTION = "GET_PROMOTION",
}

interface GetPromotionAction {
  type: PromotionActionTypes.GET_PROMOTION;
  payload: {
    promotionTypes: PromotionTypes[];
    promotionCategories: PromotionCategories[];
  };
}

export type PromotionAction = GetPromotionAction;
