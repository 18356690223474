import axios from "axios";
import { checkSignIn } from "src/api/auth";
import { store } from "src/store";
import { AuthUserActionTypes } from "src/types/auth";

export const checkLoginUser = (): any => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get("account_info_token");

    if (token) {
        localStorage.setItem("bearer_token", token);
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        window.location.replace("/");
    }

    if (localStorage.getItem("bearer_token")) {
        checkSignIn()
            .then((response) => {
                store.dispatch({
                    type: AuthUserActionTypes.LOGIN_CHECK_FETCH,
                    payload: response.data,
                });
            })
            .catch((error) => {
                store.dispatch({
                    type: AuthUserActionTypes.LOGIN_ERROR,
                    payload: error.data.message,
                });
            });
    }
};
