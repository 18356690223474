import axios from "src/api/api";
import {
  AuthUserAction,
  AuthUserActionTypes,
  AuthUserState,
} from "src/types/auth";

const initialState: AuthUserState = {
  loggedUser: null,
  loading: false,
  error: null,
};

export const authReducer = (
  state = initialState,
  action: AuthUserAction
): AuthUserState => {
  switch (action.type) {
    case AuthUserActionTypes.LOGIN: {
      return { loggedUser: null, loading: true, error: null };
    }
    case AuthUserActionTypes.LOGIN_SUCCESS: {
      localStorage.setItem("bearer_token", action.payload.bearer_token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("place_confirmed", action.payload.place_confirmed);
      axios.defaults.headers.common.Authorization = `Bearer ${action.payload.bearer_token}`;
      window.location.replace("/");
      return { loggedUser: action.payload, loading: false, error: null };
    }
    case AuthUserActionTypes.LOGIN_ERROR:
      return { loggedUser: null, loading: false, error: action.payload };
    case AuthUserActionTypes.LOGIN_CHECK: {
      let logedUser = null;
      if (
        localStorage.getItem("bearer_token") &&
        localStorage.getItem("user") &&
        localStorage.getItem("place_confirmed")
      ) {
        logedUser = {
          token: localStorage.getItem("bearer_token"),
          user: JSON.parse(localStorage.getItem("user") ?? ""),
          place_confirmed: localStorage.getItem("place_confirmed"),
        };
      }
      return { loggedUser: logedUser, loading: false, error: null };
    }
    case AuthUserActionTypes.LOGIN_CHECK_FETCH: {
      let logedUser = null;
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("place_confirmed", action.payload.place_confirmed);
      logedUser = {
        token: localStorage.getItem("bearer_token"),
        user: action.payload.user,
        place_confirmed: action.payload.place_confirmed,
      };
      return { loggedUser: logedUser, loading: false, error: null };
    }

    case AuthUserActionTypes.LOGOUT: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case AuthUserActionTypes.LOGOUT_SUCCESS: {
      localStorage.clear();
      axios.defaults.headers.common.Authorization = `Bearer null`;
      window.location.replace("/login");
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case AuthUserActionTypes.LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AuthUserActionTypes.UPDATE_PHOTO_SUCCESS: {
      const newLogedUser = state.loggedUser;
      newLogedUser.user.photo = action.payload;

      localStorage.setItem("user", JSON.stringify(newLogedUser.user));
      return {
        ...state,
        loggedUser: newLogedUser,
      };
    }
    case AuthUserActionTypes.UPDATE_PERSONAL_DETAILS_SUCCESS: {
      const newLogedUser = state.loggedUser;
      newLogedUser.user = action.payload;

      localStorage.setItem("user", JSON.stringify(newLogedUser.user));
      return {
        ...state,
        loggedUser: newLogedUser,
      };
    }
    case AuthUserActionTypes.UPDATE_SUBSCRIPTION_SUCCESS: {
      const newLogedUser = state.loggedUser;
      newLogedUser.user.subscription = action.payload;

      localStorage.setItem("user", JSON.stringify(newLogedUser.user));
      return {
        ...state,
        loggedUser: newLogedUser,
      };
    }

    default:
      return state;
  }
};
