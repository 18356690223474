import styled from "styled-components";
import { Color } from "src/types/color";

export const Wrapper = styled.div<{
  required?: boolean;
  isLabel?: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  & > .label:not(:empty) {
    color: ${Color.blueDarkText};
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
    /* &:after {
      content: ${(props) => (props.required ? `" *"` : "none")};
      color: #eb4d4b;
    } */
  }

  & > input {
    border-radius: 4px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    padding: 13px 16px 13px 45px;
    font-size: 18px;
    line-height: 20px;
    background: #ffffff;

    &::placeholder {
      color: #95989d;
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.07);
      box-shadow: none;
    }
  }

  .info-tooltip {
    position: absolute;
    right: 10px;
    bottom: 11px;
    width: 24px;
    height: 24px;
    margin: auto;
    cursor: pointer;
    transition: 0.3s all ease;
    .tooltip {
      transition: 0.3s all ease;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      bottom: 40px;
      right: -10px;
      width: 300px;
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      color: ${Color.white};
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 15px;
      padding: 10px;
      border-radius: 6px;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        right: 17px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${Color.blue} transparent transparent transparent;
      }
    }
    & svg path.circle {
      fill: #000d46;
      opacity: 0.6;
    }
    &:hover {
      svg path.circle {
        fill: ${Color.blue};
        opacity: 1;
      }
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
    & + input {
      padding-right: 35px;
    }
  }

  .place-point-icon {
    position: absolute;
    line-height: 0;
    bottom: 13px;
    left: 10px;
    & > svg {
      path {
        fill: #000d46;
        opacity: 0.6;
      }
    }
  }
`;
