import styled from "styled-components";
import { Color } from "src/types/color";

export const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);
  .create-password-content {
    max-width: 450px;
    box-sizing: border-box;
    form {
      text-align: center;
      padding: 30px 20px;
      .form-group {
        margin-bottom: 25px;
      }
    }
    .title {
      color: ${Color.blueDarkText};
      font-size: 40px;
      font-weight: 500;
      line-height: 51px;
      margin-bottom: 10px;
      @media (max-width: 600px) {
        font-size: 26px;
        line-height: 32px;
      }
    }
    .description {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 300;
      line-height: 26px;
      margin-bottom: 25px;
    }
  }
  .lynked-form {
    .form-error-message {
      margin-top: 5px;
    }
  }
`;
