import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
    .stamp-based-wrapper {
        width: 100%;
        flex-direction: row;
        align-items: start;
        justify-content: space-between;
        position: relative;
        .header {
            flex-direction: column;
            width: 70%;
            .header-tab {
                height: auto;
                display: flex;
                justify-content: flex-start;
                gap: 2%;

                .column {
                    width: 25%;
                }
            }
            .stamp-based-form {
                display: flex;
                justify-content: space-between;
                .table {
                    width: 100%;
                    .rdt_TableRow{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: 12px 0 0;
                        gap: 2%;

                        .sc-idiyUo{
                            position: absolute;
                            z-index: 9999;
                            left: -10px;
                            margin-bottom: 12px;
                        }
                        .rdt_TableCell{
                            width: 25%;
                            padding-left: 0;
                            &>div{
                                width: 100%;
                                input{
                                    width: 80%;
                                    padding-right: 40px;
                                }

                            }
                            .select{
                                margin-bottom: 12px;
                            }
                            &:nth-last-child(1){
                                width: 8.5%;
                                margin-bottom: 12px;
                                .buttons{
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    button{
                                        width: 45%;
                                        padding: 7.5px 15px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                    .save-btn{
                                        width: 100%;
                                        padding: 9.5px 10px;
                                    }
                                }

                            }
                        }
                    }



                }
                .table-edit{
                    &>div{
                        padding-bottom: 90px;
                        .select{
                            .bottom{
                                max-height: 80px;
                                width: 120%;
                            }
                        }
                    }
                }
                .modal-content{
                    text-align: center;
                    padding: 60px 50px;
                    @media (max-width: 600px) {
                        padding: 30px 15px;
                    }
                    .title {
                        color: ${Color.blueDarkText};
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 38px;
                        text-align: center;
                        @media (max-width: 600px) {
                            font-size: 23px;
                            line-height: 28px;
                        }
                    }
                    .sub-title{
                        color: ${Color.blueDarkText};
                        font-size: 25px;
                        font-weight: 500;
                        line-height: 38px;
                        text-align: center;
                        @media (max-width: 600px) {
                            font-size: 23px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }

    }
    .header {
        display: flex;
        align-items: stretch;
        //justify-content: space-between;
        margin-bottom: 15px;
        gap: 15px;
        .column {
            width: 11.4%;

            //.column-group{
            //  display: flex;
            //  //justify-content: space-between;
            //  flex-wrap: nowrap;
            //  gap: 15px;
            //  .column {
            //    width: 50%;
            //  }
            //}
            .page-title {
                font-size: 16px;
                margin: 0 0 10px 0;
                line-height: normal;
            }
            .description{
                font-size: 12px;
                line-height: normal;
            }
            //&:nth-last-child(2){
            //  width: 29%;
            //}

        }
        .column_buttons {
            width: 10%;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: inherit;
        padding-top: 20px;
        width: 100%;
        .item{
            display: flex;
            justify-content: space-between;
            &>div{
                width: 15%;
                input{
                    width: 70%;
                    padding-right: 40px;
                }
                &:nth-last-child(1){
                    width: 8.5%;
                    margin: 0;
                }
            }

        }
    }
    .add-stampCategory{
        margin: 5px 2px;
        width: 30%;
        justify-self: start;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 23px;
                height: 23px;
            }
        }
    }
`;

export { Wrapper };
