import React, { FC } from "react";
import { useLocation, Link } from "react-router-dom";

import Modal from "src/components/Modal";
import Input from "src/components/Input";
import { ResetPasswordRequest } from "src/types/auth";
import { resetPassword } from "src/api/auth";

import { ReactComponent as LogoFull } from "src/assets/img/logo-full.svg";
import checkEmailImg from "src/assets/img/check-your-email.svg";

import { Wrapper } from "./styled";

const ResetPassword: FC = () => {
  const location: any = useLocation();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [successResponse, setSuccessResponse] = React.useState<string>("");
  const [errorResponse, setErrorResponse] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] =
    React.useState<string>("");

  const handleResetPassword = (event: React.FormEvent) => {
    event.preventDefault();
    if (password === passwordConfirmation) {
      setLoading(true);

      const resetPasswordRequest: ResetPasswordRequest = {
        password,
        password_confirm: passwordConfirmation,
        token: new URLSearchParams(location.search).get("token") ?? "",
      };

      resetPassword(resetPasswordRequest)
        .then((response) => {
          setSuccessResponse(
            response?.data?.message ?? "Password has been changed"
          );
          setLoading(false);
        })
        .catch((error) => {
          setErrorResponse(error?.data?.message ?? "Server error");
          setLoading(false);
        });
    } else {
      setErrorResponse("The password confirmation does not match");
    }
  };

  return (
    <Wrapper>
      <div className="inner-wrap">
        <div className="logo">
          <LogoFull />
        </div>
        <form
          onSubmit={handleResetPassword}
          className={`${loading ? "loading-blue" : ""}`}
        >
          <div className="form-innerwrap">
            <div className="title">Reset Password</div>
            <div className="form-group">
              <Input
                type="password"
                placeholder="Password"
                required
                getValue={(value) => {
                  if (errorResponse) setErrorResponse("");
                  setPassword(value);
                }}
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                placeholder="Confirm Password"
                required
                getValue={(value) => {
                  if (errorResponse) setErrorResponse("");
                  setPasswordConfirmation(value);
                }}
              />
            </div>
            {errorResponse && (
              <div className="form-error-message">{errorResponse}</div>
            )}
            <button type="submit" className="btn-blue">
              Reset password
            </button>
          </div>
        </form>
      </div>
      {successResponse && (
        <Modal
          openModal={!!successResponse}
          setOpenModal={() => setSuccessResponse("")}
          closeOutsideClick={false}
        >
          <div className="modal-check-email-content">
            <img src={checkEmailImg} alt="" />
            <div className="title">{successResponse}</div>
            <Link to="/login" className="btn-blue login-btn">
              Login
            </Link>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default ResetPassword;
