import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import { createLightSpeed, deleteLightSpeed, getLightSpeed} from "../../api/lightSpeed";
import { ReactComponent as CirclePlus } from "../../assets/img//circle-plus.svg";
import Input from "../../components/Input";
import DataTable from "react-data-table-component";
import { ReactComponent as CloseIcon } from "../../assets/img/clear.svg";
import { ReactComponent as CheckCircle } from "../../assets/img/checkCircle.svg";
import { ReactComponent as Edit } from "../../assets/img/edit.svg";
import { ReactComponent as Delete } from "../../assets/img/delete.svg";
import Modal from "../../components/Modal";

const ExternalPosIntegration = () => {
  const [lightSpeedBuisnes, setLightSpeedBuisnes] = useState([]);
  const [clientIdInput, setClientIdinput] = useState("");
  const [clientSecretInput, setClientSecretinput] = useState("");
  const [domainPrefInput, setDomainPrefInput] = useState("");
  const [addLightSpeed, setAddLightSpeed] = useState(false);
  useEffect(() => {
    getAllLightSpeed()
  }, []);

  const getAllLightSpeed = () => {
    getLightSpeed().then((res) => {
      setLightSpeedBuisnes(res.data.lightspeedAccounts);
    });
  }

  const handleAddLightSpeed = (event: React.FormEvent) => {
    event.preventDefault();
    const requestObject = {
      clientId: clientIdInput,
      clientSecret: clientSecretInput,
      domainPrefix: domainPrefInput
    };
    closeAddForm()
    createLightSpeed(requestObject).then(res => {
      getAllLightSpeed()
    });
  };
  const [deleteLightSpeedItem, setDeleteLightSpeedItem] = useState<any>(null);

  const closeAddForm = () => {
    setAddLightSpeed(false)
    setClientIdinput('')
    setClientSecretinput('')
    setDomainPrefInput('')
  }

  const saveLightSpeedItem = (id: number) =>{
    const newItems: any = lightSpeedBuisnes.filter((item: any) => item.id === id)[0];
    newItems.edit = false;

    const requestObject = {
      id: newItems.id,
      clientId: newItems.clientId,
      clientSecret: newItems.clientSecret,
      domainPrefix: newItems.domainPrefix
    };
    // setEditStamp(false);
    createLightSpeed(requestObject).then(res => {
      getAllLightSpeed()
    });
  }
  const editLightSpeedItem = (id: number) => {
    const newItems: any[] = lightSpeedBuisnes;
    newItems[id]!.edit = true;
    // @ts-ignore
    setLightSpeedBuisnes((stamp: any) => stamp = [...newItems]);
  };

  const selectRemoveLightSpeed = (id: number) => {
    // @ts-ignore
    setDeleteLightSpeedItem(lightSpeedBuisnes.find(item => item.id === id))
  }
  const removeLightSpeedItem = () => {
    // @ts-ignore
    deleteLightSpeed({id: deleteLightSpeedItem.id})
      .then((res) => {
        getAllLightSpeed();
        setDeleteLightSpeedItem(null)
      }).catch(e => {
      console.log(e);
    });
  };

  const categoryList = [
    {
      name: "",
      width: "50%",
      cell: (item: any): JSX.Element => {
        return (
          <Input
            defaultValue={item.domainPrefix}
            getValue={(value) => item.domainPrefix = value}
            disabled={!item.edit}
          />
        );
      }
    },
    {
      name: "",
      maxWidth: "20%",
      cell: (item: any): JSX.Element => {
        return (
          <a className={item.accessToken ? 'btn-blue disabled' : 'btn-blue'} target={"_blank"} href={item.confirmUrl} rel="noreferrer">{item.accessToken ? 'Confirmed' : 'Confirm'}</a>
        );
      }
    },
    {
      name: "",
      maxWidth: "15%",
      cell: (item: any, index: number): JSX.Element => {
        return (
          <div className="buttons" data-tag="allowRowEvents">
            {item.edit && item.id ?
              <button className="btn-white edit-category save-btn" onClick={() => saveLightSpeedItem(item.id)}>
                    <span className="icon">
                      <CheckCircle />
                    </span>
                <span>Save</span>
              </button>
              : <>
                <button className="btn-white edit-category" onClick={() => editLightSpeedItem(index)}>
                      <span className="icon">
                        <Edit />
                      </span>
                </button>
                <button className="btn-white remove-category" onClick={() => selectRemoveLightSpeed(item.id)}>
                  <span className="icon">
                      <Delete />
                      </span>
                </button>
              </>}

          </div>
        );
      }
    }
  ];
  return (
    <Wrapper>
      <h1>External Pos Integration</h1>
      <div className="locations">
        <button className="btn-white add-stampCategory" onClick={() => {
          setAddLightSpeed(true);
        }}>
            <span className="icon">
              <CirclePlus />
            </span>
          <span>Add Pos Integration</span>
        </button>
        {addLightSpeed ? (
          <div className="create-location">
            <form
              onSubmit={handleAddLightSpeed}
            >
              <div className="loading-innerwrap">
                <div className="title-close">
                  <div className="title">Create new LightSpeed</div>
                  <button
                    type="button"
                    className="close"
                    onClick={closeAddForm}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="add-location-wrapper">
                  <div className="left-block">
                    <div className="input-wrap">
                      <Input
                        required
                        placeholder="Please enter domainPrefix"
                        getValue={(value) => setDomainPrefInput(value)
                        }
                      />
                    </div>
                  </div>
                  <button type="submit" className="btn-blue">
                    Add LightSpeed
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </div>
      <div className="content">
        <DataTable
          noTableHead
          noHeader
          columns={categoryList}
          data={lightSpeedBuisnes}
        />
      </div>
      {deleteLightSpeedItem && (
        <Modal
          openModal={!!deleteLightSpeedItem}
          setOpenModal={() => setDeleteLightSpeedItem(null)}
          closeOutsideClick
        >
          <div
            className={'remove-modal'}
          >
            <div className="loading-innerwrap">
              <div className="title">
                Please confirm delete light Speed &quot;
                {deleteLightSpeedItem.clientId}
              </div>
              <button
                type="button"
                className="btn-blue"
                onClick={() => {
                  removeLightSpeedItem();
                }}
              >
                Confirm delete
              </button>
            </div>
          </div>
        </Modal>
      )}


    </Wrapper>
  );
};

export default ExternalPosIntegration;
