import React, {useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import Input from "../Input";
import moment from "moment/moment";
import { ReactComponent as CalendarIcon } from "src/assets/img/calendar.svg";
import Select from "../Select";
import {SelectorListObj} from "../Select/types";
import DateRangePicker from "react-bootstrap-daterangepicker";

interface IPointsCollectionSettings {
  pointsCollectionSettingsObj: { promotionPointsValue: number,
    promotionPointsType: string,
    promotionPointsStartDate: Date | string,
    promotionPointsEndDate: Date | string,
    promotionPointsSelectedTypeValue: string};
  setPointsCollectionSettingsObj: (settings: any) => void;
}

const PointsCollectionSettings = ({setPointsCollectionSettingsObj, pointsCollectionSettingsObj}:IPointsCollectionSettings) => {
  const selectorTypeList : SelectorListObj[] = [
    {value: 1, label: 'Date Period'},
    {value: 2, label: 'Quantity Customers'},
    {value: 3, label: 'Transactions'},
  ]
  const [dates, setDatesState] = useState({
    startDate:moment(pointsCollectionSettingsObj.promotionPointsStartDate).format("MMM D, YYYY"),
    endDate: moment(pointsCollectionSettingsObj.promotionPointsEndDate).format("MMM D, YYYY")
  });
  const { loggedUser } = useTypedSelector((state) => state.auth);
  
  const setDates = (e: any, { startDate, endDate }: any) => {
    endDate >= moment() && startDate >= moment() ? setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY")
    }) : endDate >= moment() &&  startDate <= moment() ? setDatesState({
      startDate: moment().format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY")
    }) : setDatesState({
      startDate: moment().format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    });
  };
  
  useEffect(() => {
    setPointsCollectionSettingsObj({...pointsCollectionSettingsObj, promotionPointsStartDate: moment(dates.startDate).format('YYYY-MM-DD'), promotionPointsEndDate: moment(dates.endDate).format('YYYY-MM-DD')});
  }, [dates, setPointsCollectionSettingsObj]);
  
  const ranges = {
    "Today": [moment(), moment()],
    "Last 7 Days": [moment().subtract(1, "weeks"), moment()],
    "Last 30 Days": [moment().subtract(1, "months"), moment()],
    "Last Year": [moment().subtract(1, "years"), moment()],
  };
  
  return (
    <Wrapper>
      <div className="settingsPoint_header">
        <p className="title">
          Setting up the collection of points for 1{loggedUser?.user?.currency_symbol || '€'}
        </p>
        <div className="line"/>
      </div>
      <div className="settingsPoint_content">
        <div className="reward_block">
          <div className="reward_inputs">
            <Input
              required
              type="number"
              label={`Number of points for 1${loggedUser?.user?.currency_symbol || '€'}`}
              infoTooltip="Enter the value"
              getValue={(value) => {
                setPointsCollectionSettingsObj({...pointsCollectionSettingsObj, promotionPointsValue: value})
              }}
              defaultValue={pointsCollectionSettingsObj.promotionPointsValue}
            />
            <div className="form-group category-group">
            <Select
              selectorList={selectorTypeList}
              positionForShow={"bottom"}
              label="Apply settings for"
              defaultValue={selectorTypeList.find(item=>+item.value === +pointsCollectionSettingsObj.promotionPointsType)}
              required
              hideSelectorFilter
              getValue={(value) => {
                setPointsCollectionSettingsObj({...pointsCollectionSettingsObj, promotionPointsType: value})
              }}/>
            </div>
          </div>
        </div>
        {+pointsCollectionSettingsObj.promotionPointsType === 1 ? (<div className={'filters'}>
          <DateRangePicker
            onApply={setDates}
            initialSettings={{ startDate: new Date(), endDate: moment(new Date()).add(7, "days"), ranges: ranges }}
          
          >
            <div className={'isSelect select calendarSelect'}>
              <CalendarIcon/>
              <input
                type="text"
                value={dates.startDate + "-" + dates.endDate}
                onChange={(e) => {
                  // @ts-ignore
                  setDates(e, dates)
                }}
                className="form-control"
              />
            </div>
          
          </DateRangePicker>
        </div>) : (<>
          <Input
            required
            type="number"
            label={`${+pointsCollectionSettingsObj.promotionPointsType === 2 ? 'Enter the number of customers' : 'Enter the number of transactions'}`}
            getValue={(value) => {
              setPointsCollectionSettingsObj({...pointsCollectionSettingsObj, promotionPointsSelectedTypeValue: value})
            }}
            defaultValue={pointsCollectionSettingsObj.promotionPointsSelectedTypeValue}
          />
        </>)}
      </div>
    
    </Wrapper>
  );
};

export default PointsCollectionSettings;
