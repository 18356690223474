import styled, { keyframes } from "styled-components";
import { Color } from "src/types/color";

const uploadKeyFrames = keyframes`
  0% {
    transform: translateY(8px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(8px);
  }
`;

const Wrapper = styled.div`
  flex-grow: 1;
  max-height: inherit;
  overflow-y: auto;
  padding: 50px 40px 100px 100px;
  color: ${Color.blueDarkText};
  background-color: rgba(0, 13, 70, 0.03);

  .checkbox_links {
    background: linear-gradient(0deg,
      ${Color.gradientFreeCardFrom} -16.46%,
    ${Color.gradientFreeCardTo} 98.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 1200px) {
    padding: 40px 30px 40px 50px;
  }
  @media (max-width: 600px) {
    padding: 40px 15px;
  }

  .switch_label {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
    color: ${Color.blueDarkText};
  }

  .title-block {
    margin-bottom: 30px;

    .title {
      color: ${Color.blueDarkText};
      font-size: 48px;
      font-weight: 500;
      line-height: 61px;
      margin-bottom: 10px;
      @media (max-width: 768px) {
        font-size: 32px;
        line-height: 42px;
      }
    }

    .sub-title {
      opacity: 0.6;
      color: ${Color.blueDarkText};
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
      max-width: 600px;
    }
  }

  .build_card_wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .build_card_left {
      flex-basis: 50%;
      border-right: 1px solid #d8d8d8;
      padding-right: 40px;

      .label {
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 10px;
        text-align: left;
      }

      .form-group {
        margin-bottom: 30px;

        .dropZone {
          display: flex;
          flex-direction: column;
          height: 100%;

          & > div {
            position: relative;
            height: 100%;
            border: 1.5px dashed ${Color.blueDarkText};
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.09);
            outline: none;
            padding: 30px;
            cursor: pointer;
            transition: all 0.225s cubic-bezier(0.77, 0.2, 0.05, 1);

            &.active_true {
              border-color: ${Color.blueDarkText};
              box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 35%);
            }

            input {
              max-width: 100%;
              display: block !important;
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              margin: auto;
              opacity: 0;
              z-index: -1;
            }

            & > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100%;

              & > svg {
                margin-bottom: 5px;
                animation: ${uploadKeyFrames} 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

                path:last-child {
                  fill: ${Color.blueDarkText};
                }
              }

              .text {
                color: ${Color.blueDarkText};
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                  margin-bottom: 5px;
                }

                .title {
                  font-size: 18px;
                  font-weight: 300;
                  line-height: 23px;
                }

                .description {
                  opacity: 0.6;
                  font-size: 12px;
                  font-weight: 300;
                  line-height: 15px;
                }

                .file-name {
                  font-size: 21px;
                  font-weight: 300;
                  line-height: 23px;
                }
              }
            }
          }
        }

        .control_switch {
          position: relative;
          display: flex;
          align-items: center;
          max-width: 190px;
          padding: 2px;
          border: 1px solid #e4e4e4;
          border-radius: 4px;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
          background-color: white;
          overflow: hidden;

          &::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 48%;
            height: 90%;
            background: linear-gradient(0deg,
              ${Color.gradientFreeCardFrom} -16.46%,
            ${Color.gradientFreeCardTo} 98.11%);
            border-radius: 4px;
            transition: left 0.3s ease;
          }

          &_right {
            &::before {
              left: 51%;
              transition: left 0.3s ease;
            }
          }
        }

        .btn_switch {
          width: 50%;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 20px;
          color: ${Color.greyLight};
          padding: 9px 18px;
          z-index: 2;
          transition: color 0.3s ease;
          background-color: transparent;

          &_active {
            color: ${Color.white};
          }
        }

        .checkbox-view {
          background-color: ${Color.blueDarkText};
        }
      }

      .form-group_two {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;

        & > div:first-child {
          flex-basis: 70%;
        }

        & > div:last-child {
          width: 150px;

          & > * {
            margin-bottom: 0;
          }
        }

        .or {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
        }

        @media (max-width: 550px) {
          flex-wrap: wrap;
          margin-top: 30px;
          & > div:first-child {
            flex-basis: 95%;
          }

          & > div:last-child {
            width: 150px;

            & > * {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .build_card_right {
      flex-basis: 50%;
      position: sticky;
      top: -25px;
      height: 100%;

      .card-preview {
        overflow: hidden;
        width: 335px;
        border-radius: 15px;
        background-color: ${Color.white};
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
        padding: 25px;
        padding-bottom: 0;
        box-sizing: border-box;

        .top {
          display: flex;
          align-items: flex-start;
          margin-bottom: 25px;

          .logo {
            line-height: 0;
            margin-right: 15px;

            img {
              object-fit: cover;
              height: 60px;
              width: 60px;
              border-radius: 100%;
            }
          }

          .right {
            width: calc(100% - 75px);
          }

          .name-notificationCount {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 4px;

            .name {
              font-family: "Averta Std ExtraBold";
              font-size: 16px;
              line-height: 19px;
              color: #2f3640;
            }

            .notification-count {
              display: flex;
              align-items: center;

              .notification {
                margin-right: 15px;
              }

              .reward_level {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid ${Color.blue};
                border-radius: 50%;
                width: 18px;
                height: 18px;
                background-color: ${Color.white};
                padding-top: 1px;
                box-sizing: border-box;
                color: ${Color.blue};
                font-size: 12px;
                font-weight: 300;
              }

              @media (max-width: 400px) {
                flex-wrap: wrap;
                .notification {
                  flex-basis: 100%;
                  margin-right: 0px;
                }

                .count {
                  flex-basis: 100%;
                }
              }
            }
          }

          .info {
            font-family: "Averta Std Semibold";

            .title {
              color: #bcbcbc;
              font-size: 12px;
              font-weight: 500;
              line-height: 14px;
              margin-right: 3px;
            }

            .content {
              color: ${Color.grey};
              font-size: 12px;
              font-weight: 500;
              line-height: 14px;
            }
          }
        }

        .promotion-count {
          display: flex;
          align-items: center;
          gap: 10px;
          flex-wrap: wrap;

          .promotion-item {
            width: 49px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
            }

            .promotion-item-icon {
              margin: auto;
              width: max-content;
            }

            .promotion-item-icon-number {
              font-family: "Averta Std ExtraBold";
              color: #ffffff;
              position: absolute;
              top: -1px;
              left: -3px;
              font-size: 9px;
              width: -webkit-fill-available;
              height: -webkit-fill-available;
              text-align: center;
              line-height: 49px;
            }
          }

          .promotion_done {
            width: 49px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .points-progress-line {
            width: 100%;
            display: flex;

            .progress-line {
              width: calc(100% - 85px);
              margin-right: 10px;
              border-radius: 4px;
              color: ${Color.white};
              font-size: 14px;
              font-weight: 300;
              line-height: 18px;
              padding: 8px 15px;
              /* &.full { */
              background: #5771e1;
              /* } */
                /* &.empty {
                background-color: #dcdef7;
                color: ${Color.blueDarkText};
              } */
            }

            .progress-done {
              position: relative;
              line-height: 0;
              width: 75px;

              .text {
                position: absolute;
                top: 7px;
                left: 5px;
                color: ${Color.white};
                font-size: 10px;
                font-weight: 300;
                line-height: 11px;
                width: 45px;
                word-wrap: break-word;
              }

              .dark-text {
                color: ${Color.blueDarkText};
              }
            }
          }
        }

        &.full {
          .promotion-count {
            .promotion-item {
              svg {
                g {
                  opacity: 1;
                }

                path {
                  fill: ${Color.blue};
                }
              }
            }
          }
        }

        @media (max-width: 400px) {
          padding: 20px 10px;
        }

        .progress_wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-top: 8px;
        }

        .progress_line_wrapper {
          flex-grow: 1;
        }

        .progress_reward {
          display: flex;
          width: 70px;
          height: 32px;
          margin-left: 20px;
          text-transform: uppercase;
          color: ${Color.white};
          border-radius: 4px;
          overflow: hidden;

          .progress_reward_off {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            background-color: ${Color.orange};
            width: 16px;
            font-size: 6px;
            writing-mode: vertical-rl;
          }

          .progress_reward_one {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${Color.blue};
            font-size: 9px;
            font-weight: 500;
            padding: 4px;
          }
        }

        .progress_count {
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          margin-bottom: 10px;
          color: ${Color.greyMedium};
        }

        .progress_indicator {
          width: 100%;
          appearance: none;
          border-radius: 4px;

          &::-webkit-progress-value {
            background: linear-gradient(
              0deg,
              ${Color.gradientFreeCardFrom} -16.46%,
              ${Color.gradientFreeCardTo} 98.11%
            );
            border-radius: 4px;
            height: 12px;
          }

          &::-webkit-progress-bar {
            background-color: rgba(81, 92, 221, 0.2);
            border-radius: 4px;
            height: 12px;
          }
        }
      }
    }

    @media (max-width: 1100px) {
      flex-wrap: wrap;
      .build_card_left {
        flex-basis: 100%;
        order: 2;
        padding: 0px 5px;
        border-right: none;
      }

      .build_card_right {
        flex-basis: 100%;
        position: block !important;
        top: auto;

        & > div {
          margin: 0 auto;
        }
      }
    }
  }

  .card-bottom {
    height: 45px;
    background: linear-gradient(
      0deg,
      ${Color.gradientFreeCardFrom} -16.46%,
      ${Color.gradientFreeCardTo} 98.11%
    );
    margin-left: -25px;
    margin-right: -25px;
    position: relative;

    .card-bottom-control-button {
      position: absolute;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
        0deg,
        ${Color.gradientFreeCardFrom} -16.46%,
        ${Color.gradientFreeCardTo} 98.11%
      );
      border: 3px solid white;
      top: 0px;
      left: calc(50% - 13px);
      padding: 0;
    }

    .bottom-top {
      position: absolute;
      top: 0;
      width: 100%;
      height: 15px;
      border-radius: 0px 0px 15px 15px;
      background-color: white;
    }

    // &::after {
    //   position: absolute;
    //   display: block;
    //   content: "";
    //   border-radius: 50%;
    //   width: 35px;
    //   height: 35px;
    //   top: -35px;
    //   left: 0;
    //   box-shadow: -15px 15px 0 blue;
    // }
    // &::before {
    //   position: absolute;
    //   display: block;
    //   content: "";
    //   border-radius: 50%;
    //   width: 35px;
    //   height: 35px;
    //   top: -35px;
    //   right: 0;
    //   box-shadow: 15px 15px 0 blue;
    // }
  }

  .card-closed {
    margin-bottom: 0;
    max-height: 1px;
    overflow: hidden;
    transition: max-height 0.5s ease-in, margin-bottom 0.3s ease-out;
  }

  .card-opened {
    margin-bottom: 14px;
    max-height: 150px;
    transition: max-height 0.5s ease-in, margin-bottom 0.2s ease-out;
  }

  .card-bottom-control-button:hover {
    transition: transform 0.8s ease-in-out;
    background: #ec9345;
  }

  .card-button-closed {
    .card-bottom-control-button {
      transition: transform 0.8s ease-in-out;
      transform: rotate(180deg);
    }
  }

  .card-button-opened {
    .card-bottom-control-button {
      transform: rotate(360deg);
    }
  }

  .lynked-form {
    .form-error-message {
      margin-top: 5px;
    }

    .form_title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .form_sub_title {
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      opacity: 0.6;
    }

    .reward_block {
      margin: 30px 0 45px;
    }

    .reward_label_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      @media (max-width: 450px) {
        flex-wrap: wrap;
      }
    }

    .reward_label {
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      margin-right: 10px;
    }

    .reward_inputs {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      & > * {
        margin: 5px 0;
        max-width: 225px;
      }

      & > *:last-child {
        flex-basis: 100%;
        max-width: 100%;
      }

      .isSelect {
        width: 100%;
        padding-bottom: 12px;
      }

      @media (max-width: 1475px) {
        & > * {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
  }
`;

export const DaysWrapperDiv = styled.div`
  position: relative;
`;

export const SpanInputText = styled.span`
  position: absolute;
  top: 24px;
  left: 55px;
  font-size: 18px;
  color: ${Color.blueDarkText};
`

export { Wrapper };
