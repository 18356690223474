import styled from "styled-components";

export const WrapperTableList = styled.div`
  width: calc(100% - 40px);
  padding: 0 20px 10px;

  button {
    background: none;
  }
`;

export const PointWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PointInput = styled.input`
  width: 50px;
  background: none;
  color: #000D46;
  text-align: end;

  &[readonly] {
    cursor: default;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;
