export interface CampaignRequestInterface {
  limit: string;
  page: string;
  search: string | null;
}

export interface CampaignInfoInterface {
  count_campaigns: number;
  limit: number;
  page: number;
  total_pages_count: number;
  total_campaigns_count: number;
}

export interface DeleteCampaignInterface {
  id: number;
}

export interface CampaignInterface {
  id: number;
  name: string;
  group_name: string;
  status: string;
  promotion: number;
  created: string;
}export interface AdminCampaignInterface {
  id: number;
  name: string;
  group_name: string;
  status: string;
  created: string;
  complete: boolean;
}

export interface CampaignsCreateRequestInterface {
  name: string;
  customer_group: number | string;
  send_now: boolean;
  send_date: number;
  end_date: number;
  message: string;
  in_app_notification: boolean;
  push_notification: boolean;
  email_notification: boolean;
  sms_notification: boolean;
  is_promotion: boolean;
}

export interface UpdateCampaignsRequestInterface {
  id: string | undefined;
  name: string;
  customer_group: number | string;
  send_now: boolean;
  send_date: number;
  end_date: number;
  message: string;
  in_app_notification: boolean;
  push_notification: boolean;
  email_notification: boolean;
  sms_notification: boolean;
  is_promotion: boolean;
}

export interface SingleCampaignsRequestInterface {
  id: string;
}

export const campaignsPerPage = [
  {
    value: "10",
    label: "10",
  },
  {
    value: "25",
    label: "25",
  },
  {
    value: "50",
    label: "50",
  },
  {
    value: "100",
    label: "100",
  },
];
