import React, { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";

import СheckboxTable from "src/components/СheckboxTable";
import Input from "src/components/Input";
import Textarea from "src/components/Textarea";
import { ReactComponent as InfoIcon } from "src/assets/img/info.svg";
import { Wrapper as WrapperTooltip } from "src/components/infoTooltip/styled";
import Select from "src/components/Select";
import {
  getFiltersInfoAxios,
  getCustomerListAxios,
  getCustomerListWithFilterGroupsAxios,
  createCustomerGroupAxios,
  updateCustomerGroupAxios,
  getCustomerGroupInfoAxios,
} from "src/api/customers";
import {
  GroupTypeInterface,
  GroupFilterInterface,
  CustomerGroupInterface,
  CustomerInterface,
  CustomerInfoInterface,
  customerPerPage,
  CustomerGroupCreateRequestInterface,
  CustomerGroupChoosedFilterInterface,
} from "src/types/customer";
import userDefaultImg from "src/assets/img/default-user.jpg";
import { ReactComponent as ArrowBackIcon } from "src/assets/img/arrowBack.svg";
import { ReactComponent as ArrowForwardIcon } from "src/assets/img/arrowForward.svg";
import { ReactComponent as DeleteIcon } from "src/assets/img/delete.svg";

import { Wrapper } from "./styled";

interface RouteParams {
  id?: string;
}

const CustomerGroups: FC = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [pageLoading, setPageLoading] = React.useState<boolean>(true);
  const [pageEditLoading, setPageEditLoading] = React.useState<boolean>(true);
  const [pageEdit, setPageEdit] = React.useState<boolean>(false);
  const [customerGroup, setCustomerGroup] =
    React.useState<CustomerGroupInterface>({
      group_name: "",
      group_description: "",
      group_type: null,
    });
  const [groupType, setGroupType] = React.useState<Array<GroupTypeInterface>>(
    []
  );
  const [groupFilter, setGroupFilter] = React.useState<
    Array<GroupFilterInterface>
  >([]);
  const [groupMaxFilter, setGroupMaxFilter] = React.useState<number>(0);

  const getFiltersInfo = () => {
    getFiltersInfoAxios()
      .then((response) => {
        const groupTypeTemp = response.data.group_types.map(
          (item: { id: number; title: string }) => {
            return {
              value: item.id,
              label: item.title,
            };
          }
        );
        const groupFilterTemp = response.data.group_filters.map(
          (item: { id: number; description: string }) => {
            return {
              value: item.id,
              label: item.description,
            };
          }
        );
        setGroupType(groupTypeTemp);
        setGroupFilter(groupFilterTemp);
        setGroupMaxFilter(response.data.filter_max_count);
        setPageLoading(false);
      })
      .catch(() => {});
  };
  const handlesetCustomerGroup = (name: string, value: string) => {
    setCustomerGroup((prevState: any) => {
      return { ...prevState, [name]: value };
    });
  };

  /*   manual customer list   */
  const [manualShowAllCustomer, setManualShowAllCustomer] =
    React.useState<boolean>(true);
  const [manualCustomerListLoading, setManualCustomerListLoading] =
    React.useState<boolean>(false);
  const [manualCustomerList, setManualCustomerList] = React.useState<
    CustomerInterface[]
  >([]);
  const [manualCustomerChoosedList, setManualCustomerChoosedList] =
    React.useState<CustomerInterface[]>([]);
  const [manualCustomerListInfo, setManualCustomerListInfo] =
    React.useState<CustomerInfoInterface>({
      count_users: 0,
      limit: 10,
      page: 1,
      total_pages_count: 0,
      total_users_count: 0,
      // searchType: 0,
    });
  const [manualCustomerAllPageChecked, setManualCustomerAllPageChecked] =
    React.useState<boolean>(false);
  const checkCheckedManualAllUser = () => {
    return false;
  };
  const toggleManualCheckedAllUser = () => {
    if (manualCustomerAllPageChecked) {
      manualCustomerList.forEach((currentValue) => {
        const index = manualCustomerChoosedList.findIndex(
          (item) => item.id === currentValue.id
        );
        if (index >= 0) {
          setManualCustomerChoosedList((prevState) =>
            prevState.filter((item) => item.id !== currentValue.id)
          );
        }
      });
    } else {
      manualCustomerList.forEach((currentValue) => {
        const index = manualCustomerChoosedList.findIndex(
          (item) => item.id === currentValue.id
        );
        if (index < 0) {
          setManualCustomerChoosedList((prevState) => [
            ...prevState,
            currentValue,
          ]);
        }
      });
    }
    setManualCustomerAllPageChecked(!manualCustomerAllPageChecked);
  };
  const getManualCustomerList = () => {
    setManualCustomerListLoading(true);
    const requestParamtrs = {
      limit: manualCustomerListInfo.limit.toString(),
      page: manualCustomerListInfo.page.toString(),
      startDate: new Date(2022, 0, 1),
      endDate: new Date()
    };
    getCustomerListAxios(requestParamtrs).then((response) => {
      setManualCustomerList(response.data.users);
      setManualCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      setManualCustomerListLoading(false);
    });
  };
  const handleManualCustomerPagination = (page: number) => {
    setManualCustomerListLoading(true);
    const requestParamtrs = {
      limit: manualCustomerListInfo.limit.toString(),
      page: page.toString(),
      startDate: new Date(2022, 0, 1),
      endDate: new Date()
    };
    getCustomerListAxios(requestParamtrs).then((response) => {
      setManualCustomerList(response.data.users);
      setManualCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      checkCheckedManualAllUser();
      setManualCustomerListLoading(false);
    });
  };
  const handleManualCustomerPerPage = (perPage: string) => {
    setManualCustomerListLoading(true);
    const requestParamtrs = {
      limit: perPage,
      page: manualCustomerListInfo.page.toString(),
      startDate: new Date(2022, 0, 1),
      endDate: new Date()
    };
    getCustomerListAxios(requestParamtrs).then((response) => {
      setManualCustomerList(response.data.users);
      setManualCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      checkCheckedManualAllUser();
      setManualCustomerListLoading(false);
    });
  };
  const toggleManualCheckedUser = (user: CustomerInterface) => {
    const index = manualCustomerChoosedList.findIndex(
      (item) => item.id === user.id
    );
    if (index < 0) {
      setManualCustomerChoosedList((prevState) => [...prevState, user]);
    } else {
      setManualCustomerChoosedList((prevState) =>
        prevState.filter((item) => item.id !== user.id)
      );
      setManualCustomerAllPageChecked(false);
    }
  };
  const checkCheckedManualUser = (userId: number) => {
    const index = manualCustomerChoosedList.findIndex(
      (item) => item.id === userId
    );
    return !(index < 0);
  };
  const getGroupTypeLabel = (group_type: string) => {
    const curentGroupType = groupType.find((item) => {
      return item.value.toString() === group_type;
    });
    return curentGroupType?.label ? curentGroupType.label : group_type;
  };

  /*   manual customer list   */
  const [choosedFilterList, setChoosedFilterList] = React.useState<
    CustomerGroupChoosedFilterInterface[]
  >([
    {
      virtualId: 0,
      filter_type_id: "",
      value: "",
    },
  ]);
  const [choosedFiltercustomerLoading, setChoosedFiltercustomerLoading] =
    React.useState<boolean>(false);
  const [choosedFilterCustomerList, setChoosedFilterCustomerList] =
    React.useState<CustomerInterface[]>([]);
  const [choosedFilterCustomerListInfo, setChoosedFilterCustomerListInfo] =
    React.useState<CustomerInfoInterface>({
      count_users: 0,
      limit: 10,
      page: 1,
      total_pages_count: 0,
      total_users_count: 0,
      // searchType: 0,
    });
  const getGroupFilterLabel = (filter_type_id: string | number) => {
    const curentGroupFilter = groupFilter.find((item) => {
      return item.value.toString() === filter_type_id;
    });
    return curentGroupFilter?.label
      ? curentGroupFilter.label
      : filter_type_id.toString();
  };
  const addFilterThisGroup = () => {
    setChoosedFilterList((prevState) => [
      ...prevState,
      {
        virtualId:
          choosedFilterList[choosedFilterList.length - 1].virtualId + 1,
        filter_type_id: "",
        value: "",
      },
    ]);
  };
  const deleteFilterThisGroup = (virtualId: number) => {
    setChoosedFilterList((prevState) =>
      prevState.filter((item) => item.virtualId !== virtualId)
    );
  };
  const hendleSetFilterIdChoosedFilterList = (
    value: string,
    virtualId: number
  ) => {
    setChoosedFilterList((prevState) =>
      prevState.map((item) =>
        item.virtualId === virtualId ? { ...item, filter_type_id: value } : item
      )
    );
  };
  const hendleSetDaysChoosedFilterList = (value: string, virtualId: number) => {
    setChoosedFilterList((prevState) =>
      prevState.map((item) =>
        item.virtualId === virtualId ? { ...item, value } : item
      )
    );
  };
  const showCustomersByFilters = (filters = null) => {
    setChoosedFiltercustomerLoading(true);
    const requestParamtrs = {
      limit: manualCustomerListInfo.limit.toString(),
      page: manualCustomerListInfo.page.toString(),
      filters: filters ?? choosedFilterList,
    };
    getCustomerListWithFilterGroupsAxios(requestParamtrs).then((response) => {
      setChoosedFilterCustomerList(response.data.users);
      setChoosedFilterCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      setChoosedFiltercustomerLoading(false);
    });
  };
  const handleChoosedFilterCustomerPagination = (page: number) => {
    setChoosedFiltercustomerLoading(true);
    const requestParamtrs = {
      limit: manualCustomerListInfo.limit.toString(),
      page: page.toString(),
      filters: choosedFilterList,
    };
    getCustomerListWithFilterGroupsAxios(requestParamtrs).then((response) => {
      setChoosedFilterCustomerList(response.data.users);
      setChoosedFilterCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      setChoosedFiltercustomerLoading(false);
    });
  };
  const handleChoosedFilterCustomerPerPage = (perPage: string) => {
    setChoosedFiltercustomerLoading(true);
    const requestParamtrs = {
      limit: perPage,
      page: manualCustomerListInfo.page.toString(),
      filters: choosedFilterList,
    };
    getCustomerListWithFilterGroupsAxios(requestParamtrs).then((response) => {
      setChoosedFilterCustomerList(response.data.users);
      setChoosedFilterCustomerListInfo({
        count_users: Number(response.data.count_users),
        limit: Number(response.data.limit),
        page: Number(response.data.page),
        total_pages_count: Number(response.data.total_pages_count),
        total_users_count: Number(response.data.total_users_count),
        // searchType: Number(response.data.searchType),
      });
      setChoosedFiltercustomerLoading(false);
    });
  };

  /*   Get Data For Edit   */
  const getDataForEditCustomerGroup = () => {
    if (params?.id) {
      setPageEdit(true);
      getCustomerGroupInfoAxios(params.id).then((response) => {
        setCustomerGroup({
          group_name: response.data.name,
          group_description: response.data.description,
          group_type: response.data.type_id.toString(),
        });
        if (response.data.type_id.toString() === "1") {
          setManualCustomerChoosedList(response.data.users);
          setManualShowAllCustomer(false);
        } else if (response.data.type_id.toString() === "2") {
          setChoosedFilterList(response.data.filters);
          showCustomersByFilters(response.data.filters);
        }
        setPageEditLoading(false);
      });
    } else {
      setPageEditLoading(false);
    }
  };

  /* get Data */
  useEffect(() => {
    getDataForEditCustomerGroup();
    getFiltersInfo();
    getManualCustomerList();
  }, []); // eslint-disable-line

  const manualCustomerTableColumns = [
    {
      name: (
        <СheckboxTable
          checked={manualCustomerAllPageChecked}
          toggleChecked={() => toggleManualCheckedAllUser()}
        />
      ),
      minWidth: "50px",
      maxWidth: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <label htmlFor={`user_${row.id}`} className="checkbox">
            <input
              type="checkbox"
              id={`user_${row.id}`}
              checked={checkCheckedManualUser(row.id)}
              onChange={() => toggleManualCheckedUser(row)}
            />
            <span className="checkbox-view" />
          </label>
        );
      },
    },
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      },
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.phone}</div>;
      },
    },
    {
      name: "Points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.end_value ?? "-"}</div>;
      },
    },
    {
      name: "Lifetime points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.total_spend ?? "-"}</div>;
      },
    },
    {
      name: "Check in",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.check_in_count ?? "-"}</div>;
      },
    },
    {
      name: "Last visit",
      minWidth: "120px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{new Date(row.last_visit).toLocaleString("en-GB")}</div>;
      },
    },
  ];

  const manualChoosedCustomerTableColumns = [
    {
      name: "",
      width: "50px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div className="photo">
            {row.photo ? (
              <img src={row.photo} alt="" />
            ) : (
              <img src={userDefaultImg} alt="" />
            )}
          </div>
        );
      },
    },
    {
      name: "Name",
      minWidth: "150px",
      cell: (row: CustomerInterface): JSX.Element => {
        return (
          <div>
            {row.first_name} {row.last_name}
          </div>
        );
      },
    },
    {
      name: "Email",
      minWidth: "225px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.email}</div>;
      },
    },
    {
      name: "Phone",
      minWidth: "175px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.phone}</div>;
      },
    },
    {
      name: "Points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.end_value ?? "-"}</div>;
      },
    },
    {
      name: "Lifetime points",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.total_spend ?? "-"}</div>;
      },
    },
    {
      name: "Check in",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{row.check_in_count ?? "-"}</div>;
      },
    },
    {
      name: "Last visit",
      minWidth: "120px",
      cell: (row: CustomerInterface): JSX.Element => {
        return <div>{new Date(row.last_visit).toLocaleString("en-GB")}</div>;
      },
    },
  ];

  /*   Form   */
  const [customerGroupFormLoading, setCustomerGroupFormLoading] =
    React.useState<boolean>(false);
  const [customerGroupFormErrorMessage, setCustomerGroupFormErrorMessage] =
    React.useState<string>("");
  const handleCustomerGroupForm = (event: React.FormEvent) => {
    event.preventDefault();
    setCustomerGroupFormLoading(true);
    const requestParamtrst: CustomerGroupCreateRequestInterface = {
      group_name: customerGroup.group_name,
      group_description: customerGroup.group_description,
      group_type: customerGroup.group_type,
    };
    if (customerGroup.group_type === "1") {
      requestParamtrst.users = [];
      manualCustomerChoosedList.forEach((currentValue) => {
        requestParamtrst.users?.push(currentValue.id);
      });
    } else if (customerGroup.group_type === "2") {
      requestParamtrst.filters = choosedFilterList;
    }
    if (pageEdit) {
      requestParamtrst.id = params.id;
      updateCustomerGroupAxios(requestParamtrst)
        .then(() => {
          history.push("/customers/customer-groups");
        })
        .catch((error) => {
          setCustomerGroupFormLoading(false);
          setCustomerGroupFormErrorMessage(error.data.message);
        });
    } else {
      createCustomerGroupAxios(requestParamtrst)
        .then(() => {
          history.push("/customers/customer-groups");
        })
        .catch((error) => {
          setCustomerGroupFormLoading(false);
          setCustomerGroupFormErrorMessage(error.data.message);
        });
    }
  };

  return (
    <Wrapper>
      <div className="page-title">
        {pageEdit ? "Edit" : "Create"} Customer Group
        {!pageEdit ? (
          <WrapperTooltip>
            <span className="info-tooltip" style={{ marginTop: -10 }}>
              <InfoIcon />
              <span className="tooltip hiddeArrow">
                You can create a customer group within your customer base with
                certain criteria to create targeted promotions.
              </span>
            </span>
          </WrapperTooltip>
        ) : (
          ""
        )}
      </div>
      <div
        className={`content ${
          pageLoading || pageEditLoading ? "loading-blue" : ""
        }`}
      >
        <div className="loading-innerwrap">
          <form
            onSubmit={handleCustomerGroupForm}
            className={`${
              customerGroupFormLoading
                ? "loading-blue full-width-form"
                : "full-width-form"
            }`}
          >
            <div className="form-innerwrap">
              <div className="form-group">
                <Input
                  placeholder="Our Top customers"
                  label="Customer Group Name"
                  infoTooltip="This name will appear in the Quick Campaign section where you can target specific customers"
                  required
                  defaultValue={customerGroup.group_name}
                  getValue={(value) =>
                    handlesetCustomerGroup("group_name", value)
                  }
                />
              </div>
              <div className="form-group">
                <Textarea
                  label="Description"
                  placeholder="Sales team"
                  required
                  defaultValue={customerGroup.group_description}
                  getValue={(value) =>
                    handlesetCustomerGroup("group_description", value)
                  }
                />
              </div>
              <div className="form-group">
                <Select
                  label="How will customers be grouped?"
                  placeholder="Using Customers Filters"
                  selectorList={groupType}
                  disabled={!(groupType.length > 0)}
                  positionForShow="bottom"
                  required
                  defaultValue={
                    customerGroup?.group_type
                      ? {
                          value: customerGroup?.group_type?.toString() ?? " ",
                          label: getGroupTypeLabel(
                            customerGroup?.group_type?.toString() ?? " "
                          ),
                        }
                      : undefined
                  }
                  getValue={(value) =>
                    handlesetCustomerGroup("group_type", value.toString())
                  }
                  hideSelectorFilter
                />
              </div>
              {customerGroup.group_type === "1" ? (
                <div className="manually-choosing-wrap">
                  <div className="top">
                    <div className="title">Customers</div>
                    <button
                      type="button"
                      className="btn-blue-dashed"
                      onClick={() =>
                        setManualShowAllCustomer(!manualShowAllCustomer)
                      }
                    >
                      {manualShowAllCustomer
                        ? "Show choosen customers"
                        : "Show all customers"}
                    </button>
                  </div>
                  <div className="main">
                    {manualShowAllCustomer ? (
                      <div
                        className={`all-customers ${
                          manualCustomerListLoading ? "loading-blue" : ""
                        }`}
                      >
                        <div className="form-innerwrap">
                          {manualCustomerList.length > 0 ? (
                            <>
                              <div className="table">
                                <DataTable
                                  noHeader
                                  columns={manualCustomerTableColumns}
                                  data={manualCustomerList}
                                />
                              </div>
                              <div className="paginate-prepage">
                                <div className="paginate">
                                  <button
                                    className="prev-page"
                                    type="button"
                                    disabled={manualCustomerListInfo.page <= 1}
                                    onClick={() =>
                                      handleManualCustomerPagination(
                                        manualCustomerListInfo.page - 1
                                      )
                                    }
                                  >
                                    <ArrowBackIcon />
                                  </button>
                                  <button
                                    className="next-page"
                                    type="button"
                                    disabled={
                                      manualCustomerListInfo.total_pages_count <=
                                      manualCustomerListInfo.page
                                    }
                                    onClick={() =>
                                      handleManualCustomerPagination(
                                        manualCustomerListInfo.page + 1
                                      )
                                    }
                                  >
                                    <ArrowForwardIcon />
                                  </button>
                                  <div className="text">
                                    {manualCustomerListInfo.page *
                                      manualCustomerListInfo.limit -
                                      manualCustomerListInfo.limit}
                                    -
                                    {manualCustomerListInfo.page *
                                      manualCustomerListInfo.limit -
                                      manualCustomerListInfo.limit +
                                      manualCustomerListInfo.count_users}{" "}
                                    from{" "}
                                    {manualCustomerListInfo.total_users_count}
                                  </div>
                                </div>
                                <div className="perpage">
                                  <div className="text">Show on page:</div>
                                  <Select
                                    selectorList={customerPerPage}
                                    defaultValue={{
                                      value:
                                        manualCustomerListInfo.limit.toString(),
                                      label:
                                        manualCustomerListInfo.limit.toString(),
                                    }}
                                    positionForShow="top"
                                    required
                                    getValue={(value) => {
                                      handleManualCustomerPerPage(
                                        value.toString()
                                      );
                                    }}
                                    hideSelectorFilter
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="nothing-found">
                              Sorry, nothing found
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {manualCustomerChoosedList.length > 0 ? (
                          <div className="table">
                            <DataTable
                              noHeader
                              columns={manualChoosedCustomerTableColumns}
                              data={manualCustomerChoosedList}
                            />
                          </div>
                        ) : (
                          <div className="nothing-found">
                            Sorry, you need add customers
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : customerGroup.group_type === "2" ? (
                <div className="using-filters-wrap">
                  <div className="filter-limit">
                    Filter for this group (Limit of {groupMaxFilter}):
                  </div>
                  <div className="curent-filters-wrap">
                    <div className="curent-filter-titles">
                      <div className="col-filter">Customers that:</div>
                      <div className="col-days">N:</div>
                      <div className="col-delete" />
                    </div>
                    {choosedFilterList.map((item) => (
                      <div className="curent-filter" key={`${item.virtualId}`}>
                        <div className="form-group-filter">
                          <Select
                            placeholder="Have not checked in for at least ’N’ Days"
                            selectorList={groupFilter}
                            disabled={!(groupFilter.length > 0)}
                            positionForShow="bottom"
                            required
                            defaultValue={{
                              value: item.filter_type_id.toString(),
                              label: getGroupFilterLabel(item.filter_type_id),
                            }}
                            getValue={(value) =>
                              hendleSetFilterIdChoosedFilterList(
                                value.toString(),
                                item.virtualId
                              )
                            }
                            hideSelectorFilter
                          />
                        </div>
                        <div className="form-group-days">
                          <Input
                            placeholder="90"
                            type="number"
                            required
                            defaultValue={item.value}
                            getValue={(value) =>
                              hendleSetDaysChoosedFilterList(
                                value,
                                item.virtualId
                              )
                            }
                          />
                        </div>
                        <button
                          type="button"
                          className="btn-delete"
                          onClick={() => deleteFilterThisGroup(item.virtualId)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    ))}
                    <div className="butns-wrap">
                      <button
                        type="button"
                        className="btn-blue-dashed btn-add-filter"
                        disabled={choosedFilterList.length >= groupMaxFilter}
                        onClick={() => addFilterThisGroup()}
                      >
                        + Add a Filter
                      </button>
                      <button
                        type="button"
                        className="btn-blue-dashed"
                        onClick={() => showCustomersByFilters()}
                      >
                        Show customers by filters
                      </button>
                    </div>
                  </div>
                  <div
                    className={`users-by-filters ${
                      choosedFiltercustomerLoading ? "loading-blue" : ""
                    }`}
                  >
                    <div className="form-innerwrap">
                      {choosedFilterCustomerList.length > 0 ? (
                        <>
                          <div className="table">
                            <DataTable
                              noHeader
                              columns={manualChoosedCustomerTableColumns}
                              data={choosedFilterCustomerList}
                            />
                          </div>
                          <div className="paginate-prepage">
                            <div className="paginate">
                              <button
                                className="prev-page"
                                type="button"
                                disabled={
                                  choosedFilterCustomerListInfo.page <= 1
                                }
                                onClick={() =>
                                  handleChoosedFilterCustomerPagination(
                                    choosedFilterCustomerListInfo.page - 1
                                  )
                                }
                              >
                                <ArrowBackIcon />
                              </button>
                              <button
                                className="next-page"
                                type="button"
                                disabled={
                                  choosedFilterCustomerListInfo.total_pages_count <=
                                  choosedFilterCustomerListInfo.page
                                }
                                onClick={() =>
                                  handleChoosedFilterCustomerPagination(
                                    choosedFilterCustomerListInfo.page + 1
                                  )
                                }
                              >
                                <ArrowForwardIcon />
                              </button>
                              <div className="text">
                                {choosedFilterCustomerListInfo.page *
                                  choosedFilterCustomerListInfo.limit -
                                  choosedFilterCustomerListInfo.limit}
                                -
                                {choosedFilterCustomerListInfo.page *
                                  choosedFilterCustomerListInfo.limit -
                                  choosedFilterCustomerListInfo.limit +
                                  choosedFilterCustomerListInfo.count_users}{" "}
                                from{" "}
                                {
                                  choosedFilterCustomerListInfo.total_users_count
                                }
                              </div>
                            </div>
                            <div className="perpage">
                              <div className="text">Show on page:</div>
                              <Select
                                selectorList={customerPerPage}
                                defaultValue={{
                                  value:
                                    choosedFilterCustomerListInfo.limit.toString(),
                                  label:
                                    choosedFilterCustomerListInfo.limit.toString(),
                                }}
                                positionForShow="top"
                                required
                                getValue={(value) => {
                                  handleChoosedFilterCustomerPerPage(
                                    value.toString()
                                  );
                                }}
                                hideSelectorFilter
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="nothing-found">
                          Sorry, nothing found
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
              {customerGroupFormErrorMessage ? (
                <div className="form-error-message">
                  {customerGroupFormErrorMessage}
                </div>
              ) : null}
              <div className="buttons-wrap">
                <button
                  type="button"
                  className="btn-white"
                  onClick={() => history.push("/customers/customer-groups")}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-blue">
                  {pageEdit ? "Update" : "Create"} Customer Group
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default CustomerGroups;
