const Color = {
  white: "#ffffff",
  blueDark: "#101c52",
  blueLight: "#dcdef8",
  blueLightMedium: "#3FB1FF",
  blueDarkBlour: "#1a2559",
  blue: "#515CDD",
  blueHover: "#2934b5",
  blueText: "#737ce4",
  blueDarkText: "#000D46",
  blueLightBackground: "#f7f8fc",
  blueBackground: "#dcdef8",
  grey: "#2F3640",
  greyMedium: "#BCBCBC",
  greyLight: "#d8d8d8",
  orange: "#ec9345",
  green: "#2ECC71",
  gradientFreeCardFrom: "#515CDD",
  gradientFreeCardTo: "#3FB1FF",
  iconInactive: "#2F3640",
  redMedium: "red",
};

export { Color };
