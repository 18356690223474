import React from "react";

interface IPosCategoryColumn {
  title: string,
  description: string,
}

const PosCategoryColumn = ({title, description}: IPosCategoryColumn) => {


  return (
        <div className="column">
          <h2 className="page-title">{title}</h2>
          <p className="description">{description}</p>
        </div>
  );
};

export default PosCategoryColumn;
