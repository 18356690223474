import { CustomerGroupsRequestInterface } from "src/types/customer"; // TODO: Make general
import {
  CampaignsCreateRequestInterface,
  SingleCampaignsRequestInterface,
  UpdateCampaignsRequestInterface,
  DeleteCampaignInterface,
} from "src/types/campaign";
import axios from "./api";

export const getCampaignsAxios = (
  requestParamtrs: CustomerGroupsRequestInterface
): Promise<any> =>
  axios
    .post("/api/getCampaignsList", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getAdminCampaignsAxios = (
  requestParamtrs: CustomerGroupsRequestInterface
): Promise<any> =>
  axios
    .post("/api/admin/campaign/list", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const getSingleAdminCampaignsAxios = (
  requestParamtrs: SingleCampaignsRequestInterface
): Promise<any> =>
  axios
    .post("/api/admin/campaign/get", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const getSingleCampaignsAxios = (
  requestParamtrs: SingleCampaignsRequestInterface
): Promise<any> =>
  axios
    .post("/api/getCampaign", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const createCampaignsAxios = (
  requestParamtrs: CampaignsCreateRequestInterface
): Promise<any> =>
  axios
    .post("/api/createCampaign", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
export const createAdminCampaignsAxios = (
  requestParamtrs: any
): Promise<any> =>
  axios
    .post("/api/admin/campaign/create", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

export const updateCampaignsAxios = (
  requestParamtrs: UpdateCampaignsRequestInterface
): Promise<any> =>
  axios
    .post("/api/updateCampaign", requestParamtrs)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });

    export const deleteCampaignsAxios = (requestParams: DeleteCampaignInterface): Promise<any> =>
    axios
      .post("/api/removeCampaign", requestParams)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
export const deleteAdminCampaignsAxios = (requestParams: DeleteCampaignInterface): Promise<any> =>
    axios
      .post("/api/admin/campaign/remove", requestParams)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
