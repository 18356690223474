import React, {useCallback, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import Input from "../../components/Input";
import {ReactComponent as CloseIcon} from "../../assets/img/clear.svg";
import {ReactComponent as UploadIcon} from "src/assets/img/upload.svg";
import { ReactComponent as CalendarIcon } from "src/assets/img/calendar.svg";
import {ReactComponent as CheckIcon} from "src/assets/img/checked.svg";
import {
  getStaffDiscountLoyaltyCardAxios,
  saveStaffDiscountLoyaltyCardAxios,
  setDiscountPercentForAllMembersAxios,
  removeDiscountRuleAxios,
  getAllDiscountRulesAxios,
  setNewDiscountRuleAxios,
  setMaximumSpendDiscountAxios, getMaximumSpendDiscountAxios,
} from "../../api/customers";
import {Wrapper} from "./styled";

import {ReactComponent as EditIcon} from "../../assets/img/edit.svg";
import {ReactComponent as DeleteIcon} from "../../assets/img/delete.svg";
import {DropEvent, FileRejection, useDropzone} from "react-dropzone";
import {uploadPhotoAxios} from "../../api/adminPanel";
import Checkbox from "../../components/CheckBox";
import Select from "../../components/Select";
import {SelectorListObj} from "../../components/Select/types";
import CheckBox from "../../components/CheckBox/CheckBox";
import moment from "moment/moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {useTypedSelector} from "../../hooks/useTypedSelector";

const StaffDiscount = () => {
  const [loyaltyCard, setLoyaltyCard] = useState<any>({
    global_card_name: 'Test',
    card_color: '#000000',
    card_logo: '',
    staff_barcode_prefix: 'DC',
    card_image_enable: false,
    card_image: '',
    // discount_limit: ''
  })
  const { loggedUser } = useTypedSelector((state) => state.auth);
  const changeLoyaltyCard = (label: string, value: any) => {
    setLoyaltyCard({...loyaltyCard, [label]: value})
  }
  
  // const [isLoading, setIsLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openSetDiscountForm, setOpenSetDiscountForm] = useState(false);
  const [openMaximumSpendForm, setOpenMaximumSpendForm] = useState(false);
  // const [haveStaffCardModel, setHaveStaffCardModel] = useState(false);
  const [openDiscountRulesForm, setOpenDiscountRulesForm] = useState(false);
  const [savedCard, setSavedCard] = useState(false);
  const [discountForAll, setDiscountForAll] = useState<number>(0)
  const [maximumSpend, setMaximumSpend] = useState<number>(0)
  const [maximumSpendPeriod, setMaximumSpendPeriod] = useState<number>(1)
  const [useMaximumSpendDiscount, setUseMaximumSpendDiscount] = useState<boolean>(false)
  const [selectedDiscountRules, setSelectedDiscountRules] = useState<any[]>([])
  const [dates, setDatesState] = useState({
    startDate:moment(new Date(loggedUser.user.created.split(' ')[0].split('-').reverse().join('-'))).format("MMM D, YYYY"),
    endDate: moment(new Date()).format("MMM D, YYYY")
  });
  const [maximumSpendPeriodList, setMaximumSpendPeriodList] =useState<SelectorListObj[]>([{value: 1, label: 'Every first of the month'}])
  
  const discountRulesTableColumns = [
    {
      name: "Rule Name",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new || row.edit ? (
              <Input
                getValue={value=>row.ruleName = value}
                defaultValue={row.ruleName}
              placeholder={'Rule Name'}
              />
            ) : (
              row.ruleName
            )}
          </div>
        );
      },
    },{
      name: "Product Dept",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new || row.edit ? (
              <Input
                getValue={value=>row.posCategoryName = value}
                defaultValue={row.posCategoryName}
                placeholder={'Product Dept'}
              />
            ) : (
              row.posCategoryName
            )}
          </div>
        );
      },
    },{
      name: "Product Group",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new || row.edit ? (
              <Input
                getValue={value=>row.posCategory = value}
                defaultValue={row.posCategory}
              placeholder={'Product Group'}
              />
            ) : (
              row.posCategory
            )}
          </div>
        );
      },
    },{
      name: "Product SKU",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div >
            {row.new || row.edit ? (
              <Input
                getValue={value=>row.sku = value}
                defaultValue={row.sku}
                placeholder={'Product SKU'}
              />
            ) : (
              row.sku
            )}
            
          </div>
        );
      },
    },{
      name: "Discount Percent",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {row.new || row.edit ? (
              <Input
              type={"number"}
              min={0}
              max={100}
              getValue={value=> +value < 0 ?  row.discount = 0 : +value > 100 ? row.discount = 100 : row.discount = +value}
              defaultValue={row.discount}
              placeholder={'Discount Percent'}
              />
            ) : (
              row.discount ? row.discount+'%' : '0%'
            )}
            
          </div>
        );
      },
    },{
      name: "Discount Issued",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div>
            {/* eslint-disable-next-line no-useless-concat */}
            {row.totalDiscount ? `${loggedUser?.user?.currency_symbol || '€'}${row.totalDiscount}`  : `${loggedUser?.user?.currency_symbol || '€'}0`}
          </div>
        );
      },
    },
    {
      name: "",
      minWidth: "150px",
      cell: (row: any): JSX.Element => {
        return (
          <div className="action">
            {!row.new && !row.edit ? (
              <>
                <button
                  type="button"
                  className="edit"
                  onClick={() => editDiscountRule(row.id, row)}
                >
                  <EditIcon/>
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => deleteDiscountRule(row.id)}
                >
                  <DeleteIcon/>
                </button>
              </>
            ) : !row.new && row.edit ? (
              <button
                className="btn-blue-outline"
                style={{padding: "10px 20px", backgroundColor: "transparent"}}
                onClick={() => saveDiscountRule(row.id, row)}
              >
                Save
              </button>
            ) : (
              <button
                className="btn-blue-outline"
                style={{padding: "10px 20px", backgroundColor: "transparent"}}
                onClick={() => saveDiscountRule(row.id, row)}
              >
                Add
              </button>
            )}
          </div>
        );
      },
    },
  ];
  
  const getAllDiscountRulesList = useCallback(() =>{
    getAllDiscountRulesAxios({startDate: moment(dates.startDate).format('YYYY-MM-DD'), endDate: moment(dates.endDate).format('YYYY-MM-DD')}).then(res => {
      setSelectedDiscountRules(res.data.restriction_category)
    })
  },[dates])
  
  const openCloseModal = (type: string) => {
    setOpenDiscountRulesForm(false)
    setOpenForm(false)
    setOpenSetDiscountForm(false)
    setOpenMaximumSpendForm(false)
    type === 'openForm' ? setOpenForm(true) : type === 'setDiscountForm' ? setOpenSetDiscountForm(true) : type === 'setMaximumSpendForm' ? setOpenMaximumSpendForm(true) : setOpenDiscountRulesForm(true)
  }
  const editDiscountRule = (id: any, row: any) =>{
    setSelectedDiscountRules([...selectedDiscountRules.map(item => item.id === id ? item = {...item, edit: true} : item)])
  }
  const deleteDiscountRule = useCallback((id: any) =>{
    removeDiscountRuleAxios({id}).then(res => {
      getAllDiscountRulesList()
    })
    setSelectedDiscountRules([...selectedDiscountRules.filter(item => item.id !== id)])
  },[getAllDiscountRulesList, selectedDiscountRules])
  
  const saveDiscountRule = useCallback((id: any, row: any) =>{
    if((row.posCategoryName.trim() !== '' || row.posCategory.trim() !== '' || row.sku.trim() !== '')) {
      setNewDiscountRuleAxios(row).then(res=>{
        getAllDiscountRulesList()
      })
      setSelectedDiscountRules([...selectedDiscountRules.map(item => item.id === id ? item = {...item, edit: false} : item)])
    }
  },[getAllDiscountRulesList, selectedDiscountRules])
  
  function addNewDiscountRule() {
    setSelectedDiscountRules((discountList: any) => (discountList = [ ...discountList, {posCategory: '', ruleName: '', sku: '', discount: 0, posCategoryName: '', new: true}]));
  }
  const getMaximumSpendDiscountSetings = useCallback(()=>{
    getMaximumSpendDiscountAxios().then(res=>{
      setMaximumSpendPeriod(res.data.maximumSpendPeriod || 1)
      setMaximumSpend(+res.data.maximumSpendValue || 0)
      setUseMaximumSpendDiscount(res.data.usingMaximumSpend || false)
    })
  },[])
  
  useEffect(() => {
    getAllDiscountRulesList()
    
  }, [getAllDiscountRulesList])
  
  useEffect(() => {
    getMaximumSpendDiscountSetings()
    openCloseModal('openDiscountRules')
  }, [getMaximumSpendDiscountSetings]);
  
  const [loadingPhoto, setLoadingPhoto] = useState<boolean>(false);
  const onDrop = (acceptedFiles: any, fileRejections: FileRejection[], event: DropEvent) => {
    if (acceptedFiles[0]) {
      setLoadingPhoto(true);
      const requestFormData: any = new FormData();
      requestFormData.append("file", acceptedFiles[0]);
      uploadPhotoAxios(requestFormData)
        .then((res: any) => {
          // @ts-ignore
          loyaltyCard.card_image_enable ? changeLoyaltyCard('card_image', res.data.photo_url) : changeLoyaltyCard('card_logo', res.data.photo_url)
          // setLogo(+event.target.className, res.data.photo)
          setLoadingPhoto(false);
        })
        .catch(() => {
          setLoadingPhoto(false);
        });
    }
  };
  
  const {isDragActive, getRootProps, getInputProps} = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    multiple: false,
    onDrop,
  });
  
  const openLoyaltyCardWrap = useCallback(() => {
    getStaffDiscountLoyaltyCardAxios().then(res => {
      setLoyaltyCard(res.data.loyalty_card)
      openCloseModal('openForm')
    })
  },[])
  
  const handlerSaveLoyaltyCard = useCallback((e: any) => {
    e.preventDefault()
    saveStaffDiscountLoyaltyCardAxios(loyaltyCard).then(res => {
      setSavedCard(true)
      getStaffDiscountLoyaltyCardAxios().then(res => {
        setLoyaltyCard(res.data.loyalty_card)
        // getStaff();
        setTimeout(()=>{
          setSavedCard(false)
          // setOpenForm(!openForm)
          // window.location.reload()
         
        }, 2000)
      })
    })
  },[loyaltyCard])
  
  const handleSetDiscountPercentForAllMembers = useCallback((e: any) => {
    e.preventDefault()
    setDiscountPercentForAllMembersAxios({discount: discountForAll}).then(res => {
      // getStaff()
    }).catch((err) => {
      console.log(err)
    }).finally(() => {
      // setOpenSetDiscountForm(false)
      setDiscountForAll(0)
    })
  },[discountForAll])
  
  const handleSetMaximumSpendDiscount = useCallback((e: any) =>{
    e.preventDefault()
    setMaximumSpendDiscountAxios({maximumSpendValue: maximumSpend, maximumSpendPeriod: maximumSpendPeriod, usingMaximumSpend: useMaximumSpendDiscount}).then(res=>{
      getMaximumSpendDiscountSetings()
      // setOpenMaximumSpendForm(false)
    })
  },[getMaximumSpendDiscountSetings, maximumSpend, maximumSpendPeriod, useMaximumSpendDiscount])
  
  const setDates = (e: any, { startDate, endDate }: any) => {
    endDate >= moment() && startDate >= moment() ? setDatesState({
      startDate: moment().format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : endDate >= moment() &&  startDate <= moment() ? setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: moment().format("MMM D, YYYY")
    }) : setDatesState({
      startDate: startDate.format("MMM D, YYYY"),
      endDate: endDate.format("MMM D, YYYY")
    });
  };
  
  const ranges = {
    "Today": [moment(), moment()],
    "Last 7 Days": [moment().subtract(1, "weeks"), moment()],
    "Last 30 Days": [moment().subtract(1, "months"), moment()],
    "Last Year": [moment().subtract(1, "years"), moment()],
  };
  
  return (
    <Wrapper>
      <div className="page-title">Staff discounts</div>
      <div className="top-bar staff-top-bar">
        <div className="buttons-wrap">
          <button
            type="button"
            className="btn-white staff-add-btn"
            style={{marginBottom: "12px"}}
            onClick={openLoyaltyCardWrap}
          >
            Staff card model
          </button>
          <button
            type="button"
            className="btn-white staff-add-btn"
            style={{marginBottom: "12px"}}
            onClick={() => openCloseModal('setDiscountForm')}
          >
            Set Discount for All
          </button>
          <button
            type="button"
            className="btn-white staff-add-btn"
            style={{marginBottom: "12px"}}
            onClick={() => openCloseModal('setMaximumSpendForm')}
          >
            Maximum Spend Discount
          </button>
          <button
            type="button"
            className="btn-white staff-add-btn"
            style={{marginBottom: "12px"}}
            onClick={() => openCloseModal('openDiscountRules')}
          >
            Discount Rules
          </button>
        </div>
        <div className="data-wrap">
          <DateRangePicker
            onApply={setDates}
            initialSettings={{startDate: moment(new Date()).subtract(7, "days"), endDate: new Date(), ranges: ranges}}
          
          >
            <div className={'isSelect select'}>
              <CalendarIcon/>
              <input
                type="text"
                value={dates.startDate + "-" + dates.endDate}
                onChange={(e) => {
                  // @ts-ignore
                  setDates(e, dates)
                }}
                className="form-control"
              />
            </div>
          
          </DateRangePicker>
        </div>
      </div>
      {openForm && (<div className="create-new-user">
          {
            <form
              onSubmit={(e) => handlerSaveLoyaltyCard(e)}
              autoComplete="off"
            
            >
              <input role="presentation" autoComplete="none" name="hidden" type="text" style={{display: "none"}}/>
              <div className="loading-innerwrap">
                <div className="title-close">
                  <div className="title">Make your card</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      setOpenForm(false)
                    }}
                  >
                    <CloseIcon/>
                  </button>
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                  <div className="input-wrap">
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <div className="first_name-group">
                        <Input
                          placeholder="Card Name"
                          required
                          label={'Card Name'}
                          autoComplete="off"
                          defaultValue={loyaltyCard.global_card_name}
                          getValue={(value) =>
                            changeLoyaltyCard("global_card_name", value)
                          }
                        />
                      </div>
                      <div className="first_name-group">
                        <span data-tag="allowRowEvents" className="label">Color</span>
                        <input type="color" value={loyaltyCard.card_color}
                               onChange={(e) => changeLoyaltyCard('card_color', e.target.value)}
                               className={'colorInput'}/>
                      </div>
                      <div className="password-group">
                        <Input
                          placeholder="Barcode Prefix"
                          required
                          label={'Barcode Prefix'}
                          defaultValue={loyaltyCard.staff_barcode_prefix}
                          getValue={(value) => {
                            changeLoyaltyCard("staff_barcode_prefix", value.replace('_', '').replace('__', ''))
                          }
                          }
                        />
                      </div>
                      {/*<div className="password-group">*/}
                      {/*  <Input*/}
                      {/*    placeholder="Discount Limit"*/}
                      {/*    required*/}
                      {/*    type={"number"}*/}
                      {/*    label={'Discount Limit'}*/}
                      {/*    defaultValue={loyaltyCard.discount_limit}*/}
                      {/*    getValue={(value) =>*/}
                      {/*      changeLoyaltyCard("discount_limit", value)*/}
                      {/*    }*/}
                      {/*  />*/}
                      {/*</div>*/}
                      <Checkbox label={'Use Loyalty Card Image'} isChecked={loyaltyCard.card_image_enable}
                                onChange={(value) => changeLoyaltyCard('card_image_enable', value)}/>
                    
                    </div>
                    {loyaltyCard.card_image_enable ? (<div className={'selectLogo-box'}>
                      <span data-tag="allowRowEvents" className="label">Loyalty Card Image</span>
                      <div className={`column`}>
                        <div
                          className={`photo-wrap selectImg ${loadingPhoto ? "loading-blue" : ""}`}>
                          <div
                            className={`dropZone ${
                              loyaltyCard.card_image ? "with-photo" : ""
                            }`}
                          >
                            <div {...getRootProps()} className={`active_${isDragActive}`}>
                              {" "}
                              {/*eslint-disable-line*/}
                              <input {...getInputProps()}/>{" "}
                              {/*eslint-disable-line*/}
                              {loyaltyCard.card_image ? (
                                <div className="img">
                                  <img src={loyaltyCard.card_image} alt=""/>
                                </div>
                              ) : null}
                              <div className="dropZone-content-text">
                                <UploadIcon/>
                                <span className="text">
                                <span className="title">
                                  {loyaltyCard.card_image
                                    ? "Update your logo"
                                    : "Please upload your logo"}
                                </span>
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>) : (<div className={'selectLogo-box'}>
                      <span data-tag="allowRowEvents" className="label">Loyalty Card Logo</span>
                      <div className={`column`}>
                        <div
                          className={`photo-wrap selectImg ${loadingPhoto ? "loading-blue" : ""}`}>
                          <div
                            className={`dropZone ${
                              loyaltyCard.card_logo ? "with-photo" : ""
                            }`}
                          >
                            <div {...getRootProps()} className={`active_${isDragActive}`}>
                              {" "}
                              {/*eslint-disable-line*/}
                              <input {...getInputProps()} />{" "}
                              {/*eslint-disable-line*/}
                              {loyaltyCard.card_logo ? (
                                <div className="img">
                                  <img src={loyaltyCard.card_logo} alt=""/>
                                </div>
                              ) : null}
                              <div className="dropZone-content-text">
                                <UploadIcon/>
                                <span className="text">
                                <span className="title">
                                  {loyaltyCard.card_logo
                                    ? "Update your logo"
                                    : "Please upload your logo"}
                                </span>
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)}
                  </div>
                  {loyaltyCard.card_image_enable ? (<div className="cardView cardImage" style={{
                    backgroundColor: loyaltyCard.card_color,
                    marginTop: '30px',
                    backgroundImage: `url(${loyaltyCard.card_image})`
                  }}>
                  </div>) : (<div className="cardView" style={{backgroundColor: loyaltyCard.card_color, marginTop: '30px',}}>
                    <img src={loyaltyCard.card_logo} alt="cardLogo"/>
                  </div>)}
                
                </div>
                
                <button type="submit" className="btn-blue saveCard-btn">
                  {savedCard ? <CheckIcon/> : 'Save Loyalty Card'}
                </button>
              </div>
            </form>
            
          }
        
        </div>
      )}
      {openSetDiscountForm && (<div className="create-new-user">
          {
            <form
              onSubmit={(e) => handleSetDiscountPercentForAllMembers(e)}
              // className={`${
              //   formAddUserToLocationLoading ? "loading-blue" : ""
              // }`}
              autoComplete="off"
            
            >
              <div className="loading-innerwrap">
                <div className="title-close">
                  <div className="title">Set Discount percent for all members</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      setOpenSetDiscountForm(false)
                    }}
                  >
                    <CloseIcon/>
                  </button>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className="input-wrap">
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <div className="first_name-group">
                        <input
                          placeholder="Discount percent"
                          required
                          type={"number"}
                          autoComplete="off"
                          pattern={'[0-9]{3}'}
                          inputMode={'numeric'}
                          defaultValue={discountForAll}
                          min={0}
                          max={100}
                          onInput={(e) =>
                            +e.currentTarget.value >= 100 ?
                              setDiscountForAll(100) : +e.currentTarget.value < 0 ? setDiscountForAll(0) :
                                setDiscountForAll(+e.currentTarget.value)
                          }
                          style={{
                            borderRadius: "4px",
                            padding: "13px 16px",
                            boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="btn-blue saveCard-btn">
                  Save Discount for All
                </button>
              </div>
            </form>
            
          }
        
        </div>
      )}
      {openMaximumSpendForm && (<div className="create-new-user">
          {
            <form
              onSubmit={(e) => handleSetMaximumSpendDiscount(e)}
              // className={`${
              //   formAddUserToLocationLoading ? "loading-blue" : ""
              // }`}
              autoComplete="off"
            
            >
              <div className="loading-innerwrap">
                <div className="title-close">
                  <div className="title">Set Maximum Spend Discount for all members</div>
                  <button
                    type="button"
                    className="close"
                    onClick={() => {
                      setOpenMaximumSpendForm(false)
                    }}
                  >
                    <CloseIcon/>
                  </button>
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start'}}>
                  <div className="input-wrap">
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <div className="first_name-group">
                        <span data-tag="allowRowEvents" className="label">Maximum Spend Discount Value</span>
                        <input
                          placeholder="Maximum Spend Discount Value"
                          required
                          type={"number"}
                          autoComplete="off"
                          inputMode={'numeric'}
                          defaultValue={maximumSpend}
                          onInput={(e) =>
                            setMaximumSpend(+e.currentTarget.value)
                          }
                          style={{
                            borderRadius: "4px",
                            padding: "13px 16px",
                            boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.1)",
                            width: '100%'
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-wrap">
                    <div className="first_name-group">
                      <Select selectorList={maximumSpendPeriodList}
                              placeholder={'Select updating period'}
                              label={'Select updating period'}
                              hideSelectorFilter
                              getValue={(value) => setMaximumSpendPeriod(+value)}
                              defaultValue={maximumSpendPeriodList.length > 0 ? maximumSpendPeriodList.find(item => +item.value === +maximumSpendPeriod) : maximumSpendPeriodList[0]}
                              positionForShow={'bottom'}/>
                    </div>
                  </div>
                </div>
                <div className="field" style={{marginBottom: '20px'}}>
                  <CheckBox
                    label="Use Maximum Spend Discount"
                    isChecked={
                      useMaximumSpendDiscount
                    }
                    onChange={(isChecked) => {
                      setUseMaximumSpendDiscount(isChecked)
                    }}
                  />
                </div>
                <button type="submit" className="btn-blue saveCard-btn">
                  Save Maximum Spend Discount
                </button>
              </div>
            </form>
            
          }
        
        </div>
      )}
      {openDiscountRulesForm && (<div className="create-new-user">
          {
            <>
              <div className={'discountRule_wrapper'}
              >
                <div className="loading-innerwrap">
                  <div className="title-close">
                    <div className="title">Create discount rules for specific products
                    </div>
                    <button
                      type="button"
                      className="close"
                      onClick={() => {
                        setOpenDiscountRulesForm(false)
                      }}
                    >
                      <CloseIcon/>
                    </button>
                  </div>
                  <div className="discount_rules-list">
                    <DataTable
                      noHeader
                      columns={discountRulesTableColumns}
                      data={selectedDiscountRules}
                    />
                  </div>
                  <button onClick={addNewDiscountRule} className="btn-blue addNew_rule">
                    Add New Discount Rule
                  </button>
                </div>
                {/*<div className="discount_list">*/}
                {/*  <p className={'title'}>List of groups of products on which the discount will not apply</p>*/}
                {/*  */}
                {/*</div>*/}
              </div>
            
            </>
            
          }
        
        </div>
      )}
    </Wrapper>
  );
};

export default StaffDiscount;
