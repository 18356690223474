import React, {useEffect, useState} from 'react';
import {Wrapper} from "./styled";
import PosTabWrapper from "../../components/PosTabWrapper";
import PosCategoryColumn from "../../components/PosCategoryColumnWrapper/PosCategoryColumn";
import DataTable from "react-data-table-component";
import {ReactComponent as CirclePlus} from "../../assets/img//circle-plus.svg";
import {ReactComponent as CheckCircle} from "../../assets/img/checkCircle.svg";
import {ReactComponent as Edit} from "../../assets/img/edit.svg";
import {ReactComponent as Delete} from "../../assets/img/delete.svg";
import {IMinimumSpendItem} from "../../types/posRyles";
import Input from "../../components/Input";
import Select from "../../components/Select";
import CheckBox from "../../components/CheckBox/CheckBox";
import {DaysWrapperDiv, SpanInputText} from "../Promotions/Promotion/styled";
import {SelectorListObj} from "../../components/Select/types";
import {getActivateDaysAxios} from "../Registration/Step/RegistrationBuildYourCard/api";
import {
  getAllMinimumSpendRulesAxios,
  saveNewMinimumSpendRulesItemAxios,
  removeMinimumSpendRulesItemAxios
} from "../../api/minimumSpend";
import {useTypedSelector} from "../../hooks/useTypedSelector";


const MinimumSpend = () => {
  const [activateDaysList, setActivateDaysList] = useState<Array<SelectorListObj>>(
    []
  );
  const [edit, setEdit] = useState(false)
  const { loggedUser } = useTypedSelector((state) => state.auth);
  
  const [minimumSpendList, setMinimumSpendList] = useState<IMinimumSpendItem[]>([{
    spendValue: 0,
    rewardValue: 0,
    isExpired: false,
    edit: false,
    placePoints: {
      activateAfter: 1,
      dateExpire: 365
    },
  }])
  
  useEffect(() => {
    getActivateDaysAxios().then((response) => {
      setActivateDaysList(response.data.activateDays);
    });
    getAllMinimumSpendList()
    
  }, []);
  const addNewSpend = () => {
    const newItem: IMinimumSpendItem = {
      spendValue: 0,
      rewardValue: 0,
      isExpired: true,
      edit: true,
      id: 'new',
      placePoints: {
        activateAfter: 1,
        dateExpire: 365
      },
    }
    setMinimumSpendList(items => items = [...items, newItem])
    setEdit(true)
  }
  
  const getAllMinimumSpendList = () => {
    getAllMinimumSpendRulesAxios().then(res => {
      setMinimumSpendList(res.data.min_spend)
    })
  }
  
  const saveEditSpend = (id: number | undefined | string) => {
    const newItems: IMinimumSpendItem = minimumSpendList.filter(item => item.id === id)[0];
    newItems.edit = true;
    
    const requestItem: IMinimumSpendItem = {
      id: newItems.id,
      spendValue: newItems.spendValue,
      rewardValue: newItems.rewardValue,
      isExpired: newItems.isExpired,
      placePoints: {
        activateAfter: newItems.placePoints.activateAfter,
        dateExpire: newItems.placePoints.dateExpire,
      }
    }
    saveNewMinimumSpendRulesItemAxios(requestItem).then(res => {
      getAllMinimumSpendList()
      setEdit(false)
    })
  }
  
  const saveNewMinimumSpend = (index: number) => {
    const newItems: IMinimumSpendItem = minimumSpendList[index];
    newItems.edit = false;
    
    const requestItem: any = {
      spendValue: newItems.spendValue,
      rewardValue: newItems.rewardValue,
      isExpired: newItems.isExpired,
      placePoints: {
        activateAfter: newItems.placePoints.activateAfter,
        dateExpire: newItems.placePoints.dateExpire,
      }
    }
    saveNewMinimumSpendRulesItemAxios(requestItem).then(res => {
      getAllMinimumSpendList()
      setEdit(false)
    })
  }
  
  const removeSpendItem = (id: number | undefined | string) => {
    removeMinimumSpendRulesItemAxios({id})
      .then((res) => {
        getAllMinimumSpendList();
      }).catch(e => {
      console.log(e);
    });
  };
  const editMinimumSpendItem = (id: number) => {
    const newItems: IMinimumSpendItem[] = minimumSpendList;
    newItems[id].edit = true;
    setMinimumSpendList((stamp: Array<any>) => stamp = [...newItems]);
    setEdit(true)
  };
  
  const categoryList = [
    {
      name: "",
      width: "17%",
      cell: (item: IMinimumSpendItem): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Input
              defaultValue={`${item.spendValue}`}
              getValue={(value) => item.spendValue = +value}
              disabled={!item.edit}
              type={"number"}
              min={0}
            />
          </div>
        
        );
      }
    },
    {
      name: "",
      maxWidth: "17%",
      cell: (item: IMinimumSpendItem): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Input
              defaultValue={`${item.rewardValue}`}
              required
              getValue={(value) => item.rewardValue = +value}
              disabled={!item.edit}
              type={'number'}
              min={0}
            />
          </div>
        
        );
      }
    },
    {
      name: "",
      maxWidth: "17%",
      cell: (item: IMinimumSpendItem): JSX.Element => {
        return (
          <div style={item.edit ? {paddingBottom: '200px'} : {}}>
            <Select
              selectorList={activateDaysList}
              positionForShow={"bottom"}
              defaultValue={activateDaysList.find(activ => activ.value === item.placePoints.activateAfter)}
              required
              hideSelectorFilter
              disabled={!item.edit}
              getValue={(value) => {
                item.placePoints.activateAfter = +value
              }}
            />
          </div>
        
        )
          ;
      }
    }, {
      name: "",
      maxWidth: "17%",
      cell: (item: IMinimumSpendItem, i: number): JSX.Element => {
        return (
          <div className="reward_expired" style={item.edit ? {paddingBottom: '200px'} : {}}>
            <CheckBox
              label="Expires After"
              isChecked={
                item.isExpired
              }
              isDisabled={!item.edit}
              onChange={(isChecked) => {
                setMinimumSpendList([...minimumSpendList.map(spend => spend.id === item.id ? spend = {
                    ...spend,
                    isExpired: isChecked, placePoints: {...spend.placePoints, dateExpire: isChecked ? 365 : 0}
                  } : spend)])
              }}
            />
            <DaysWrapperDiv>
              <Input
                type="number"
                placeholder="Enter Expire Date"
                required
                disabled={!item.isExpired || !item.edit}
                defaultValue={`${+item.placePoints.dateExpire}`}
                getValue={(value) => item.placePoints.dateExpire = +value}
              />
              {item.placePoints.dateExpire ? <SpanInputText>days</SpanInputText> : null}
            </DaysWrapperDiv>
          </div>
        )
          ;
      }
    },
    {
      name: "",
      maxWidth: "7.5%",
      cell: (item: IMinimumSpendItem, index: number): JSX.Element => {
        
        return (
          <div className="buttons" data-tag="allowRowEvents" style={item.edit ? {paddingBottom: '200px'} : {}}>
            {item.edit && item.id !== 'new' ?
              <button className="btn-white edit-category save-btn" onClick={() => {
                saveEditSpend(item.id)
              }}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button>
              : item.edit ? <button className="btn-white edit-category save-btn" onClick={() => {
                saveNewMinimumSpend(index)
              }}>
                    <span className="icon">
                      <CheckCircle/>
                    </span>
                <span>Save</span>
              </button> : <>
                <button disabled={edit} className="btn-white edit-category" onClick={() => {
                  editMinimumSpendItem(index)
                }}>
                      <span className="icon">
                        <Edit/>
                      </span>
                </button>
                <button disabled={edit} className="btn-white remove-category" onClick={() => {
                  removeSpendItem(item.id)
                }}>
                      <span className="icon">
                      <Delete/>
                      </span>
                </button>
              </>}
          
          </div>
        );
      }
    }
  ];
  
  return (
    <Wrapper>
      <PosTabWrapper title={"Minimum Spend Settings"}
                     description={`You can offer your customers the opportunity to receive a discount by making purchases of a certain amount, for example, by purchasing valid goods worth ${loggedUser.user.currency_symbol || '€'}50, you can receive a voucher for ${loggedUser.user.currency_symbol || '€'}5.`}
                     button={false}>
        <div className={'content'}>
          <div className="header">
            <div className="header-tab">
              <PosCategoryColumn title={'Minimum Spend Value'}
                                 description={`Enter your minimum spend, for example ${loggedUser.user.currency_symbol || '€'}50`}/>
              <PosCategoryColumn title={'Voucher Value'}
                                 description={'Enter your voucher value'}/>
              <PosCategoryColumn title={'Voucher Activation'}
                                 description={'Select your voucher Activation time'}/>
              <PosCategoryColumn title={'Expires Time'}
                                 description={'Select your voucher Expires time'}/>
              <div className="column_buttons"></div>
            
            </div>
            <div className="stamp-based-form">
              
              {minimumSpendList !== null ? (
                minimumSpendList.length > 0 ? (
                  <>
                    <div className="table">
                      <DataTable
                        noTableHead
                        noHeader
                        columns={categoryList}
                        data={minimumSpendList}
                      />
                    </div>
                  </>
                ) : (
                  <div className="nothing-found">Sorry, nothing found</div>
                )
              ) : null}
            </div>
            <button disabled={edit} className="btn-white add-stampCategory" onClick={() => {
              addNewSpend()
            }}>
            <span className="icon">
              <CirclePlus/>
            </span>
              <span>Add Stamp Category</span>
            </button>
          </div>
        
        </div>
      </PosTabWrapper>
    </Wrapper>
  );
};

export default MinimumSpend;
