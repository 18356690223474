import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
    h1{
        font-weight: 500;
    }
  
  .locations {
    position: relative;
    /* margin-right: 30px; */
    /* width: calc(70% - 30px); */
    margin-top: 30px;
    border-radius: 4px;
    background-color: ${Color.white};
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 25px 30px;
    box-sizing: border-box;

    .title {
      color: ${Color.blueDarkText};
      font-size: 20px;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 25px;
    }

    .create-filter {
      margin-bottom: 15px;
    }

    .create-location {
      form {
        margin-bottom: 30px;
        border: 1px solid rgba(0, 13, 70, 0.1);
        border-radius: 5px;
        padding: 15px;
        position: relative;
      }

      .title-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 0px;
        }

        .close {
          background: none;
          opacity: 0.6;
          line-height: 0;

          &:hover {
            opacity: 1;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .select-deal-container {
        .select {
          margin-bottom: 15px;
        }
      }

      .input-wrap {
        margin-bottom: 15px;

        .location-group {
          svg path {
            fill: ${Color.blue};
          }
        }
      }

      .add-location-wrapper {
        display: flex;
        flex-direction: column;

        .left-block {
          width: 100%;
          margin-right: 15px;
        }

        .right-block {
          width: 100%;

          .upload-image {
            /* height: 150px; */
            margin-bottom: 15px;
          }
        }
      }
    }

    .create-new-user {
      form {
        margin-bottom: 30px;
        border: 1px solid rgba(0, 13, 70, 0.1);
        border-radius: 5px;
        padding: 15px;
        overflow: hidden;
      }

      .title-close {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          margin-bottom: 0px;
        }

        .close {
          background: none;
          opacity: 0.6;
          line-height: 0;

          &:hover {
            opacity: 1;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .input-wrap {
        display: flex;
        margin-bottom: 15px;

        & > div {
          flex: 1;
          width: calc(100% / 3);
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .location-item {
      border-radius: 4px;
      background-color: ${Color.blueLightBackground};
      margin-bottom: 10px;
      padding: 20px;
      box-sizing: border-box;

      .main {
        border-bottom: 1px solid rgba(0, 13, 70, 0.1);
        margin-bottom: 15px;

        .logo-nameAddress {
          display: flex;
          align-items: flex-start;
        }

        .logo {
          line-height: 0;
          margin-right: 15px;

          img {
            height: 40px;
            width: 40px;
            border-radius: 100%;
            object-fit: cover;
          }
        }

        .name-address {
          width: calc(100% - 55px);
        }

        .name {
          color: ${Color.blueDarkText};
          font-size: 18px;
          font-weight: 500;
          line-height: 23px;
          margin-bottom: 5px;
        }

        .address {
          opacity: 0.6;
          color: ${Color.blueDarkText};
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          margin-bottom: 20px;
        }

        .edit-remove {
          display: flex;
          align-items: center;

          button {
            background: none;
            padding: 0;
            opacity: 0.6;

            &:hover {
              opacity: 1;
            }
          }

          .edit {
            margin-right: 10px;
          }
        }
      }

      .two_btn_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @media (max-width: 600px) {
          * {
            flex-basis: 100%;
            margin-bottom: 10px;
          }
        }
        button {
          background: none;
          color: ${Color.blue};
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-align: center;
        }
      }

      &.hide-staff {
        .show-hide-staff {
          button {
            .hide {
              display: none;
            }
          }
        }

        .staff-list-wrap {
          height: 0px;
          overflow: hidden;
        }
      }

      &.show-staff {
        .show-hide-staff {
          button {
            .show {
              display: none;
            }
          }
        }
      }

      .show-hide-staff {
        text-align: center;
      }

      .staff-list-wrap {
        transition: 0.5s all ease;
      }

      .create-filter {
        display: flex;

        .search-wrap {
          margin-left: 20px;
          flex: 1;
          width: 100%;
        }

        button {
          margin-bottom: 15px;
        }
      }

      .staff-list {
        border-bottom: 1px solid rgba(0, 13, 70, 0.1);
        padding-bottom: 20px;
        box-sizing: border-box;
        margin-bottom: 15px;

        &.table {
          & > div {
            overflow: unset;
          }
        }

        &-table-wrap {
          position: relative;
        }

        div.rdt_TableRow {
          padding: 13px 0;
        }

        div:first-child {
          &::-webkit-scrollbar {
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: ${Color.white};
            border-radius: 3px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: ${Color.blueText};
          }

          &:hover {
            &::-webkit-scrollbar-thumb {
              background: ${Color.blueLight};
            }

            &::-webkit-scrollbar-thumb:hover {
              background: ${Color.blueText};
            }
          }
        }
      }
      @media (max-width: 500px) {
        padding: 15px;
      }
    }

    .edit_location {
      margin-bottom: 25px;
      .close-wrap {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0px 15px;
        .close {
          background: none;
        }
      }

      .edit_location_form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        & > *:nth-child(1) {
          flex-basis: 45%;
        }
        & > *:nth-child(2) {
          flex-basis: 45%;
        }
        & > *:nth-child(3) {
          flex-basis: 100%;
        }
        .btn_wrapper {
          width: 100%;
        }
        @media (max-width: 600px) {
          & > *:nth-child(1) {
            flex-basis: 100%;
          }
          & > *:nth-child(2) {
            flex-basis: 100%;
            margin: 15px 0px;
          }
        }
      }

      .location-group-logo {
        flex-basis: 100%;
        margin: 15px 0px;
      }
      .welcome_gift_wrapper {
        background-color: ${Color.white};
        padding: 16px;
        flex-basis: 100%;
        margin-bottom: 20px;
      }
      .welcome_gift_form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        & > * {
          flex-basis: 100%;
          margin: 10px 0px;
        }
      }
      .title_block {
        color: ${Color.blueDarkText};
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        margin: 0px 0px 16px;
      }
    }

    .sidebar-edit-location {
      .location-group {
        padding: 5px 3px 0px 3px;
        margin-bottom: 15px;
      }

      .location-group-google-autocomplete {
        .place-point-icon {
          svg path {
            fill: ${Color.blue};
          }
        }
      }

      .location-group-logo {
        .dropZone > div .img img {
          position: absolute;
        }
      }

      form {
        button[type="submit"] {
          margin-top: 30px;
        }
      }
    }

    .sidebar-edit-staff {
      .staff-group {
        padding: 5px 3px 0px 3px;
        margin-bottom: 15px;
      }

      form {
        button[type="submit"] {
          margin-top: 30px;
        }
      }
    }
    @media (max-width: 500px) {
      padding: 15px 10px;
    }
  }
  .remove-modal {
    text-align: center;
    padding: 30px 35px;

    .title {
      color: ${Color.blueDarkText};
      font-size: 21px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
    }

    button {
      margin-top: 20px;
      width: auto;
      padding: 10px 20px;
    }
  }
  .main-blocks-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 4px;
    .item {
      flex: 1;
      border-radius: 4px;
      background-color: ${Color.white};
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      padding: 15px 20px;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }

      .title {
        opacity: 0.6;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        margin-bottom: 15px;
      }
      .count {
        color: ${Color.blueDarkText};
        font-size: 44px;
        font-weight: 500;
        line-height: 56px;
        margin-bottom: 10px;
      }
      .description {
        opacity: 0.2;
        color: ${Color.blueDarkText};
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
  .add-category{
    margin: 5px 2px;
    width: 20%;
    justify-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 23px;
        height: 23px;
      }
    }
  }.add-stampCategory{
    margin: 5px 2px;
    width: 40%;
    justify-self: start;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 23px;
        height: 23px;
      }
    }
  }

  .header {
    display: flex;
    align-items: stretch;
    //justify-content: space-between;
    margin-bottom: 15px;
    gap: 15px;
    .column {
      width: 11.4%;

      //.column-group{
      //  display: flex;
      //  //justify-content: space-between;
      //  flex-wrap: nowrap;
      //  gap: 15px;
      //  .column {
      //    width: 50%;
      //  }
      //}
      .page-title {
        font-size: 16px;
        margin: 0 0 10px 0;
        line-height: normal;
      }
      .description{
        font-size: 12px;
        line-height: normal;
      }
      //&:nth-last-child(2){
      //  width: 29%;
      //}
      
    }
    .column_buttons {
      width: 10%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    padding-top: 20px;
    width: 100%;
    
      h2{
          text-align: center;
          font-weight: 500;
      }
  }
  
  .stamp-based-wrapper {
    width: 100%;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    position: relative;
    .header {
      flex-direction: column;
      width: 70%;
      .header-tab {
        height: auto;
        display: flex;
        justify-content: space-between;
        gap: 2%;

        .column {
          width: 20%;
        }
      }
      .stamp-based-form {
        display: flex;
        justify-content: space-between;
        .table {
          width: 100%;
          .rdt_TableRow{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0 0;
            
            .sc-idiyUo{
              position: absolute;
              z-index: 9999;
              left: -10px;
              margin-bottom: 12px;
            }
            .rdt_TableCell{
              width: 20%;
              padding-left: 0;
              &>div{
                width: 100%;
                input{
                  width: 70%;
                  padding-right: 40px;
                }
                
              }
              .select{
                margin-bottom: 12px;
              }
              &:nth-last-child(1){
                width: 8.5%;
                margin-bottom: 12px;
                .buttons{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  button{
                    width: 45%;
                    padding: 7.5px 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                  .save-btn{
                    width: 100%;
                    padding: 9.5px 10px;
                  }
                }
                
              }
            }
          }
          
          
          
        }
        .table-edit{
          &>div{
            padding-bottom: 90px;
          }
          .itNnZW > ul{
            max-height: 90px;
          }

        }
        .modal-content{
          text-align: center;
          padding: 60px 50px;
          @media (max-width: 600px) {
            padding: 30px 15px;
          }
          .title {
            color: ${Color.blueDarkText};
            font-size: 30px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
            @media (max-width: 600px) {
              font-size: 23px;
              line-height: 28px;
            }
          }
          .sub-title{
            color: ${Color.blueDarkText};
            font-size: 25px;
            font-weight: 500;
            line-height: 38px;
            text-align: center;
            @media (max-width: 600px) {
              font-size: 23px;
              line-height: 28px;
            }
          }
        }
      }
    }
    
  }
  
  @media (max-width: 1300px) {
    .main-blocks-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
      margin-right: -30px;
      margin-bottom: -30px;

      .item.all-customers {
        margin: 0 30px 30px 0;
      }
    }
  }
  @media (max-width: 399px) {
    .main-blocks-row {
      margin-right: 0px;
      .item.all-customers {
        margin-right: 0;
      }
    }
  }
`;

export { Wrapper };
