import styled from "styled-components";
import { Color } from "src/types/color";

const Wrapper = styled.div`
  .nav-item {
    line-height: 0;
    position: relative;
    &.active-parent-page,
    &.active-page {
      & > div > a {
        opacity: 1;
        border-radius: 4px;
        background-color: rgb(0 13 70 / 10%);
      }
    }
    &.active-parent-page {
      border-radius: 4px;
      background-color: ${Color.blueLightBackground};
      .arrow-drop-down {
        transform: rotate(180deg);
      }
      .nav-children-items {
        display: block;
        .active-page {
          & > div > a {
            opacity: 1;
            border-radius: 4px;
            background-color: rgb(0 13 70 / 10%);
          }
        }
      }
    }
    &.show-sub-page {
      border-radius: 4px;
      background-color: ${Color.blueLightBackground};
      .arrow-drop-down {
        transform: rotate(180deg);
      }
      .nav-children-items {
        display: block;
      }
    }
    &:hover {
      & > div > a {
        opacity: 1;
        border-radius: 4px;
        background-color: rgb(0 13 70 / 10%);
      }
    }
    a {
      &:hover {
        opacity: 1;
      }
    }
    &_not_active {
      &.active-parent-page,
      &.active-page {
        & > div > a {
          opacity: 1;
          border-radius: 4px;
          background-color: ${Color.blueLightBackground};
        }
      }
    }
  }

  .active-page,
  .active-parent-page {
    & > div > a {
      opacity: 1;
    }
  }

  .nav-children-items {
    display: none;
  }

  .item-parent {
    .arrow-drop-down {
      background: none;
      padding: 0;
      cursor: pointer;
      line-height: 0;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 20px;
      top: 18px;
    }
  }
`;

export { Wrapper };
