import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import Modal from "src/components/Modal";
import { declineAccount } from "src/api/auth";

import { ReactComponent as LogoFull } from "src/assets/img/logo-full.svg";

import { Wrapper } from "./styled";

const DeclineAccount: FC = () => {
  const location: any = useLocation();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [successResponse, setSuccessResponse] = React.useState<string>("");
  const [errorResponse, setErrorResponse] = React.useState<string>("");

  const сonfirmDeclineAccount = () => {
    setLoading(true);
    declineAccount(
      new URLSearchParams(location.search).get("verification_token") ?? ""
    )
      .then((response) => {
        setSuccessResponse(
          response?.data?.message ??
            "This user was successfully deleted from our system"
        );
        setLoading(false);
      })
      .catch((error) => {
        setErrorResponse(error?.data?.message ?? "Server error");
        setLoading(false);
      });
  };

  return (
    <Wrapper>
      <div className="inner-wrap">
        <div className="logo">
          <LogoFull />
        </div>
        <div className={`form ${loading ? "loading-blue" : ""}`}>
          <div className="form-innerwrap">
            <div className="title">Decline Account</div>
            <div className="sub-title">
              After confirming the decline account your account will be deleted
            </div>
            <button
              type="submit"
              className="btn-blue"
              onClick={сonfirmDeclineAccount}
            >
              Confirm Decline Account
            </button>
            {errorResponse && (
              <div className="form-error-message">{errorResponse}</div>
            )}
          </div>
        </div>
      </div>
      {successResponse && (
        <Modal
          openModal={!!successResponse}
          setOpenModal={() => setSuccessResponse("")}
          closeOutsideClick={false}
        >
          <div className="modal-content">
            <div className="title">{successResponse}</div>
          </div>
        </Modal>
      )}
    </Wrapper>
  );
};

export default DeclineAccount;
